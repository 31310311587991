import { Box, Grid } from '@mui/material'
import style from './NumericStatsCard.module.css'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Query, QueryParam } from '../../../../common/api/Query'
import { UserFormQuery } from '../../../../modules/forms/models/UserForm'
import { UserFormService } from '../../../../modules/forms/services/UserFormService'
import { USER_FORM_SERVICE_KEY } from '../../../../modules/forms'
import { getFormContainer } from '../../../../container/form-module'
import { getUserCircleContainer } from '../../../../container/user-circle-module'
import {
  IUserCircleActiveService,
  USER_CIRCLE_ACTIVE_SERVICE_KEY,
} from '../../../../modules/user-circle'
import { ReadingStatus } from '../../../../modules/forms/enums/ReadingStatus'

type NumericStatsCardProps = {
  numberPendingChats: number
  numberNewResources: number
}

const userFormService = getFormContainer().get<UserFormService>(USER_FORM_SERVICE_KEY)
const UserCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)
export const NumericStatsCard: React.FC<NumericStatsCardProps> = (props) => {
  const { t } = useTranslation()
  const activeFullUserCircle = UserCircleActiveService.getActiveFullUserCircle()
  const [pendingForms, setPendingForms] = React.useState(0)

  useEffect(() => {
    userFormService
      .getFilteredList(
        new Query({
          query: [
            new QueryParam<UserFormQuery>('userID', activeFullUserCircle?.user?.id ?? ''),
            new QueryParam<UserFormQuery>('userCircleID', activeFullUserCircle?.id ?? ''),
          ],
          sort: [{ field: 'assigned', desc: true }],
        })
      )
      .subscribe((res) => {
        let counter = 0
        res.items.forEach((form) => {
          if (form.readingStatus === ReadingStatus.Pending) {
            counter++
          }
        })
        setPendingForms(counter)
      })
  }, [])

  return (
    <Box className={style.container}>
      <Grid container spacing={2} className={style.mainGrid}>
        <Grid className={style.gridItem} item xs={3}>
          <p className={style.underLainedTitle}>{t('newForms')}</p>
          <p className={style.numberStat}>{pendingForms}</p>
          <p className={style.showmore}>{t('showDetails')} &gt;</p>
        </Grid>
        <Grid className={style.gridItem} item xs={3}>
          <p className={style.underLainedTitle}>{t('pendingChats')}</p>
          <p className={style.numberStat}>{props.numberPendingChats}</p>
          <p className={style.showmore}>{t('showDetails')} &gt;</p>
        </Grid>
        <Grid className={style.gridItem} item xs={3}>
          <p className={style.underLainedTitle}>{t('newResources')}</p>
          <p className={style.numberStat}>{props.numberNewResources}</p>
          <p className={style.showmore}>{t('showDetails')} &gt;</p>
        </Grid>
        <Grid className={style.gridItem} sx={{ borderRight: 'none !important' }} item xs={3}>
          <p className={style.underLainedTitle}>{t('Access')}</p>
          <p className={style.numberStat}>{activeFullUserCircle?.user.accesses}</p>
          <p className={style.showmore}>{t('lastUpdate')}</p>
        </Grid>
      </Grid>
    </Box>
  )
}
