import React from 'react'
import { ArticlesSearchValidate } from '../../features/article'
import { RouteProps } from '../../routes/AppRouter'
import { useLocation } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function ArticlesValidate(props: RouteProps) {
  const title = props.title ?? ''

  const location = useLocation()

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <ArticlesSearchValidate
        tagList={location.search !== '' ? location.search.slice(1).split('&') : []}
      />
    </>
  )
}
