import { useTranslation } from 'react-i18next'
import { getNotificationContainer } from '../../container/notification-module'
import { UserConfigService } from '../../modules/notifications/services/UserConfigService'
import { USER_CONFIG_SERVICE_KEY } from '../../modules/notifications'
import { getUserContainer } from '../../container/user-module'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import React, { useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { UserConfig } from '../../modules/notifications/models/UserConfig'
import { SourceType, sourceTypes } from '../../modules/notifications/enums/SourceType'
import { TransportType } from '../../common/enums/TransportType'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { useNavigate } from 'react-router-dom'
import { ROUTE_CONFIGURATION } from '../../routes/routes-constants'

const userConfigService = getNotificationContainer().get<UserConfigService>(USER_CONFIG_SERVICE_KEY)
const loggedUserService = getUserContainer().get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

export function NotificationsConfig(props: any) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const loggedUser = loggedUserService.get()

  const [userConfigs, setUserConfigs] = useState<ItemList<UserConfig>>(emptyList<UserConfig>())

  useEffect(() => {
    userConfigService.getListByUserID(loggedUser?.id ?? '').subscribe((res) => setUserConfigs(res))
  }, [loggedUser])

  const isChecked = (st: SourceType, tt: TransportType): boolean =>
    !!userConfigs.items.find((uc) => uc.sourceType === st && uc.transportType === tt)

  const handleSave = () => {
    userConfigService.setConfigs(loggedUser?.id ?? '', userConfigs.items).subscribe()
    goBack()
  }
  const goBack = () => navigate(ROUTE_CONFIGURATION)
  const handleChange = (st: SourceType, tt: TransportType) => {
    if (userConfigs.items.find((uc) => uc.sourceType === st && uc.transportType === tt)) {
      setUserConfigs({
        count: userConfigs.count,
        items: userConfigs.items.filter((uc) => uc.sourceType !== st || uc.transportType !== tt),
      })
    } else {
      setUserConfigs({
        count: userConfigs.count,
        items: [
          ...userConfigs.items,
          new UserConfig({
            userID: loggedUser?.id ?? '',
            sourceType: st,
            transportType: tt,
          }),
        ],
      })
    }
  }

  return (
    <>
      <Box bgcolor="white" m="1rem">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <h4 style={{ fontFamily: 'Poppins' }}>{t('source type')}</h4>
                </TableCell>
                <TableCell>
                  <h4 style={{ fontFamily: 'Poppins' }}>{t('email')}</h4>
                </TableCell>
                <TableCell>
                  <h4 style={{ fontFamily: 'Poppins' }}>{t('sms')}</h4>
                </TableCell>
                <TableCell>
                  <h4 style={{ fontFamily: 'Poppins' }}>{t('newsWall')}</h4>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(sourceTypes()).map((k) => {
                const st = parseInt(k) as SourceType
                return (
                  <TableRow key={k}>
                    <TableCell component="th" scope="row">
                      <span style={{ fontFamily: 'Poppins' }}>
                        {t(sourceTypes()[st].toLowerCase())}
                      </span>
                    </TableCell>
                    <TableCell scope="row">
                      <Checkbox
                        style={{ width: '25px', height: '25px' }}
                        checked={isChecked(st, TransportType.Email)}
                        onChange={() => handleChange(st, TransportType.Email)}
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <Checkbox
                        style={{ width: '25px', height: '25px' }}
                        checked={isChecked(st, TransportType.SMS)}
                        onChange={() => handleChange(st, TransportType.SMS)}
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <Checkbox
                        style={{ width: '25px', height: '25px' }}
                        checked={isChecked(st, TransportType.App)}
                        onChange={() => handleChange(st, TransportType.App)}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Box mt={3} flexDirection={'row'}>
          <AppButton
            theme={ButtonTheme.NewPrimary}
            type={'button'}
            label={t('save')}
            handler={handleSave}
          />
        </Box>
      </Box>
    </>
  )
}
