// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Table_pendingStatus__3PT3J {\n  color: rgb(142, 8, 8) !important;\n}\n\n.Table_completedStatus__nC51h {\n  color: rgb(24, 130, 24) !important;\n}\n\n.Table_permanentStatus__1rqjd {\n  color: rgb(255, 128, 0) !important;\n}\n", "",{"version":3,"sources":["webpack://src/features/form-patients/Table.module.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;AAClC;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":[".pendingStatus {\n  color: rgb(142, 8, 8) !important;\n}\n\n.completedStatus {\n  color: rgb(24, 130, 24) !important;\n}\n\n.permanentStatus {\n  color: rgb(255, 128, 0) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pendingStatus": "Table_pendingStatus__3PT3J",
	"completedStatus": "Table_completedStatus__nC51h",
	"permanentStatus": "Table_permanentStatus__1rqjd"
};
export default ___CSS_LOADER_EXPORT___;
