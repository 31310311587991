import { RouteProps } from '../../routes/AppRouter'
import { useTranslation } from 'react-i18next'
import { ActivePlanConfigTable as APT } from '../../features/sportCenter-config'
import React from 'react'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function ActivePlanConfig(props: RouteProps) {
  const title = props.title || ''
  const subtitle = props.subtitle || ''
  const { t } = useTranslation()

  return (
    <>
      <CurrentNavHeaderEsfera
        title={t(title)}
        subtitle={t('sportCentersLarge')}
        headerTitle={''}
        divider={true}
      />
      <APT />
    </>
  )
}
