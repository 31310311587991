import React, { useCallback, useEffect, useState } from 'react'
import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material'
import style from './ActivePlanCard.module.scss'
import { TypographyStyle } from './ActivePlanCardStyleMui'
import { dividerStyle } from './ActivePlanCardStyleMui'
import { useGetActiveUser } from '../../../../hooks/get-active-user/useGetActiveUser'
import { Query, QueryParam } from '../../../../common/api/Query'
import { Training } from '../../../../modules/training/models/Training'
import { getTrainingContainer } from '../../../../container/training-module'
import { TrainingService } from '../../../../modules/training/services/TrainingService'
import { TRAINING_SERVICE_KEY } from '../../../../modules/training'
import GenericChartDashboard from '../../../../components/dashboard/generic-chart-dashboard/GenericChartDashboard'
import { ChartType } from 'chart.js'
import { EffortLevelCard } from './EffortLevelCard'
import { GlobalDailyAttendanceCard } from './GlobalDailyAttendanceCard'
import { FitnessLevelCard } from './FitnessLevelCard'

type ActivePlanCardProps = {}

interface Dataset {
  label?: string
  data: number[]
  backgroundColor?: string | string[]
  borderColor?: string
}

interface GenericChartDashboardComponentProps {
  datasets: Dataset[]
  labels: string[]
  title: string
  typeChart: ChartType
  legendPosition: 'top' | 'bottom' | 'left' | 'right'
  showXAxis?: boolean
  showYAxis?: boolean
}

interface CardComponentProps {
  title: string
  percent?: string
  children: React.ReactNode
}

const trainingService = getTrainingContainer().get<TrainingService>(TRAINING_SERVICE_KEY)

export const ActivePlanCard: React.FC<ActivePlanCardProps> = () => {
  const { activeUser } = useGetActiveUser()
  const [attendanceDays, setAttendanceDays] = useState<number[]>([])
  const [intensity, setIntensity] = useState<Training[]>([])
  const [trainingPhysicalCondition, setTrainingPhysicalCondition] = useState<Training[]>([])

  const fetchTrainingData = useCallback(() => {
    const queryAttendance = new Query({
      query: [
        new QueryParam<Training>('userCircleID', activeUser.id),
        new QueryParam<Training>('finishTime', new Date().toISOString()),
      ],
    })

    trainingService.getFilteredList(queryAttendance).subscribe((data) => {
      if (!data) return
      setAttendanceDays([
        getCountAttendance(data.items, 1),
        getCountAttendance(data.items, 0),
        getCountAttendance(data.items, 2),
      ])
    })
  }, [activeUser])

  const getCountAttendance = (trainings: Training[], value: number): number => {
    let result = 0
    trainings.forEach((training) => {
      if (value === training.attendance) {
        result += 1
      }
    })
    return result
  }

  const fetchIntensityData = useCallback(() => {
    const queryIntensity = new Query({
      query: [
        new QueryParam<Training>('userCircleID', activeUser.id),
        new QueryParam<Training>('attendance', 1),
      ],
      pager: { offset: 0, limit: 6 },
      sort: [{ field: 'finishTime', desc: true }],
    })

    trainingService.getFilteredList(queryIntensity).subscribe((data) => {
      if (!data) return
      setIntensity(data.items)
    })
  }, [activeUser])

  const fetchPhysicalCondition = useCallback(() => {
    const queryFisicalCondition = new Query({
      query: [
        new QueryParam<Training>('userCircleID', activeUser.id),
        new QueryParam<Training>('attendance', 1),
      ],
      sort: [{ field: 'finishTime', desc: true }],
    })

    trainingService.getFilteredList(queryFisicalCondition).subscribe((data) => {
      if (!data) return
      setTrainingPhysicalCondition(data.items)
    })
  }, [activeUser])

  useEffect(() => {
    fetchTrainingData()
    fetchIntensityData()
    fetchPhysicalCondition()
  }, [fetchTrainingData, fetchIntensityData, fetchPhysicalCondition])

  return (
    <Grid container spacing={2}>
      <Grid display={'flex'} flexDirection={'row'} mt={4} item xs={12} sm={6} md={12}>
        <Grid item xs={12} sm={3} md={3}>
          <GlobalDailyAttendanceCard attendanceDays={attendanceDays} />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <EffortLevelCard intensity={intensity} />
        </Grid>
        <Grid item xs={12} sm={5} md={5}>
          <FitnessLevelCard training={trainingPhysicalCondition} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export const CardComponent: React.FC<CardComponentProps> = ({ percent, title, children }) => (
  <Card className={style.card}>
    <CardContent className={style.containerCard}>
      <Box className={style.rowSpaceBetween}>
        <Typography sx={TypographyStyle} variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography sx={TypographyStyle} variant="h5" gutterBottom>
          {percent}
        </Typography>
      </Box>

      <Divider sx={dividerStyle} />
      {children}
    </CardContent>
  </Card>
)

export const GenericChartDashboardComponent: React.FC<GenericChartDashboardComponentProps> = ({
  datasets,
  labels,
  title,
  typeChart,
  legendPosition,
  showXAxis,
  showYAxis,
}) => (
  <GenericChartDashboard
    datasets={datasets}
    labels={labels}
    title={title}
    typeChart={typeChart}
    legendPosition={legendPosition}
    showXAxis={showXAxis}
    showYAxis={showYAxis}
  />
)
