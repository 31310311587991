// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GenericTooltipListExpressions_genericValueTooltip__1v8G4 {\n  font-size: 0.8rem !important;\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://src/components/generic-tooltip/GenericTooltipListExpressions.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,WAAW;AACb","sourcesContent":[".genericValueTooltip {\n  font-size: 0.8rem !important;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"genericValueTooltip": "GenericTooltipListExpressions_genericValueTooltip__1v8G4"
};
export default ___CSS_LOADER_EXPORT___;
