import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import loaderStyles from '../../components/loading-spinner/LoadingSpinner.module.css'
import { Query } from '../../common/api/Query'
import { getContentContainer } from '../../container/content-module'
import { TAG_SERVICE_KEY } from '../../modules/content'
import { LoadingSpinner } from 'components/loading-spinner/LoadingSpinner'
import { TagService } from '../../modules/content/services/TagService'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import genericStyle from '../../common/utils/generic.module.css'
import searchIcon from '../../assets/resource_icons/search_icon.svg'
import styles from './Search.module.css'
import { TableGrid } from './TableGrid'
import { Header } from '../../components/header/Header'
import { TEMPLATES_PRO_TITLE } from 'routes/title-constants'
import { ROUTE_CREATE, ROUTE_TEMPLATES_FORM } from '../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import tick from '../../assets/calendar/tick.svg'
import { CircleQuery } from '../../modules/circle/models/Circle'
import { CircleDTO } from '../../modules/circle/models/CircleDTO'
import { getCircleContainer } from '../../container/circle-module'
import { CIRCLE_SERVICE_KEY, ICircleService } from '../../modules/circle'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { UserCircleService } from '../../modules/user-circle/services/UserCircleConfigService'
import { USER_CIRCLE_SERVICE_KEY } from '../../modules/user-circle'

const contentContainer = getContentContainer()
const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)
const userCircleService = getUserCircleContainer().get<UserCircleService>(USER_CIRCLE_SERVICE_KEY)
const tagsService = contentContainer.get<TagService>(TAG_SERVICE_KEY)

type SearchProps = {
  tagList: string[]
}

export function Search(props: SearchProps) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [filter, setFilter] = useState<any>({})
  const [searchText, setSearchText] = useState<string>("")
  const [filterToSend, setFilterToSend] = useState<string[]>([])
  const [circle, setCircle] = useState<CircleDTO[]>([])
  const [circles, setUserCircles] = useState<any>([])

  const navigate = useNavigate()

  useEffect(() => {
    // circleService.getFilteredList(new Query<CircleQuery>({})).subscribe((res) => {
    //   const map = new Map()
    //   if (!res) return
    //   setCircle(res.items)
    //   res.items.forEach((o: any) => map.set(o.name, o.id))
    //   let mapString:string[] = []
    //   res.items.forEach(obj => mapString.push(obj.name))
    //   setIsLoading(false)
    // })
    let circles:any[] = []
    let userCircles:CircleDTO[] = []
    let logged = JSON.parse(localStorage.getItem("logged user") || "null")
    userCircleService.getUserCirclesByUserId(logged.id).subscribe((res) => {
      if(res && (res?.length > 0)){
        res.forEach(obj => circles.push(Number(obj.circle.id)))
        res.forEach(obj => userCircles.push(obj.circle))
      }
      setIsLoading(false)
      setCircle(userCircles)
      setUserCircles(circles)
    })
  }, [])

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    let aux:string[] = []
    Object.assign(aux, filterToSend)
    setFilter({
      ...filter,
      [event.target.name]: event.target.checked,
    })
    if(filterToSend.find(obj => obj === event.target.name)){
      aux = aux.filter(obj => obj !== event.target.name)
      setFilterToSend(aux)
    }else{
      aux.push(event.target.name)
      setFilterToSend(aux)
    }
  }

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchText(e.target.value)
  }

  const handleCreate = () => {
    navigate(ROUTE_TEMPLATES_FORM + '/' + ROUTE_CREATE)
  }

  return (
    <>
      {!isLoading ? (
        <Box className={genericStyle.pageContainer}>
          <Box>
            <Header label={t('filters')} />
          </Box>

          <div className={styles.legendCheck2}>
            <span className={styles.filterCategory} style={{ textWrap: 'nowrap' }}>
              Filtrar por círculo:
            </span>
            <div className={styles.legendCheck}>
              <div className={styles.legendCheck2} style={{ flexWrap: 'wrap' }}>
                {Object.values(circle).map((k) => (
                  <label key={k.id} className={styles.labelCheck} style={{ textWrap: 'nowrap' }}>
                    <input
                      type="checkbox"
                      name={k.name}
                      className={styles.checkboxInput}
                      onChange={handleCheck}
                      checked={filter[k.name]}
                    />
                    {k.name}
                    {filter[k.name] && (
                      <img src={tick} alt="Icono de tick" className={styles.checkboxImage} />
                    )}
                  </label>
                ))}
              </div>
            </div>
          </div>

          <Box mb={3} className={styles.buttons} display="flex" justifyContent="flex-end">
            <div className={styles.button}>
              <AppButton
                theme={ButtonTheme.NewPrimary}
                type={'button'}
                label={t('newTemplate')}
                handler={handleCreate}
              />
            </div>
          </Box>

          <Box mb={3}>
            <TableGrid categoryList={filterToSend} search={searchText} circles={circles}/>
          </Box>
        </Box>
      ) : (
        <LoadingSpinner className={loaderStyles.loadingSpinner} />
      )}
    </>
  )
}
