import React from 'react'
import { TutorialsSearch } from 'features/tutorial'
import { RouteProps } from '../../routes/AppRouter'
import { useLocation } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function Tutorials(props: RouteProps) {
  const title = props.title ?? ''

  const location = useLocation()

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <TutorialsSearch
        tagList={location.search !== '' ? location.search.slice(1).split('&') : []}
      />
    </>
  )
}
