import React from 'react'

interface ProgressSemiCircleProps {
  percentage: number
}

const ProgressSemiCircle: React.FC<ProgressSemiCircleProps> = ({ percentage }) => {
  const radius = 80
  const circumference = 2 * Math.PI * radius
  const strokeDashoffset = circumference - (percentage / 2 / 100) * circumference

  return (
    <svg width="240" height="120">
      <circle stroke="#E3F5FF" fill="transparent" strokeWidth="40" r={radius} cx="120" cy="120" />
      <circle
        stroke="#f18b24"
        fill="transparent"
        strokeWidth="40"
        strokeDasharray={`${circumference}`}
        strokeDashoffset={strokeDashoffset}
        r={radius}
        cx="120"
        cy="120"
        transform={`rotate(180 120 120)`}
      />
      <circle
        stroke="#ffffff"
        fill="transparent"
        strokeWidth="40"
        strokeDasharray="2 4"
        r={radius}
        cx="120"
        cy="120"
        transform={`rotate(180 120 120)`}
      />
      <text x="120" y="120" textAnchor="middle" fontSize="20" fontWeight="bold">
        {`${percentage}%`}
      </text>
    </svg>
  )
}
export default ProgressSemiCircle
