import { Container } from '../../common/container/Container'

export type ReportContainerConfig = {
  moduleFullUrl: string
}

export type ReportProps = {
  parentContainer: Container
  config: ReportContainerConfig
}

export const REPORT_MODULE = Symbol('REPORT_MODULE')

export const REPORT_API_KEY = Symbol('REPORT_API_KEY')

export const REPORT_SERVICE_KEY = Symbol('REPORT_SERVICE_KEY')
