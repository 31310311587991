import GenericChartDashboard from '../../../../components/dashboard/generic-chart-dashboard/GenericChartDashboard'
import { ChartEnum } from '../../../../components/dashboard/generic-chart-dashboard/ChartEnum'
import { useEffect, useState } from 'react'
import { getActivePlanContainer } from '../../../../container/active-plan-module'
import { ActivePlanService } from '../../../../modules/active-plan/services/ActivePlanService'
import { ACTIVE_PLAN_SERVICE_KEY } from '../../../../modules/active-plan'
import { NumberPatientsByBuilding } from '../../../../modules/active-plan/models/NumberPatientsByBuilding'

const activePlanService = getActivePlanContainer().get<ActivePlanService>(ACTIVE_PLAN_SERVICE_KEY)

const SportCenterCard = () => {
  const [numberOfPatients, setNumberOfPatients] = useState<NumberPatientsByBuilding[]>([])
  const backgroundColors = ['#0063A6', '#F18B24', '#B6B5B5', '#F1DD25', '#68B3E0', '#4CBF0D']

  useEffect(() => {
    activePlanService.getNumberOfPatientsByBuilding().subscribe((data) => {
      if (!data) return
      setNumberOfPatients(data)
    })
  }, [])

  const datasets = numberOfPatients.map((item, index) => ({
    data: [item.numberOfPatients],
    backgroundColor: backgroundColors[index],
    label: item.buildingName,
  }))

  return (
    <GenericChartDashboard
      datasets={datasets}
      labels={['']}
      title={''}
      typeChart={ChartEnum.Bar}
      legendPosition={'bottom'}
    />
  )
}
export default SportCenterCard
