import { useTranslation } from 'react-i18next'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { SourceType } from '../../../modules/notifications/enums/SourceType'
import { Notification } from '../../../modules/notifications/models/Notification'
import { DeliveryStatus } from '../../../modules/notifications/enums/DeliveryStatus'
import { PriorityLevel } from '../../../modules/notifications/enums/PriorityLevel'
import { Message } from 'm../../modules/notifications/models/Message'
import { TransportType } from '../../../common/enums/TransportType'
import { Box, Checkbox, Grid, Modal, TextField } from '@mui/material'
import { CustomModal } from '../../../components/modal/CustomModal'
import style from './CustomModal.module.css'
import { Header } from '../../../components/header/Header'
import close from '../../../assets/table_icons/ico-eliminar.svg'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import styles from '../symptoms/Editor.module.css'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers'
import Button from '@mui/material/Button'
import { ReportPreview } from './ReportPreview'

import InformePlanActiva from "./informePlanActiva.json"
import { getReportContainer } from '../../../container/report-module'
import { ReportService } from '../../../modules/report/services/ReportService'
import { REPORT_SERVICE_KEY } from '../../../modules/report'

export type AddReportResp = {
  id: string,
  createdByUserID: string,
  userCircleID: string,
  createdAt: string,
  startDate: string,
  finishDate: string,
  generalInfoID: string,
  specificInfoID: string,
  rcvValoration: string,
  sessionsAttendance: string
}

export type ReportProps = {
  generalData: boolean,
  attendanceMonth: boolean,
  sessionsAttendance: boolean,
  SF12: boolean,
  specificData: boolean,
  intensityMonth: boolean,
  efdComments: boolean,
  RCV: boolean,
  antroData: boolean,
  totalSessions: boolean,
  condition: boolean
}

function EmptyReportProps() {
  return {
    generalData: true,
    specificData: true,
    sessionsAttendance: false,
    attendanceMonth: false,
    SF12: false,
    intensityMonth: false,
    efdComments: false,
    RCV: false,
    antroData: false,
    totalSessions: false,
    condition: false
  }
}

const reportContainer = getReportContainer();
const reportService = reportContainer.get<ReportService>(REPORT_SERVICE_KEY)

export function ReportModal({handleClose, closeModal, label, updateReports, reportsFromView, reportId}: any) {
  const { t } = useTranslation()
  const [reportProps, setReportProps] = useState<ReportProps>(EmptyReportProps())
  const [previewReportModal, setPreviewReportModal] = useState<boolean>(false)
  const [showGenerateModal, setShowGenerateModal] = useState<boolean>(false)
  const [toDate, setToDate] = useState<Date>(new Date())
  const [fromDate, setFromDate] = useState<Date>(
    new Date(new Date().setDate(new Date().getDate() - 7))
  )
  const [dataToPrint, setDataToPrint] = useState({})
  const [selectedReportId, setSelectedReportId] = useState("")
  const [dataReport, setDataReport] = useState({})

  useEffect(() => {
    setDataReport(InformePlanActiva)
  }, [])

  useEffect(() => {
    setReportProps(reportsFromView)
    setDataToPrint(reportsFromView)
  }, [reportsFromView])

  const handleSave = (event: any) => {
    if(localStorage.getItem("selected user circle")) {
      let userCircle = JSON.parse(localStorage.getItem("selected user circle") || "null")
      let logged = JSON.parse(localStorage.getItem("logged user") || "null")

      // if(reportId){
      //   reportService.update({
      //     id: reportId,
      //     createdByUserID: logged.id,
      //     userCircleID: userCircle.id,
      //     createdAt: new Date(),
      //     startDate: fromDate,
      //     finishDate: toDate,
      //     generalInformation: reportProps.generalData,
      //     anthropometricData: reportProps.antroData,
      //     avgAttendanceMonth: reportProps.attendanceMonth,
      //     avgIntensityMonth: reportProps.intensityMonth,
      //     efdComments: reportProps.efdComments,
      //     healthFormulary: reportProps.SF12,
      //     physicalCondition: reportProps.condition,
      //     rcvValoration: reportProps.RCV,
      //     sessionsAttendance: reportProps.sessionsAttendance,
      //     specificInformation: reportProps.specificData
      //   }).subscribe(resp => {
      //     updateReports()
      //   })
      // }else{
        reportService.add({
          createdByUserID: logged.id,
          userCircleID: userCircle.id,
          createdAt: new Date(),
          startDate: fromDate,
          finishDate: toDate,
          generalInformation: reportProps.generalData,
          anthropometricData: reportProps.antroData,
          avgAttendanceMonth: reportProps.attendanceMonth,
          avgIntensityMonth: reportProps.intensityMonth,
          efdComments: reportProps.efdComments,
          healthFormulary: reportProps.SF12,
          physicalCondition: reportProps.condition,
          rcvValoration: reportProps.RCV,
          sessionsAttendance: reportProps.sessionsAttendance,
          specificInformation: reportProps.specificData
        }).subscribe(resp => {
          updateReports()
        })
      // }

    }
    setShowGenerateModal(true)
    handleClose()
  }

  const handleFromDateChange = (date: any) => {
    if (date) {
      setFromDate(date.toDate())
    }
  }

  const handleToDateChange = (date: any) => {
    if (date) {
      setToDate(date.toDate())
    }
  }

  const handlePreview = () => {
    if(localStorage.getItem("selected user circle")) {
      let userCircle = JSON.parse(localStorage.getItem("selected user circle") || "null")
      let logged = JSON.parse(localStorage.getItem("logged user") || "null")

      reportService.add({
        createdByUserID: logged.id,
        userCircleID: userCircle.id,
        createdAt: new Date(),
        startDate: fromDate,
        finishDate: toDate,
        generalInformation: reportProps.generalData,
        anthropometricData: reportProps.antroData,
        avgAttendanceMonth: reportProps.attendanceMonth,
        avgIntensityMonth: reportProps.intensityMonth,
        efdComments: reportProps.efdComments,
        healthFormulary: reportProps.SF12,
        physicalCondition: reportProps.condition,
        rcvValoration: reportProps.RCV,
        sessionsAttendance: reportProps.sessionsAttendance,
        specificInformation: reportProps.specificData
      }).subscribe(resp => {
        // @ts-ignore
        setSelectedReportId(resp?.id)
        setPreviewReportModal(true)
      })
    }
    // setPreviewReportModal(true)
  }

  const handleClosePreview = () => {
    // setReportProps(EmptyReportProps())
    setPreviewReportModal(false)
    reportService.delete(selectedReportId)
  }
  const handleCloseGenerate = () => {
    setShowGenerateModal(false)
  }

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    const value = reportProps[e.target.name]
    setReportProps(Object.assign({ ...reportProps }, { [e.target.name]: !value }))

    // @ts-ignore
    if(!value){
      // @ts-ignore
      // setDataToPrint(Object.assign({ ...dataToPrint }, { [e.target.name]: dataReport[e.target.name] }))
      setDataToPrint(Object.assign({ ...dataToPrint }, { [e.target.name]: !value }))
    }else{
      let aux= {}
      let aux1 = Object.assign(aux, dataToPrint)
      // @ts-ignore
      delete aux1[e.target.name]
      setDataToPrint(aux1)
    }
  }

  return (
    <>
      <Modal open={previewReportModal} onClose={handleClosePreview}>
        <ReportPreview
          reportData={dataReport}
          handleClose={handleClosePreview}
          dataToPrint={dataToPrint}
          reportId={selectedReportId}
        />
      </Modal>
      <Modal open={showGenerateModal} onClose={handleCloseGenerate}>
        <div className={style.agoraContainer}>
          <Box className={style.containerInside}
               style={{ height: '500px', overflow: 'auto', justifyContent: 'space-between' }}>
            <Box style={{ overflow: 'auto' }}>
              <Header label={t('generateReport')} icon={close} onClick={handleClose} />
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              {!label ?
                <h1 style={{ color: '#f18b24' }}>
                  EL INFORME HA SIDO GUARDADO
                </h1> :
                <h1 style={{ color: '#f18b24' }}>
                  EL INFORME HA SIDO EDITADO Y GUARDADO
                </h1>
              }
              <h4>
                ¿Quiere descargar el informe?
              </h4>
            </Box>
            <Box display="flex" flexDirection="row" gap="20px" alignItems="center" justifyContent="center">
            <Box style={{ paddingTop: '20px' }}>
                <AppButton
                  theme={ButtonTheme.NewPrimary}
                  type={'submit'}
                  label={t('no')}
                  handler={handleCloseGenerate}
                />
              </Box>
              <Box style={{ paddingTop: '20px' }}>
                <AppButton
                  theme={ButtonTheme.NewPrimary}
                  type={'submit'}
                  label={t('yes')}
                  handler={() => {}}
                />
              </Box>
            </Box>
          </Box>
        </div>
      </Modal>

      <div className={style.agoraContainer}>
        <Box className={style.containerInside}>
          <Header label={t('customizableReports')} icon={close} onClick={handleClose} />
          <Grid container>
            <Grid container>
              <div style={{ padding: '20px 0 10px 0' }}>
                <p style={{ color: '#0063a6', fontWeight: 'bold' }}>{label ? t(label) : t('generateNewReport')}</p>
              </div>
            </Grid>
            <Grid container display={'flex'} flexDirection={'row'} alignItems={'center'}
                  borderBottom={'2px solid #68b3e0'} padding={'15px 0 15px 0'}>
              <Grid item xs={3}>
                <div>
                  <p style={{ fontWeight: 'bold' }}>{t('reportPeriod')}</p>
                </div>
              </Grid>
              <Grid item xs={3} display={'flex'} flexDirection={'row'}>
                <p>{t('from')}:</p>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                  <DatePicker
                    key={'date'}
                    disabled={label === 'editReport'}
                    PopperProps={{
                      sx: {
                        '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                          fontFamily: 'Poppins',
                          textTransform: 'capitalize',
                        },
                        '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                          fontFamily: 'Poppins',
                        },
                        '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                          fontFamily: 'Poppins',
                        },
                        '& .css-3eghsz-PrivatePickersYear-button': {
                          fontFamily: 'Poppins',
                        },
                      },
                    }}

                    renderInput={(props) => (
                      <TextField
                        // style={{ width: '20%' }}
                        sx={{
                          '.css-1ptx2yq-MuiInputBase-root-MuiInput-root': { paddingRight: '17px' },
                          '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                            fontFamily: 'Poppins',
                          },
                        }}
                        variant={'outlined'}
                        // helperText={props.error && DateTimePickerError}
                        {...props}
                      />
                    )}
                    inputFormat="DD/MM/YYYY"
                    value={fromDate}
                    onChange={handleFromDateChange}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={3} display={'flex'} flexDirection={'row'}>
              <p>{t('to')}:</p>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                  <DatePicker
                    key={'date'}
                    disabled={label==="editReport"}
                    PopperProps={{
                      sx: {
                        '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                          fontFamily: 'Poppins',
                          textTransform: 'capitalize',
                        },
                        '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                          fontFamily: 'Poppins',
                        },
                        '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                          fontFamily: 'Poppins',
                        },
                        '& .css-3eghsz-PrivatePickersYear-button': {
                          fontFamily: 'Poppins',
                        },
                      },
                    }}
                    renderInput={(props) => (
                      <TextField
                        className={"dateField"}
                        // style={{ width: '20%' }}
                        sx={{
                          '.css-1ptx2yq-MuiInputBase-root-MuiInput-root': { paddingRight: '17px' },
                          '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                            fontFamily: 'Poppins',
                          },
                        }}
                        variant={'outlined'}
                        // helperText={props.error && DateTimePickerError}
                        {...props}
                      />
                    )}
                    inputFormat="DD/MM/YYYY"
                    value={toDate}
                    onChange={handleToDateChange}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={3} display={"flex"} justifyContent={"end"}>
                <Button
                  onClick={handlePreview}
                  style={{
                    backgroundColor: '#68b3e0',
                    marginLeft:'20px',
                    height: "53px",
                    float: 'right',
                    color: 'white',
                  }}
                >
                  Previsualizar informe
                </Button>
              </Grid>

            </Grid>

            <Grid container padding={'10px'} spacing={1}
                  style={{ border: '1px solid rgb(104 213 231)', marginTop: '15px', borderRadius: '17px' }}>
              <Grid item xs={4} display={'flex'} flexDirection={'row'}>
                <Checkbox
                  name={'generalData'}
                  disabled
                  checked={reportProps.generalData}
                  onChange={(e) => handleCheckbox(e)}
                />
                <p className={style.label}>{t('generalData')}</p>
              </Grid>

              <Grid item xs={4} display={'flex'}>
                <Checkbox
                  name={'attendanceMonth'}
                  checked={reportProps.attendanceMonth}
                  onChange={(e) => handleCheckbox(e)}
                />
                <p className={style.label}>{t('attendanceMonth')}</p>
              </Grid>

              <Grid item xs={4} display={'flex'}>
                <Checkbox
                  name={'SF12'}
                  checked={reportProps.SF12}
                  onChange={(e) => handleCheckbox(e)}
                />
                <p className={style.label}>{t('SF12')}</p>
              </Grid>

              <Grid item xs={4} display={'flex'}>
                <Checkbox
                  name={'specificData'}
                  disabled
                  checked={reportProps.specificData}
                  onChange={(e) => handleCheckbox(e)}
                />
                <p className={style.label}>{t('specificData')}</p>
              </Grid>

              <Grid item xs={4} display={'flex'}>
                <Checkbox
                  name={'intensityMonth'}
                  checked={reportProps.intensityMonth}
                  onChange={(e) => handleCheckbox(e)}
                />
                <p className={style.label}>{t('intensityMonth')}</p>
              </Grid>

              <Grid item xs={4} display={'flex'}>
                <Checkbox
                  name={'efdComments'}
                  checked={reportProps.efdComments}
                  onChange={(e) => handleCheckbox(e)}
                />
                <p className={style.label}>{t('efdComments')}</p>
              </Grid>

              <Grid item xs={4} display={'flex'}>
                <Checkbox
                  name={"RCV"}
                  checked={reportProps.RCV}
                  onChange={(e) => handleCheckbox(e)}
                />
                <p className={style.label}>{t('RCV')}</p>
              </Grid>

              <Grid item xs={8} display={'flex'}>
                <Checkbox
                  name={"antroData"}
                  checked={reportProps.antroData}
                  onChange={(e) => handleCheckbox(e)}
                />
                <p className={style.label}>{t('antroData')}</p>
              </Grid>

              <Grid item xs={4} display={'flex'}>
                <Checkbox
                  name={"sessionsAttendance"}
                  checked={reportProps.sessionsAttendance}
                  onChange={(e) => handleCheckbox(e)}
                />
                <p className={style.label}>{t('totalSessions')}</p>
              </Grid>

              <Grid item xs={4} display={'flex'}>
                <Checkbox
                  name={"condition"}
                  checked={reportProps.condition}
                  onChange={(e) => handleCheckbox(e)}
                />
                <p className={style.label}>{t('condition')}</p>
              </Grid>

            </Grid>
          </Grid>


          <Box display="flex" justifyContent="center">
            <Box style={{ paddingTop: '20px' }}>
              <AppButton
                theme={ButtonTheme.NewPrimary}
                type={'submit'}
                label={t('save')}
                handler={handleSave}
              />
            </Box>
          </Box>
        </Box>
      </div>
    </>
  )
}
