import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Modal,
  Typography,
} from '@mui/material'

import React, { useEffect, useMemo, useState } from 'react'
import { AppTable, Field } from '../../../components/table'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { Actions, Pager } from '../../../components/table_type/types'
import { useTranslation } from 'react-i18next'
import { Landmark } from '../../../modules/patient-data/models/Landmark'
import { CustomModal } from '../../../components/modal/CustomModal'
import { Query, QueryParam } from '../../../common/api/Query'
import styles from '../../generic/GenericTable.module.css'
import tableStyles from '../../form-patients/Table.module.css'
import changeStateIcon from '../../../assets/table_icons/ico-edit.svg'
import iconEditHover from '../../../assets/table_icons/ico-edit-hover.svg'
import { LANDMARK_SERVICE_KEY } from '../../../modules/patient-data'
import { LandMarkService } from '../../../modules/patient-data/services/LandmarkService'
import { fromModel } from '../../../modules/patient-data/models/LandmarkDTO'
import { getPatientDataContainer } from '../../../container/patient-data-module'
import downBlanco from '../../../assets/table_icons/down-blanco.svg'
import { useIsRelated } from 'hooks/relatedUsers/getRelatedUsers'
import { useGetActiveUser } from '../../../hooks/get-active-user/useGetActiveUser'

const landmarkContainer = getPatientDataContainer()
const landmarkService = landmarkContainer.get<LandMarkService>(LANDMARK_SERVICE_KEY)

export const LandmarkTable = () => {
  const { t } = useTranslation()
  const { activeUser } = useGetActiveUser()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [landmarks, setLandmarks] = useState<ItemList<Landmark>>(emptyList<Landmark>())
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pager, setPager] = useState<Pager>()
  const [landmarksPerPage, setLandmarksPerPage] = useState<number>(10)
  const [currentLandmark, setCurrentLandmark] = useState<Landmark>()
  const [openModal, setOpenModal] = useState<boolean>(false)

  useEffect(() => {
    if (!isLoading || !activeUser?.id) {
      return
    }
    landmarkService
      .getFilteredList(
        new Query({
          query: [new QueryParam('userID', activeUser.user.id)],
          sort: [{ field: 'date', desc: false }],
        })
      )
      .subscribe((res) => {
        const newList = emptyList<Landmark>()
        newList.items = res.items
        newList.count = res.count
        setLandmarks(newList)
        setCount(newList.count)
        setIsLoading(false)
      })
  }, [isLoading])

  useEffect(() => {
    setIsLoading(!isLoading)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: landmarksPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, landmarksPerPage])

  const handlePaginationChange = (e: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(e.target.value)) {
      setLandmarksPerPage(10)
      return
    }
    setLandmarksPerPage(Number.parseInt(e.target.value))
  }

  const changeState = (e: Landmark) => {
    if (e.state) return
    if (e) setCurrentLandmark(e)
    setOpenModal(true)
  }

  const handleCloseModal = () => setOpenModal(false)

  const handleSave = () => {
    if (currentLandmark) {
      const newLandmark = Object.assign(
        { ...fromModel(currentLandmark) },
        { state: true, endDate: new Date() }
      )
      landmarkService.update(newLandmark).subscribe((_) => setIsLoading(!isLoading))
    }
    setCurrentLandmark(undefined)
    setOpenModal(false)
  }

  const fields: Field<Landmark>[] = [
    {
      name: 'title',
      label: t('landmark'),
    },
    /* {
      name: 'date',
      label: t('date'),
      renderFunc: (f, i) => new Date(i.date).toLocaleDateString(),
    }, */
    {
      name: 'endDate',
      label: t('completedDate'),
      renderFunc: (f, i) => {
        const dateEnd = new Date(i.endDate)
        if (dateEnd.getFullYear() === 0) {
          return ''
        } else {
          return dateEnd.toLocaleDateString()
        }
      },
    },
    {
      name: 'state',
      label: t('state'),
      styleFunc: (f, i) => t(i.state ? tableStyles.completedStatus : tableStyles.permanentStatus),
      renderFunc: (f, i) => t(i.state ? 'completed' : 'pending'),
    },
  ]

  const ic = (l: Landmark): boolean => l.state

  const actions: Actions<Landmark> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: changeState,
        icon: iconEditHover,
        label: 'changeState',
        hidden: useIsRelated() ? (l) => ic(l) : (l) => true,
      },
      {
        handler: changeState,
        icon: changeStateIcon,
        label: 'changeState',
        hidden: useIsRelated() ? (l) => !ic(l) : (l) => true,
      },
    ],
  }

  // filtro por la fecha
  const item: any[] = useMemo(() => {
    let m = 0
    let y = 0
    let n = 0
    const arr: any[] = []
    let xarr: any[] = []
    let sarr: any[] = []
    landmarks.items.forEach((element) => {
      n++
      if (
        element.date.getMonth() === landmarks.items[0].date.getMonth() + m &&
        element.date.getFullYear() === landmarks.items[0].date.getFullYear() + y
      ) {
        xarr.push(element)
        if (landmarks.items.length === n) {
          sarr.push(m + 12 * y)
          sarr.push(xarr)
          arr.push(sarr)
        }
      } else {
        sarr.push(m + 12 * y)
        sarr.push(xarr)
        arr.push(sarr)
        xarr = []
        sarr = []
        m = element.date.getMonth() - landmarks.items[0].date.getMonth()
        y = element.date.getFullYear() - landmarks.items[0].date.getFullYear()
        xarr.push(element)
      }
    })
    return arr
  }, [landmarks])

  /*  const useStyles = makeStyles({
    accordion: {
      '& ': {
        backgroundColor: '#68b3e0',
        borderRadius: '10px',
        marginBottom: '10px',
        padding: '0px 10px 0px',
        marginLeft: '0px',
        marginRight: '0px',
        height: '20px',
        width: '100%',
      },
      '& .MuiTypography-root': {
        color: 'white',
        fontFamily: 'Poppins',
      },
      '& .MuiAccordionDetails-root': {
        padding: '0px 0px 0px',
        margin: '0px',
      },
    },
  })

  const classes = useStyles() */
  // solo se expande un accordeon
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.ChangeEvent<any>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Box>
      {item.map((element) => (
        <Accordion
          key={element.id}
          sx={{
            '& ': {
              marginLeft: 4,
              marginRight: 4,
            },
          }}
          elevation={0}
          expanded={expanded === element[0]}
          onChange={handleChange(element[0])}
        >
          <AccordionSummary
            sx={{
              color: 'white',
              '& ': {
                backgroundColor: '#68b3e0',
                borderRadius: '10px',
                marginBottom: '10px',
                padding: '0px 10px 0px',
                marginLeft: '0px',
                marginRight: '0px',
                height: '20px',
                width: '100%',
                color: 'white',
              },
            }}
            // className={classes.accordion}
            expandIcon={<img src={downBlanco} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography style={{ color: 'white' }}>{element[0]} meses</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: '0px 0px 0px',
              margin: '0px',
            }}
          >
            <Box className={styles.accordionDetails}>
              {landmarks.items.length > 0 ? (
                <AppTable items={element[1]} rowKeyField="id" fields={fields} actions={actions} />
              ) : (
                <h1 key="no-landmarks" className={styles.noElements}>
                  {t('noLandmarks')}
                </h1>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}

      <Modal open={openModal} onClose={handleCloseModal}>
        <CustomModal
          handleClose={handleCloseModal}
          handleSave={handleSave}
          title={t('changeMilestone')}
          warningText={t('irreversibleLandmarkAction')}
        />
      </Modal>
    </Box>
  )
}
