import JsPDF from 'jspdf'
import { renderToStaticMarkup, renderToString } from 'react-dom/server'

export async function PrintPDFComponent(component: any) {
  convertToBuffer(component).then(resp => {
    // mergePdfs(resp).then(resp => {
    //@ts-ignore
      const url = window.URL.createObjectURL(new Blob([resp], { type: 'application/pdf' }))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', "Plan Activa - Mario García" + '.pdf')
      document.body.appendChild(link)
      link.click()
    // })
  })
}


function convertToBuffer(component: any) {
  return new Promise(resolve => {
    let div = document.createElement('div')
    let obj = component
    const doc = new JsPDF('p', 'px', 'a1')
    let idRandom = 'printPDF-' + Math.floor((Math.random() * 9999))
    let queryRandom = '#' + idRandom
    div.id = idRandom
    div.style.display = 'hidden'
    div.style.width = '900px'
    div.style.height = '600px'
    div.innerHTML = renderToStaticMarkup(obj)
    const promise = new Promise((resolve) => {
      resolve(renderToString(obj))
    })


    promise
      .then(async (resp) => {
        // if (resp === "") {
        //  throw new Error("Empty object");
        // }
        //@ts-ignore
        div.innerHTML = resp
        document.body.appendChild(div)
        if (div.innerHTML !== '') {
          //@ts-ignore
          await doc.html(document.querySelector(queryRandom), { margin: 30 })
            .then(() => {
              for (let j = 1; j <= doc.getNumberOfPages(); j++) {
                // doc.setPage(j)
                // doc.text(branchName, 1720, 1235)
                // doc.text('page ' + Number(i + j) + '/' + data.length, 1720, 1250)
              }
              // return doc.save(script.name + ".pdf");
              return doc.output('arraybuffer')
            })
            .then((obj) => {
              // arrayBuffer.push(obj)
              document.body.removeChild(div)
              // if (arrayBuffer.length === count) {
                // debugger;
                return resolve(obj)
              // }
            })
        }
      })
      .catch((error) => {
        alert(error)
      })
  })
}

