export type DashBoardProps = {
  labels: string[]
  data: number[]
}

export class DashBoard {
  private _labels: string[]
  private _data: number[]

  constructor(props: DashBoardProps) {
    this._labels = props.labels
    this._data = props.data
  }

  get labels(): string[] {
    return this._labels
  }

  get data(): number[] {
    return this._data
  }
}
