// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoadingSpinner_loadingSpinner__1Fe72 {\n  position: absolute;\n  right: 40%;\n  bottom: 30%;\n}\n\n.LoadingSpinner_loadingResourceSpinner__1nltF {\n  position: absolute;\n  right: 60%;\n  bottom: 50%;\n}\n\n.LoadingSpinner_loadingSpinnerCalendar__3TGxP {\n  position: absolute;\n  right: 35%;\n  bottom: 35%;\n}\n", "",{"version":3,"sources":["webpack://src/components/loading-spinner/LoadingSpinner.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;AACb","sourcesContent":[".loadingSpinner {\n  position: absolute;\n  right: 40%;\n  bottom: 30%;\n}\n\n.loadingResourceSpinner {\n  position: absolute;\n  right: 60%;\n  bottom: 50%;\n}\n\n.loadingSpinnerCalendar {\n  position: absolute;\n  right: 35%;\n  bottom: 35%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingSpinner": "LoadingSpinner_loadingSpinner__1Fe72",
	"loadingResourceSpinner": "LoadingSpinner_loadingResourceSpinner__1nltF",
	"loadingSpinnerCalendar": "LoadingSpinner_loadingSpinnerCalendar__3TGxP"
};
export default ___CSS_LOADER_EXPORT___;
