import { Message } from './Message'
import { ConsultaUser } from './ConsultaUser'
import { Consulta } from './Consulta'
import { Reaction } from './Reaction'
import { ReadingStatus } from '../enums/ReadingStatus'
import { MessageUser } from './MessageUser'
import { v4 as uuidv4 } from 'uuid'

export interface ConsultaDTO {
  id: string | undefined
  name: string
  description: string
  users: ConsultaUserDTO[]
  messages: MessageDTO[]
  unreadMessages: number
  closed: boolean
  circleID: string | undefined
  userCircleID: string | undefined
  pathologyID: string
  consultaType: string | undefined
}

export function emptyConsultaDTO(user: string, circle: string, pathology: string) {
  const id = uuidv4()
  return {
    id,
    name: '',
    description: '',
    users: [emptyConsultaUserDTO(id, user)],
    messages: [],
    unreadMessages: 0,
    closed: false,
    circleID: circle,
    pathologyID: pathology,
    consultaType: '',
  }
}

export interface ConsultaUserDTO {
  consultaID: string
  isAdmin: boolean
  userID: string
}

export function emptyConsultaUserDTO(consulta: string, user: string) {
  return {
    consultaID: consulta,
    isAdmin: true,
    userID: user,
  }
}

export interface MessageDTO {
  id: string | undefined
  text: string
  files: string[] | undefined
  author: string
  consultaID: string
  rootID: string
  parentID: string
  message2Users: Message2UsersDTO[]
  reactions: ReactionDTO[] | undefined
  createdAt: Date
  isPinned: boolean
}

export interface Message2UsersDTO {
  userID: string
  messageID: string
  readingStatus: ReadingStatus
}

export interface ReactionDTO {
  userID: string
  messageID: string
  emoji: string
}

export function fromModel(c: Consulta): ConsultaDTO {
  return {
    id: c.id,
    name: c.name,
    description: c.description,
    users: c.users.map((c) => consultaUserFromModel(c)),
    messages: c.messages.map((m) => messageFromModel(m)),
    unreadMessages: c.unreadMessages,
    closed: c.closed,
    circleID: c.circleID,
    userCircleID: c.userCircleID,
    pathologyID: c.pathologyID,
    consultaType: c.consultaType,
  }
}

export function consultaUserFromModel(c: ConsultaUser): ConsultaUserDTO {
  return {
    consultaID: c.consultaID,
    isAdmin: c.isAdmin,
    userID: c.userID,
  }
}

export function messageFromModel(m: Message): MessageDTO {
  return {
    id: m.id,
    text: m.text,
    files: m.files,
    author: m.author,
    consultaID: m.consultaID,
    rootID: m.rootID,
    parentID: m.parentID,
    message2Users: m.message2Users,
    reactions: m.reactions?.map((r) => reactionFromModel(r)),
    createdAt: new Date(m.createdAt),
    isPinned: m.isPinned,
  }
}

export function reactionFromModel(r: Reaction): ReactionDTO {
  return {
    emoji: r.emoji,
    messageID: r.messageID,
    userID: r.userID,
  }
}

export function toModel(d: ConsultaDTO): Consulta {
  return new Consulta({
    description: d.description,
    id: d.id,
    closed: d.closed,
    messages: d.messages.map((m) => messageToModel(m)) || [],
    name: d.name,
    unreadMessages: d.unreadMessages,
    users: d.users.map((u) => consultaUserToModel(u)),
    circleID: d.circleID,
    userCircleID: d.userCircleID,
    pathologyID: d.pathologyID,
    consultaType: d.consultaType,
  })
}

export function consultaUserToModel(c: ConsultaUserDTO): ConsultaUser {
  return new ConsultaUser(c)
}

export function messageToModel(m: MessageDTO): Message {
  return new Message({
    author: m.author,
    consultaID: m.consultaID,
    createdAt: new Date(m.createdAt),
    files: m.files ?? [],
    id: m.id,
    isPinned: m.isPinned,
    message2Users: m.message2Users?.map((m) => message2UsersToModel(m)) || [],
    parentID: m.parentID,
    reactions: m.reactions?.map((r) => reactionToModel(r)) ?? [],
    rootID: m.rootID,
    text: m.text,
  })
}

export function reactionToModel(r: ReactionDTO): Reaction {
  return new Reaction(r)
}

export function message2UsersToModel(m: Message2UsersDTO): MessageUser {
  return new MessageUser(m)
}
