import { Query, QueryParam } from '../../common/api/Query'
import { Notification as N } from '../../modules/notifications/models/Notification'
import { TransportType } from '../../common/enums/TransportType'
import { useEffect, useState } from 'react'
import { getNotificationContainer } from '../../container/notification-module'
import { NotificationService } from '../../modules/notifications/services/NotificationService'
import { MESSAGES_SERVICE_KEY, NOTIFICATION_SERVICE_KEY } from '../../modules/notifications'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { Message } from '../../modules/notifications/models/Message'
import { forkJoin, Observable } from 'rxjs'
import { MessageService } from '../../modules/notifications/services/MessageService'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { User } from '../../modules/users/models/User'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { IUserCircleActiveService, USER_CIRCLE_ACTIVE_SERVICE_KEY } from '../../modules/user-circle'

const notificationContainer = getNotificationContainer()
const notificationService = notificationContainer.get<NotificationService>(NOTIFICATION_SERVICE_KEY)
const userCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)
const messageService = notificationContainer.get<MessageService>(MESSAGES_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export const useGetNotifications = (
  scope: boolean | User | undefined,
  notificationsPerPager?: number
) => {
  const loggedUser = loggedUserService.get()
  const circle = userCircleActiveService.getActiveUser()
  const [notificationNumber, setNotificationNumber] = useState<number>(0)
  const [notifications, setNotifications] = useState<N[]>([])
  const [notificationMessages, setNotificationMessages] = useState<Map<string, Message>>(new Map())

  const getMessages = (ids: string[]): Observable<Message[]> =>
    forkJoin(ids.map((id) => messageService.getByID(id)) as unknown) as Observable<Message[]>

  useEffect(() => {
    if (!loggedUser || !circle) {
      return
    }

    notificationService
      .getFilteredList(
        new Query({
          pager: { offset: 0, limit: notificationsPerPager ?? 50 },
          query: [
            new QueryParam<N>('recipientID', loggedUser.id),
            new QueryParam<N>('userCircleID', circle.id),
            new QueryParam<N>('transportType', TransportType.App),
          ],
          sort: [{ field: 'sendAt', desc: true }],
        })
      )
      .subscribe((res) => {
        setNotificationNumber(res.count)
        setNotifications(res.items.sort((a, b) => b.sendAt.getTime() - a.sendAt.getTime()))
        getMessages(res.items.map((n) => n.message)).subscribe((ml) => {
          const newMap = new Map()
          ml.filter((m) => m).forEach((m) => {
            const n = res.items.find((n) => n.message === m.id)
            if (n) {
              newMap.set(n.id, m)
            }
          })
          setNotificationMessages(new Map(newMap))
        })
      })
  }, [scope])

  return { notifications, notificationMessages, notificationNumber }
}
