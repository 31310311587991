import i18n from '../../../i18n'

export enum AttendanceType {
  absence = 0,
  assistance = 1,
}

export const attendanceType = (): Record<AttendanceType, string> => ({
  [AttendanceType.assistance]: i18n.t('assistance'),
  [AttendanceType.absence]: i18n.t('absence'),
})
