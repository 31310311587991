import React, { ChangeEvent, useEffect, useState } from 'react'
import { Box, Fab } from '@mui/material'
import { TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import style from './Searcher.module.css'
import { useIsRelated } from 'hooks/relatedUsers/getRelatedUsers'
import AddIcon from '@mui/icons-material/Add'
import { ItemList } from '../../common/models/ItemList'
import { SegmentedNotification } from '../../modules/notifications/models/SegmentedNotification'

type SearcherProps = {
  handler: any,
  data?: ItemList<SegmentedNotification>
}

export function Searcher(props: SearcherProps): JSX.Element {
  const { t } = useTranslation()

  const [search, setSearch] = useState<string>('')
  /* const [descToAsc, setDescToAsc] = useState<boolean>(false) */

  const handlerChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setSearch(event.target.value)

  useEffect(() => {
    let result = props.data?.items.filter(item => item.name.toLowerCase().includes(search.toLowerCase()))
    props.handler(result)
  }, [search])

  /* const handleFilterChange = () => {
    props.reverse()
    setDescToAsc(!descToAsc)
  } */

  return (
    <>
      <Box className={style.inputBox}>
        <TextField
          sx={{
            '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': { fontFamily: 'Poppins' },
            zIndex: 0,
          }}
          className={style.inputSearcher}
          label={'Buscar...'}
          variant={'outlined'}
          type="text"
          value={search}
          onChange={handlerChange}
        />
      </Box>
    </>
  )
}

/* function containerClassName(descToAsc: boolean): string {
  if (descToAsc) {
    return style.toAsc + ' ' + style.filterListIcon
  }
  return style.toDesc
} */
