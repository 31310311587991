import { RouteProps } from '../../routes/AppRouter'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'
import { DashboardCaseManager as DCaseManager } from '../../features/dashboard/case-manager'
export function DashboardCaseManager(props: RouteProps) {
  const title = props.title ?? ''
  const subtitle = props.circleSubtitle ?? ''
  const { t } = useTranslation()

  return (
    <>
      <CurrentNavHeaderEsfera
        title={t(title)}
        circleSubtitle={t(subtitle)}
        headerTitle={''}
        divider={true}
      />
      <DCaseManager />
    </>
  )
}
