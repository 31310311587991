import { UserPendingDTO } from './UserPendingDTO'

export class UserPending {
  private _email: string
  private _token: string
  private _roleId: string
  private _circleId: string

  constructor(p: UserPendingDTO) {
    this._email = p.email
    this._token = p.token
    this._roleId = p.roleId
    this._circleId = p.circleId
  }

  get email(): string {
    return this._email
  }

  get token(): string {
    return this._token
  }

  get roleId(): string {
    return this._roleId
  }

  get circleId(): string {
    return this._circleId
  }

  set email(value: string) {
    this._email = value
  }

  set token(value: string) {
    this._token = value
  }

  set roleId(value: string) {
    this._roleId = value
  }

  set circleId(value: string) {
    this._circleId = value
  }
}
