import { Box, Button, Modal, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Actions, Field } from '../table_type/types'
import { makeStyles } from '@mui/styles'

import { useTranslation } from 'react-i18next'
import Draggable from 'react-draggable'
import draggableIcon from '../../assets/table_icons/barra-desplegable.svg'
import { reduceString } from '../../common/utils/strings'

export type DraggableModalProps<T> = {
  readonly open: boolean
  readonly fields: Field<T>[]
  readonly items: T[]
  readonly rowKeyField: keyof T
  readonly actions?: Actions<T>
  readonly handleClose: () => void
  readonly item: T
  readonly fieldsInDraggable?: boolean
}

export function DraggableModal<T extends Record<string, any>>(props: DraggableModalProps<T>) {
  const { t } = useTranslation()
  const [deltaPosition, setDeltaPosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    setDeltaPosition({ x: 0, y: 0 })
  }, [props.handleClose])

  const handleDrag = (e: any, ui: any) => {
    const { x, y } = deltaPosition

    setDeltaPosition({
      x: x + ui.deltaX,
      y: y + ui.deltaY,
    })
  }

  const dragging = (e: any) => {
    e.preventDefault()
    if (deltaPosition.y < 10 || deltaPosition.y < 0) {
      return
    }
    return props.handleClose()
  }

  const useStyles = makeStyles(() => ({
    iconModal: {
      cursor: 'pointer',
      marginTop: 'auto',
      marginBottom: '10px',
      width: '35px',
      marginRight: '15px',
    },
    listItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '10px',
    },
    listItemText: {
      fontSize: global.innerWidth < 400 ? '14px' : '18px',
      marginBottom: '8px',
      cursor: 'pointer',
    },
    modal: {
      position: 'absolute' as 'absolute',
      top: 'auto',
      bottom: '0',
      left: 'auto',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      minHeight: '200px',
      backgroundColor: '#ffff',
      borderTopRightRadius: 30,
      borderTopLeftRadius: 30,
      padding: 4,
    },
    boxContentModal: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '20px',
      marginLeft: '20px',
      marginRight: '20px',
      marginBottom: '10px',
    },
    modalTitle: {
      marginTop: '10px',
      marginLeft: '30px',
      fontSize: global.innerWidth < 400 ? '24px' : '30px',
      fontWeight: 'bold',
    },
    cellName: {
      backgroundColor: '#ffffff',
      justifyContent: 'flex-start',
      fontWeight: 'bold',
      borderBottom: '2px solid #cfd0d0',
      minHeight: '45px',
      marginBottom: '8px',
      fontSize: '24px',
    },
    buttonDraggable: {
      textAlign: 'center',
      cursor: 'move',
    },
    iconDraggable: {
      width: '65px',
    },
  }))

  const classes = useStyles()

  const arrayOfExcludedWords: string[] = ['name', 'related', 'date', 'symptomID', 'title', 'color']

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Draggable
        axis="y"
        bounds={{ top: 0 }}
        positionOffset={{ x: '0', y: '0' }}
        scale={1}
        handle=".handle"
        onDrag={(e, ui) => {
          handleDrag(e, ui)
        }}
        onStop={dragging}
      >
        <Box className={classes.modal}>
          <Box onTouchEnd={props.handleClose} className={classes.buttonDraggable}>
            <Button className="handle">
              <img className={classes.iconDraggable} src={draggableIcon} />
            </Button>
          </Box>
          {Object.values(props.fields).map((field) => {
            return (
              (field.name === 'name' || field.name === 'symptomID' || field.name === 'title') && (
                <Box className={classes.cellName}>
                  <Typography className={classes.modalTitle}>
                    {field.renderFunc
                      ? field.renderFunc(field, props.item)
                      : props.item[field.name].toString()}
                  </Typography>
                </Box>
              )
            )
          })}
          <Box className={classes.boxContentModal}>
            {props.fieldsInDraggable &&
              Object.values(props.fields).map((field) => (
                <Box key={props.item[props.rowKeyField]} className={classes.listItem}>
                  {!arrayOfExcludedWords.includes(field.name.toString()) && (
                    <>
                      <p className={classes.listItemText}>
                        {field.renderFunc && field.renderFunc(field, props.item) === ''
                          ? ''
                          : field.label}
                      </p>
                      <p className={classes.listItemText}>
                        {field.name === 'eventCategory' ? (
                          <p style={{ padding: 0, margin: 0, fontSize: '12px' }}>
                            {field.renderFunc
                              ? field.renderFunc(field, props.item)
                              : props.item[field.name].toString()}
                          </p>
                        ) : field.renderFunc ? (
                          reduceString(field.renderFunc(field, props.item).toString(), 25)
                        ) : (
                          reduceString(props.item[field.name].toString(), 25)
                        )}
                      </p>
                    </>
                  )}
                </Box>
              ))}
            {props.actions &&
              props.actions.items?.length > 0 &&
              props.actions?.items.map((a, i) => {
                if (a.hidden && a.hidden(props.item)) {
                  return <></>
                }
                return (
                  <Box key={i} className={classes.listItem} onClick={() => a.handler(props.item)}>
                    <p className={classes.listItemText}>{t(`${a.label}`)}</p>
                    <img
                      src={a.icon}
                      className={classes.iconModal}
                      key={props.item[props.rowKeyField]}
                    />
                  </Box>
                )
              })}
          </Box>
        </Box>
      </Draggable>
    </Modal>
  )
}
