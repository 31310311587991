import React, { ReactNode, useState } from 'react'
import { Grid, Divider, Box } from '@mui/material'
import { DashboardSectionCard } from '../../../components/section-card/DashboardSectionCard'
import { dividerStyle } from '../patient/active-plan-card/ActivePlanCardStyleMui'
import PatientNumberCard from './patient-number-card/PatientNumberCard'
import SportCenterCard from './sport-center-card/SportCenterCard'
import PatientStatisicsCard from './patient-statisics-card/PatientStatisicsCard'
import PatientGroupCard from './patient-group-card/PatientGroupCard'
import PieCircleStateCard from './pie-circle-state-card/CircleStateCard'
import { ActivePlanService } from '../../../modules/active-plan/services/ActivePlanService'
import { ACTIVE_PLAN_SERVICE_KEY } from '../../../modules/active-plan'
import { getActivePlanContainer } from '../../../container/active-plan-module'
import seeIcon from '../../../assets/dashboard/seeIcon.svg'
import { StatusCircleModal } from './pie-circle-state-card/StatusCircleModal'
import { ChatsCard } from '../patient/chats-card/ChatsCard'
import { EducationalContentCard } from '../patient/educational-content-card/EducationalContentCard'
import { ActiveQueriesCard } from '../patient/active-query-card/ActiveQueriesCard'
import { ACTIVE_PLAN_CIRCLE_ID } from '../../../common/const'
import { CircleDTO } from '../../../modules/circle/models/CircleDTO'
import { ProfessionalCirclesCard } from './professional-circles-card/ProfessionalCirclesCard'
import { NotificationsCard } from '../patient/notification-card/NotificationsCard'
import { NumericStatsCard } from '../patient/numeric-stats-card/NumericStatsCard'

const activePlanService = getActivePlanContainer().get<ActivePlanService>(ACTIVE_PLAN_SERVICE_KEY)

interface CardSectionProps {
  label: string
  children: ReactNode
  showIcon?: boolean
  onClick?: () => void
}

const CardSection: React.FC<CardSectionProps> = ({ label, children, showIcon, onClick }) => (
  <Grid mr={2} item xs={12} sm={6} md={6}>
    <DashboardSectionCard
      onClick={onClick}
      icon={showIcon ? seeIcon : ''}
      label={label}
      borderBottom={'2px solid #68B3E0'}
    >
      {children}
    </DashboardSectionCard>
  </Grid>
)
export const DashboardProfessional = () => {
  const [openStatusCircleModal, setOpenStatusCircleModal] = useState(false)
  const [selectedCircle, setSelectedCircle] = useState<CircleDTO>()
  const [numberPendingChats, setNumberPendingChats] = useState<number>(0)
  const [numberNewResources, setNumberNewResources] = useState<number>(0)
  console.log(numberPendingChats)
  const handlerOpenStatusCircleModal = () => {
    setOpenStatusCircleModal(!openStatusCircleModal)
  }
  return (
    <Box>
      <DashboardSectionCard label={'generalSection'}>
        <Grid width={'100%'} container spacing={2}>
          <Grid mt={4} item xs={12} sm={6} md={4}>
            {
              <NotificationsCard
                ProfessionalNotifications={true}
                SetNumberNewResources={setNumberNewResources}
              />
            }
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <Grid display={'flex'} xs={12} sm={6} md={12}>
              <Grid mt={4} mr={2} item xs={12} sm={6} md={6}>
                <ProfessionalCirclesCard
                  selectedUserCircle={selectedCircle}
                  setSelectedUserCircle={setSelectedCircle}
                />
              </Grid>
              <Grid mt={4} item xs={12} sm={6} md={6}>
                <ChatsCard setNumberPendingChats={setNumberPendingChats} />
              </Grid>
            </Grid>
            <Grid display={'flex'} xs={12} sm={6} md={12}>
              <Grid mt={4} mr={2} item xs={12} sm={6} md={6}>
                <EducationalContentCard />
              </Grid>
              <Grid mt={4} item xs={12} sm={6} md={6}>
                <ActiveQueriesCard />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DashboardSectionCard>
      {/*   {selectedCircle?.id !== ACTIVE_PLAN_CIRCLE_ID && (
        <DashboardSectionCard label={'stats'} showMore={true}>
          <ProfessionalGeneralStats activePlanService={activePlanService} />
        </DashboardSectionCard>
      )} */}
      {selectedCircle?.id === ACTIVE_PLAN_CIRCLE_ID && (
        <DashboardSectionCard label={'activePlan'}>
          <Grid container spacing={2}>
            <Grid display={'flex'} flexDirection={'row'} mt={4} item xs={12} sm={6} md={12}>
              <CardSection label={'numberOfPatients'}>
                <SportCenterCard />
              </CardSection>
              <CardSection label={'statisticsPatients'}>
                <Divider sx={dividerStyle} />
                <PatientNumberCard />
              </CardSection>
            </Grid>
            <Grid display={'flex'} flexDirection={'row'} mt={4} item xs={12} sm={6} md={12}>
              <CardSection label={'numberOfPatients'}>
                <PatientStatisicsCard activePlanService={activePlanService} />
              </CardSection>
              <CardSection
                onClick={handlerOpenStatusCircleModal}
                showIcon={true}
                label={'circleStatus'}
              >
                <PieCircleStateCard activePlanService={activePlanService} />
              </CardSection>
              <CardSection label={'numberOfPatients'}>
                <PatientGroupCard />
              </CardSection>
            </Grid>
          </Grid>
        </DashboardSectionCard>
      )}
      {openStatusCircleModal && (
        <StatusCircleModal open={openStatusCircleModal} onClose={handlerOpenStatusCircleModal} />
      )}

      <NumericStatsCard
        numberPendingChats={numberPendingChats}
        numberNewResources={numberNewResources}
      ></NumericStatsCard>
    </Box>
  )
}
