// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_divHeader__2RP9N {\n  background-color: rgb(212, 228, 239);\n  padding: 7px;\n}\n\n.Header_divHeaderLeft__4CDe1 > * {\n  margin-left: 1rem;\n}\n\n.Header_contactFullName__3bz-p {\n  margin-right: 1rem;\n}\n\n.Header_tooltipConversationUsers__2W2-L {\n  margin-left: 1rem;\n}\n\n.Header_conversationLeft__3eRzT {\n  flex-flow: column;\n  align-items: end;\n}\n\n.Header_conversationLeft__3eRzT p {\n  padding-top: 0;\n  margin-top: 0;\n  margin-bottom: 0;\n}\n\n.Header_conversationOptions__1S7RT {\n  justify-content: flex-end;\n  padding-bottom: 0;\n  width: 100%;\n}\n\n.Header_conversationOptions__1S7RT > div {\n  width: 2rem;\n  display: flex;\n  justify-content: center;\n}\n\n.Header_MuiAvatar-fallback__jMJeM {\n  background-color: #68b3e0;\n}\n", "",{"version":3,"sources":["webpack://src/features/messenger/Header.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".divHeader {\n  background-color: rgb(212, 228, 239);\n  padding: 7px;\n}\n\n.divHeaderLeft > * {\n  margin-left: 1rem;\n}\n\n.contactFullName {\n  margin-right: 1rem;\n}\n\n.tooltipConversationUsers {\n  margin-left: 1rem;\n}\n\n.conversationLeft {\n  flex-flow: column;\n  align-items: end;\n}\n\n.conversationLeft p {\n  padding-top: 0;\n  margin-top: 0;\n  margin-bottom: 0;\n}\n\n.conversationOptions {\n  justify-content: flex-end;\n  padding-bottom: 0;\n  width: 100%;\n}\n\n.conversationOptions > div {\n  width: 2rem;\n  display: flex;\n  justify-content: center;\n}\n\n.MuiAvatar-fallback {\n  background-color: #68b3e0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divHeader": "Header_divHeader__2RP9N",
	"divHeaderLeft": "Header_divHeaderLeft__4CDe1",
	"contactFullName": "Header_contactFullName__3bz-p",
	"tooltipConversationUsers": "Header_tooltipConversationUsers__2W2-L",
	"conversationLeft": "Header_conversationLeft__3eRzT",
	"conversationOptions": "Header_conversationOptions__1S7RT",
	"MuiAvatar-fallback": "Header_MuiAvatar-fallback__jMJeM"
};
export default ___CSS_LOADER_EXPORT___;
