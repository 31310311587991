import { SourceType } from '../enums/SourceType'
import { Notification } from './Notification'
import { Message } from './Message'

export interface MessageDTO {
  id: string | undefined
  title: string
  description: string
  message: string
  sourceType: SourceType
  url: string
  notifications: Notification[]
  lastSendAt?: Date
}

export function fromModel(m: Message): MessageDTO {
  return {
    id: m.id,
    title: m.title,
    description: m.description,
    message: m.message,
    sourceType: m.sourceType,
    url: m.url,
    notifications: m.notifications,
    lastSendAt: m.lastSendAt,
  }
}

export function toModel(d: MessageDTO): Message {
  return new Message(d)
}
