// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppTable_searchContainer__H76e5 {\n  display: flex;\n  justify-content: flex-end;\n  flex-wrap: wrap;\n}\n\n/* Estilos para pantallas más pequeñas */\n@media (max-width: 1300px) {\n  .AppTable_responsiveTable__17XwC {\n    width: 100%;\n    overflow-x: auto;\n  }\n  .AppTable_responsiveTable__17XwC th,\n  .AppTable_responsiveTable__17XwC td {\n    white-space: nowrap; /* Evita que el texto se ajuste automáticamente */\n  }\n  /* Ajusta los estilos de las filas y celdas según sea necesario */\n  .AppTable_responsiveTable__17XwC th {\n    /* Estilos para encabezados en pantallas pequeñas */\n  }\n  .AppTable_responsiveTable__17XwC td {\n    /* Estilos para celdas en pantallas pequeñas */\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/table_AP/AppTable.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,eAAe;AACjB;;AAEA,wCAAwC;AACxC;EACE;IACE,WAAW;IACX,gBAAgB;EAClB;EACA;;IAEE,mBAAmB,EAAE,iDAAiD;EACxE;EACA,iEAAiE;EACjE;IACE,mDAAmD;EACrD;EACA;IACE,8CAA8C;EAChD;AACF","sourcesContent":[".searchContainer {\n  display: flex;\n  justify-content: flex-end;\n  flex-wrap: wrap;\n}\n\n/* Estilos para pantallas más pequeñas */\n@media (max-width: 1300px) {\n  .responsiveTable {\n    width: 100%;\n    overflow-x: auto;\n  }\n  .responsiveTable th,\n  .responsiveTable td {\n    white-space: nowrap; /* Evita que el texto se ajuste automáticamente */\n  }\n  /* Ajusta los estilos de las filas y celdas según sea necesario */\n  .responsiveTable th {\n    /* Estilos para encabezados en pantallas pequeñas */\n  }\n  .responsiveTable td {\n    /* Estilos para celdas en pantallas pequeñas */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchContainer": "AppTable_searchContainer__H76e5",
	"responsiveTable": "AppTable_responsiveTable__17XwC"
};
export default ___CSS_LOADER_EXPORT___;
