import { SegmentedMessage, SegmentedNotification } from '../models/SegmentedNotification'
import { Container, IInit } from '../../../common/container/Container'
import { NotificationContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { prepareURL } from '../../../common/api/http-helpers'
import { Query } from '../../../common/api/Query'
import { catchError, map } from 'rxjs/operators'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { DeliveryStatus } from '../enums/DeliveryStatus'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import {
  SegmentedNotificationDTO,
  toModel,
  fromModel,
  SegmentedMessageDTO,
  toModelMessage, fromModelMessage,
} from '../models/SegmentedNotificationDTO'

export interface ISegmentedNotificationApi extends IInit {
  getByID(id: string): Observable<SegmentedNotification | undefined>

  getFilteredItems(q: Query<SegmentedNotification>): Observable<SegmentedNotification[]>

  add(e: SegmentedNotification): Observable<SegmentedNotification | undefined>

  update(e: SegmentedNotification): Observable<SegmentedNotification | undefined>

  addNotification(e: SegmentedMessage): Observable<SegmentedMessage | undefined>

  // addNotification(e: SegmentedNotification): Observable<SegmentedNotification | undefined>
  getMessagesById(id: string): Observable<ItemList<SegmentedMessage>>

  getAll(): Observable<ItemList<SegmentedNotification>>

  delete(id: string | undefined): Observable<boolean>

  getFilteredList(q: Query<SegmentedNotification>): Observable<ItemList<SegmentedNotification>>

}
const apiURL = process.env.REACT_APP_BACKEND_URL
export class SegmentedNotificationApi implements ISegmentedNotificationApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = apiURL + '/segmented-notifications'
  }

  add(e: SegmentedNotification): Observable<SegmentedNotification | undefined> {
    return this._httpClient.post<SegmentedNotification>({ url: this._url+"/group", body: fromModel(e) }).pipe(
      map<SegmentedNotificationDTO, SegmentedNotification>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string | undefined): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/group/${id}`}).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  // getByID(id: string): Observable<SegmentedNotification | undefined> {
  //   return this._httpClient.get<SegmentedNotification>({ url: `${this._url}/group/${id}` }).pipe(
  //     map<SegmentedNotificationDTO, SegmentedNotification>((d) => toModel(d)),
  //     catchError((err) => {
  //       this._statusService.sendStatus({ variant: 'error', error: err })
  //       return of(undefined)
  //     })
  //   )
  // }
  getByID(id: string): Observable<SegmentedNotification | undefined> {
    return this._httpClient.get<SegmentedNotification>({ url: `${this._url}/${id}` }).pipe(
      map<SegmentedNotificationDTO, SegmentedNotification>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getMessagesById(id: string): Observable<ItemList<SegmentedMessage>> {
    return this._httpClient.get<ItemList<SegmentedMessage>>({ url: `${this._url}/message/group/${id}` }).pipe(
      map<ItemList<SegmentedMessageDTO>, ItemList<SegmentedMessage>>((dto) => {
        const itemList = emptyList<SegmentedMessage>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModelMessage(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<SegmentedMessage>())
      })
    )
  }

  getAll():Observable<ItemList<SegmentedNotification>> {
    return this._httpClient
      .get<ItemList<SegmentedNotification>>({
        url: prepareURL(this._url)+"/",
        // url: prepareURL(this._url)+"/group",
      })
      .pipe(
        map<ItemList<SegmentedNotificationDTO>, ItemList<SegmentedNotification>>((dto) => {
          const itemList = emptyList<SegmentedNotification>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<SegmentedNotification>())
        })
      )
  }

  getFilteredItems(q: Query<SegmentedNotification>): Observable<SegmentedNotification[]> {
    return this._httpClient.get<SegmentedNotification[]>({ url: prepareURL(this._url, q) }).pipe(
      map<SegmentedNotificationDTO[], SegmentedNotification[]>((dto) => dto.map((d) => toModel(d))),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of([])
      })
    )
  }

  getFilteredList(q: Query<SegmentedNotification>): Observable<ItemList<SegmentedNotification>> {
    return this._httpClient
      .get<ItemList<SegmentedNotification>>({
        url: prepareURL(this._url, q),
      })
      .pipe(
        map<ItemList<SegmentedNotificationDTO>, ItemList<SegmentedNotification>>((dto) => {
          const itemList = emptyList<SegmentedNotification>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<SegmentedNotification>())
        })
      )
  }

  update(e: SegmentedNotification): Observable<SegmentedNotification | undefined> {
    return this._httpClient.put<SegmentedNotification>({ url: this._url+"/group", body: fromModel(e) }).pipe(
      map<SegmentedNotificationDTO, SegmentedNotification>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  addNotification(e: SegmentedMessage): Observable<SegmentedMessage | undefined> {
    return this._httpClient.post<SegmentedMessage>({ url: this._url+"/message", body: fromModelMessage(e) }).pipe(
      map<SegmentedMessageDTO, SegmentedMessage>((d) => toModelMessage(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
  // addNotification(e: SegmentedNotification): Observable<SegmentedNotification | undefined> {
  //   return this._httpClient.post<SegmentedNotification>({ url: this._url+"/group/notification", body: fromModel(e) }).pipe(
  //     map<SegmentedNotificationDTO, SegmentedNotification>((d) => toModel(d)),
  //     catchError((err) => {
  //       this._statusService.sendStatus({ variant: 'error', error: err })
  //       return of(undefined)
  //     })
  //   )
  // }
}
