export type { IArticleService } from './services/ArticleService'
export type { ITagService } from './services/TagService'
export type { ILikesService } from './services/LikesService'
export {
  CONTENT_MODULE,
  ARTICLE_API_KEY,
  TAG_API_KEY,
  LIKES_API_KEY,
  ARTICLE_SERVICE_KEY,
  TAG_SERVICE_KEY,
  LIKES_SERVICE_KEY,
} from './container'
