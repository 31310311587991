import {
  Box,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import style from './AppUserTable.module.css'
import { Searcher } from '../../../components/table/Searcher'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import patient from '../../../assets/role_icons/ico-rol-paciente.svg'
import professionalSMS from '../../../assets/role_icons/ico-rol-profesional.svg'
import family from '../../../assets/role_icons/ico-rol-familiar.svg'
import externProfessional from '../../../assets/role_icons/ico-rol-externo.svg'
import career from '../../../assets/role_icons/ico-rol-cuidador.svg'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import { UserPending } from '../../../modules/users/models/UserPending'
import { Participant } from '../Table'
import { Action, Actions, Field, Search, Sort } from '../../../components/table_type/types'
import { getUserContainer } from '../../../container/user-module'
import { RolesService } from '../../../modules/users/services/RolesServices'
import { IUserService, ROLES_SERVICE_KEY, USER_SERVICE_KEY } from '../../../modules/users'
import { enqueueSnackbar } from 'notistack'

export type UsersTableProps<T, Q> = {
  readonly titleActions: string
  readonly titleNewItems: string
  readonly addButtonLabel: string
  readonly removeButtonLabel: string
  readonly titleCaption: string
  readonly items: Participant[]
  readonly newItems?: UserPending[]
  readonly fields: Field<T>[]
  readonly sort?: Sort<T>
  readonly actions?: Actions<T>
  readonly search?: Search<Q>
  readonly autocompleteItems?: string[]
  readonly autocompleteLabel?: string
  readonly autocompleteAction?: Action
  readonly isPreventDefault?: boolean
  handlerAdd: (e?: any) => void
  hasLinkPermission: boolean
}

const roleService = getUserContainer().get<RolesService>(ROLES_SERVICE_KEY)
const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)

export function AppUsersTable<T extends Record<string, any>, Q extends Record<string, any>>(
  props: UsersTableProps<T, Q>
) {
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pending, setPending] = useState<Boolean>()

  const [user, setUser] = useState<Participant>()
  const [selectedPendingUser, setSelectedPendingUser] = useState<UserPending>()
  const [pendingUsers, setPendingUsers] = useState<UserPending[]>(props.newItems ?? [])
  const [pendingIcons, setPendingIcons] = useState<(string | undefined)[]>([])
  const [rowsPending, setRowsPending] = useState<JSX.Element[]>([])
  const changeUser = (u: Participant) => {
    setPending(false)
    setSelectedPendingUser(undefined)
    setUser(u)
  }

  const useStyles = () => ({
    table: {
      borderCollapse: 'separate',
      borderSpacing: '0 1rem',
    },
    row: {
      boxShadow: '4px 4px 10px rgba(0.4, 0, 0, 0.1)',
    },
    headCell: {
      marginLeft: '10px !important',
      backgroundColor: '#FFFFFF',
      color: '#515151',
      border: 'none !important',
      fontFamily: 'Montserrat-SemiBold, sans-serif !important',
      fontStyle: 'normal !important',
      fontSize: '16px',
      lineHeight: '24px !important',
      padding: 0,
      textAlign: 'center',
    },

    firtsCell: {
      border: 'none',
    },

    firtsBodyCell: {
      maginTop: '1rem',
      marginLeft: '10px !important',
      backgroundColor: '#FFFFFF',
      color: '#515151',
      borderLeft: '1px solid',
      borderBottom: '1px solid',
      borderTop: '1px solid',
      fontFamily: 'Montserrat-SemiBold, sans-serif !important',
      fontStyle: 'normal !important',
      fontSize: '16px',
      lineHeight: '24px !important',
      padding: '1.5rem 0',
      textAlign: 'center',
      borderTopLeftRadius: '20px',
      borderBottomLeftRadius: '20px',
      borderColor: '#68B3E0',
    },
    middleBodyCell: {
      maginTop: '1rem',
      marginLeft: '10px !important',
      backgroundColor: '#FFFFFF',
      color: '#515151',
      borderBottom: '1px solid',
      borderTop: '1px solid',
      fontFamily: 'Montserrat-SemiBold, sans-serif !important',
      fontStyle: 'normal !important',
      fontSize: '16px',
      lineHeight: '24px !important',
      padding: '1.5rem 0',
      textAlign: 'center',
      borderColor: '#68B3E0',
    },

    lastBodyCell: {
      maginTop: '1rem',
      marginLeft: '10px !important',
      backgroundColor: '#FFFFFF',
      color: '#515151',
      borderRight: '1px solid',
      borderBottom: '1px solid',
      borderTop: '1px solid',
      fontFamily: 'Montserrat-SemiBold, sans-serif !important',
      fontStyle: 'normal !important',
      fontSize: '16px',
      lineHeight: '24px !important',
      padding: '1.5rem 0',
      textAlign: 'center',
      borderTopRightRadius: '20px',
      borderBottomRightRadius: '20px',
      borderColor: '#68B3E0',
    },
  })

  const classes = useStyles()

  const setImgRole = (role: string | undefined) => {
    if (role === undefined) {
      return
    }

    let imgRole: string = 'patient'

    switch (role) {
      case (role = 'patient'):
        imgRole = patient
        break
      case (role = 'family/Tutor'):
        imgRole = family
        break

      case (role = 'professionalSMS'):
        imgRole = professionalSMS
        break

      case (role = 'carer/Teacher'):
        imgRole = career
        break

      case (role = 'externProfessional'):
        imgRole = externProfessional
        break
    }

    return imgRole
  }

  const headCells = Object.values(props.fields).map((f) => (
    <TableCell sx={classes.headCell} key={(f.name as string) + uuidv4()}>
      {f.label}
    </TableCell>
  ))

  const headCells2 = (
    <>
      <TableCell sx={classes.headCell}></TableCell>
      <TableCell sx={classes.headCell} key={'email'}>
        {t('email')}
      </TableCell>
      <TableCell sx={classes.headCell} key={'role'}>
        {t('role')}
      </TableCell>
    </>
  )

  const rows = props.items.map((item, index) => {
    const imgrole = setImgRole(item._role)

    const hasLinkPermission = props.hasLinkPermission
    return (
      <TableRow sx={classes.row} key={index}>
        <TableCell sx={classes.firtsBodyCell}>
          {hasLinkPermission && (
            <Radio
              checked={user === item}
              onClick={() => {
                changeUser(item)
              }}
            />
          )}
        </TableCell>
        <TableCell sx={classes.middleBodyCell}>
          {item.name} {item.lastname}
        </TableCell>
        <TableCell sx={classes.middleBodyCell}>
          <img src={imgrole} alt={item._role} />
        </TableCell>
        <TableCell sx={classes.lastBodyCell}></TableCell>
      </TableRow>
    )
  })

  useEffect(() => {
    setPendingIcons([])
    if (props.newItems) {
      // Mapear los nuevos elementos y suscribirse a las promesas
      const promises = pendingUsers.map(async (item, index) => {
        return await roleService
          .getByID(item.roleId)
          .toPromise()
          .then((role) => {
            return setImgRole(role?.name)
          })
      })

      // Esperar a que todas las promesas se completen
      Promise.all(promises).then((newRows) => {
        // Actualizar el estado con todas las filas completadas
        setPendingIcons(newRows ?? [])
      })
    }
  }, [pendingUsers])

  useEffect(() => {
    const pending = pendingUsers.map((item, index) => {
      if (pendingIcons?.length !== pendingUsers?.length) {
        return <></>
      }
      return (
        <TableRow sx={classes.row} key={index}>
          <TableCell sx={classes.firtsBodyCell}>
            <Radio
              onClick={() => {
                setSelectedPendingUser(item)
                setUser(undefined)
              }}
              checked={item.email === selectedPendingUser?.email}
            />
          </TableCell>
          <TableCell sx={classes.middleBodyCell}>{item.email}</TableCell>
          <TableCell sx={classes.lastBodyCell}>
            <img src={pendingIcons[index]} alt={'familiarIcon'} />
          </TableCell>
        </TableRow>
      )
    })
    setRowsPending(pending ?? [])
  }, [pendingIcons, selectedPendingUser])

  const handlerDelete = () => {
    if (selectedPendingUser) {
      setPendingUsers((prev) => prev.filter((item) => item.email !== selectedPendingUser.email))
      userService.deleteUserPending(selectedPendingUser.email)
    } else if (user) {
      enqueueSnackbar(t('cantDeleteExistingParticipants'), { variant: 'error' })
    }
  }
  return (
    <>
      <Box className={style.containerTableUsers}>
        <Box className={style.containerActions}>
          <p className={style.title}>{props.titleActions}</p>
          <Box className={style.containerSearch}>
            {props.search && (
              <Searcher
                search={props.search}
                autocompleteAction={props.autocompleteAction}
                autocompleteItems={props.autocompleteItems}
                autocompleteLabel={props.autocompleteLabel}
                isPreventDefault={props.isPreventDefault}
              />
            )}
          </Box>

          {props.hasLinkPermission && (
            <Box className={style.containerAction}>
              <AppButton
                theme={ButtonTheme.addParticipant}
                type={'button'}
                label={props.addButtonLabel}
                handler={props.handlerAdd}
              />

              <AppButton
                theme={ButtonTheme.RemoveGroup}
                type={'button'}
                label={props.removeButtonLabel}
                handler={handlerDelete}
              />
            </Box>
          )}
        </Box>

        <Box className={style.containerParticipants}>
          <TableContainer>
            <Table sx={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell sx={classes.firtsCell}></TableCell>
                  {headCells}
                </TableRow>
              </TableHead>

              <TableBody>{rows}</TableBody>
            </Table>
          </TableContainer>
          <Box className={style.containerPendings}>
            {props.hasLinkPermission && pendingUsers && pendingUsers.length > 0 && (
              <>
                <p className={style.title}>{props.titleNewItems}</p>
                <TableContainer>
                  <Table sx={classes.table}>
                    <TableHead>
                      <TableRow>{headCells2}</TableRow>
                    </TableHead>

                    <TableBody>{rowsPending}</TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Box>
          <Box className={style.roleContainer}>
            <Box>
              <p>{props.titleCaption}:</p>
            </Box>
            <Box className={style.role}>
              <img src={professionalSMS} alt={t('professionalSMS')} />
              {t('professionalSMS')}
            </Box>
            <Box className={style.role}>
              <img src={patient} alt={t('patient')} />
              {t('patient')}
            </Box>
            <Box className={style.role}>
              <img src={career} alt={t('carer/Teacher')} />
              {t('carer/Teacher')}
            </Box>
            <Box className={style.role}>
              <img src={family} alt={t('family/Tutor')} />
              {t('family/Tutor')}
            </Box>
            <Box className={style.role}>
              <img src={externProfessional} alt={t('externProfessional')} />
              {t('externProfessional')}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
