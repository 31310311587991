// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConversationEditor_newConversationContainer__15ztp {\n  flex-direction: column;\n  min-height: 300px;\n  width: 700px;\n  display: flex;\n  background-color: var(--color-white);\n  padding: 1.5rem;\n}\n\n.ConversationEditor_label__3LCHw {\n  width: 25%;\n  font-weight: bold;\n}\n\n.ConversationEditor_roleIcon__36Ucj {\n  padding-right: 10px;\n  width: 25px;\n}\n\n.ConversationEditor_chip__1d9yw {\n  margin: 3px 5px;\n}\n", "",{"version":3,"sources":["webpack://src/features/messenger/ConversationEditor.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,oCAAoC;EACpC,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".newConversationContainer {\n  flex-direction: column;\n  min-height: 300px;\n  width: 700px;\n  display: flex;\n  background-color: var(--color-white);\n  padding: 1.5rem;\n}\n\n.label {\n  width: 25%;\n  font-weight: bold;\n}\n\n.roleIcon {\n  padding-right: 10px;\n  width: 25px;\n}\n\n.chip {\n  margin: 3px 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newConversationContainer": "ConversationEditor_newConversationContainer__15ztp",
	"label": "ConversationEditor_label__3LCHw",
	"roleIcon": "ConversationEditor_roleIcon__36Ucj",
	"chip": "ConversationEditor_chip__1d9yw"
};
export default ___CSS_LOADER_EXPORT___;
