import JsPDF from 'jspdf'
import { renderToStaticMarkup, renderToString } from 'react-dom/server'

export async function PrintPDFComponent(component: any) {
  convertToBuffer(component).then(resp => {
    // mergePdfs(resp).then(resp => {
    //@ts-ignore
      const url = window.URL.createObjectURL(new Blob([resp], { type: 'application/pdf' }))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', "Plan Activa" + '.pdf')
      document.body.appendChild(link)
      link.click()
    // })
  })
}


function convertToBuffer(component: any) {
  return new Promise(resolve => {
    let div = document.createElement('div')
    let obj = component
    const doc = new JsPDF('p', 'px', 'a1')
    let idRandom = 'printPDF-' + Math.floor((Math.random() * 9999))
    let queryRandom = '#' + idRandom
    div.id = idRandom
    div.style.display = 'hidden'
    div.style.width = '1000px'
    div.style.height = '600px'
    div.innerHTML = renderToStaticMarkup(obj)
    const promise = new Promise((resolve) => {
      resolve(renderToString(obj))
    })


    promise
      .then(async (resp) => {
        //@ts-ignore
        div.innerHTML = resp
        document.body.appendChild(div)
        if (div.innerHTML !== '') {
          //@ts-ignore
          await doc.html(document.querySelector(queryRandom), { margin: 30 })
            .then(() => {
              for (let j = 1; j <= doc.getNumberOfPages(); j++) {
              }
              return doc.output('arraybuffer')
            })
            .then((obj) => {
              document.body.removeChild(div)
                return resolve(obj)
              // }
            })
        }
      })
      .catch((error) => {
        alert(error)
      })
  })
}

