import { Message } from './Message'
import { ConversationUser } from './ConversationUser'
import { Conversation } from './Conversation'
import { Reaction } from './Reaction'
import { ReadingStatus } from '../enums/ReadingStatus'
import { MessageUser } from './MessageUser'
import { v4 as uuidv4 } from 'uuid'

export interface ConversationDTO {
  id: string | undefined
  name: string
  description: string
  users: ConversationUserDTO[]
  messages: MessageDTO[]
  unreadMessages: number
  isPublic: boolean
  isReadOnly: boolean
  circleID: string | undefined
  userCircleID: string | undefined
}

export function emptyConversationDTO(user: string, circle: string, userCircle: string) {
  const id = uuidv4()
  return {
    id,
    name: '',
    description: '',
    users: [emptyConversationUserDTO(id, user)],
    messages: [],
    unreadMessages: 0,
    isPublic: false,
    isReadOnly: false,
    circleID: circle,
    userCircleID: userCircle,
  }
}

export interface ConversationUserDTO {
  conversationID: string
  isAdmin: boolean
  userID: string
}

export function emptyConversationUserDTO(conversation: string, user: string) {
  return {
    conversationID: conversation,
    isAdmin: true,
    userID: user,
  }
}

export interface MessageDTO {
  id: string | undefined
  text: string
  files: string[] | undefined
  author: string
  conversationID: string
  rootID: string
  parentID: string
  message2Users: Message2UsersDTO[]
  reactions: ReactionDTO[] | undefined
  createdAt: Date
  isPinned: boolean
}

export interface Message2UsersDTO {
  userID: string
  messageID: string
  readingStatus: ReadingStatus
}

export interface ReactionDTO {
  userID: string
  messageID: string
  emoji: string
}

export function fromModel(c: Conversation): ConversationDTO {
  return {
    id: c.id,
    name: c.name,
    description: c.description,
    users: c.users.map((c) => conversationUserFromModel(c)),
    messages: c.messages.map((m) => messageFromModel(m)),
    unreadMessages: c.unreadMessages,
    isPublic: c.isPublic,
    isReadOnly: c.isReadOnly,
    circleID: c.circleID,
    userCircleID: c.userCircleID,
  }
}

export function conversationUserFromModel(c: ConversationUser): ConversationUserDTO {
  return {
    conversationID: c.conversationID,
    isAdmin: c.isAdmin,
    userID: c.userID,
  }
}

export function messageFromModel(m: Message): MessageDTO {
  return {
    id: m.id,
    text: m.text,
    files: m.files,
    author: m.author,
    conversationID: m.conversationID,
    rootID: m.rootID,
    parentID: m.parentID,
    message2Users: m.message2Users,
    reactions: m.reactions?.map((r) => reactionFromModel(r)),
    createdAt: new Date(m.createdAt),
    isPinned: m.isPinned,
  }
}

export function reactionFromModel(r: Reaction): ReactionDTO {
  return {
    emoji: r.emoji,
    messageID: r.messageID,
    userID: r.userID,
  }
}

export function toModel(d: ConversationDTO): Conversation {
  return new Conversation({
    description: d.description,
    id: d.id,
    isPublic: d.isPublic,
    isReadOnly: d.isReadOnly,
    messages: d.messages.map((m) => messageToModel(m)) || [],
    name: d.name,
    unreadMessages: d.unreadMessages,
    users: d.users.map((u) => conversationUserToModel(u)),
    circleID: d.circleID,
    userCircleID: d.userCircleID,
  })
}

export function conversationUserToModel(c: ConversationUserDTO): ConversationUser {
  return new ConversationUser(c)
}

export function messageToModel(m: MessageDTO): Message {
  return new Message({
    author: m.author,
    conversationID: m.conversationID,
    createdAt: new Date(m.createdAt),
    files: m.files ?? [],
    id: m.id,
    isPinned: m.isPinned,
    message2Users: m.message2Users?.map((m) => message2UsersToModel(m)) || [],
    parentID: m.parentID,
    reactions: m.reactions?.map((r) => reactionToModel(r)) ?? [],
    rootID: m.rootID,
    text: m.text,
  })
}

export function reactionToModel(r: ReactionDTO): Reaction {
  return new Reaction(r)
}

export function message2UsersToModel(m: Message2UsersDTO): MessageUser {
  return new MessageUser(m)
}
