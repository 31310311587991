import { Box, Table, TableCell, TableHead, TableRow, Tooltip, TableBody } from '@mui/material'
import React, { useEffect, useState } from 'react'
import style from '../calendar/WeekView.module.css'
import { dateToDateString } from '../../common/utils/date'
import dayjs from 'dayjs'
import { capitalize } from '../../common/utils/strings'
import { useTranslation } from 'react-i18next'
import styles from './AddSeeEditSportCenterModal.module.css'
import { CalendarTooltip } from './CalendarTooltip'
import { weekdayTypes } from '../../common/enums/Weekday'
import {
  TrainingGroupArrDTO,
  TrainingGroupDTO,
} from '../../modules/training-group/models/TrainingGroupDTO'
import { groupColors as colors } from '../../common/enums/Colors'

type WeekCalendarProps = {
  startDate: Date
  sportCenterID?: string
  trainingGroupArr: TrainingGroupArrDTO
  handlerEditGroup: (group: TrainingGroupDTO) => void
  handlerDeleteGroup: (id: string) => void
}

type TimeTableGroup = {
  id: string
  name: string
  weekDay: string
  timeTableStart: string
  timeTableFinish: string
  capacity: string
  mailEFD: string
  nameEFD: string
}

export const WeekCalendar = ({
  startDate,
  sportCenterID,
  trainingGroupArr,
  handlerEditGroup,
  handlerDeleteGroup,
}: WeekCalendarProps) => {
  const { t } = useTranslation()
  const [timeTableGroup, setTimeTableGroup] = useState<Map<string, TimeTableGroup[]>>(new Map())
  const [groupColor, setGroupColor] = useState<string[][]>([])

  const weekDays = (): Date[] => {
    const day = startDate
    const datesRange = []
    for (let i = 0; i < 7; i++) {
      const newDate = new Date(day)
      newDate.setDate(day.getDate() - day.getDay() + i + 1)
      datesRange.push(newDate)
    }
    return datesRange
  }

  const hours = (): string[] => {
    const hourRange = []
    for (let i = 9; i < 22; i++) {
      if (i < 10) {
        hourRange.push('0' + i + ':00')
      } else {
        hourRange.push(i + ':00')
      }
    }
    return hourRange
  }

  useEffect(() => {
    if (trainingGroupArr.groups.length !== 0) {
      makeTimeTable(trainingGroupArr.groups)
      makeGroupColor(trainingGroupArr.groups)
    }
  }, [trainingGroupArr.groups])

  const makeTimeTable = (grupos: TrainingGroupDTO[]) => {
    const mapTimeTable: Map<string, TimeTableGroup[]> = new Map<string, TimeTableGroup[]>()
    grupos.forEach((group) => {
      group.timeTable.forEach((tt) => {
        const startDate = new Date(tt.startTime)
        const finishDate = new Date(tt.finishTime)
        const timeTableGroup: TimeTableGroup = {
          id: group.id,
          name: group.name,
          weekDay: t(weekdayTypes()[tt.weekDay]),
          timeTableStart:
            addCero(startDate.getHours().toString()) +
            ':' +
            addCero(startDate.getMinutes().toString()),
          timeTableFinish:
            addCero(finishDate.getHours().toString()) +
            ':' +
            addCero(finishDate.getMinutes().toString()),
          capacity: '' + group.capacity,
          mailEFD: group.trainerEmail,
          nameEFD: group.trainerName,
        }
        const mapKey = tt.weekDay + ',' + addCero(startDate.getHours().toString())
        if (mapTimeTable.has(mapKey)) {
          mapTimeTable.get(mapKey)?.push(timeTableGroup)
        } else {
          mapTimeTable.set(mapKey, [timeTableGroup])
        }
      })
    })
    setTimeTableGroup(mapTimeTable)
  }

  const makeGroupColor = (grupos: TrainingGroupDTO[]) => {
    const colorArr: string[][] = []
    let value = 0
    grupos.forEach((grupo, index) => {
      if (index < colors.length) {
        value = index
      } else {
        for (let i = 0; i < colors.length; i++) {
          const rest = (index - i) % colors.length
          if (rest === 0) {
            value = i
          }
        }
      }
      colorArr.push([grupo.id, grupo.name, colors[value]])
    })
    setGroupColor(colorArr)
  }

  const addCero = (text: string): string => {
    return text.length < 2 ? '0' + text : text
  }

  const findColor = (id: string): string => {
    const colorEntry = groupColor.find((gColor) => gColor[0] === id)
    return colorEntry ? colorEntry[2] : 'white'
  }

  const handlerEditGroupVariable = (group: TimeTableGroup) => {
    const groupModel = trainingGroupArr.groups.find(
      (value: TrainingGroupDTO) => value.id === group.id
    )
    if (groupModel) {
      handlerEditGroup(groupModel)
    }
  }
  return (
    <>
      <Box className={style.headerCalendar}>
        <Box className={styles.headerTitle}>{t('calendar')}</Box>
        <Box className={style.headerCalendar2}>
          <Box
            style={groupColor.length === 0 ? { display: 'none' } : {}}
            className={style.rowTimeTableTitle}
          >
            Grupos:
            {groupColor.map((color) => (
              <Box key={color[0]} className={style.rowTimeTableTitle}>
                <Box style={{ backgroundColor: color[2] }} className={style.groupTitleColor}></Box>
                <Box className={style.groupTitleName}>{color[1]}</Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box className={style.calendar}>
        <Table className={style.tableContainer}>
          <TableHead>
            <TableRow>
              <TableCell className={style.weekHeader} style={{ width: '70px' }} />
              {weekDays().map((d, i) => (
                <TableCell key={d.toString() + i} className={style.weekHeader}>
                  <div className={`${i === 0 ? style.day1 : ''}`} key={dateToDateString(d)}>
                    <div className={style.weekDay}>
                      <div>
                        {capitalize(
                          dayjs(d).locale(navigator.language.split('-')[0]).format('ddd')
                        ).substring(0, 3)}
                      </div>
                    </div>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {hours().map((h, i) => (
              <TableRow className={style.groupRow} key={h}>
                <TableCell key={i} className={style.hourRow}>
                  <div className={style.hour} key={i}>
                    {h}
                  </div>
                </TableCell>
                {weekDays().map((d) => (
                  <TableCell className={style.eventRow} key={String(d) + String(h)}>
                    <Box className={style.tabCelGroupRow}>
                      {timeTableGroup
                        .get(new Date(d).getDay() + ',' + h.slice(0, 2))
                        ?.map((ttGroup) => (
                          <Tooltip
                            key={ttGroup.id}
                            title={
                              <CalendarTooltip
                                trainingGroup={ttGroup}
                                handlerEditGroup={handlerEditGroupVariable}
                                handlerDeleteGroup={handlerDeleteGroup}
                              />
                            }
                            arrow={true}
                            placement="right"
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  backgroundColor: 'white',
                                  boxShadow: '1px 1px 5px 3px grey',
                                  '& .MuiTooltip-arrow': {
                                    color: 'white',
                                  },
                                },
                              },
                            }}
                          >
                            <Box
                              style={{ backgroundColor: findColor(ttGroup.id) }}
                              className={style.groupColor}
                            ></Box>
                          </Tooltip>
                        ))}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  )
}
