export { PatientData } from './PatientData'
export { Table as Symptoms } from './symptoms/Table'
export { Editor as SymptomsForm } from './symptoms/Editor'
export { Table as Treatments } from './treatments/Table'
export { Table as SymptomsConfig } from './symptoms-config/Table'
export { Editor as SymptomsConfigForm } from './symptoms-config/Editor'
export { VaccinationSchedule } from './vaccinationSchedule/VaccinationSchedule'
export { EvolutionControl } from './evolutionControl/EvolutionControl'
export { Table as DentistTable } from './dentists/Table'
export { Editor as DentistForm } from './dentists/Editor'
export { View as DentistMap } from './dentists/View'
export { MedicalData } from './medicalData/MedicalData'
export { LandmarkTable } from './medicalData/LandmarkTable'
export { LandmarkEditor as LandmarkForm } from './medicalData/LandmarkEditor'
export { GrowthMonitoringTable } from './medicalData/GrowthMonitoringTable'
export { GrowthMonitoringEditor } from './medicalData/GrowthMonitoringEditor'
export { FamiliarDataTable } from './medicalData/FamiliarDataTable'
export { FamiliarDataEditor } from './medicalData/FamiliarDataEditor'
export { Wearables } from './wearables/Wearables'
export { Goals } from './wearables/Goals'
export { ProfessionalTypeConfig } from './professionalType-config/ProfessionalTypeConfig'
export { Table as QueryTable } from './query-config/Table'
export { MainBoard as ActivePlan } from './activePlan/MainBoard'
