// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Editor_checkBoxLabel__fT38w {\n  display: inline;\n}\n\n.Editor_label__ifFDq {\n  margin: 0;\n}\n\n.Editor_select__1yZXS {\n  width: 120px;\n}\n\n.Editor_styleCheckBox__21dPQ {\n  width: 25px;\n  height: 25px;\n}\n", "",{"version":3,"sources":["webpack://src/features/patient-data/symptoms/Editor.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".checkBoxLabel {\n  display: inline;\n}\n\n.label {\n  margin: 0;\n}\n\n.select {\n  width: 120px;\n}\n\n.styleCheckBox {\n  width: 25px;\n  height: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkBoxLabel": "Editor_checkBoxLabel__fT38w",
	"label": "Editor_label__ifFDq",
	"select": "Editor_select__1yZXS",
	"styleCheckBox": "Editor_styleCheckBox__21dPQ"
};
export default ___CSS_LOADER_EXPORT___;
