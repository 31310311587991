import { Container, IInit } from '../../../common/container/Container'
import { ContentContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { catchError, map } from 'rxjs/operators'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { VisualizationsDTO, toModel } from '../models/VisualizationsDTO'
import { Visualizations } from '../models/Visualizations'
import { STATUS_SERVICE_KEY } from '../../../container/app'

export interface IVisualizationsApi extends IInit {
  getByID(id: string): Observable<Visualizations | undefined>

  getVisualizationsByArticleID(id: string | undefined): Observable<ItemList<Visualizations>>

  getVisualizationsByUserID(id: string | undefined): Observable<ItemList<Visualizations>>

  getVisualizationsByCircleID(id: string | undefined): Observable<ItemList<Visualizations>>

  add(e: VisualizationsDTO): Observable<Visualizations | undefined>

  update(e: VisualizationsDTO): Observable<Visualizations | undefined>

  delete(id: string): Observable<boolean>
}

export class VisualizationsApi implements IVisualizationsApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as ContentContainerConfig).moduleFullUrl + '/visualizations'
  }

  add(e: VisualizationsDTO): Observable<Visualizations | undefined> {
    return this._httpClient.post<VisualizationsDTO>({ url: this._url, body: e }).pipe(
      map<VisualizationsDTO, Visualizations>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  update(e: VisualizationsDTO): Observable<Visualizations | undefined> {
    return this._httpClient.put<VisualizationsDTO>({ url: this._url, body: e }).pipe(
      map<VisualizationsDTO, Visualizations>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<Visualizations | undefined> {
    return this._httpClient.get<Visualizations>({ url: `${this._url}/${id}` }).pipe(
      map<VisualizationsDTO, Visualizations>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getVisualizationsByArticleID(id: string): Observable<ItemList<Visualizations>> {
    return this._httpClient
      .get<ItemList<Visualizations>>({ url: `${this._url}/articles/${id}` })
      .pipe(
        map<ItemList<VisualizationsDTO>, ItemList<Visualizations>>((dto) => {
          const itemList = emptyList<Visualizations>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Visualizations>())
        })
      )
  }

  getVisualizationsByUserID(id: string): Observable<ItemList<Visualizations>> {
    return this._httpClient
      .get<ItemList<Visualizations>>({ url: `${this._url}/articles/${id}` })
      .pipe(
        map<ItemList<VisualizationsDTO>, ItemList<Visualizations>>((dto) => {
          const itemList = emptyList<Visualizations>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Visualizations>())
        })
      )
  }

  getVisualizationsByCircleID(id: string): Observable<ItemList<Visualizations>> {
    return this._httpClient
      .get<ItemList<Visualizations>>({ url: `${this._url}/articles/${id}` })
      .pipe(
        map<ItemList<VisualizationsDTO>, ItemList<Visualizations>>((dto) => {
          const itemList = emptyList<Visualizations>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Visualizations>())
        })
      )
  }
}
