// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_container__27QFJ {\n  display: flex;\n  justify-content: space-between !important;\n  align-items: center;\n  border-bottom: 3px solid #f18b24;\n  width: 100% !important;\n}\n\n.Header_containerNoBorder__2ZHNC {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n}\n\n.Header_header__1MOL- {\n  font-weight: bold;\n  font-size: 1.5rem;\n  font-family: Montserrat, sans-serif;\n}\n\n.Header_icon__3fOz6 {\n  margin-bottom: 8px;\n  cursor: pointer;\n  width: 25px;\n  margin-right: 4px;\n}\n", "",{"version":3,"sources":["webpack://src/components/header/Header.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yCAAyC;EACzC,mBAAmB;EACnB,gCAAgC;EAChC,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,mCAAmC;AACrC;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,iBAAiB;AACnB","sourcesContent":[".container {\n  display: flex;\n  justify-content: space-between !important;\n  align-items: center;\n  border-bottom: 3px solid #f18b24;\n  width: 100% !important;\n}\n\n.containerNoBorder {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n}\n\n.header {\n  font-weight: bold;\n  font-size: 1.5rem;\n  font-family: Montserrat, sans-serif;\n}\n\n.icon {\n  margin-bottom: 8px;\n  cursor: pointer;\n  width: 25px;\n  margin-right: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Header_container__27QFJ",
	"containerNoBorder": "Header_containerNoBorder__2ZHNC",
	"header": "Header_header__1MOL-",
	"icon": "Header_icon__3fOz6"
};
export default ___CSS_LOADER_EXPORT___;
