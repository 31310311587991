/* tslint:disable */
//@ts-nocheck
import { File } from './File'
import { v4 as uuidv4 } from 'uuid'

export interface FileDTO {
  id: string | undefined
  data: string
  name: string
  extension: string
  basePath: string
  size: number
  mimeType: string
  miniPreview: string
  order: number
  creator: string
  userCircleID: string
  parent: string | null | undefined
  isDir: boolean | undefined
  isSystem: string | undefined
  sharedWith: string[] | undefined
  articleId: string | null
  messageId: string | null
  eventId: string | null
  isVisible: boolean
  isUploaded: boolean
  createdByDoctor: boolean
  checkBoxesSelected?: string
  specialities: string
  circles: string
  target?: string
  clonedFrom?: string
  createdAt: Date
  creationDate?: Date
  isCircleConfResource?: boolean
}

export function emptyFileDTO(
  creator: string | undefined,
  circleID: string | undefined,
  createdByDoctor: boolean | undefined
): FileDTO {
  return {
    id: uuidv4(),
    data: '',
    name: '',
    extension: '',
    basePath: '',
    size: 0,
    mimeType: '',
    miniPreview: '',
    order: 0,
    creator: creator ?? '',
    userCircleID: circleID ?? '',
    parent: null,
    isDir: false,
    isSystem: undefined,
    sharedWith: [],
    articleId: null,
    messageId: null,
    eventId: null,
    isVisible: false,
    isUploaded: false,
    createdByDoctor: createdByDoctor ?? false,
    checkBoxesSelected: '',
    specialities: '',
    circles: '',
    target: '' || undefined,
    clonedFrom: '' || undefined,
    createdAt: new Date(),
    creationDate: new Date(),
  }
}

export function fromModel(f: File): FileDTO {
  return {
    id: f.id,
    data: f.data,
    name: f.name,
    extension: f.extension,
    basePath: f.basePath,
    size: f.size,
    mimeType: f.mimeType,
    miniPreview: f.miniPreview,
    order: f.order,
    creator: f.creator,
    userCircleID: f.userCircleID,
    parent: f.parent,
    isDir: f.isDir,
    isSystem: f.isSystem,
    sharedWith: f.sharedWith,
    articleId: f.articleId,
    messageId: f.messageId,
    eventId: f.eventId,
    isVisible: f.isVisible,
    isUploaded: f.isUploaded,
    createdByDoctor: f.createdByDoctor,
    checkBoxesSelected: f.checkBoxesSelected,
    specialities: f.specialities,
    circles: f.circles,
    target: f.target,
    clonedFrom: f.clonedFrom,
    createdAt: f.createdAt,
    creationDate: f.creationDate,
  }
}

export function toModel(d: FileDTO): File {
  return new File(d)
}
