import i18n from '../../../i18n'

export enum StatusPlanActive {
  Active = 'active',
  Rejected = 'rejected',
  Pending = 'pending',
  Inactive = 'inactive',
  Closed = 'closed',
}

export const statusPlanActiveTypes = (): Record<StatusPlanActive, string> => ({
  [StatusPlanActive.Active]: i18n.t('active'),
  [StatusPlanActive.Rejected]: i18n.t('rejected'),
  [StatusPlanActive.Pending]: i18n.t('pending'),
  [StatusPlanActive.Inactive]: i18n.t('inactive'),
  [StatusPlanActive.Closed]: i18n.t('closed'),
})
