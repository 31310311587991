import { Query } from '../../../common/api/Query'
import { UserCircleDTO } from './UserCircleDTO'

export type UserCircleProps = {
  id: string
  circleID: string
  userID: string
  familiarsID: string[]
  profSmsID: string[]
  profExtID: string[]
  careersID: string[]
}

export class UserCircle {
  private readonly _id: string
  private _circleID: string
  private _userID: string
  private _familiarsID: string[]
  private _profSmsID: string[]
  private _profExtID: string[]
  private _careersID: string[]

  constructor(p: UserCircleProps) {
    this._id = p.id
    this._circleID = p.circleID
    this._userID = p.userID
    this._familiarsID = p.familiarsID
    this._profSmsID = p.profSmsID
    this._profExtID = p.profExtID
    this._careersID = p.careersID
  }

  get id(): string {
    return this._id
  }

  get circleID(): string {
    return this._circleID
  }

  set circleID(value: string) {
    this._circleID = value
  }

  get userID(): string {
    return this._userID
  }

  set userID(value: string) {
    this._userID = value
  }

  get familiarsID(): string[] {
    return this._familiarsID
  }

  set familiarsID(value: string[]) {
    this._familiarsID = value
  }

  get profSmsID(): string[] {
    return this._profSmsID
  }

  set profSmsID(value: string[]) {
    this._profSmsID = value
  }

  get profExtID(): string[] {
    return this._profExtID
  }

  set profExtID(value: string[]) {
    this._profExtID = value
  }

  get careersID(): string[] {
    return this._careersID
  }

  set careersID(value: string[]) {
    this._careersID = value
  }

  toDTO(): UserCircleDTO {
    return {
      id: this.id,
      circleID: this.circleID,
      userID: this.userID,
      familiarsID: this.familiarsID,
      profSmsID: this.profSmsID,
      profExtID: this.profExtID,
      careersID: this.careersID,
    }
  }
}

export class UserCircleQuery extends Query<UserCircle> {
  userID?: string
  roleID?: string[]
  includesUserID?: string
  searchByCircleOrUser?: string
  searchByDate?: string
  paginatedWithCircleAndUser?: string
}
