// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConfigForm_boxRecurrence__3qjKO {\n  display: flex;\n  margin-bottom: 20px;\n}\n\n.ConfigForm_boxRecurrence__3qjKO > p {\n  font-family: 'Poppins', sans-serif !important;\n  font-size: 18px !important;\n}\n\n.ConfigForm_cardContainer__12ZQP {\n  box-shadow: none !important;\n  width: 100%;\n}\n\n.ConfigForm_containerConfig__1DUat {\n  height: 500px;\n}\n", "",{"version":3,"sources":["webpack://src/features/form-generator/config-form-section/ConfigForm.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,6CAA6C;EAC7C,0BAA0B;AAC5B;;AAEA;EACE,2BAA2B;EAC3B,WAAW;AACb;;AAEA;EACE,aAAa;AACf","sourcesContent":[".boxRecurrence {\n  display: flex;\n  margin-bottom: 20px;\n}\n\n.boxRecurrence > p {\n  font-family: 'Poppins', sans-serif !important;\n  font-size: 18px !important;\n}\n\n.cardContainer {\n  box-shadow: none !important;\n  width: 100%;\n}\n\n.containerConfig {\n  height: 500px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxRecurrence": "ConfigForm_boxRecurrence__3qjKO",
	"cardContainer": "ConfigForm_cardContainer__12ZQP",
	"containerConfig": "ConfigForm_containerConfig__1DUat"
};
export default ___CSS_LOADER_EXPORT___;
