import { Query } from '../../../common/api/Query'

export type UserProps = {
  userID: string
  consultaID: string
  isAdmin: boolean
}

export class ConsultaUser {
  private readonly _userID: string
  private readonly _consultaID: string

  private _isAdmin: boolean

  constructor(p: UserProps) {
    this._userID = p.userID
    this._consultaID = p.consultaID
    this._isAdmin = p.isAdmin
  }

  get userID(): string {
    return this._userID
  }

  get consultaID(): string {
    return this._consultaID
  }

  get isAdmin(): boolean {
    return this._isAdmin
  }

  set isAdmin(value: boolean) {
    this._isAdmin = value
  }
}

export class UserQuery extends Query<ConsultaUser> {}
