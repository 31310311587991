import { SxProps } from '@mui/material'

export const TypographyProfileTitleInformation: SxProps = {
  fontFamily: 'Poppins',
  fontSize: '12px',
  color: '#000000',
}
export const TypographyProfileText: SxProps = {
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#000000',
}
export const dividerStyle: SxProps = {
  margin: '0 20px',
  height: '40px',
}
export const avatarStyle: SxProps = {
  width: '80px',
  height: '80px',
  marginBottom: '20px',
}
