import React, { useEffect, useState } from 'react'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { Query, QueryParam } from '../../common/api/Query'
import { Actions, Pager, Search, SearchValue } from '../../components/table_type/types'
import { AppTable, Field } from '../../components/table'
import { useTranslation } from 'react-i18next'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { Permission } from '../../common/permission'
import { Modal } from '@mui/material'
import { CustomModal } from 'components/modal/CustomModal'
import { Article } from '../../modules/content/models/Article'
import { getContentContainer } from '../../container/content-module'
import { ArticleService } from '../../modules/content/services/ArticleService'
import { ARTICLE_SERVICE_KEY } from '../../modules/content'
import { getCircleContainer } from '../../container/circle-module'
import { CircleService } from '../../modules/circle/services/CircleService'
import { CIRCLE_SERVICE_KEY } from '../../modules/circle'
import { CircleQuery } from '../../modules/circle/models/Circle'
import { CircleDTO } from '../../modules/circle/models/CircleDTO'
import { ROUTE_ARTICLES, ROUTE_ARTICLES_FORM } from '../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import { FormQuery } from '../../modules/forms/models/Form'
import editIcon from '../../assets/table_icons/ico-edit.svg'
import deleteIcon from '../../assets/table_icons/ico-eliminar.svg'

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const contentContainer = getContentContainer()
const articlesService = contentContainer.get<ArticleService>(ARTICLE_SERVICE_KEY)
const circleService = getCircleContainer().get<CircleService>(CIRCLE_SERVICE_KEY)

const fileType = {
  'template': 'Plantilla',
  'tutorial': 'Tutorial',
}

export function Table() {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [articles, setArticles] = useState<ItemList<Article>>(emptyList<Article>())
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [articlesPerPage, setArticlesPerPage] = useState<number>(100)
  const [pager, setPager] = useState<Pager>()
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [currentArticle, setCurrentArticle] = useState<Article>()
  const [category, setCategory] = useState<CircleDTO[]>([])
  const [filtering, setFiltering] = useState<boolean>(false)
  const [searcher, setSearcher] = useState<SearchValue<FormQuery>[]>([
    {
      name: 'allFields',
      label: t('search') + '...',
      value: '',
    },
  ])

  const navigate = useNavigate()

  const getObjects = () => {
    let userID = JSON.parse(localStorage.getItem("logged user") || "null").id
    let query = [
      new QueryParam<Article>('types', ['template', 'tutorial']),
      new QueryParam<Article>('userID', userID),
    ]

    if(searcher[0].value !== '' && searcher[0].value !== undefined){
      query.push(new QueryParam<Article>('titleCoincidences', searcher[0].value))
    }
    articlesService
      .getFilteredList(
        new Query({
          query: query,
          pager: { offset: page * articlesPerPage, limit: articlesPerPage },
        })
      )
      .subscribe((res) => {
        setIsLoading(false)
        const articleList = emptyList<Article>()

        articleList.count = 0
        for (let i = res.items.length - 1; i >= 0; i--) {
          const articleItem = res.items[i]
          articleList.items.push(articleItem)
          articleList.count++
        }
        articleList.items = articleList.items.sort((a, b) => {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        })
        setArticles(articleList)
        setCount(res.count)
      })

    circleService.getFilteredList(new Query<CircleQuery>({})).subscribe((res) => {
      if (!res) return
      setCategory(res.items)
    })
  }

  useEffect(() => {
    getObjects()
  }, [])

  useEffect(() => {
    getObjects()
  }, [searcher])

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: articlesPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, articlesPerPage])

  const search: Search<FormQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<FormQuery>[]) => {
      setFiltering(!!svs[0].value)

      const result = svs.map((s) => {
        if (s.type !== 'date' || !s.value) {
          return s
        }

        const date = new Date(s.value)
        date.setDate(date.getDate() + 1)
        date.setHours(1, 0, 0, 0)
        return Object.assign({ ...s }, { value: date.toJSON() })
      })
      setSearcher(result)
    },
  }

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setArticlesPerPage(10)
      return
    }
    setArticlesPerPage(Number.parseInt(event.target.value))
  }

  const handleSee = (article: Article) => navigate(`${ROUTE_ARTICLES}/${article.id}`)

  const fields: Field<Article>[] = [
    {
      name: 'title',
      label: t('title'),
      renderFunc: (f, i) => (
        <div style={{ margin: '0px', cursor: 'pointer' }} onClick={() => handleSee(i)}>{i.title}</div>
      ),
    },
    {
      name: 'createdAt',
      label: t('creationDate'),
      renderFunc: (f, i) => {
        return new Date(i.createdAt).toLocaleDateString()
      },
    },
    {
      name: 'category',
      label: t('circle'),
      // @ts-ignore
      renderFunc: (f, i) => {
        const circleName = category.find(category => {
          // @ts-ignore
          if(Number(category.id) === i.category){
            return category.name
          }else return ""
        })
        return circleName?.name
      },
    },
    {
      name: 'type',
      label: t('type'),
      renderFunc: (f, i) => {
        // @ts-ignore
        return <div style={{ margin: '0px', cursor: 'pointer' }}>{fileType[i.type]}</div>
      },
    },
    {
      name: 'author',
      label: t('author'),
      renderFunc: (f, i) => {
        return <div style={{ margin: '0px', cursor: 'pointer' }}>{i.authorName}</div>
      },
    },
  ]

  const editArticle = (article: Article) => {
      navigate(`${ROUTE_ARTICLES_FORM}/${article.id}`)
  }

  const removeArticle = (article: Article) => {
    setCurrentArticle(article)
    setOpenDeleteModal(true)
  }

  const actions: Actions<Article> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: editArticle,
        icon: editIcon,
        label: 'edit',
        hidden: () => !loggedUserService.userCan(Permission.createContent),
      },
      {
        handler: removeArticle,
        icon: deleteIcon,
        label: 'delete',
        hidden: () => !loggedUserService.userCan(Permission.createContent),
      },
    ],
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handleDeleteArticle = () => {
    if (currentArticle?.id)
      articlesService.delete(currentArticle.id).subscribe((_) => {
        setIsLoading(true)
        getObjects()
      })
    setOpenDeleteModal(false)
    setIsLoading(true)
  }

  return (
    <>
      <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <CustomModal
          handleClose={handleCloseDeleteModal}
          handleSave={handleDeleteArticle}
          title={t('delete')+" "+(currentArticle?.type=== "template" ? "plantilla" : "tutorial")}
          warningText={currentArticle?.type=== "template" ? t('irreversibleTemplateAction') : t('irreversibleTutorialAction')}
        />
      </Modal>
      <AppTable
        items={articles.items}
        rowKeyField="id"
        fields={fields}
        search={search}
        actions={actions}
        pager={pager}
      />
    </>
  )
}
