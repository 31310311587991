import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styles from './MonthView.module.css'
import React from 'react'
import { addCero } from '../../common/utils/strings'
import { AllCalendarEventDTO } from '../../modules/calendar/models/AllCalendarEventDTO'
import { EventCategory, eventCategoryTypes } from '../../modules/calendar/enums/EventCategory'
import { Zone, zoneTypes } from '../../modules/epileptical/enums/Zone'
import { Duration, durationTypes } from '../../modules/epileptical/enums/Duration'
import { Awareness, awarenessTypes } from '../../modules/epileptical/enums/Awareness'
import { Movement, movementTypes } from '../../modules/epileptical/enums/Movement'
import { Vision, visionTypes } from '../../modules/epileptical/enums/Vision'
import { SkinColor, skinColorTypes } from '../../modules/epileptical/enums/SkinColor'
import { Symptom, symptomTypes } from '../../modules/epileptical/enums/Symptom'
import { Mouth, mouthTypes } from '../../modules/epileptical/enums/Mouth'
import { AfterDuration, afterDurationTypes } from '../../modules/epileptical/enums/AfterDuration'
import style from '../patient-calendar-config/Modal.module.css'

type CalendarTooltipProps = {
  events?: AllCalendarEventDTO[]
}

export const CalendarTooltip = (props: CalendarTooltipProps) => {
  const { t } = useTranslation()
  const drawEvent = () => {
    return (
      <>
        {props.events?.map((ev) => (
          <Box key={ev.id} className={styles.generalTooltip}>
            <Box className={styles.tooltipTitleRow}>
              <Box className={styles.tooltipTitle}>{ev.title}</Box>
            </Box>
            <hr className={styles.horizontalLine} />
            <Box>
              <Box className={styles.toolTipRow}>
                <Box className={styles.toolTipBold}>{t('type')} :</Box>
                <Box className={styles.toolTipText}>{eventCategoryTypes()[ev.eventCategory]}</Box>
              </Box>
              <Box className={styles.toolTipRow}>
                <Box className={styles.toolTipBold}>{t('timeTable')} :</Box>
                <Box className={styles.toolTipText}>
                  {t('timeTableFrom')} {addCero(new Date(ev.startDate).getHours())}:
                  {addCero(new Date(ev.startDate).getMinutes())}h {t('timeTableTo')}{' '}
                  {addCero(new Date(ev.finishDate).getHours())}:
                  {addCero(new Date(ev.finishDate).getMinutes())}h
                </Box>
              </Box>

              {ev.eventCategory === 10 && (
                <Box>
                  <Box className={styles.toolTipRow}>
                    <Box className={styles.toolTipBold}>{t('howYourDay')}</Box>
                    <Box className={styles.toolTipText}>
                      <label
                        className={
                          ev.crisisDetails.epilepticalMood === 1
                            ? style.dayLabelg
                            : ev.crisisDetails.epilepticalMood === 2
                              ? style.dayLabely
                              : ev.crisisDetails.epilepticalMood === 3
                                ? style.dayLabelr
                                : style.dayLabelg
                        }
                      >
                        {ev.crisisDetails.epilepticalMood === 1
                          ? 'Día bueno'
                          : ev.crisisDetails.epilepticalMood === 2
                            ? 'Día regular'
                            : ev.crisisDetails.epilepticalMood === 3
                              ? 'Día malo'
                              : ''}
                      </label>
                    </Box>
                  </Box>
                  <Box className={styles.toolTipRow}>
                    <Box className={styles.toolTipBold}>{t('observations')} :</Box>
                    <Box className={styles.toolTipText}>{ev.crisisDetails.observations}</Box>
                  </Box>
                  <Box
                    className={styles.toolTipRow}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Box className={styles.toolTipBold}>{t('medium')} :</Box>
                    <Box
                      style={{
                        width: '60px',
                        height: '20px',
                        backgroundColor: ev.crisisDetails.color,
                      }}
                    ></Box>
                  </Box>
                  <Box>
                    <Box className={styles.toolTipRow}>
                      <Box className={styles.toolTipBold}>{t('beforeTheCrisis')}</Box>
                    </Box>
                    <Box>
                      <Box className={styles.toolTipText}>
                        {ev.crisisDetails.comment ? ev.crisisDetails.comment : t('noComments')}
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box className={styles.toolTipRow}>
                      <Box className={styles.toolTipBold}>{t('duringTheCrisis')}</Box>
                    </Box>
                    <Box className={styles.toolTipRow}>
                      <Box className={styles.toolTipText}>{t('zone')} :</Box>
                      <Box className={styles.toolTipText}>
                        {zoneTypes()[ev.crisisDetails.zone as Zone]}
                      </Box>
                    </Box>
                    <Box className={styles.toolTipRow}>
                      <Box className={styles.toolTipText}>{t('eventDuration')} :</Box>
                      <Box className={styles.toolTipText}>
                        {durationTypes()[ev.crisisDetails.duration as Duration]}
                      </Box>
                    </Box>
                    <Box className={styles.toolTipRow}>
                      <Box className={styles.toolTipText}>{t('awareness')} :</Box>
                      <Box className={styles.toolTipText}>
                        {awarenessTypes()[ev.crisisDetails.awareness as Awareness]}
                      </Box>
                    </Box>
                    <Box className={styles.toolTipRow}>
                      <Box className={styles.toolTipText}>{t('movement')} :</Box>
                      <Box className={styles.toolTipText}>
                        {movementTypes()[ev.crisisDetails.movement as Movement]}
                      </Box>
                    </Box>
                    <Box className={styles.toolTipRow}>
                      <Box className={styles.toolTipText}>{t('vision')} :</Box>
                      <Box className={styles.toolTipText}>
                        {visionTypes()[ev.crisisDetails.vision as Vision]}
                      </Box>
                    </Box>
                    <Box className={styles.toolTipRow}>
                      <Box className={styles.toolTipText}>{t('skinColor')} :</Box>
                      <Box className={styles.toolTipText}>
                        {skinColorTypes()[ev.crisisDetails.skinColor as SkinColor]}
                      </Box>
                    </Box>
                    <Box className={styles.toolTipRow}>
                      <Box className={styles.toolTipText}>{t('symptom')} :</Box>
                      <Box className={styles.toolTipText}>
                        {symptomTypes()[ev.crisisDetails.symptom as Symptom]}
                      </Box>
                    </Box>
                    <Box className={styles.toolTipRow}>
                      <Box className={styles.toolTipText}>{t('mouth')} :</Box>
                      <Box className={styles.toolTipText}>
                        {mouthTypes()[ev.crisisDetails.mouth as Mouth]}
                      </Box>
                    </Box>
                    <Box className={styles.toolTipRow}>
                      <Box className={styles.toolTipBold}>{t('afterTheCrisis')}</Box>
                    </Box>
                    <Box className={styles.toolTipRow}>
                      <Box className={styles.toolTipText}>{t('eventDuration')} :</Box>
                      <Box className={styles.toolTipText}>
                        {afterDurationTypes()[ev.crisisDetails.afterDuration as AfterDuration]}
                      </Box>
                    </Box>
                    <Box className={styles.toolTipRow}>
                      <Box className={styles.toolTipText}>
                        {ev.crisisDetails.afterSymptom?.join(', ')}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
              {ev.idIcon === 'Poo' && (
                <Box className={styles.toolTipRow}>
                  <Box className={styles.toolTipBold}>{t('numberDeposition') + ' :'}</Box>
                  <Box className={styles.toolTipText}>{ev.value}</Box>
                </Box>
              )}
              {/(Pain)$/.test(ev.idIcon) && (
                <Box className={styles.toolTipRow}>
                  <Box className={styles.toolTipBold}>{t('pain') + ' :'}</Box>
                  <Box className={styles.toolTipText}>{t(ev.idIcon)}</Box>
                </Box>
              )}
              {/(Bleeding)$/.test(ev.idIcon) && (
                <Box className={styles.toolTipRow}>
                  <Box className={styles.toolTipBold}>{t('bleeding') + ' :'}</Box>
                  <Box className={styles.toolTipText}>{t(ev.idIcon)}</Box>
                </Box>
              )}

              {ev.eventCategory === 15 && (
                <Box className={styles.toolTipRow}>
                  <Box className={styles.toolTipBold}>{t('place')} :</Box>
                  <Box className={styles.toolTipText}>{ev.place}</Box>
                </Box>
              )}
              {(ev.eventCategory === 12 || ev.eventCategory === 13 || ev.eventCategory === 14) && (
                <Box>
                  <Box className={styles.toolTipRow}>
                    <Box className={styles.toolTipBold}>{t('state')} :</Box>
                    <Box className={styles.toolTipText}>
                      {eventCategoryTypes()[ev.eventCategory as EventCategory]}
                    </Box>
                  </Box>
                  <Box className={styles.toolTipRow}>
                    <Box className={styles.toolTipBold}>{t('place')} :</Box>
                    <Box className={styles.toolTipText}>{ev.place}</Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        ))}
      </>
    )
  }

  return (
    <Box className={styles.tooltipContainer}>
      <div className={styles.tooltipContent}> {drawEvent()}</div>
    </Box>
  )
}
