import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import genericStyle from '../../common/utils/generic.module.css'
import { Table } from './Table'
import { Header } from '../../components/header/Header'

export function AdminTemplatesTutorials() {
  const { t } = useTranslation()

  return (
    <Box className={genericStyle.pageContainer}>
      <Box>
        <Header label={t('adminTutorialTemplates')} />
      </Box>
      <Box mb={3}>
        <Table />
      </Box>
    </Box>
  )
}
