// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".View_select__3jm5M {\n  min-width: 180px;\n  font-family: 'Poppins', sans-serif !important;\n}\n\n.View_checkbox__fedGV {\n  display: inline-block;\n  font-family: 'Poppins', sans-serif !important;\n  text-overflow: ellipsis;\n  word-wrap: break-word;\n  overflow: hidden;\n  max-height: 3.6em;\n  line-height: 1.8em;\n}\n\n.View_text__8yw7Q {\n  display: block; /* or inline-block */\n  text-overflow: ellipsis;\n  word-wrap: break-word;\n  overflow: hidden;\n  max-height: 3.6em;\n  line-height: 1.8em;\n}\n\n.View_hourPicker__2FRU0 {\n  margin-right: 0px !important;\n  border: 1px solid #d1d1d1 !important;\n  padding: 4px 8px !important;\n  border-radius: 4px !important;\n  width: 120px !important;\n  font-family: 'Poppins', sans-serif !important;\n}\n", "",{"version":3,"sources":["webpack://src/features/form-patients/View.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,6CAA6C;AAC/C;;AAEA;EACE,qBAAqB;EACrB,6CAA6C;EAC7C,uBAAuB;EACvB,qBAAqB;EACrB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,cAAc,EAAE,oBAAoB;EACpC,uBAAuB;EACvB,qBAAqB;EACrB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,4BAA4B;EAC5B,oCAAoC;EACpC,2BAA2B;EAC3B,6BAA6B;EAC7B,uBAAuB;EACvB,6CAA6C;AAC/C","sourcesContent":[".select {\n  min-width: 180px;\n  font-family: 'Poppins', sans-serif !important;\n}\n\n.checkbox {\n  display: inline-block;\n  font-family: 'Poppins', sans-serif !important;\n  text-overflow: ellipsis;\n  word-wrap: break-word;\n  overflow: hidden;\n  max-height: 3.6em;\n  line-height: 1.8em;\n}\n\n.text {\n  display: block; /* or inline-block */\n  text-overflow: ellipsis;\n  word-wrap: break-word;\n  overflow: hidden;\n  max-height: 3.6em;\n  line-height: 1.8em;\n}\n\n.hourPicker {\n  margin-right: 0px !important;\n  border: 1px solid #d1d1d1 !important;\n  padding: 4px 8px !important;\n  border-radius: 4px !important;\n  width: 120px !important;\n  font-family: 'Poppins', sans-serif !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": "View_select__3jm5M",
	"checkbox": "View_checkbox__fedGV",
	"text": "View_text__8yw7Q",
	"hourPicker": "View_hourPicker__2FRU0"
};
export default ___CSS_LOADER_EXPORT___;
