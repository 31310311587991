import { Box, Tab, Tabs, Typography } from '@mui/material'
import styles from '../evolutionControl/EvolutionControl.module.css'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { PathologyTable } from './pathology/Table'
import { ProfessionalTypeTable } from './professional-type/Table'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}
export function ProfessionalTypeConfig() {
  const { t } = useTranslation()
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div
        style={{ fontFamily: 'Poppins, sans-serif' }}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box style={{ backgroundColor: 'white' }}>
            <Typography style={{ fontFamily: 'Poppins, sans-serif' }}>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }
  return (
    <>
      <Box>
        <Box style={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} className={styles.caltabs}>
            <Tab
              label={t('professionalType')}
              {...a11yProps(0)}
              style={
                value === 0
                  ? {
                      backgroundColor: '#ffffff',
                      color: '#515151',
                      fontWeight: 'bold',
                      textAlign: 'left',
                      fontFamily: 'Poppins, sans-serif',
                      textTransform: 'none',
                    }
                  : {
                      backgroundColor: '#cccccc',
                      color: '#282828',
                      fontWeight: 'bold',
                      textAlign: 'left',
                      fontFamily: 'Poppins, sans-serif',
                      textTransform: 'none',
                    }
              }
            />
            <Tab
              label={t('circles')}
              {...a11yProps(1)}
              style={
                value === 1
                  ? {
                      backgroundColor: '#ffffff',
                      color: '#515151',
                      fontWeight: 'bold',
                      textAlign: 'left',
                      fontFamily: 'Poppins, sans-serif',
                      textTransform: 'none',
                    }
                  : {
                      backgroundColor: '#cccccc',
                      color: '#282828',
                      fontWeight: 'bold',
                      textAlign: 'left',
                      fontFamily: 'Poppins, sans-serif',
                      textTransform: 'none',
                    }
              }
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box style={{ backgroundColor: 'white', padding: '1em' }}>
            <ProfessionalTypeTable />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box style={{ backgroundColor: 'white', padding: '1em' }}>
            <PathologyTable />
          </Box>
        </TabPanel>
      </Box>
    </>
  )
}
