// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppTable_searchContainer__3kpv8 {\n  display: flex;\n  justify-content: flex-end;\n  flex-wrap: wrap;\n}\n\n.AppTable_iconBody__xoU0X {\n  vertical-align: middle;\n  cursor: pointer;\n  padding-top: 0;\n  padding-bottom: 0;\n}\n", "",{"version":3,"sources":["webpack://src/components/table/AppTable.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,eAAe;EACf,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".searchContainer {\n  display: flex;\n  justify-content: flex-end;\n  flex-wrap: wrap;\n}\n\n.iconBody {\n  vertical-align: middle;\n  cursor: pointer;\n  padding-top: 0;\n  padding-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchContainer": "AppTable_searchContainer__3kpv8",
	"iconBody": "AppTable_iconBody__xoU0X"
};
export default ___CSS_LOADER_EXPORT___;
