import { EventCategory } from '../enums/EventCategory'
import { CrisisDetailsForCalendarDTO } from './AllCalendarEventDTO'

export type AllCalendarEventProps = {
  id: string | undefined
  title: string
  description: string
  value: string
  idIcon: string
  startDate: Date
  finishDate: Date
  startCron: Date
  finishCron: Date
  repeatingUntil: Date | undefined
  repeating: string
  place: string
  eventCategory: EventCategory
  files: string[]
  ownerID: string
  creatorID: string
  users: string[]
  crisisDetails: CrisisDetailsForCalendarDTO
}

const getEstOffset = () => {
  const stdTimezoneOffset = () => {
    const jan = new Date(0, 1)
    const jul = new Date(0, 6)
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
  }

  const today = new Date()

  const isDstObserved = (today: Date) => {
    return today.getTimezoneOffset() < stdTimezoneOffset()
  }

  if (isDstObserved(today)) {
    return 1
  } else {
    return 2
  }
}

export class AllCalendarEvent {
  private readonly _id: string | undefined
  private readonly _title: string
  private readonly _description: string
  private readonly _value: string
  private readonly _idIcon: string
  private readonly _startDate: Date
  private readonly _finishDate: Date
  private readonly _startCron: Date
  private readonly _finishCron: Date
  private readonly _repeatingUntil: Date | undefined
  private readonly _repeating: string
  private readonly _place: string
  private readonly _eventCategory: EventCategory
  private readonly _files: string[]
  private readonly _ownerID: string
  private readonly _creatorID: string
  private readonly _users: string[]
  private readonly _crisisDetails: CrisisDetailsForCalendarDTO

  constructor(p: AllCalendarEventProps) {
    const userOffset = new Date(p.startDate).getTimezoneOffset() * 60 * 1000
    const centralOffset = 60 * 60 * 1000 * getEstOffset()
    const offSet = userOffset + centralOffset

    this._id = p.id
    this._title = p.title
    this._description = p.description
    this._value = p.value
    this._idIcon = p.idIcon
    this._startDate = new Date(new Date(p.startDate).getTime() + offSet)
    this._finishDate = new Date(new Date(p.finishDate).getTime() + offSet)
    this._startCron = new Date(new Date(p.startCron).getTime() + offSet)
    this._finishCron = new Date(new Date(p.finishCron).getTime() + offSet)
    this._repeatingUntil = p.repeatingUntil ? new Date(p.repeatingUntil) : undefined
    this._repeating = p.repeating
    this._place = p.place
    this._eventCategory = p.eventCategory
    this._files = p.files
    this._ownerID = p.ownerID
    this._creatorID = p.creatorID
    this._users = p.users
    this._crisisDetails = p.crisisDetails
  }

  get description(): string {
    return this._description
  }

  get value(): string {
    return this._value
  }

  get idIcon(): string {
    return this._idIcon
  }

  get id(): string | undefined {
    return this._id
  }

  get title(): string {
    return this._title
  }

  get startDate(): Date {
    return this._startDate
  }

  get finishDate(): Date {
    return this._finishDate
  }

  get startCron(): Date {
    return this._startCron
  }

  get finishCron(): Date {
    return this._finishCron
  }

  get repeatingUntil(): Date | undefined {
    return this._repeatingUntil
  }

  get repeating(): string {
    return this._repeating
  }

  get place(): string {
    return this._place
  }

  get eventCategory(): EventCategory {
    return this._eventCategory
  }

  get files(): string[] {
    return this._files
  }

  get ownerID(): string {
    return this._ownerID
  }

  get creatorID(): string {
    return this._creatorID
  }

  get users(): string[] {
    return this._users
  }

  get crisisDetails(): CrisisDetailsForCalendarDTO {
    return this._crisisDetails
  }
}
