import React, { ChangeEvent, useEffect, useState, useRef } from 'react'
import styles from './View.module.css'
import { getDentistContainer } from '../../../container/dentist-module'
import { DENTIST_SERVICE_KEY } from '../../../modules/dentists'
import { DentistService } from '../../../modules/dentists/services/DentistService'
import { Query } from '../../../common/api/Query'
import { Box, FormControlLabel, Switch, SwitchClassKey, SwitchProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Dentist } from '../../../modules/dentists/models/Dentist'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { Map } from './Map'
import privateIcon from '../../../assets/map/private-icon.svg'
import listIcon from '../../../assets/map/list_icon_blue.svg'
import { Header } from '../../../components/header/Header'
import mapIcon from '../../../assets/map/clinic-medical.svg'
import { createStyles, withStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import { ROUTE_PATIENT_DATA } from '../../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import TileLayer from 'ol/layer/Tile'
import { XYZ } from 'ol/source'
import { Map as OlMap, View as OlView } from 'ol'
import { fromLonLat } from 'ol/proj'

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string
}

interface Props extends SwitchProps {
  classes: Styles
}

const dentistContainer = getDentistContainer()
const dentistService = dentistContainer.get<DentistService>(DENTIST_SERVICE_KEY)

export const View = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [dentists, setDentists] = useState<ItemList<Dentist>>(emptyList<Dentist>())
  const [initialDentists, setInitialDentists] = useState<ItemList<Dentist>>(emptyList<Dentist>())
  const [selectedDentist, setSelectedDentist] = useState<string>('')
  const [showPrivates, setShowPrivates] = useState<boolean>(true)
  const [view, setView] = useState<string>('listView')
  const [viewIcon, setViewIcon] = useState<string>(listIcon)
  const [mapStyle, setMapStyle] = useState<object>()
  const [listStyle, setListStyle] = useState<object>()
  const [header, setHeader] = useState<string>('dentistList')

  useEffect(() => {
    if (initialDentists.items.length !== 0) return
    dentistService.getFilteredList(new Query({ sort: [{ field: 'name' }] })).subscribe((res) => {
      setDentists(res)
      setInitialDentists(res)
    })
  }, [])

  useEffect(() => {
    if (showPrivates) {
      setDentists({ items: [...initialDentists.items], count: initialDentists.count })
      return
    }
    setDentists({
      items: initialDentists.items.filter((d) => d.isPublic === showPrivates) ?? [],
      count: initialDentists.count,
    })
  }, [showPrivates])

  const IOSSwitch = withStyles((theme: Theme) =>
    createStyles({
      root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
      },
      switchBase: {
        padding: 1,
        '&$checked': {
          transform: 'translateX(16px)',
          color: theme.palette.common.white,
          '& + $track': {
            backgroundColor: '#68b3e0', // --color-light-blue
            opacity: 1,
            border: 'none',
          },
        },
        '&$focusVisible $thumb': {
          color: '#68b3e0', // --color-light-blue
          border: '6px solid #fff',
        },
      },
      thumb: {
        width: 24,
        height: 24,
      },
      track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
      },
      checked: {},
      focusVisible: {},
    })
  )(({ classes, ...props }: Props) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    )
  })

  const handlePrivate = (e: ChangeEvent<HTMLInputElement>) => setShowPrivates(e.target.checked)

  const dentistItem = (d: Dentist) => (
    <Box
      key={d.id}
      className={
        styles.listElement + ' ' + (d.id === selectedDentist ? styles.selectedElement : '')
      }
      onClick={() => d.id && setSelectedDentist(d.id)}
    >
      <p className={styles.dentistName}>{d.name}</p>
      <p className={styles.dentistInfo}>{d.address}</p>
      <p className={styles.dentistInfo}>{d.phone}</p>
      <p className={styles.dentistLastInfo}>{d.email}</p>
      {!d.isPublic && (
        <p className={styles.dentistLastInfo + ' ' + styles.privateInfo}>
          {t('private')}
          {<img className={styles.privateIcon} src={privateIcon} alt="privateIcon" />}
        </p>
      )}
    </Box>
  )

  const handleChangeView = () => {
    if (view === 'mapView') {
      setView('listView')
      setViewIcon(listIcon)
      setHeader('locationMap')
      setMapStyle({
        display: 'block',
      })
      setListStyle({
        display: 'none',
      })
    } else {
      setView('mapView')
      setViewIcon(mapIcon)
      setHeader('dentistList')
      setMapStyle({
        display: 'none',
      })
      setListStyle({
        display: 'inherit',
      })
    }
  }

  const goBack = () => navigate(ROUTE_PATIENT_DATA)

  const murciaLon = -1.130615
  const murciaLat = 37.99137
  const baseLayer = useRef(
    new TileLayer({
      source: new XYZ({
        url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      }),
    })
  )
  const initialMap = useRef<OlMap>(
    new OlMap({
      target: undefined,
      layers: [baseLayer.current],
      view: new OlView({
        center: fromLonLat([murciaLon, murciaLat]),
        zoom: 10,
      }),
    })
  )

  return (
    <Box>
      <Box mb={3} display={'flex'} justifyContent={'space-between'}>
        <AppButton
          theme={ButtonTheme.NewSecondary}
          type={'button'}
          label={t('back')}
          handler={goBack}
        />
      </Box>
      <Box className={styles.box} display="flex">
        <Box className={styles.listBox}>
          <Box className={styles.headerContainer}>
            <Header label={t(header)} />
          </Box>
          <FormControlLabel
            sx={{
              '.css-ahj2mt-MuiTypography-root': {
                fontFamily: 'Poppins',
              },
            }}
            className={styles.switch}
            control={<IOSSwitch checked={showPrivates} onChange={handlePrivate} />}
            label={t('privateDentists')}
            labelPlacement="start"
          />
          <Box className={styles.viewBox} onClick={handleChangeView}>
            <img className={styles.viewIcon} src={viewIcon} alt={t(viewIcon)} />
            <p className={styles.viewSwitch}>{t(view)}</p>
          </Box>
          <Box className={styles.listElements} style={listStyle}>
            {dentists.items.map((d) => dentistItem(d))}
          </Box>
        </Box>
        <Box component={'div'} className={styles.mapBox} style={mapStyle}>
          <Map
            dentists={dentists.items}
            selectedDentist={selectedDentist}
            handleSelectedDentist={setSelectedDentist}
            baseLayer={baseLayer.current}
            initialMap={initialMap.current}
          />
        </Box>
      </Box>
    </Box>
  )
}
