import React from 'react'

interface ProgressCircleProps {
  progress: number
}
const ProgressCircle: React.FC<ProgressCircleProps> = ({ progress }) => {
  const radius = 70
  const circumference = 2 * Math.PI * radius
  const strokeDashoffset = circumference - (progress / 100) * circumference

  return (
    <svg height="150" width="150">
      <circle stroke="#E3F5FF" fill="transparent" strokeWidth="10px" r={radius} cx="75" cy="75" />
      <circle
        stroke="#f18b24"
        fill="transparent"
        strokeWidth="10px"
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset }}
        r={radius}
        cx="75"
        cy="75"
        transform="rotate(+90 75 75)"
      />
      <text x="50%" y="50%" textAnchor="middle" stroke="black" strokeWidth="1px" dy=".3em">
        {progress}%
      </text>
    </svg>
  )
}
export default ProgressCircle
