import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import loaderStyles from '../../components/loading-spinner/LoadingSpinner.module.css'
import { Query } from '../../common/api/Query'
import { getContentContainer } from '../../container/content-module'
import { TAG_SERVICE_KEY } from '../../modules/content'
import { LoadingSpinner } from 'components/loading-spinner/LoadingSpinner'
import { TagService } from '../../modules/content/services/TagService'
import genericStyle from '../../common/utils/generic.module.css'
import { TableValidate } from './TableValidate'
import { Header } from '../../components/header/Header'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { UserCircleService } from '../../modules/user-circle/services/UserCircleConfigService'
import { USER_CIRCLE_SERVICE_KEY } from '../../modules/user-circle'

const userCircleService = getUserCircleContainer().get<UserCircleService>(USER_CIRCLE_SERVICE_KEY)

type SearchProps = {
  tagList: string[]
}

export function SearchValidate(props: SearchProps) {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [circles, setUserCircles] = useState<any>([])
  const [tagSearch, setTagSearch] = useState<string[]>([])

  useEffect(() => {
    let circles:any[] = []
    let logged = JSON.parse(localStorage.getItem("logged user") || "null")
    userCircleService.getUserCirclesByUserId(logged.id).subscribe((res) => {
      if(res && (res?.length > 0)){
        res.forEach(obj => circles.push(Number(obj.circle.id)))
      }
      setUserCircles(circles)
    })
  }, [])

  /*

  const handleSearch = () => setTagSearch(Array.from(tagList.values()))

*/

  return (
    <>
      {!isLoading ? (
        <Box className={genericStyle.pageContainer}>
          <Box>
            <Header label={t('validateContent')} />
          </Box>

          <Box mb={3}>
            <TableValidate tagIDList={tagSearch}/>
          </Box>
        </Box>
      ) : (
        <LoadingSpinner className={loaderStyles.loadingSpinner} />
      )}
    </>
  )
}
