import { Actions, Field } from '../table_type/types'
import React from 'react'
import List from '@mui/material/List'
import { Box, Button } from '@mui/material'
import { DraggableModal } from '../draggable-modal/DraggableModal'
import dotsIcon from '../../assets/resource_icons/dots-2.svg'
import { reduceString } from '../../common/utils/strings'

export type ListTableProps<T> = {
  readonly fields: Field<T>[]
  readonly items: T[]
  readonly rowKeyField: keyof T
  readonly actions?: Actions<T>
  fieldsInDraggable?: boolean
}

const DEFAULT_ROW_KEY = 'id'

export function ListTable<T extends Record<string, any>>(props: ListTableProps<T>) {
  const [open, setOpen] = React.useState(false)
  const [item, setItem] = React.useState<T>()
  const [loading, setLoading] = React.useState(false)

  const handleClose = () => setOpen(!open)

  const handleOpen = (item: T) => {
    setOpen(true)
    setLoading(true)
    setItem(item)
  }

  const useStyles = () => ({
    body: {
      fontSize: '14px',
      fontFamily: 'poppins',
      borderBottom: '1px solid #D1D1D1',
      padding: '0 auto 0 auto',
    },
    icon: {
      cursor: 'pointer',
      width: '20px',
      marginBottom: 'auto',
    },
    listItem: {
      borderBottom: '4px solid #68b3e0', // blue
    },
    key: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    cell: {
      backgroundColor: '#f3f7f6',
      fontSize: '14px',
      paddingLeft: '8px',
      paddingRight: '8px',
      '&:nth-child(2)': { borderTop: '2px solid #D1D1D1 ' },
    },
    flex: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    cellName: {
      paddingLeft: '8px',
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: '#ffffff',
      fontWeight: 'bold',
      minHeight: '45px',
      fontSize: '18px',
    },
    cellRol: {
      borderTop: 'none',
      paddingBottom: '8px',
    },
    actionsCell: {
      minHeight: '45px',
    },
    title: {
      backgroundColor: '#ffffff',
      fontWeight: 'bold',
      fontSize: '18px',
    },
  })

  const classes = useStyles()

  const styles = (field: any) => {
    if (arrayOfExcludedWords.includes(field.name.toString())) {
      return classes.cellName
    } else if (field.name === 'related') {
      return classes.cellRol
    } else {
      return classes.cell
    }
  }

  const handlerFields = (field: Field<T>, item: T) => {
    switch (field.name) {
      case 'name':
        return field.renderFunc
          ? field.renderFunc(field, item)
          : reduceString(item[field.name].toString(), 20)
      case 'title':
        return field.renderFunc
          ? field.renderFunc(field, item)
          : reduceString(item[field.name].toString(), 20)
      case 'related':
        return (
          <Box style={{ width: '100%' }}>
            <p style={{ margin: '0px' }}>
              {field.renderFunc ? field.renderFunc(field, item) : item[field.name].toString()}
            </p>
          </Box>
        )
      case 'color':
        return field.renderFunc
          ? field.renderFunc(field, item)
          : reduceString(item[field.name].toString(), 20)
      case 'eventCategory':
        return (
          <>
            <p style={{ fontWeight: 'bold', margin: 0, fontSize: '12px', padding: '8px 0 8px' }}>
              {field.label}
            </p>
            <p style={{ padding: '8px 0 8px', margin: 0, fontSize: '12px' }}>
              {field.renderFunc ? field.renderFunc(field, item) : item[field.name].toString()}
            </p>
          </>
        )
      case 'date':
        return (
          <>
            <p style={{ fontWeight: 'bold', margin: 0, fontSize: '12px', padding: '8px 0 8px' }}>
              {field.label}
            </p>
            <p style={{ padding: '8px 0 8px', margin: 0, fontSize: '12px' }}>
              {field.renderFunc ? field.renderFunc(field, item) : item[field.name].toString()}
            </p>
          </>
        )
      case 'symptomStartDate':
        return (
          <>
            <p style={{ fontWeight: 'bold', margin: 0, fontSize: '12px', padding: '8px 0 8px' }}>
              {field.label}
            </p>
            <p style={{ padding: '8px 0 8px', margin: 0, fontSize: '12px' }}>
              {field.renderFunc ? field.renderFunc(field, item) : item[field.name].toString()}
            </p>
          </>
        )
      case 'symptomID':
        return field.renderFunc ? field.renderFunc(field, item) : item[field.name].toString()
    }
  }

  const arrayOfExcludedWords: string[] = [
    'name',
    'symptomID',
    'title',
    'information',
    'type',
    'color',
  ]
  const selectedWords: string[] = ['name', 'symptomID', 'title', 'information', 'type', 'color']

  return (
    <>
      <List>
        <Box marginTop={4} sx={classes.listItem} />
        {props.items.map((item, itemIndex) => {
          return (
            <Box
              key={item[props.rowKeyField]}
              sx={classes.listItem}
              display={'flex'}
              flexDirection={'column'}
            >
              {Object.values(props.fields).map((field, index) => {
                const style = field.styleFunc?.(field, item)
                const foundActions = props.actions?.items.find(
                  (icon) => !icon.hidden || (icon.hidden && !icon.hidden(item))
                )
                return (
                  <Box key={item[props.rowKeyField]} sx={styles(field)}>
                    {props.fieldsInDraggable ? (
                      <Box display={'flex'} justifyContent={'space-between'} sx={classes.key}>
                        {handlerFields(field, item)}
                      </Box>
                    ) : (
                      <Box sx={classes.flex}>
                        {!arrayOfExcludedWords.includes(field.name.toString()) && (
                          <p style={{ padding: '4px 0 4px' }} className={style}>
                            {field.label}
                          </p>
                        )}
                        <p className={style}>
                          {field.renderFunc
                            ? field.renderFunc(field, item)
                            : reduceString(item[field.name].toString(), 20)}
                        </p>
                      </Box>
                    )}
                    {selectedWords.includes(field.name.toString()) &&
                      props.actions &&
                      props.actions.items?.length > 0 &&
                      !props.fieldsInDraggable &&
                      foundActions && (
                        <Button style={{ borderRadius: '100%' }} onClick={() => handleOpen(item)}>
                          <img src={dotsIcon} style={{ width: '20px', height: '20px' }} />
                        </Button>
                      )}
                    {props.fieldsInDraggable && index === 0 && (
                      <Button style={{ borderRadius: '100%' }} onClick={() => handleOpen(item)}>
                        <img src={dotsIcon} style={{ width: '20px', height: '20px' }} />
                      </Button>
                    )}
                  </Box>
                )
              })}
            </Box>
          )
        })}
      </List>
      {loading ? (
        <DraggableModal
          open={open}
          fields={props.fields}
          items={props.items}
          rowKeyField={props.rowKeyField || DEFAULT_ROW_KEY}
          actions={props.actions}
          handleClose={handleClose}
          item={item as T}
          fieldsInDraggable={props.fieldsInDraggable}
        />
      ) : undefined}
    </>
  )
}
