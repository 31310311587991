import { Box } from '@mui/material'
import style from '../AddNewCircle.module.css'
import { TextFieldItem } from '../../../../components/form-card/TextFieldItem'
import { AppButton, ButtonTheme } from '../../../../components/app-button/AppButton'
import deleteButton from '../../../../assets/esfera/buttons/delete.svg'
import React from 'react'
import { Role } from '../../../../modules/users/models/Role'
import { useTranslation } from 'react-i18next'
import { Participant } from './Participant'
import CustomRoleSelect from '../../CustomRoleSelect'

type AddParticipantProps = {
  roles?: Role[]
  deleteParticipant: (index: number) => void
  handleNumberChange: (value: number, index: number) => void
  handleSelectedParticipant: (value: string, id: number) => void
  id?: number
  index: number
  participant: Participant[]
}

export const AddParticipant = ({
  roles,
  deleteParticipant,
  id,
  participant,
  handleNumberChange,
  index,
  handleSelectedParticipant,
}: AddParticipantProps) => {
  const { t } = useTranslation()

  console.log('roles,', roles)

  return (
    <Box style={{ display: 'flex', marginTop: '30px', justifyContent: 'space-between' }}>
      <Box style={{ width: '30%' }}>
        <CustomRoleSelect
          roles={roles ?? []}
          value={participant[index]?.role ?? ''}
          onChange={handleSelectedParticipant}
          index={index}
          id={id}
          participant={participant}
        />
      </Box>

      <Box
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <b className={style.titleLabel}>{t('numberOfUsers')}</b>
        <TextFieldItem
          style={{ width: '15%' }}
          sx={{
            '.MuiInputBase-input': {
              fontFamily: 'Poppins',
              minHeight: 10,
              height: 20,
              paddingLeft: 2,
            },
            '.MuiInputLabel-outlined': {
              fontFamily: 'Poppins',
              fontSize: 15,
            },
          }}
          field={'numberOfUsers'}
          value={participant[index]?.numberOfParticipant || 0}
          handleChange={(e) => {
            const inputValue = e.target.value.replace(/\D/g, '') // Elimina caracteres no numéricos
            handleNumberChange(parseInt(inputValue), index)
          }}
          label={''}
          required={true}
        ></TextFieldItem>
      </Box>
      <Box>
        <AppButton
          theme={ButtonTheme.removeField}
          type={'button'}
          label={t('')}
          startIcon={deleteButton}
          marginStartIcon={{ marginRight: '10px' }}
          handler={() => deleteParticipant(id ?? 0)}
          vertical={true}
        />
      </Box>
    </Box>
  )
}
