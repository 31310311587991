import React from 'react'

import GenericChartDashboard from '../../../../components/dashboard/generic-chart-dashboard/GenericChartDashboard'
import { ChartEnum } from '../../../../components/dashboard/generic-chart-dashboard/ChartEnum'

const PatientNumberCard = () => {
  const labels = [
    '0-10',
    '11-20',
    '21-30',
    '31-40',
    '41-50',
    '51-60',
    '61-70',
    '71-80',
    '81-90',
    '+91',
  ]
  const data = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
  const backgroundColors = [
    '#0063A6',
    '#F18B24',
    '#B6B5B5',
    '#F1DD25',
    '#68B3E0',
    '#4CBF0D',
    '#6048C3',
    '#8A4242',
    '#4B4B4B',
    '#757112',
  ]

  const datasets = data.map((item, index) => ({
    data: [item],
    backgroundColor: backgroundColors[index],
    label: labels[index],
  }))
  return (
    <GenericChartDashboard
      datasets={datasets}
      labels={['']}
      title={''}
      typeChart={ChartEnum.Bar}
      legendPosition="bottom"
    />
  )
}

export default PatientNumberCard
