import React, { useEffect, useState } from 'react'
import { Avatar, Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material'
import stepIcon from '../../../../assets/dashboard/stepsIcon.svg'
import caloriesIcon from '../../../../assets/dashboard/caloriesIcon.svg'
import style from './MonitoredActivityCard.module.scss'
import { useTranslation } from 'react-i18next'
import { useGetActiveUser } from '../../../../hooks/get-active-user/useGetActiveUser'
import {
  avatarStyle,
  dividerStyle,
  TypographyProfileText,
  TypographyProfileTitleInformation,
} from './MonitoredActivityCardStyleMui'
import ProgressCircle from '../../../../components/dashboard/circle-progress/CircleProgress'
import ProgressSemiCircle from '../../../../components/dashboard/circle-progress/SemiCircleProgress'
import { dateToYYYYMMDD } from '../../../../common/utils/date'
import { getPatientDataContainer } from '../../../../container/patient-data-module'
import { WearableService } from '../../../../modules/patient-data/services/WearableService'
import { WEARABLE_SERVICE_KEY } from '../../../../modules/patient-data/container'
import styles from '../../../patient-data/wearables/Wearables.module.css'
import genericStyle from '../../../../common/utils/generic.module.css'
import { Graphs } from '../../../patient-data/wearables/Graphs'

type MonitoredActivityCardProps = {}

const patientDataContainer = getPatientDataContainer()
const wearableService = patientDataContainer.get<WearableService>(WEARABLE_SERVICE_KEY)

export const MonitoredActivityCard: React.FC<MonitoredActivityCardProps> = () => {
  const { t } = useTranslation()
  const { activeUser } = useGetActiveUser()
  const [wearablesData, setWearablesData] = useState<any>({})
  const [visibleCard, setVisibleCard] = useState<boolean>(false)
  const [dataType, setType] = useState<string>("")

  useEffect(() => {
    // let date = new Date()
    let newDate = dateToYYYYMMDD(new Date())
    wearableService.getData(newDate).subscribe((res) => {
      // alert(res)
      if (res) {
        setWearablesData(res)
      }
      // console.log(res)
    })
  }, [])

  const getDistances = (): any => {
    if (wearablesData.dailyActivitySummaryResponse &&
      wearablesData.dailyActivitySummaryResponse.summary.distances &&
      wearablesData.dailyActivitySummaryResponse.summary.distances.length > 0) {
      let aux = 0
      // @ts-ignore
      aux = wearablesData.dailyActivitySummaryResponse.summary.distances.find(obj => obj.activity === "total").distance.toFixed(2)
      return aux
    }
    return '0 '
  }

  const getGoalDistance = (): any => {
    if (wearablesData.dailyActivitySummaryResponse) {
      let aux = wearablesData.dailyActivitySummaryResponse.goals.distance
      if(aux === 0) return "0"
      let total = (getDistances()/aux) * 100;
      return total === Infinity ? 0: Math.trunc(total);
    }
    return '0 '
  }

  const getSleepEfficiency = (): any => {
    if (wearablesData.sleepResponse &&
      wearablesData.sleepResponse.sleep &&
      wearablesData.sleepResponse.sleep.length > 0) {
      let efficiency = wearablesData.sleepResponse.sleep[wearablesData.sleepResponse.sleep.length - 1].efficiency
      return efficiency
    }
    return '0'
  }

  const parseSleepMinutes = (): any => {
    if (wearablesData.sleepResponse) {
      let minutes = wearablesData.sleepResponse.summary.totalMinutesAsleep
      let h: number = Math.floor(minutes / 60)
      let m: number = minutes % 60
      // @ts-ignore
      h = h < 10 ? '0' + h : h
      // @ts-ignore
      m = m < 10 ? '0' + m : m
      return h + ':' + m
    }
    return 'NA'
  }

  const changeToGraphic = (type: string): any => {
    setType(type)
    setVisibleCard(true)
  }

  const returnToWearables = (): any => {
    setVisibleCard(false)
  }

  if (visibleCard) {
    return (
      <Box className={genericStyle.pageContainer}>
        <Box className={styles.chartContainer} flexGrow={'1'} display={'flex'} flexDirection={"column"}>
          <Graphs returnToWearables={returnToWearables} type={dataType}/>
        </Box>
      </Box>
    )
  } else
  return (
    <Grid container spacing={2}>
      <Grid display={'flex'} flexWrap={'wrap'} flexDirection={'row'} mt={4} item xs={12} sm={6} md={12}>
        <Grid item xs={12} sm={3} md={3} className={styles.marginCard}>
          <Card className={styles.cardProfile}>
            <CardContent className={styles.containerCard}>
              <Avatar sx={avatarStyle}
                      src={wearablesData.userResponse && wearablesData.userResponse.user.avatar640} />
              <Typography variant="h6" gutterBottom>
                {`${wearablesData.userResponse && wearablesData.userResponse.user.displayName}`}
              </Typography>
              <Box display={'flex'} mt={4}>
                <Box display={'flex'} alignItems={'center'}>
                  <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Typography sx={TypographyProfileTitleInformation}>{t('age')}</Typography>
                    <Typography
                      sx={TypographyProfileText}>{wearablesData.userResponse && wearablesData.userResponse.user.age} años</Typography>
                  </Box>
                  <Divider orientation={'vertical'} sx={dividerStyle} />
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Typography sx={TypographyProfileTitleInformation}>{t('height')}</Typography>
                    <Typography
                      sx={TypographyProfileText}>{wearablesData.userResponse && wearablesData.userResponse.user.height} cm</Typography>
                  </Box>
                  <Divider orientation={'vertical'} sx={dividerStyle} />
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Typography sx={TypographyProfileTitleInformation}>{t('weight')}</Typography>
                    <Typography
                      sx={TypographyProfileText}>{wearablesData.userResponse && wearablesData.userResponse.user.weight} Kg</Typography>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6} sm={2} md={2} className={styles.marginCard} onClick={() => changeToGraphic("steps")}>
          <Card className={styles.card}>
            <CardContent className={styles.containerCard}>
              <Avatar src={stepIcon} sx={avatarStyle} />
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <Typography variant="h6" gutterBottom>
                  {t('steps')}
                </Typography>
                <Typography
                  sx={TypographyProfileText}>{wearablesData.dailyActivitySummaryResponse && wearablesData.dailyActivitySummaryResponse.summary.steps} {t('today')}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6} sm={6} md={2} className={styles.marginCard} onClick={() => changeToGraphic("calories")}>
          <Card className={styles.card}>
            <CardContent className={styles.containerCard}>
              <Avatar src={caloriesIcon} sx={avatarStyle} />
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <Typography variant="h6" gutterBottom>
                  {t('calories')}
                </Typography>
                <Typography
                  sx={TypographyProfileText}>{wearablesData.dailyActivitySummaryResponse && wearablesData.dailyActivitySummaryResponse.summary.caloriesOut} {t('today')}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={styles.marginCard} onClick={() => changeToGraphic("distance")}>
          <Card className={styles.card}>
            <CardContent className={styles.containerCard}>
              <ProgressSemiCircle percentage={getGoalDistance()} />
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <Typography variant="h6" gutterBottom>
                  {t('distance')}
                </Typography>
                <Typography sx={TypographyProfileText}>{getDistances() + 'km ' + t('today')}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={2} className={styles.marginCard} onClick={() => changeToGraphic("sleepHours")}>
          <Card className={styles.card}>
            <CardContent className={styles.containerCard}>
              <ProgressCircle progress={getSleepEfficiency()} />
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <Typography variant="h6" gutterBottom>
                  {t('sleepHours')}
                </Typography>
                <Typography sx={TypographyProfileText}> {parseSleepMinutes() + ' h ' + t('today')}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
    // <Grid container spacing={2}>
    //   <Grid display={'flex'} flexDirection={'row'} mt={4} item xs={12} sm={6} md={12}>
    //     <Grid item xs={12} sm={6} md={3}>
    //       <Card className={style.cardProfile}>
    //         <CardContent className={style.containerCard}>
    //           <Avatar sx={avatarStyle} />
    //           <Typography variant="h6" gutterBottom>
    //             {`${activeUser.user.name} ${activeUser.user.lastname}`}
    //           </Typography>
    //           <Box display={'flex'} mt={4}>
    //             <Box display={'flex'} alignItems={'center'}>
    //               <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
    //                 <Typography sx={TypographyProfileTitleInformation}>{t('age')}</Typography>
    //                 <Typography sx={TypographyProfileText}>20 años</Typography>
    //               </Box>
    //               <Divider orientation={'vertical'} sx={dividerStyle} />
    //             </Box>
    //             <Box display={'flex'} alignItems={'center'}>
    //               <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
    //                 <Typography sx={TypographyProfileTitleInformation}>{t('height')}</Typography>
    //                 <Typography sx={TypographyProfileText}>180cm</Typography>
    //               </Box>
    //               <Divider orientation={'vertical'} sx={dividerStyle} />
    //             </Box>
    //             <Box display={'flex'} alignItems={'center'}>
    //               <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
    //                 <Typography sx={TypographyProfileTitleInformation}>{t('weight')}</Typography>
    //                 <Typography sx={TypographyProfileText}>70kg</Typography>
    //               </Box>
    //             </Box>
    //           </Box>
    //         </CardContent>
    //       </Card>
    //     </Grid>
    //     <Grid item xs={12} sm={6} md={2}>
    //       <Card className={style.card}>
    //         <CardContent className={style.containerCard}>
    //           <Avatar src={stepIcon} sx={avatarStyle} />
    //           <Typography variant="h6" gutterBottom>
    //             {t('steps')}
    //           </Typography>
    //         </CardContent>
    //       </Card>
    //     </Grid>
    //     <Grid item xs={12} sm={6} md={2}>
    //       <Card className={style.card}>
    //         <CardContent className={style.containerCard}>
    //           <Avatar src={caloriesIcon} sx={avatarStyle} />
    //           <Typography variant="h6" gutterBottom>
    //             {t('calories')}
    //           </Typography>
    //         </CardContent>
    //       </Card>
    //     </Grid>
    //     <Grid item xs={12} sm={6} md={2}>
    //       <Card className={style.card}>
    //         <CardContent className={style.containerCard}>
    //           <ProgressSemiCircle percentage={70} />
    //           <Typography variant="h6" gutterBottom>
    //             {t('distance')}
    //           </Typography>
    //         </CardContent>
    //       </Card>
    //     </Grid>
    //     <Grid item xs={12} sm={6} md={3}>
    //       <Card className={style.card}>
    //         <CardContent className={style.containerCard}>
    //           <ProgressCircle progress={30} />
    //           <Typography variant="h6" gutterBottom>
    //             {t('sleepHours')}
    //           </Typography>
    //         </CardContent>
    //       </Card>
    //     </Grid>
    //   </Grid>
    // </Grid>
  )
}
