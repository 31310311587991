import { Pathology } from './Pathology'
import { v4 as uuidv4 } from 'uuid'

export interface PathologyDTO {
  id: string
  name: string
  symptoms: string[]
  typeID: string
  description: string
}

export function fromModel(d: Pathology): PathologyDTO {
  return {
    id: d.id,
    name: d.name,
    symptoms: d.symptoms,
    typeID: d.typeID,
    description: d.description,
  }
}

export function toModel(d: PathologyDTO): Pathology {
  return new Pathology(d)
}

export function emptyPathologyDTO() {
  return {
    id: uuidv4(),
    name: '',
    symptoms: [''],
    typeID: '',
    description: '',
  }
}
