import React, { ChangeEvent, useEffect, useState } from 'react'
import { Avatar, Box, Modal, TextField } from '@mui/material'
import styles from './List.module.css'
import Button from '@mui/material/Button'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { Header } from '../../components/header/Header'
import { useTranslation } from 'react-i18next'
import womanIcon from '../../assets/chatIcons/woman.svg'
import maleIcon from '../../assets/chatIcons/male.svg'
import { GroupModal } from './NewGroup'
import { getNotificationContainer } from '../../container/notification-module'
import {
  SEGMENTED_NOTIFICATION_API_KEY,
} from '../../modules/notifications'
import { RichText } from '../../components/text-editor/RichText'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import style from './CustomModal.module.css'
import { NotificationModal } from './NotificationModal'
import {
  EmptySegmentedMessageDTO,
  toModelMessage,
} from '../../modules/notifications/models/SegmentedNotificationDTO'
import { SegmentedNotificationService } from '../../modules/notifications/services/SegmentedNotificationService'

const notificationContainer = getNotificationContainer()
const segmentedNotificationService = notificationContainer.get<SegmentedNotificationService>(SEGMENTED_NOTIFICATION_API_KEY)

function dataPrettier(data: string) {
  return new Date(data).toLocaleDateString('es-ES').concat(' ' + new Date(data).toLocaleTimeString())
}

export type NotificationDataProps = {
  title: string,
  description: string,
  segmentedId: string,
  userCircleId?: string,
  userId?: string,
  isPatient?: boolean,
  segmentedGroup?: any,
  handleClose?: () => void,
  data: any
}

type NotificationMessageProps = {
  userCircleId?: string,
  userId?: string,
  id: string,
  lastNoti: any,
  lastSend: any,
  handleShowHistory?: any,
  fromNewGroup: boolean
  updateMessages?:any
  segmentedGroup?: any
  setUpdateMessages?: any
}

type MessageResp = {
  title: string,
  description: string
}

function MessageResp() {
  return {
    title: '',
    description: '[{"type":"paragraph","children":[{"text":""}]}]',
  }
}

function NewNotificationMessage(props: NotificationMessageProps) {
  const { t } = useTranslation()
  const [notificationMessage, setNotificationMessage] = useState<MessageResp>(MessageResp())
  const [openMessageCreated, setOpenMessageCreated] = useState<boolean>(false)

  useEffect(() => {
    console.log(props)
    debugger;
    setNotificationMessage(MessageResp())
  }, [])
  // useEffect(() => {
  //   setNotificationMessage(MessageResp())
  // }, [props])

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNotificationMessage(Object.assign({ ...notificationMessage }, { title: e.target.value }))
  }

  const handleContent = (e: string) => {
    setNotificationMessage(Object.assign({ ...notificationMessage }, { description: e }))
  }

  const handleSave = (event: any) => {
    let message = EmptySegmentedMessageDTO();
    message.title = notificationMessage.title
    message.description = notificationMessage.description
    message.segmentedId = props.segmentedGroup.id
    segmentedNotificationService.addNotification(toModelMessage(message)).subscribe(resp => {
      setOpenMessageCreated(true)
      props.setUpdateMessages(!props.updateMessages)
      event.preventDefault()
      setNotificationMessage({title:"", description:'[{"type":"paragraph","children":[{"text":""}]}]'})
      props.handleShowHistory(true)
    })
  }

  const handleCloseCreatedModal = () => {
    setOpenMessageCreated(false)
    //@ts-ignore
    document.querySelector("#notiContent>div:nth-child(2)>p>span>span>span").innerHTML = " "
  }
  return (
    <>
      <Modal open={openMessageCreated} onClose={handleCloseCreatedModal}>
        <div className={style.agoraContainer}>
          <Box className={style.containerInside} style={{width:"500px"}}>
            <Box display="flex" justifyContent="center" textAlign={"center"} className={"titleSend"}>
              <h3>
                {props.segmentedGroup.name}
              </h3>
            </Box>
            <Box display="flex" justifyContent="center" textAlign={"center"} className={"titleSend"}>
              <h2 style={{color:"#f29940"}}>
                {t('successfullySended').toUpperCase()}
              </h2>
            </Box>
            <Box display="flex" justifyContent="center">
              <Box style={{ padding: '10px' }}>
                <AppButton
                  theme={ButtonTheme.NewPrimary}
                  type={'submit'}
                  label={t('close')}
                  handler={handleCloseCreatedModal}
                />
              </Box>
            </Box>
          </Box>
        </div>
      </Modal>
      {
        !props.fromNewGroup &&
        <Box mt={3} mb={3} display={'flow'} justifyContent={'space-between'}>
          <p className={style.label}>{t('lastNotificationSend')}:</p>
          {
            props.lastNoti.length > 0 &&
            <NotificationCard
              props={props.lastNoti[0]}
              userId={props.userId}
              userCircleId={props.userCircleId}
              segmentedId={props.id}
              segmentedGroup={props.segmentedGroup}
              updateMessages={props.updateMessages}
              setUpdateMessages={props.setUpdateMessages}
            />
          }
        </Box>
      }
      <p className={style.label}>{t('newNotification')}:</p>
      <Box className={style.newNotification}>
        <Box mt={3} mb={3} display={'flex'} justifyContent={'space-between'}>
          <p className={style.label}>{t('title')}*</p>
          <TextField
            key={'name'}
            id={'name'}
            name={'name'}
            value={notificationMessage.title}
            onChange={handleInput}
            required={true}
            style={{ padding: '10px' }}
            size={'small'}
            variant={'outlined'}
            fullWidth={true}
          />
        </Box>

        <p>{t('description') + ': '}</p>
        <Box mb={3} id={"notiContent"}>
          <RichText
            edit={true}
            content={notificationMessage.description}
            handleChange={(e) => handleContent(e)}
          />
        </Box>
        <Box display="flex" justifyContent="end">
          <Box style={{ padding: '0px 10px 10px 10px' }}>
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'submit'}
              label={t('send')}
              handler={handleSave}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}
function NotificationCard({ props, segmentedId, userId, userCircleId, updateMessages, setUpdateMessages, isPatient, segmentedGroup}: any) {
  const { t } = useTranslation()
  const [gender, setGender] = useState('')
  const [openEditNotification, setOpenEditNotification] = useState<boolean>(false)
  const uploadComment = (comment: string) => {
    setOpenEditNotification(false)
  }
  const handleCloseEditNotification = () => {
    setOpenEditNotification(false)
  }

  const handleShowEditNotification = () => {
    setOpenEditNotification(true)
  }

  let contentText
  if (props && props.description && props.description.charAt(0) === '[') {

    contentText = <RichText content={props.description} edit={true} view={true} />
  } else {
    contentText = <>{props?.description}</>
  }
  return (
    <>
      <Modal open={openEditNotification} onClose={handleCloseEditNotification}>
        <NotificationModal
          handleClose={handleCloseEditNotification}
          handleSave={uploadComment}
          data={props}
          segmentedId={segmentedId}
          userId={userId}
          userCircleId={userCircleId}
          closeModal={handleCloseEditNotification}
          updateMessages={updateMessages}
          segmentedGroup={segmentedGroup}
          setUpdateMessages={setUpdateMessages}
        />
      </Modal>
      <Box className={styles.notificationsWall}>
        <Box className={styles.avatarWrapper} flexDirection="row" >
          <header style={{ display: 'flex', height: "30px", borderBottom: 'white'}}>
            <Avatar
              src={gender === 'female' ? womanIcon : maleIcon}
              style={{ color: '#d2e2ee', backgroundColor: '#FFFFFF', padding: '0' }}
            />
            <h3 className={styles.notificationTitle}>{props.title}</h3>
              {props.lastSendAt && (
                <h4 style={{margin: "7px 13px 1px 10px"}}>
                  {dataPrettier(props.lastSendAt)}
                </h4>
              )}
            <Box className={styles.conversationBlock} style={{ textAlign: 'right' }}>
              {props.updatedAt &&
                dataPrettier(props.updatedAt.toString())
              }
            </Box>
            {/*</Box>*/}
          </header>
        </Box>
        <div className={styles.blueSeparator} />
        <Box className={styles.notificationBlock}>
              <span className={styles.notificationDescription}>
                {contentText}
              </span>
        </Box>
        {
          !isPatient &&
          <Box className={styles.resendButton} style={{cursor:"pointer"}} onClick={handleShowEditNotification}>
              <span>
                {t('resend')}
              </span>
            <RotateLeftIcon style={{color:'#f29940'}}/>
        </Box>
        }
      </Box>
    </>
  )
}

export function NotificationData(props: NotificationDataProps): JSX.Element {
  const { t } = useTranslation()
  const [openGroupModal, setOpenGroupModal] = useState<boolean>(false)
  const [showHistory, setShowHistory] = useState<boolean>(false)
  const [updateMessages, setUpdateMessages] = useState<boolean>(false)
  const [messages, setMessages] = useState<any>([])
  const [messagesInit, setMessagesInit] = useState<any>([])
  const [searchValue, setSearchValue] = useState<string>("")

  useEffect(() => {
    setMessages([])
    segmentedNotificationService.getMessagesById(props.data.id).subscribe((res) => {
      res.items = res.items.sort((a, b) => {
        return b.updatedAt.getTime() - a.updatedAt.getTime()
      })
      setMessages(res.items)
      setMessagesInit(res.items)
      if (props.isPatient) {
        setShowHistory(true)
      }
    })
    // if(props.isPatient){
      setShowHistory(true)
    // }
  }, [props.segmentedId, updateMessages])

  useEffect(() => {
    if(searchValue !== ""){
      let aux = messagesInit.filter((obj: any) => {
        if (obj.title.toLowerCase().includes(searchValue.toLowerCase())) {
          return obj
        }
      })
      setMessages(aux)
    }else{
      setMessages(messagesInit)
    }
  }, [searchValue])

  const handleSearch = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const handleCloseGroupModal = () => {
    if (props.handleClose) {
      props.handleClose()
    }
    setOpenGroupModal(false)
  }
  const handleShowHistory = () => {
    setShowHistory(!showHistory)
  }
  const handleOpenGroupModal = () => {
    setOpenGroupModal(true)
  }

  const uploadComment = (comment: string) => {
    setOpenGroupModal(false)
  }
  return (
    <>
      <Modal open={openGroupModal} onClose={handleCloseGroupModal}>
        <GroupModal
          handleClose={handleCloseGroupModal}
          handleSave={uploadComment}
          userCircleId={props.userCircleId}
          segmentedId={props.segmentedId}
          userId={props.userId}
          data={props.data}
        />
      </Modal>
      <Box className={styles.test1}>
        <Box display={'flex'} flexDirection={'column'} className={styles.notificationContainer}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              flex: 1,
              position:"sticky",
              top:"0",
              backgroundColor: "#ffffff",
              zIndex:100,
              borderBottom: "3px solid #f18b24"
            }}
          >
            <Header label={props.title} />
            {
              !props.isPatient &&
              <>
                <Button
                  onClick={handleOpenGroupModal}
                  // variant="contained"
                  style={{
                    backgroundColor: '#68b3e0',
                    marginBottom: '10px',
                    marginRight: '20px',
                    width: '15%',
                    float: 'right',
                    color: 'white',
                  }}
                >{t('groupEdit')}
                </Button>
                <Button
                  onClick={handleShowHistory}
                  // variant="contained"
                  style={{
                    backgroundColor: '#68b3e0',
                    marginBottom: '10px',
                    float: 'right',
                    width: '20%',
                    color: 'white',
                  }}
                >{
                  showHistory
                    ?
                    t('newNotification')
                    :
                    t('getHistory')
                }
                </Button>
              </>
            }
          </Box>
          <Box alignItems={'center'} className={styles.horizontalTimeline}>
            {
              showHistory &&
              <Box mt={3} mb={3} display={'flex'} justifyContent={'space-between'}>
              <p className={style.label}>{t('search')}</p>
              <TextField
                key={'name'}
                id={'name'}
                name={'name'}
                value={searchValue}
                onChange={handleSearch}
                required={true}
                style={{ padding: '10px' }}
                size={'small'}
                variant={'outlined'}
                fullWidth={true}
              />
            </Box>
            }
            {
              showHistory
                ?
                messages.map((item: any) => {
                  return <NotificationCard
                    props={item}
                    segmentedId={props.data.id}
                    userId={props.userId}
                    userCircleId={props.userCircleId}
                    updateMessages={updateMessages}
                    setUpdateMessages={setUpdateMessages}
                    segmentedGroup={props.data.data}
                    // setUpdateMessages={setCentMessages()}
                    isPatient={props.isPatient}
                  />
                })
                :
                <NewNotificationMessage id={props.data.id}
                                        lastSend={props.data.lastSendAt}
                                        userId={props.userId}
                                        userCircleId={props.userCircleId}
                                        lastNoti={messages}
                                        fromNewGroup={false}
                                        updateMessages={updateMessages}
                                        handleShowHistory={setShowHistory}
                                        setUpdateMessages={setUpdateMessages}
                                        segmentedGroup={props.data.data}
                />
            }
          </Box>
        </Box>
      </Box>
    </>
  )
}
