import { Query } from '../../../common/api/Query'

export type UserProps = {
  userID: string
  conversationID: string
  isAdmin: boolean
}

export class ConversationUser {
  private readonly _userID: string
  private readonly _conversationID: string

  private _isAdmin: boolean

  constructor(p: UserProps) {
    this._userID = p.userID
    this._conversationID = p.conversationID
    this._isAdmin = p.isAdmin
  }

  get userID(): string {
    return this._userID
  }

  get conversationID(): string {
    return this._conversationID
  }

  get isAdmin(): boolean {
    return this._isAdmin
  }

  set isAdmin(value: boolean) {
    this._isAdmin = value
  }
}

export class UserQuery extends Query<ConversationUser> {}
