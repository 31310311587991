import GenericChartDashboard from '../../../../components/dashboard/generic-chart-dashboard/GenericChartDashboard'
import { ChartEnum } from '../../../../components/dashboard/generic-chart-dashboard/ChartEnum'
import { ActivePlanService } from '../../../../modules/active-plan/services/ActivePlanService'
import React, { useEffect, useMemo, useState } from 'react'
import { Query } from '../../../../common/api/Query'

type PatientStatisicsCardProps = {
  activePlanService: ActivePlanService
}

type Status = {
  type: 'general' | 'suma' | 'cardio' | 'families' | 'parkinson'
  count: number
}

const PatientStatisicsCard: React.FC<PatientStatisicsCardProps> = ({ activePlanService }) => {
  // const labels = ['general', 'suma', 'cardio', 'families', 'parkinson']
  const backgroundColors = ['#0063A6', '#F18B24', '#B6B5B5', '#F1DD25']

  const [patientForPetition, setPatientForPetition] = useState<Status[]>([])

  const statusTypes = useMemo(
    () => ({
      general: 'general',
      suma: 'suma',
      cardio: 'cardio',
      families: 'families',
      parkinson: 'parkinson',
    }),
    []
  )

  useEffect(() => {
    const subscription = activePlanService
      .getFilteredList(new Query({ query: [] }))
      .subscribe((response) => {
        console.log('response', response)
        if (!response) return
        const statusCounts = {
          general: response.items.filter((item) => item.protocol === 'general').length,
          suma: response.items.filter((item) => item.protocol === 'suma').length,
          cardio: response.items.filter((item) => item.protocol === 'cardio').length,
          families: response.items.filter((item) => item.protocol === 'families').length,
          parkinson: response.items.filter((item) => item.protocol === 'parkinson').length,
        }

        const filteredStatusCounts: Status[] = Object.entries(statusCounts)
          .filter(([_, count]) => count > 0)
          .map(([type, count]) => ({
            type: type as 'general' | 'suma' | 'cardio' | 'families' | 'parkinson',
            count,
          }))

        setPatientForPetition(filteredStatusCounts)
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [activePlanService])

  const datasets = patientForPetition.map((item, index) => ({
    data: [item.count],
    backgroundColor: backgroundColors[index],
    label: patientForPetition.map((status) => statusTypes[status.type])[index],
  }))

  return (
    <GenericChartDashboard
      datasets={datasets}
      labels={['']}
      title={''}
      typeChart={ChartEnum.Bar}
      legendPosition={'bottom'}
    />
  )
}
export default PatientStatisicsCard
