import { getNotificationContainer } from '../../container/notification-module'
import { NotificationService } from '../../modules/notifications/services/NotificationService'
import { MESSAGES_SERVICE_KEY, NOTIFICATION_SERVICE_KEY } from '../../modules/notifications'
import { MessageService } from '../../modules/notifications/services/MessageService'
import { getMessengerContainer } from '../../container/messenger-module'
import { ConversationService } from '../../modules/messenger/services/ConversationService'
import { CONVERSATION_SERVICE_KEY } from '../../modules/messenger'
import { getUserContainer } from '../../container/user-module'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { Notification as N } from '../../modules/notifications/models/Notification'
import { Message } from '../../modules/notifications/models/Message'
import styles from './List.module.css'
import { ROUTE_MESSENGER } from '../../routes/routes-constants'
import { Box } from '@mui/material'
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner'
import style from '../../components/loading-spinner/LoadingSpinner.module.css'
import chatIcon from '../../assets/left_menu/chat.svg'
import rightArrow from '../../assets/calendar/arrow.svg'
import { ChatWidget } from './ChatWidget'
import { ActivityWidget } from './ActivityWidget'
import { Query, QueryParam } from '../../common/api/Query'
import { Conversation } from '../../modules/messenger/models/Conversation'
import { TransportType } from '../../common/enums/TransportType'
import { SourceType } from '../../modules/notifications/enums/SourceType'
import { Header } from '../../components/header/Header'
import { Link as RouterLink } from 'react-router-dom'
import { forkJoin, Observable } from 'rxjs'
import { User } from '../../modules/users/models/User'
import { DeliveryStatus } from 'modules/notifications/enums/DeliveryStatus'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { IUserCircleActiveService, USER_CIRCLE_ACTIVE_SERVICE_KEY } from '../../modules/user-circle'
import { Timeline, TimelineContent, TimelineItem, TimelineOppositeContent } from '@mui/lab'
import Button from '@mui/material/Button'
import { UserCircleWithCircleAndUser } from '../../modules/user-circle/models/UserCircleWithCircleAndUser'

const notificationContainer = getNotificationContainer()
const notificationService = notificationContainer.get<NotificationService>(NOTIFICATION_SERVICE_KEY)
const messageService = notificationContainer.get<MessageService>(MESSAGES_SERVICE_KEY)
const messengerContainer = getMessengerContainer()
const conversationsService = messengerContainer.get<ConversationService>(CONVERSATION_SERVICE_KEY)
const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)

const notificationsPerPage = 7

export const NewsWall = () => {
  const { t } = useTranslation()
  const userCircle = userCircleActiveService.getActiveFullUserCircle()

  const loggedUser = loggedUserService.get()

  const [fullUserCircle, setFulluserCircle] = useState<UserCircleWithCircleAndUser>()
  const [conversationCollection, setConversationCollection] = useState<Conversation[]>([])
  const [notifications, setNotifications] = useState<N[]>([])
  const [notificationMessages, setNotificationMessages] = useState<Map<string, Message>>(new Map())
  const [authors, setAuthors] = useState<Map<string, string>>(new Map())
  const [isLoading, setIsLoading] = useState<boolean>(true)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [page, setPage] = useState<number>(1)

  useEffect(() => {
    if (isLoading && loggedUser?.id && userCircle?.id) {
      userCircleActiveService.getActiveObservableUserCircle().subscribe((res) => {
        setFulluserCircle(res)
      })

      conversationsService
        .getFilteredItems(
          new Query({
            pager: { offset: (page - 1) * notificationsPerPage, limit: notificationsPerPage },
            query: [
              new QueryParam<Conversation>('userID', loggedUser.id),
              new QueryParam<Conversation>('circleID', fullUserCircle?.id ?? ''),
            ],
          })
        )
        .subscribe((res) => {
          setConversationCollection(res)
        })

      notificationService
        .getFilteredList(
          new Query({
            pager: { offset: (page - 1) * notificationsPerPage, limit: notificationsPerPage },
            query: [
              new QueryParam<N>('recipientID', loggedUser?.id || ''),
              new QueryParam<N>('userCircleID', userCircle.id),
              new QueryParam<N>('transportType', TransportType.App),
            ],
            sort: [{ field: 'sendAt', desc: true }],
          })
        )
        .subscribe((res) => {
          console.log(res)
          setNotifications(res.items.sort((a, b) => b.sendAt.getTime() - a.sendAt.getTime()))
          const notificationMessagesTmp = new Map()

          getMessages(res.items.map((n) => n.message)).subscribe((ml) => {
            ml.forEach((m, i) => {
              notificationMessagesTmp.set(res.items[i].id, m)
            })
            setNotificationMessages(new Map(notificationMessagesTmp))
          })
          setIsLoading(false)
        })
    }
  }, [isLoading])

  useEffect(() => {
    if (!notifications.length) {
      return
    }
    const authorsTmp = new Map()
    getUsers(notifications.map((n) => n.senderID)).subscribe((res) => {
      res
        .filter((u) => u)
        .map((u) => {
          return authorsTmp.set(u.id, u.name)
        })
      setAuthors(authorsTmp)
    })
  }, [notifications])

  const getUsers = (ids: string[]): Observable<User[]> =>
    forkJoin(ids.map((id) => userService.getByID(id))) as unknown as Observable<User[]>

  const getMessages = (ids: string[]): Observable<Message[]> =>
    forkJoin(ids.map((id) => messageService.getByID(id))) as unknown as Observable<Message[]>

  const handleSee = (not: N) => {
    not.deliveryStatus = DeliveryStatus.Viewed
    notificationService.update(not).subscribe((res) => {
      setIsLoading(true)
    })
  }

  const mappedNotifications = () =>
    notifications.map((n) => {
      if (!n.id) {
        return null
      }

      const message = notificationMessages.get(n.id)

      if (!message || message.sourceType === SourceType.MessengerMessageCreated) {
        return null
      }

      return (
        <TimelineItem key={message.id}>
          <TimelineOppositeContent style={{ padding: 0, flex: 0 }} />
          <TimelineContent>
            <ActivityWidget
              id={message.id}
              source={message.sourceType}
              title={t(message.title)}
              description={t(message.description)}
              creator={authors.get(n.senderID)}
              date={n.sendAt}
              url={message.url}
              not={n}
              handleSee={message.sourceType === SourceType.NewRegisteredUser ? handleSee : () => {}}
            />
          </TimelineContent>
        </TimelineItem>
      )
    })

  return (
    <>
      {!isLoading ? (
        <Box className={styles.wallContainer} style={{ height: '100vh' }}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            className={styles.chatWidgetContainer}
            style={{ maxHeight: '80vh', overflowY: 'auto' }}
          >
            <Header label={t('chats')} icon={chatIcon} />
            <Box>
              {conversationCollection.length ? (
                <>
                  {conversationCollection.slice(0, 6).map((c) => (
                    <ChatWidget
                      key={c.id}
                      id={c.id}
                      title={c.name}
                      conversationUsers={c.users}
                      description={c.description || undefined}
                      avatarUrl={'AvatarUrl'}
                      date={
                        c.messages.length ? c.messages[c.messages.length - 1].createdAt : undefined
                      }
                    />
                  ))}
                </>
              ) : (
                <h2>{t('noChats')}</h2>
              )}
            </Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                flex: 1,
              }}
            >
              <Button
                component={RouterLink}
                to={ROUTE_MESSENGER}
                variant="contained"
                endIcon={<img src={rightArrow} alt="right arrow" />}
                style={{
                  backgroundColor: '#68b3e0',
                  marginTop: '10px',
                  float: 'right',
                  color: 'white',
                }}
              >
                {t('goToChats')}
              </Button>
            </Box>
          </Box>

          <Box className={styles.test1}>
            <Box display={'flex'} flexDirection={'column'} className={styles.notificationContainer}>
              <Header label={t('activity')} />
              <Box alignItems={'center'} className={styles.horizontalTimeline}>
                {notifications.length ? (
                  <Timeline className={styles.test2}>{mappedNotifications()}</Timeline>
                ) : (
                  <h2>{t('noNotifications')}</h2>
                )}
              </Box>
            </Box>

            <Box display={'flex'} flexDirection={'column'} className={styles.audivisualContent}>
              <Header label={t('audiovisualContent')} />
              <Box alignItems={'center'}>
                {/* LO TIENEN QUE HACER ELLOS */}

                {/* <Grid container spacing={2}>
                  {notifications.length ? (
                    notifications.map((notification, index) => (
                      <Grid item xs={12} md={6} lg={4} key={index}>
                        <Card
                          className={styles.hoverCard}
                          sx={{ borderRadius: 5, borderColor: '#68b3e0' }}
                          variant="outlined"
                        >
                          <Header label={t('audiovisualContent')} />
                            <Timeline>{mappedNotifications(notification)}</Timeline>
                        </Card>
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12}>
                      <Card
                        className={styles.hoverCard}
                        sx={{ borderRadius: 5, borderColor: '#68b3e0' }}
                        variant="outlined"
                      >
                        <h2>{t('noNotifications')}</h2>
                      </Card>
                    </Grid>
                  )}
                </Grid> */}
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <LoadingSpinner className={style.loadingSpinner} />
      )}
    </>
  )
}
