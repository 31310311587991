import { Treatment, TreatmentQuery } from '../models/Treatment'
import { Container, IInit } from '../../../common/container/Container'
import { DataPatientContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { prepareURL } from '../../../common/api/http-helpers'
import { catchError, map } from 'rxjs/operators'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { toModel, TreatmentDTO } from '../models/TreatmentDTO'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { STATUS_SERVICE_KEY } from '../../../container/app'

export interface ITreatmentApi extends IInit {
  getByID(id: string | null): Observable<Treatment | undefined>

  getFilteredList(q: Query<TreatmentQuery>): Observable<ItemList<Treatment>>

  getAll(): Observable<ItemList<Treatment>>

  getFilteredItems(q: Query<TreatmentQuery>): Observable<Treatment[]>

  add(e: TreatmentDTO): Observable<Treatment | undefined>

  update(e: TreatmentDTO): Observable<Treatment | undefined>

  delete(id: string): Observable<boolean>
}

export class TreatmentApi implements ITreatmentApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as DataPatientContainerConfig).moduleFullUrl + '/treatments'
  }

  getByID(id: string | null): Observable<Treatment | undefined> {
    return this._httpClient.get<Treatment>({ url: this._url + '/' + id }).pipe(
      map<TreatmentDTO, Treatment>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  add(e: TreatmentDTO): Observable<Treatment | undefined> {
    return this._httpClient.post<Treatment>({ url: this._url, body: e }).pipe(
      map<TreatmentDTO, Treatment>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getFilteredList(q: Query<TreatmentQuery>): Observable<ItemList<Treatment>> {
    return this._httpClient.get<ItemList<Treatment>>({ url: prepareURL(this._url, q) }).pipe(
      map<ItemList<TreatmentDTO>, ItemList<Treatment>>((dto) => {
        const itemList = emptyList<Treatment>()
        itemList.items = dto.items.map((d) => toModel(d))
        itemList.count = dto.count
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<Treatment>())
      })
    )
  }

  getAll(): Observable<ItemList<Treatment>> {
    return this._httpClient.get<ItemList<Treatment>>({ url: `${(this._container.config as DataPatientContainerConfig).moduleFullUrl}/treatments-all` }).pipe(
      map<ItemList<TreatmentDTO>, ItemList<Treatment>>((dto) => {
        const itemList = emptyList<Treatment>()
        itemList.items = dto.items.map((d) => toModel(d))
        itemList.count = dto.count
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<Treatment>())
      })
    )
  }

  getFilteredItems(q: Query<TreatmentQuery>): Observable<Treatment[]> {
    return this._httpClient.get<Treatment[]>({ url: prepareURL(this._url, q) }).pipe(
      map<TreatmentDTO[], Treatment[]>((dto) => dto.map((d) => toModel(d))),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of([])
      })
    )
  }

  update(e: TreatmentDTO): Observable<Treatment | undefined> {
    return this._httpClient.put<Treatment>({ url: this._url, body: e }).pipe(
      map<TreatmentDTO, Treatment>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
}
