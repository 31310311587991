// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddFile_btnAttachFile__1kWSL {\n  display: flex;\n  align-items: center;\n}\n\n.AddFile_btnAttachFileActive__2qmys {\n  cursor: pointer;\n}\n\n.AddFile_btnAttachFileDisabled__3BT-Z {\n  cursor: auto;\n  opacity: 0.3;\n}\n\n.AddFile_attachIconRotate__gpTJ- {\n  transform: rotate(-90deg);\n}\n", "",{"version":3,"sources":["webpack://src/features/files/AddFile.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".btnAttachFile {\n  display: flex;\n  align-items: center;\n}\n\n.btnAttachFileActive {\n  cursor: pointer;\n}\n\n.btnAttachFileDisabled {\n  cursor: auto;\n  opacity: 0.3;\n}\n\n.attachIconRotate {\n  transform: rotate(-90deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnAttachFile": "AddFile_btnAttachFile__1kWSL",
	"btnAttachFileActive": "AddFile_btnAttachFileActive__2qmys",
	"btnAttachFileDisabled": "AddFile_btnAttachFileDisabled__3BT-Z",
	"attachIconRotate": "AddFile_attachIconRotate__gpTJ-"
};
export default ___CSS_LOADER_EXPORT___;
