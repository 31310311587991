import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardComponent, GenericChartDashboardComponent } from './ActivePlanCard'
import { Training } from '../../../../modules/training/models/Training'

type FitnessLevelCardProps = {
  training: Training[]
}
export const FitnessLevelCard: React.FC<FitnessLevelCardProps> = ({ training }) => {
  const { t } = useTranslation()
  const [phisCondition, setPhisCondition] = useState<number[]>([])
  const [monthLabel, setMonthLabel] = useState<string[]>([])

  const labels = [
    'En',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sept',
    'Oct',
    'Nov',
    'Dic',
  ]

  useEffect(() => {
    if (training.length === 0) return
    getPhisicalCondition()
  }, [training])

  const getPhisicalCondition = () => {
    const month: number[] = []
    const result: number[] = []
    let auxValu = 0
    let auxCont = 0
    let currentDate = new Date()
    training.forEach((trainingValue, index) => {
      const startTime = new Date(trainingValue.startTime)
      if (index === 0) {
        currentDate = startTime
        auxValu = trainingValue.physicalCondition
        auxCont = 1
      } else if (
        currentDate.getMonth() === startTime.getMonth() &&
        currentDate.getFullYear() === startTime.getFullYear()
      ) {
        auxCont += 1
        auxValu += trainingValue.physicalCondition
      } else {
        month.push(currentDate.getMonth())
        result.push(Math.round(auxValu / auxCont))
        currentDate = startTime
        auxValu = trainingValue.physicalCondition
        auxCont = 1
      }

      if (index === training.length - 1) {
        month.push(currentDate.getMonth())
        result.push(Math.round(auxValu / auxCont))
      }
    })

    const auxMonth: string[] = []
    month.forEach((monthNumber) => {
      auxMonth.push(labels[monthNumber])
    })
    setMonthLabel(auxMonth)
    setPhisCondition(result)
  }

  console.log(training, phisCondition, monthLabel)
  const datasets = [
    {
      label: 'Condición física',
      data: phisCondition,
      borderColor: 'blue',
    },
  ]

  return (
    <CardComponent title={t('fitnessLevel')}>
      <GenericChartDashboardComponent
        datasets={datasets}
        labels={monthLabel}
        title={''}
        typeChart={'line'}
        legendPosition={'bottom'}
      />
    </CardComponent>
  )
}
