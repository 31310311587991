import React from 'react'
import { Box, Chip } from '@mui/material'
import styles from './Header.module.css'
import style from '../current-nav-header-esfera/CurrentNavHeaderEsfera.module.css'
import { getFirstWord } from '../../common/utils/strings'
import { useTranslation } from 'react-i18next'
import showMoreIcon from '../../assets/dashboard/showMoreIcon.svg'

type HeaderProps = {
  label: string
  icon?: string
  onClick?: () => void
  circle?: string
  userName?: string
  subtitle?: string
  divider?: boolean
  showMore?: boolean
  borderBottom?: string
}

export function Header({
  label,
  icon,
  onClick,
  circle,
  userName,
  subtitle,
  divider,
  showMore,
  borderBottom,
}: HeaderProps) {
  const { t } = useTranslation()

  const renderUserCircle = () => (
    <Box style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', zIndex: 0}}>
      {circle && <Chip label={circle} className={style.pathologies} />}
      {userName && (
        <div className={style.circleContainer}>
          <p>{getFirstWord(userName)}</p>
        </div>
      )}
    </Box>
  )

  const renderShowMore = () => (
    <Box className={style.showMore} onClick={onClick}>
      <p>{t('showMore')}</p>
      <img src={showMoreIcon} alt={'arrow-right'} />
    </Box>
  )

  return (
    <Box
      sx={{ borderBottom: borderBottom ?? '' }}
      className={divider ? styles.containerNoBorder : styles.container}
    >
      <p className={styles.header}>{t(label)}</p>
      {icon && <img className={styles.icon} src={icon} alt={label} onClick={onClick} />}
      {circle ?? userName ? renderUserCircle() : null}
      {showMore ? renderShowMore() : null}
    </Box>
  )
}
