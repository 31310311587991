import { Container } from '../../common/container/Container'

export type ContentContainerConfig = {
  moduleFullUrl: string
}

export type ContentProps = {
  parentContainer: Container
  config: ContentContainerConfig
}

export const CONTENT_MODULE = Symbol('CONTENT_MODULE')
export const ARTICLE_API_KEY = Symbol('ARTICLE_API_KEY')
export const TAG_API_KEY = Symbol('TAG_API_KEY')
export const LIKES_API_KEY = Symbol('LIKES_API_KEY')
export const VISUALIZATIONS_API_KEY = Symbol('VISUALIZATIONS_API_KEY')
export const COMMENTS_API_KEY = Symbol('COMMENTS_API_KEY')
export const ARTICLE_SERVICE_KEY = Symbol('ARTICLE_SERVICE_KEY')
export const TAG_SERVICE_KEY = Symbol('TAG_SERVICE_KEY')
export const LIKES_SERVICE_KEY = Symbol('LIKES_SERVICE_KEY')
export const VISUALIZATIONS_SERVICE_KEY = Symbol('VISUALIZATIONS_SERVICE_KEY')
export const COMMENTS_SERVICE_KEY = Symbol('COMMENTS_SERVICE_KEY')
