import { Box } from '@mui/material'
import style from './Login.module.css'
import React, { ChangeEvent, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import { getUserContainer } from './container/user-module'
import { IUserService, USER_SERVICE_KEY } from './modules/users'
import { UserPending } from './modules/users/models/UserPending'
import { CirpaForm } from './components/forms-role-register/CirpaForm'
import { AppButton, ButtonTheme } from './components/app-button/AppButton'
import { emptyUserDTO, toModel, UserDTO } from './modules/users/models/User'
import { ExternalForm } from './components/forms-role-register/ExternalForm'

const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)

type ExternRegisterProps = {
  token: string
}

export default function ExternRegister(props: ExternRegisterProps) {
  const [dni, setDni] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [hasError, setHasError] = useState(false)
  const [message, setMessage] = useState(false)
  const [passwordMessage, setPasswordMessage] = useState('')
  const [legal, setLegal] = useState<boolean>(false)
  const [inPass, setInPass] = useState(false)
  const [userPending, setUserPending] = useState<UserPending>()
  const [user, setUser] = useState<UserDTO>(emptyUserDTO())
  const [registerButtonDisabled, setRegisterButtonDisabled] = useState<boolean>(false)
  const [forms, setForms] = useState<boolean>()
  const [messageDniFormat, setMessageDniFormat] = useState<string>('')
  const [phoneError, setPhoneError] = useState<string>('')
  const navigate = useNavigate()

  const handleChange = () => setLegal(!legal)

  useEffect(() => {
    userService.checkToken(props.token).subscribe((res) => {
      if (res) {
        setUserPending(res)
      } else {
        // setTokenError(true)
      }
    })
  }, [])

  useEffect(() => {
    userPending && setEmail(userPending.email)
    userPending &&
      setUser(
        Object.assign(
          { ...user },
          { related: [userPending?.circleId], roles: [userPending?.roleId] }
        )
      )
  }, [userPending])

  const handlerRegister = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    doRegister()
  }

  const passwordFormat = () => {
    if (
      !password.match(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[~!@#$%^&*=+|,.¿?\-_();:'"<>[\]]).{8,}$/
      )
    ) {
      setHasError(true)
      setPasswordMessage(
        'La contraseña debe tener al menos 8 caracteres e incluir una mayúscula, un número y un símbolo'
      )
      return false
    }
    return true
  }

  const dniFormat = (value: string) => {
    const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET'
    const nifnieRexp = /^[0-9XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i
    // let nieRexp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    const str = value.toString().toUpperCase()

    if (!nifnieRexp.test(str)) {
      setMessageDniFormat('Formato de DNI inválido')
      return false
    }

    const nie = str.replace(/^[X]/, '0').replace(/^[Y]/, '1').replace(/^[Z]/, '2')

    const letter = str.substr(-1)
    const charIndex = parseInt(nie.substr(0, 8)) % 23

    if (validChars.charAt(charIndex) === letter) {
      setMessageDniFormat('')
    } else {
      setMessageDniFormat('DNI inválido')
    }

    return validChars.charAt(charIndex) === letter
  }

  const doRegister = () => {
    if (!userPending?.circleId) return

    if (!passwordFormat()) return

    if (!email || !password || password !== passwordConfirm || !legal) {
      setHasError(true)
      return
    }

    userService
      .register({
        dni,
        email,
        password,
        circleID: userPending.circleId,
        roleName: userPending.roleId,
      })
      .subscribe((res) => {
        if (res) userService.deleteUserPending(props.token).subscribe()
      })

    setHasError(false)
    setMessage(true)
    setRegisterButtonDisabled(true)
    setInterval(() => {
      handleReload()
    }, 5000)
  }

  const handleReload = () => {
    navigate(window.location.href.split('?')[0])
    window.location.reload()
  }

  const handleLegal = () => {
    window.open('https://sede.carm.es/sms/portaldelpaciente/view/avisoLegal.xhtml', '_Blank')
  }

  const handleEmail = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const handleDni = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setDni(e.target.value)
  }

  const handlePassword = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const handleInPass = (value: boolean) => {
    setInPass(value)
  }

  const handlePasswordConfirm = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPasswordConfirm(e.target.value)
  }

  const handleDate = (event: any) => {
    if (event) {
      setUser(Object.assign({ ...user }, { birthDate: event.toDate() }))
    }
  }

  const handleExternalChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (e) {
      setUser(Object.assign({ ...user }, { [e.target.name]: e.target.value }))
    }
  }

  const handleExternalGender = (e: number) => {
    if (e) {
      setUser(Object.assign({ ...user }, { gender: e }))
    }
  }

  const formatPhoneNumber = (numberPhone: string) => {
    const regexNumberPhone = /^[0-9]{9}$/
    const str = numberPhone.toString().toUpperCase()

    if (!regexNumberPhone.test(str)) {
      setPhoneError('Formato de teléfono inválido')
      return false
    } else {
      return true
    }
  }

  const saveExternalRegister = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!userPending?.circleId) return

    if (
      !email ||
      !password ||
      password !== passwordConfirm ||
      !legal ||
      !user.dni ||
      !dniFormat(user.dni) ||
      !passwordFormat() ||
      !formatPhoneNumber(user.phone)
    ) {
      setHasError(true)
      return
    } else {
      setHasError(false)
      setMessageDniFormat('')
      setPasswordMessage('')
      setPhoneError('')
    }

    const newUser: UserDTO = {
      ...user,
      username: email,
      email,
      password,
    }
    userService.add(toModel(newUser)).subscribe((res) => {
      // FIXME: get roles by id
      if (res) {
        let rKind
        switch (res.roles[0]) {
          case '49a09919-f40e-4c78-a2ef-85de44673f96':
            rKind = 6
            break
          case '8822cb0d-d8ae-491c-b648-205ec2c0c2d6':
            rKind = 7
            break
          case '09d546be-7cd8-4ff3-9c10-0df1d3354c22':
            rKind = 3
            break
          default:
            rKind = 6
        }
        userService
          .addRelated({ id: res.id, kind: rKind }, userPending.circleId)
          .subscribe((res) => {
            setHasError(false)
            setMessage(true)
            setRegisterButtonDisabled(true)
            if (res) userService.deleteUserPending(props.token).subscribe()
            setInterval(() => {
              handleReload()
            }, 5000)
          })
      }
    })
  }

  // TODO ADD FORM FOR NO SMS USER

  return (
    <>
      <Box className={style.loginContainer} display={'flex'}>
        <Box style={{ marginBottom: '100px' }} className={style.login}>
          <h2>Registro en CIRCULO DEL PACIENTE</h2>

          <>
            {hasError && <Typography color="error">{'Los campos son incorrectos'}</Typography>}
            {passwordMessage && <Typography color="error">{passwordMessage}</Typography>}
            {messageDniFormat && <Typography color="error">{messageDniFormat}</Typography>}
            {phoneError && <Typography color="error">{phoneError}</Typography>}
            {message && (
              <Typography color="primary">
                {'El registro se ha completado, redirigiendo...'}
              </Typography>
            )}

            {userPending?.roleId === 'family/Tutor' && (
              <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box style={{ margin: 4 }}>
                  <AppButton
                    label={'Registarse con DNI'}
                    theme={ButtonTheme.NewPrimary}
                    handler={() => setForms(true)}
                    type={'button'}
                  />
                </Box>
                <Box style={{ margin: 4 }}>
                  <AppButton
                    label={'Registarse como externo'}
                    theme={ButtonTheme.NewPrimary}
                    handler={() => setForms(false)}
                    type={'button'}
                  />
                </Box>
              </Box>
            )}

            {userPending?.roleId === 'family/Tutor' && forms && (
              <CirpaForm
                dni={dni}
                email={email}
                password={password}
                inPass={inPass}
                passwordConfirm={passwordConfirm}
                handleLegal={handleLegal}
                registerButtonDisabled={registerButtonDisabled}
                legal={legal}
                handleChange={handleChange}
                handlerRegister={handlerRegister}
                handleEmail={handleEmail}
                handleDni={handleDni}
                handlePassword={handlePassword}
                handlePasswordConfirm={handlePasswordConfirm}
                handleInPass={handleInPass}
              />
            )}
            {userPending?.roleId === 'family/Tutor' && !forms && (
              <ExternalForm
                user={user}
                dni={dni}
                email={email}
                password={password}
                inPass={inPass}
                passwordConfirm={passwordConfirm}
                handleLegal={handleLegal}
                registerButtonDisabled={registerButtonDisabled}
                legal={legal}
                handleChange={handleChange}
                saveExternalRegister={saveExternalRegister}
                handleExternalChange={handleExternalChange}
                handlePassword={handlePassword}
                handlePasswordConfirm={handlePasswordConfirm}
                handleDate={handleDate}
                handleInPass={handleInPass}
                handleExternalGender={handleExternalGender}
              />
            )}
            {userPending?.roleId !== 'family/Tutor' && (
              <ExternalForm
                user={user}
                dni={dni}
                email={email}
                password={password}
                inPass={inPass}
                passwordConfirm={passwordConfirm}
                handleLegal={handleLegal}
                registerButtonDisabled={registerButtonDisabled}
                legal={legal}
                handleChange={handleChange}
                saveExternalRegister={saveExternalRegister}
                handleExternalChange={handleExternalChange}
                handlePassword={handlePassword}
                handlePasswordConfirm={handlePasswordConfirm}
                handleDate={handleDate}
                handleInPass={handleInPass}
                handleExternalGender={handleExternalGender}
              />
            )}
          </>
        </Box>
      </Box>
    </>
  )
}
