import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { FormCard } from '../../../components/form-card/FormCard'
import { Alert, Autocomplete, Box, Checkbox, Chip, Modal } from '@mui/material'
import { TextFieldItem } from '../../../components/form-card/TextFieldItem'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import { useNavigate } from 'react-router-dom'
import { ROUTE_CALENDAR } from '../../../routes/routes-constants'
import { useTranslation } from 'react-i18next'
import { emptyEventDTO, EventDTO, fromModel } from '../../../modules/calendar/models/EventDTO'
import { getCalendarContainer } from '../../../container/calendar-module'
import { EventService } from '../../../modules/calendar/services/EventService'
import { EVENT_SERVICE_KEY } from '../../../modules/calendar'
import { CronItemMockup } from '../../../components/form-card/CronItemMockup'
import { FileItem } from '../../../components/form-card/FileItem'
import { File as F } from '../../../modules/files/models/File'
import { getUserContainer } from '../../../container/user-module'
import { LoggedUserService } from '../../../modules/users/services/LoggedUserService'
import {
  IUserService,
  LOGGED_USER_SERVICE_KEY,
  ROLES_SERVICE_KEY,
  USER_SERVICE_KEY,
} from '../../../modules/users'
import { TransportType } from '../../../common/enums/TransportType'
import { Select } from '../../notification-list/Select'
import { CalendarNotificationDTO } from '../../../modules/calendar/models/Notification'
import { getFileContainer } from '../../../container/file-module'
import { FileService } from '../../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../../modules/files'
import style from '.././Editor.module.css'
import { Query } from '../../../common/api/Query'
import { User } from '../../../modules/users/models/User'
import styles from '../../user-profile/Editor.module.css'
import professionalSMS from '../../../assets/role_icons/ico-rol-profesional.svg'
import patient from '../../../assets/role_icons/ico-rol-paciente.svg'
import career from '../../../assets/role_icons/ico-rol-cuidador.svg'
import family from '../../../assets/role_icons/ico-rol-familiar.svg'
import externProfessional from '../../../assets/role_icons/ico-rol-externo.svg'
import { Header } from '../../../components/header/Header'
import { forkJoin, Observable } from 'rxjs'
import { Roles } from '../../../modules/users/enums/Roles'
import { Cron, cronString } from '../../../modules/forms/enums/Cron'
import { RolesService } from '../../../modules/users/services/RolesServices'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import TextField from '@mui/material/TextField'
import dayjs from 'dayjs'
import cronstrue from 'cronstrue'
import 'cronstrue/locales/es'
import { emptyFormDTO } from '../../../modules/forms/models/FormDTO'
import { useGetActiveUser } from '../../../hooks/get-active-user/useGetActiveUser'
import close from '../../../assets/esfera/buttons/delete.svg'
import { useCalendarEvents } from '../../../hooks/calendarEvents/useCalendarEvents'
import { getNotificationContainer } from '../../../container/notification-module'
import { UserConfigService } from '../../../modules/notifications/services/UserConfigService'
import { USER_CONFIG_SERVICE_KEY } from '../../../modules/notifications'
import { SourceType } from '../../../modules/notifications/enums/SourceType'

type EditorProps = {
  id?: string
  date?: Date
  open?: boolean
  onClose?: () => void
}

type AutocompleteUserInterface = {
  key: string
  value: string
  role: string
}

const calendarContainer = getCalendarContainer()
const eventService = calendarContainer.get<EventService>(EVENT_SERVICE_KEY)

const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

const fileContainer = getFileContainer()
const fileService = fileContainer.get<FileService>(FILE_SERVICE_KEY)

const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)

const roleService = getUserContainer().get<RolesService>(ROLES_SERVICE_KEY)

const userConfigService = getNotificationContainer().get<UserConfigService>(USER_CONFIG_SERVICE_KEY)

export function NewPersonalEvent(props: EditorProps) {
  const { t } = useTranslation()
  const cronTypes = cronString()
  const loggedUser = loggedUserService.get()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [event, setEvent] = useState<EventDTO>(emptyEventDTO(props.date))
  const [isFinishReminder, setIsFinishReminder] = useState<boolean>(false)
  const [isRecurrence, setIsRecurrence] = useState<boolean>(false)
  const [files, setFiles] = useState<F[]>([])
  const [users, setUsers] = useState<Map<string, AutocompleteUserInterface>>(new Map())
  const [selectedUsers, setSelectedUsers] = useState<AutocompleteUserInterface[]>([])
  const [cron, setCron] = useState<string>(cronTypes[Cron.Empty])
  const [roles, setRoles] = useState<Map<string, string>>(new Map())
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')
  const navigate = useNavigate()
  const { activeUser, activeUserCircleParticipants } = useGetActiveUser()
  const { refreshCalendarEvents, setRefreshCalendarEvents } = useCalendarEvents()
  const [availableNotificationsConfig, setAvailableNotificationsConfig] =
    useState<TransportType[]>()

  useEffect(() => {
    userConfigService.getListByUserID(loggedUser?.id ?? '').subscribe((userConfigs) => {
      const isChecked = (st: SourceType, tt: TransportType): boolean =>
        userConfigs.items.some((uc) => uc.sourceType === st && uc.transportType === tt)
      const auxAvailableNotifications: TransportType[] = []
      Object.keys(TransportType).forEach((key) => {
        const transportType = TransportType[key as keyof typeof TransportType]
        if (isChecked(SourceType.CalendarEventCreated, transportType)) {
          auxAvailableNotifications.push(transportType)
        }
      })
      setAvailableNotificationsConfig(auxAvailableNotifications)
    })
  }, [])
  const handleChange = (s: string, sd: Date, fd: Date) => {
    setCron(s)
    setEvent(Object.assign({ ...event }, { startCron: sd, finishCron: fd }))
  }
  const getFiles = (ids: string[]): Observable<F[]> =>
    forkJoin(ids.map((id) => fileService.getByID(id)) as unknown) as Observable<F[]>

  const getUsers = (ids: string[]): Observable<User[]> =>
    forkJoin(ids.map((id) => userService.getByID(id))) as unknown as Observable<User[]>

  useEffect(() => {
    roleService
      .getAll(
        new Query({
          sort: [{ field: 'name' }],
          pager: { offset: 0, limit: -1 },
        })
      )
      .subscribe((res) => {
        const tmpMap = new Map<string, string>()
        res.forEach((r) => tmpMap.set(r.id, r.name))
        setRoles(tmpMap)
      })

    props.id &&
      eventService.getByID(props.id).subscribe((res) => {
        if (res) {
          setEvent(fromModel(res))
          if (res.repeating !== '0 0 0 0 0') {
            setIsRecurrence(true)
            setCron(res.repeating)
          }

          res.repeatingUntil && setIsFinishReminder(true)

          res.files?.length && getFiles(res.files).subscribe((fl) => setFiles(fl.filter((f) => f)))

          getUsers(res.users).subscribe((rul) => {
            const ul = rul.map((u) => ({
              key: u.id,
              value: u.name,
              role: u.roles?.length ? u.roles[0] : '',
            }))
            const filterLoggedUser = ul.filter((u) => u.key !== loggedUser?.id)
            setSelectedUsers([...filterLoggedUser].sort((a, b) => a.value.localeCompare(b.value)))
          })
        }
      })
    if (!activeUser?.user.id) {
      return
    }

    setEvent(Object.assign({ ...event }, { ownerID: activeUser.user.id }))
    if (!props.id) {
      const finishDateAux = new Date()
      finishDateAux.setHours(finishDateAux.getHours() + 1)
      setEvent(
        Object.assign(
          { ...event },
          { startDate: new Date(), finishDate: finishDateAux, ownerID: activeUser.user.id }
        )
      )
    }

    const userCircleParticipants = activeUserCircleParticipants

    if (!userCircleParticipants) return
    try {
      const users = [
        ...userCircleParticipants.careers,
        ...userCircleParticipants.familiars,
        ...userCircleParticipants.profSms,
        ...userCircleParticipants.profExt,
      ]

      const tmpMap = new Map()
      tmpMap.set('every', { key: 'every', value: 'every', role: '' })

      users.forEach((u) => {
        tmpMap.set(u.id, {
          key: u.id,
          value: u.name,
          role: u.roles?.length ? u.roles[0] : '',
        })
      })
      setUsers(new Map(tmpMap))
    } catch (error) {
      console.error(error)
    }
  }, [])

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setEvent(Object.assign({ ...event }, { [e.target.name]: e.target.value }))

  const handleDate = (e: any, name: string) =>
    e && setEvent(Object.assign({ ...event }, { [name]: e.toDate() }))

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    switch (e.target.name) {
      case 'finishReminder':
        setIsFinishReminder(!isFinishReminder)
        break
      case 'recurrence':
        setIsRecurrence(!isRecurrence)
        break
      default:
        break
    }
  }

  const validateStringRequest = () => {
    if (event.place.length > 0 && event.title.length > 0 && event.description.length > 0) {
      return true
    }
    setErrorMessage('Los campos con * son obligatorios')
    return false
  }

  const handleFiles = (field: string, value: F[]) => {
    setFiles([...value])
    setEvent(Object.assign({ ...event }, { files: value.map((f) => f.id) }))
  }

  const handleNotification = (notifications: CalendarNotificationDTO) => {
    if (
      event.notifications?.some(
        (n) =>
          n.notifyAt === notifications.notifyAt && n.transportType === notifications.transportType
      )
    ) {
      return
    }
    const aux = event.notifications ?? []
    aux.push(notifications)
    setEvent(Object.assign({ ...event }, { notifications: aux }))
  }

  const removeNotification = (date: Date, type: string) => {
    let typeTransport: TransportType
    switch (type) {
      case 'SMS':
        typeTransport = TransportType.SMS
        break
      case 'Email':
        typeTransport = TransportType.Email
        break
      case 'App':
        typeTransport = TransportType.App
        break
      default:
        typeTransport = TransportType.None
        break
    }
    const aux =
      event.notifications?.filter((n: any) => {
        const nDate = new Date(n.notifyAt)
        const inputDate = new Date(date)
        return !(
          nDate.getDate() === inputDate.getDate() &&
          nDate.getMonth() === inputDate.getMonth() &&
          nDate.getFullYear() === inputDate.getFullYear() &&
          n.transportType === typeTransport
        )
      }) ?? []
    setEvent(Object.assign({ ...event }, { notifications: aux }))
  }

  const handleUsers = (value: AutocompleteUserInterface[]) => {
    if (!value) {
      return
    }
    if (value.find((v) => v.key === 'every')) {
      const result = Array.from(users.values()).filter((v) => v.key !== 'every')
      setSelectedUsers(result)
      setEvent(Object.assign({ ...event }, { users: result.map((v) => v.key) }))
      return
    }
    const result = [...selectedUsers, ...value]
    setSelectedUsers(result)
    setEvent(Object.assign({ ...event }, { users: result.map((v) => v.key) }))
  }

  const saveEvent = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (event.finishDate < event.startDate) return
    const notificationsDTO = event.notifications?.map((n) => {
      const notifyAt = new Date(n.notifyAt)
      const transportType = n.transportType
      const id = n.id
      return {
        notifyAt,
        transportType,
        id,
      }
    })

    const newEvent = Object.assign(
      { ...event },
      {
        repeatingUntil: isFinishReminder ? event.repeatingUntil : undefined,
        creatorID: loggedUser?.id,
        repeating: cron,
        users: selectedUsers.map((u) => u.key),
        notifications: notificationsDTO,
      }
    )

    if (props.id) {
      eventService.update(newEvent).subscribe(() => props.onClose && props.onClose())
      setRefreshCalendarEvents(!refreshCalendarEvents)
      return
    }
    eventService.add(newEvent).subscribe(() => {
      props.onClose && props.onClose()
    })
  }
  const getImage = (option: AutocompleteUserInterface) => {
    if (option.key === 'every') return
    switch (roles.get(option.role)) {
      case Roles.Professional:
        return <img className={styles.roleIcon} src={professionalSMS} alt={t(Roles.Professional)} />

      case Roles.ContentManager:
        return <img className={styles.roleIcon} src={professionalSMS} alt={t(Roles.Professional)} />

      case Roles.Patient:
        if (option.key !== activeUser?.user.id) {
          return <img className={styles.roleIcon} src={family} alt={t(Roles.FamilyOrTutor)} />
        }
        return <img className={styles.roleIcon} src={patient} alt={t(Roles.Patient)} />

      case Roles.CarerOrTeacher:
        return <img className={styles.roleIcon} src={career} alt={t(Roles.CarerOrTeacher)} />

      case Roles.FamilyOrTutor:
        if (option.key === activeUser?.user.id) {
          return <img className={styles.roleIcon} src={patient} alt={t(Roles.Patient)} />
        }
        return <img className={styles.roleIcon} src={family} alt={t(Roles.FamilyOrTutor)} />

      case Roles.ProfessionalExtern:
        return (
          <img
            className={styles.roleIcon}
            src={externProfessional}
            alt={t(Roles.ProfessionalExtern)}
          />
        )
    }
  }

  const goBack = () => navigate(ROUTE_CALENDAR, { state: { selectedDate: props.date } })

  const removeUser = (id: string) => setSelectedUsers(selectedUsers.filter((u) => u.value !== id))

  /* const showRecurrence = () => {
        if (event.repeating !== '0 0 0 0 0') {
          // TODO transform repeating to STRING
          return (
            cronstrue.toString(event.repeating, { locale: 'es' }) +
            ' desde el ' +
            event.startCron.toLocaleDateString() +
            ' hasta el ' +
            event.finishCron.toLocaleDateString()
          )
        }
        return 'No hay recurrencia asignada'
      } */

  const showRecurrence = () => {
    if (event.repeating !== '0 0 0 0 0') {
      const finishDate = new Date(event.finishCron)
      const endDate = new Date(event.startCron)
      endDate.setFullYear(endDate.getFullYear() + 2)
      const noFinishDate = new Date(endDate)
      if (finishDate.getTime() === noFinishDate.getTime()) {
        return (
          cronstrue.toString(event.repeating, { locale: 'es' }) +
          ' desde el ' +
          event.startCron.toLocaleDateString() +
          ' sin fecha fin.'
        )
      }
      return (
        cronstrue.toString(event.repeating, { locale: 'es' }) +
        ' desde el ' +
        event.startCron.toLocaleDateString() +
        ' hasta el ' +
        event.finishCron.toLocaleDateString()
      )
    }
    return 'No hay recurrencia asignada'
  }

  // TODO Set initial finishDate to 30 minutes more than startDate
  return (
    <Modal className={style.modal} open={props.open ?? false} onClose={props.onClose}>
      <Box className={style.modalContainer}>
        <FormCard>
          <>
            <Box className={style.exitIcon}>
              <img className={style.icon} src={close} alt={'close icon'} onClick={props.onClose} />
            </Box>
            <Header label={t('newEditEvent')} />
            <form className={style.form}>
              <Box
                mt={3}
                mb={3}
                className={style.textField}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-between'}
              >
                <p className={style.label}>{`${t('title')}*`}</p>
                <TextField
                  key={'title'}
                  id={'title'}
                  name={'title'}
                  value={event.title}
                  onChange={handleInput}
                  required={true}
                  size={'small'}
                  variant={'outlined'}
                  fullWidth={true}
                />
              </Box>
              <Box
                mb={3}
                className={style.textField}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-between'}
              >
                <p className={style.label}>{`${t('description')}*`}</p>
                <TextFieldItem
                  field="description"
                  value={event.description}
                  label={''}
                  type={'text'}
                  handleChange={handleInput}
                  rows={9}
                  required={true}
                  sx={{ fontFamily: 'Open Sans, sans-serif' }}
                />
              </Box>
              <Box
                mb={3}
                display={'flex'}
                justifyContent={'space-between'}
                className={style.datePickerContainer}
              >
                <Box display={'flex'} justifyContent={'space-between'} className={style.datePicker}>
                  <p>{t('startDateWithoutOf')}</p>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                    <DateTimePicker
                      PopperProps={{
                        sx: {
                          '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                            fontFamily: 'Open Sans, sans-serif',
                            textTransform: 'capitalize',
                          },
                          '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                            fontFamily: 'Open Sans, sans-serif',
                          },
                          '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                            fontFamily: 'Open Sans, sans-serif',
                          },
                          '& .css-3eghsz-PrivatePickersYear-button': {
                            fontFamily: 'Open Sans, sans-serif',
                          },
                        },
                      }}
                      key={'startDate'}
                      onError={(reason) => {
                        switch (reason) {
                          case 'invalidDate':
                            setDateTimePickerError(t('invalidDateMessage'))
                            break
                          case 'minDate':
                            setDateTimePickerError(t('minDateMessage'))
                            break
                        }
                      }}
                      renderInput={(props) => (
                        <TextField
                          sx={{
                            '.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input': {
                              fontFamily: 'Open Sans, sans-serif',
                              padding: '8px',
                            },
                          }}
                          style={{ width: '64%' }}
                          size={'small'}
                          helperText={props.error && DateTimePickerError}
                          {...props}
                        />
                      )}
                      ampm={false}
                      minDate={props.date ?? new Date()}
                      inputFormat="DD/MM/YYYY HH:mm"
                      onChange={(e) => handleDate(e, 'startDate')}
                      value={event.startDate}
                      label={''}
                    />
                  </LocalizationProvider>
                </Box>
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  className={style.datePicker2}
                >
                  <p className={style.dateLabel}>{t('endDateWithoutOf')}</p>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                    <DateTimePicker
                      PopperProps={{
                        sx: {
                          '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                            fontFamily: 'Open Sans, sans-serif',
                            textTransform: 'capitalize',
                          },
                          '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                            fontFamily: 'Open Sans, sans-serif',
                          },
                          '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                            fontFamily: 'Open Sans, sans-serif',
                          },
                          '& .css-3eghsz-PrivatePickersYear-button': {
                            fontFamily: 'Open Sans, sans-serif',
                          },
                        },
                      }}
                      key={'finishDate'}
                      onError={(reason) => {
                        switch (reason) {
                          case 'invalidDate':
                            setDateTimePickerError(t('invalidDateMessage'))
                            break
                          case 'minDate':
                            setDateTimePickerError(t('minDateMessage'))
                            break
                        }
                      }}
                      renderInput={(props) => (
                        <TextField
                          sx={{
                            '.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input': {
                              fontFamily: 'Open Sans, sans-serif',
                              padding: '8px',
                            },
                          }}
                          style={{ width: '64%' }}
                          size={'small'}
                          helperText={props.error && DateTimePickerError}
                          {...props}
                        />
                      )}
                      ampm={false}
                      value={event.finishDate}
                      // minDate={event.startDate}
                      minDateTime={dayjs(event.startDate)}
                      inputFormat="DD/MM/YYYY HH:mm"
                      onChange={(e) => handleDate(e, 'finishDate')}
                      label={''}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
              {!props.id ? (
                <>
                  <Box mb={3} className={style.textField}>
                    <p className={style.recurrenceLabel}>{t('recurrence')}</p>
                    <Checkbox
                      style={{ height: '25px', width: '25px' }}
                      checked={isRecurrence}
                      name={'recurrence'}
                      onChange={handleCheckbox}
                      disabled={!!props.id}
                    />
                  </Box>
                  {isRecurrence && (
                    <Box mb={3} className={style.textField}>
                      <CronItemMockup
                        handleChange={handleChange}
                        form={emptyFormDTO()}
                        isCreator={true}
                        finishDate={event.finishDate}
                      />
                    </Box>
                  )}
                </>
              ) : (
                <Box mb={3} display={'flex'} className={style.textField}>
                  <p
                    style={{ width: window.innerWidth < 2500 ? '12.6%' : '12.8' }}
                    className={style.recurrenceLabel}
                  >
                    {t('recurrence') + ':'}
                  </p>
                  <p className={style.label}>{showRecurrence()}</p>
                </Box>
              )}
              <Box
                mb={3}
                className={style.textField}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-between'}
              >
                <p style={{ width: '14.5%' }} className={style.label}>{`${t('place')}*`}</p>
                <TextField
                  key={'place'}
                  id={'place'}
                  name={'place'}
                  value={event.place}
                  onChange={handleInput}
                  required={true}
                  size={'small'}
                  variant={'outlined'}
                  fullWidth={true}
                />
              </Box>
              <Box mb={3} display={'flex'} flexDirection={'column'} className={style.textField}>
                <p className={style.label + ' ' + style.usersLabel}>{t('users')}</p>
                <Box style={{ display: 'inline' }}>
                  <Autocomplete
                    className={style.autocomplete}
                    key={event.id}
                    id={`${event.id}-standard`}
                    value={[]}
                    multiple
                    options={Array.from(users.values()).filter(
                      (u) => !selectedUsers.some((su) => su.key === u.key && su.value === u.value)
                    )}
                    getOptionLabel={(opt) => t(opt.value)}
                    renderOption={(props, option) => (
                      <React.Fragment>
                        <span {...props}>
                          {getImage(option)}
                          {t(option.value)}
                        </span>
                      </React.Fragment>
                    )}
                    onChange={(_, v) => handleUsers(v)}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                    noOptionsText={t('noElementsAvailable')}
                    size="small"
                  />
                </Box>
                {selectedUsers.length > 0 && (
                  <Box mt={2} display={'inline'}>
                    {selectedUsers.map((u) => (
                      <Chip
                        key={u.key}
                        className={style.chip}
                        label={u.value}
                        onDelete={() => removeUser(u.value)}
                      />
                    ))}
                  </Box>
                )}
              </Box>
              <Box
                mb={3}
                display={'flex'}
                style={{ minWidth: '23%' }}
                className={style.textField}
                justifyContent={'space-between'}
              >
                <Box>
                  <p className={style.filesLabel}>{t('addFile')}</p>
                </Box>
                <Box style={{ width: '90%' }}>
                  <FileItem
                    key={'files'}
                    field={'files'}
                    filesID={event.files ?? []}
                    handleFiles={handleFiles}
                    userCircleID={activeUser?.id ?? ''}
                    userID={loggedUser?.id ?? ''}
                    cleanFiles={false}
                    uploadRemoveFileDirectly={true}
                    disabled={false}
                    destinationFolder={'Event'}
                    eventID={event.id}
                    defaultFile={files}
                  />
                </Box>
              </Box>
              <Box mb={3} className={style.textField}>
                <p className={style.notificationLabel}>{t('addNotification')}</p>
                {availableNotificationsConfig?.map((value) => (
                  <Select
                    type={value.toString()}
                    key={value.toString()}
                    value={TransportType[value]}
                    notifications={event.notifications}
                    handlerChange={handleNotification}
                    cleanNotifications={false}
                    removeNotification={removeNotification}
                  />
                ))}
              </Box>
              <Box width={'100%'} display="flex" justifyContent="space-between">
                <AppButton
                  theme={ButtonTheme.NewSecondary}
                  type={'button'}
                  label={t('cancel')}
                  handler={() => {
                    goBack()
                    props.onClose && props.onClose()
                  }}
                />
                <AppButton
                  theme={ButtonTheme.NewPrimary}
                  type={'button'}
                  label={t('save')}
                  handler={(e) => {
                    if (validateStringRequest()) {
                      saveEvent(e)
                    }
                  }}
                />
              </Box>
              {errorMessage && (
                <Box mb={3}>
                  <Alert severity="warning" key="errorMessage" id="errorMessage">
                    {t(errorMessage)}
                  </Alert>
                </Box>
              )}
            </form>
          </>
        </FormCard>
      </Box>
    </Modal>
  )
}
