import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { ItemList } from '../../../common/models/ItemList'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Comments } from '../models/Comments'
import { CommentsDTO } from '../models/CommentsDTO'
import { ICommentsApi } from '../api/CommentsApi'

type Props = {
  apiKey: symbol
}

export interface ICommentsService extends IInit {
  getByID(id: string): Observable<Comments | undefined>

  getCommentsByArticleID(id: string | undefined): Observable<ItemList<Comments>>

  getCommentsByUserID(id: string | undefined): Observable<ItemList<Comments>>

  add(e: CommentsDTO): Observable<Comments | undefined>

  update(e: CommentsDTO): Observable<Comments | undefined>

  delete(id: string): Observable<boolean>
}

export class CommentsService implements ICommentsService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: ICommentsApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container): void {
    this._container = c
    this._api = this._container.get<ICommentsApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: CommentsDTO): Observable<Comments | undefined> {
    return this._api.add(e)
  }

  update(e: CommentsDTO): Observable<Comments | undefined> {
    return this._api.update(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<Comments | undefined> {
    return this._api.getByID(id)
  }

  getCommentsByArticleID(id: string | undefined): Observable<ItemList<Comments>> {
    return this._api.getCommentsByArticleID(id)
  }

  getCommentsByUserID(id: string | undefined): Observable<ItemList<Comments>> {
    return this._api.getCommentsByUserID(id)
  }
}
