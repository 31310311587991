export type VisualizationsProps = {
  id: string
  articleID: string
  userID: string
  circleID: string
  createdAt: Date
}

export class Visualizations {
  private readonly _id: string

  private readonly _articleID: string
  private readonly _userID: string
  private readonly _circleID: string
  private readonly _createdAt: Date

  constructor(props: VisualizationsProps) {
    this._id = props.id
    this._articleID = props.articleID
    this._userID = props.userID
    this._circleID = props.circleID
    this._createdAt = props.createdAt
  }

  get id(): string {
    return this._id
  }

  get articleID(): string {
    return this._articleID
  }

  get userID(): string {
    return this._userID
  }

  get circleID(): string {
    return this._circleID
  }

  get createdAt(): Date {
    return this._createdAt
  }
}
