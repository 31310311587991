import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ROUTE_ARTICLES_FORM, ROUTE_CREATE, ROUTE_TAG_LIST } from '../../routes/routes-constants'
import { useTranslation } from 'react-i18next'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import genericStyle from '../../common/utils/generic.module.css'
import styles from './Search.module.css'
import { Table } from './Table'
import { Header } from '../../components/header/Header'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { Permission } from '../../common/permission'

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

export function SearchMyPosts() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const createArticle = () => navigate(`${ROUTE_ARTICLES_FORM}/${ROUTE_CREATE}`)
  const viewTags = () => navigate(ROUTE_TAG_LIST)

  return (
    <Box className={genericStyle.pageContainer}>
      <Box>
        <Header label={t('myPosts')} />
      </Box>

      <Box mb={3} className={styles.buttons} display="flex" justifyContent="flex-end">
        {loggedUserService.userCan(Permission.createContent) && (
          <Box className={styles.button}>
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'button'}
              label={t('createPost')}
              handler={createArticle}
            />
          </Box>
        )}
        {loggedUserService.userCan(Permission.createContent) && (
          <div className={styles.button}>
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'button'}
              label={t('viewTags')}
              handler={viewTags}
            />
          </div>
        )}
      </Box>
      <Box mb={3}>
        <Table />
      </Box>
    </Box>
  )
}
