import { RouteProps } from '../../routes/AppRouter'
import React from 'react'
import { TemplateForm as AF } from '../../features/template'
import { ROUTE_CREATE } from '../../routes/routes-constants'
import { useParams } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function TemplatesForm(props: RouteProps) {
  const { id } = useParams()
  const title = props.title ?? ''

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <AF id={id === ROUTE_CREATE ? undefined : id} />
    </>
  )
}
