import { Box, Modal } from '@mui/material'
import React, { useEffect, useState, useRef } from 'react'
import { AppTable, Field } from '../../../../components/table'
import { useNavigate } from 'react-router-dom'
import { AppButton, ButtonTheme } from '../../../../components/app-button/AppButton'
import { Actions, Pager, Search, SearchValue } from '../../../../components/table_type/types'
import { useTranslation } from 'react-i18next'
import { ROUTE_CONFIGURATION } from '../../../../routes/routes-constants'
import { Query, QueryParam, QueryParamN } from '../../../../common/api/Query'
import { ProfessionalType } from '../../../../modules/users/models/ProfessionalType'
import {
  emptyProfessionalTypeDTO,
  ProfessionalTypeDTO,
  toModel,
} from '../../../../modules/users/models/ProfessionalTypeDTO'
import editIcon from '../../../../assets/table_icons/ico-edit.svg'
import deleteIcon from '../../../../assets/table_icons/ico-eliminar.svg'
import { CustomModal } from 'components/modal/CustomModal'
import { getUserContainer } from '../../../../container/user-module'
import { PROFESSIONALTYPE_SERVICE_KEY } from '../../../../modules/users'
import { ProfessionalTypesService } from '../../../../modules/users/services/ProfessionalTypeService'
import { v4 as uuidv4 } from 'uuid'

const userContainer = getUserContainer()
const professionalTypeService = userContainer.get<ProfessionalTypesService>(
  PROFESSIONALTYPE_SERVICE_KEY
)

type UserProps = {
  id?: string
}

export function ProfessionalTypeTable(props: UserProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [count, setCount] = useState<number>(0)
  const [professional, setProfessional] = useState<ProfessionalType[]>()

  const [professionalPerPage, setProfessionalPerPage] = useState<number>(10)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false)
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [currentProfessionalType, setCurrentProfessionalType] = useState<ProfessionalTypeDTO>(
    emptyProfessionalTypeDTO()
  )
  const currentPT = useRef(emptyProfessionalTypeDTO())
  const nameEdit = useRef('')
  const descriptionEdit = useRef('')

  const [searcher, setSearcher] = useState<SearchValue<ProfessionalType>[]>([
    {
      name: 'name',
      label: t('search') + '...',
    },
  ])

  useEffect(() => {
    const searchTerms = [...searcherQuery(searcher)]
    const name = searchTerms.find((s) => s.name === 'name')

    if (name) {
      professionalTypeService
        .getAll(
          new Query({
            query: [
              new QueryParam<ProfessionalType>('name', name.value),
              ...searcherQuery(searcher),
            ],
          })
        )
        .subscribe((res) => {
          setCount(res.length)
        })
      professionalTypeService
        .getAll(
          new Query({
            pager: { limit: professionalPerPage, offset: page * professionalPerPage },
            query: [
              new QueryParam<ProfessionalType>('name', name.value),
              ...searcherQuery(searcher),
            ],
            sort: [{ field: 'name', desc: false }],
          })
        )
        .subscribe((res) => {
          setProfessional(res)
        })
    } else {
      professionalTypeService.getAll(new Query({})).subscribe((res) => {
        setCount(res.length)
      })
      professionalTypeService
        .getAll(
          new Query({
            pager: { limit: professionalPerPage, offset: page * professionalPerPage },
            sort: [{ field: 'name', desc: false }],
          })
        )
        .subscribe((res) => {
          setProfessional(res)
        })
    }
  }, [isLoading])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setProfessionalPerPage(10)
      return
    }
    setProfessionalPerPage(Number.parseInt(event.target.value))
  }

  useEffect(() => {
    setIsLoading(!isLoading)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: professionalPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, professionalPerPage])

  const goBack = () => navigate(ROUTE_CONFIGURATION)

  const createProfessionalType = () => {
    if (currentProfessionalType) {
      currentPT.current.id = uuidv4()
      professionalTypeService.create(toModel(currentPT.current)).subscribe((res) => {
        setIsLoading(!isLoading)
      })
    }

    setOpenCreateModal(false)
  }

  const handleCreateVariable = (name: string, description: string) => {
    currentPT.current = Object.assign({ ...currentProfessionalType }, { name, description })
  }

  const search: Search<ProfessionalType> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<ProfessionalType>[]) => {
      setSearcher(svs)
      setIsLoading(!isLoading)
    },
  }

  const editProfessionalType = (s: ProfessionalType) => {
    currentPT.current = s
    nameEdit.current = currentPT.current.name
    descriptionEdit.current = currentPT.current.description
    setOpenEditModal(true)
  }

  const objectEditProfessionalType = (name: string, description: string) => {
    currentPT.current = Object.assign(
      { ...currentProfessionalType },
      { id: currentPT.current.id, name, description }
    )
  }

  const editSaveProfessionalType = () => {
    if (currentProfessionalType) {
      professionalTypeService.update(toModel(currentPT.current)).subscribe((res) => {
        setIsLoading(!isLoading)
      })
    }

    setOpenEditModal(false)
  }

  const removeProfessionalType = (s: ProfessionalType) => {
    setCurrentProfessionalType(s)
    setOpenDeleteModal(true)
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handleCloseCreateModal = () => {
    setOpenCreateModal(false)
  }

  const handleCloseEditModal = () => {
    setOpenEditModal(false)
  }

  const handleDeleteProfessionalType = () => {
    if (currentProfessionalType?.id) {
      professionalTypeService
        .delete(currentProfessionalType.id)
        .subscribe(() => setIsLoading(!isLoading))
    }
    setOpenDeleteModal(false)
  }

  const fields: Field<ProfessionalType>[] = [
    {
      name: 'name',
      label: t('name'),
    },
    {
      name: 'description',
      label: t('description'),
    },
  ]

  const actions: Actions<ProfessionalType> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: editProfessionalType,
        icon: editIcon,
        label: 'edit',
      },
      {
        handler: removeProfessionalType,
        icon: deleteIcon,
        label: 'delete',
      },
    ],
  }

  return (
    <>
      <Box mb={3} display="flex" justifyContent="space-between">
        <AppButton
          theme={ButtonTheme.NewSecondary}
          type={'button'}
          label={t('back')}
          handler={goBack}
        />
        <AppButton
          theme={ButtonTheme.NewPrimary}
          type={'button'}
          label={t('add_proffessionalType')}
          handler={() => setOpenCreateModal(true)}
        />
      </Box>

      <Box>
        <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
          <CustomModal
            handleClose={handleCloseDeleteModal}
            handleSave={handleDeleteProfessionalType}
            title={t('deleteProfessionalType')}
            warningText={t('irreversibleProfessionalTypeAction')}
          />
        </Modal>

        <Modal open={openCreateModal} onClose={handleCloseCreateModal}>
          <CustomModal
            handleClose={handleCloseCreateModal}
            handleSave={createProfessionalType}
            handleCreateVariable={handleCreateVariable}
            title={t('add_proffessionalType')}
            create={true}
          />
        </Modal>
        <Modal open={openEditModal} onClose={handleCloseEditModal}>
          <CustomModal
            handleClose={handleCloseEditModal}
            handleSave={editSaveProfessionalType}
            handleCreateVariable={objectEditProfessionalType}
            title={t('edit_proffessionalType')}
            create={true}
            name={nameEdit.current}
            description={descriptionEdit.current}
          />
        </Modal>
        <AppTable
          items={professional ?? []}
          rowKeyField="id"
          fields={fields}
          actions={actions}
          pager={pager}
          search={search}
        />
      </Box>
    </>
  )
}

const searcherQuery = (
  svs: SearchValue<ProfessionalType>[]
): QueryParam<ProfessionalType>[] | QueryParamN<ProfessionalType>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))
