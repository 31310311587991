import { Visualizations } from './Visualizations'
import { v4 as uuidv4 } from 'uuid'

export interface VisualizationsDTO {
  id: string
  articleID: string
  userID: string
  circleID: string
  createdAt: Date
}

export function emptyVisualizationsDTO(): VisualizationsDTO {
  return {
    id: uuidv4(),
    articleID: '',
    userID: '',
    circleID: '',
    createdAt: new Date(),
  }
}

export function fromModel(t: Visualizations): VisualizationsDTO {
  return {
    id: t.id,
    articleID: t.articleID,
    userID: t.userID,
    circleID: t.circleID,
    createdAt: t.createdAt,
  }
}

export function toModel(d: VisualizationsDTO): Visualizations {
  return new Visualizations(d)
}
