import { UserPending } from './UserPending'

export interface UserPendingDTO {
  email: string
  token: string
  roleId: string
  circleId: string
}

export function pendingToModel(d: UserPendingDTO): UserPending {
  return new UserPending(d)
}
