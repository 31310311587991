import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getActivePlanContainer } from '../../../container/active-plan-module'
import { ActivePlanService } from '../../../modules/active-plan/services/ActivePlanService'
import { ACTIVE_PLAN_SERVICE_KEY } from '../../../modules/active-plan'

import { ActivePlan } from '../../../modules/active-plan/models/ActivePlan'
import {
  emptyActivePlanDTO,
  toModel as toModelActivePlan,
  fromModel as fromModelActivePlan,
} from '../../../modules/active-plan/models/ActivePlanDTO'
import { Box, Tab, Tabs } from '@mui/material'
import Typography from '@mui/material/Typography'
import { InclutionData } from './InclutionData'
import { GeneralData } from './GeneralData'
import {
  TrainingGroup,
  TrainingGroupQuery,
} from '../../../modules/training-group/models/TrainingGroup'
import {
  emptyTrainingGroupDTO,
  toModel as toModelGroup,
  trainingGroupToModelList,
} from '../../../modules/training-group/models/TrainingGroupDTO'
import { SportCenter } from '../../../modules/sport-center/models/SportCenter'
import {
  emptySportCenterDTO,
  toModel as toModelSportCenter,
} from '../../../modules/sport-center/models/SportCenterDTO'
import { getTrainingGroupContainer } from '../../../container/training-group-module'
import { TrainingGroupService } from '../../../modules/training-group/services/TrainingGroupService'
import { TRAINING_GROUP_SERVICE_KEY } from '../../../modules/training-group'
import { getSportCenterContainer } from '../../../container/sport-center-module'
import { SportCenterService } from '../../../modules/sport-center/services/SportCenterService'
import { SPORT_CENTER_SERVICE_KEY } from '../../../modules/sport-center'
import style from './ActivePlan.module.css'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import { UnsubscribeActivePlan } from './UnsubscribeActivePlan'
import { ActivePlanStatusType } from '../../../common/enums/activePlan'
import { SelectGroup } from './SelectGroup'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { Query, QueryParam } from '../../../common/api/Query'
import { getUserTrainingGroupContainer } from '../../../container/user-training-group-module'
import { UserTrainingGroupService } from '../../../modules/user-training-group/services/UserTrainingGroupService'
import { USER_TRAINING_GROUP_SERVICE_KEY } from '../../../modules/user-training-group'
import {
  UserTrainingGroup,
  UserTrainingGroupQuery,
} from '../../../modules/user-training-group/models/UserTrainingGroup'
import { getUserCircleContainer } from '../../../container/user-circle-module'
import {
  IUserCircleActiveService,
  USER_CIRCLE_ACTIVE_SERVICE_KEY,
} from '../../../modules/user-circle'
import {
  emptyUserTrainingGroupDTO,
  toModel as UGtoModel,
  UserTrainingGroupDTO,
} from '../../../modules/user-training-group/models/UserTrainingGroupDTO'
import { useSnackbar } from 'notistack'
import { Reports } from './Reports'

const activePlanService = getActivePlanContainer().get<ActivePlanService>(ACTIVE_PLAN_SERVICE_KEY)
const groupService = getTrainingGroupContainer().get<TrainingGroupService>(
  TRAINING_GROUP_SERVICE_KEY
)
const sportCenterService =
  getSportCenterContainer().get<SportCenterService>(SPORT_CENTER_SERVICE_KEY)
const userCircleGroupService = getUserTrainingGroupContainer().get<UserTrainingGroupService>(
  USER_TRAINING_GROUP_SERVICE_KEY
)
const userCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)

export function MainBoard() {
  const { t } = useTranslation()
  const [activePlan, setActivePlan] = useState<ActivePlan>(toModelActivePlan(emptyActivePlanDTO()))
  const [group, setGroup] = useState<TrainingGroup>(toModelGroup(emptyTrainingGroupDTO()))
  const [selectedGroup, setSelectedGroup] = useState<UserTrainingGroupDTO>(
    emptyUserTrainingGroupDTO()
  )
  const [sportCenter, setSportCenter] = useState<SportCenter>(
    toModelSportCenter(emptySportCenterDTO())
  )
  const [tabValue, setTabValue] = useState<number>(0)
  const [openUnsubscribe, setOpenUnsubscribe] = useState<boolean>(false)
  const [openSelectGroup, setOpenSelectGroup] = useState<boolean>(false)
  const [groupsMap, setGroupMap] = useState<Map<string, TrainingGroup[]>>(
    new Map<string, TrainingGroup[]>()
  )
  const [registeredUserMap, setRegisteredUserMap] = useState<Map<string, number>>(
    new Map<string, number>()
  )
  const [sportCenters, setSportCenters] = useState<ItemList<SportCenter>>(emptyList<SportCenter>())
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [userCircleGroup, setUserCircleGroup] = useState<UserTrainingGroup>(
    UGtoModel(emptyUserTrainingGroupDTO())
  )
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (!isLoading) return
    userCircleGroupService
      .getByUserCircleID(userCircleActiveService.getActiveFullUserCircle()?.id ?? '')
      .subscribe((res) => {
        if (!res) {
          return
        }
        setUserCircleGroup(res)
      })

    activePlanService
      .getByUserCircleID(userCircleActiveService.getActiveFullUserCircle()?.id ?? '')
      .subscribe((res) => {
        if (!res) {
          return
        }
        setActivePlan(res[0])
      })

    groupService
      .getByUserCircleID(userCircleActiveService.getActiveFullUserCircle()?.id ?? '')
      .subscribe((res) => {
        if (!res) {
          return
        }
        setGroup(res)
        getSportCenter(res)
      })
  }, [isLoading])

  const getSportCenter = (g: TrainingGroup) => {
    if (g.centerID === '') {
      return
    }

    sportCenterService.getByID(g.centerID).subscribe((res) => {
      if (!res) return
      setSportCenter(res)
      setIsLoading(!isLoading)
    })
  }

  useEffect(() => {
    sportCenterService
      .getFilteredList(
        new Query({
          query: [new QueryParam('cityHallID', 'fccd3216-8a48-4e3f-b240-7e788f2ffb3b')],
        })
      )
      .subscribe((res) => {
        if (res) {
          setSportCenters(res)
          getGroups(res)
        }
      })
  }, [isLoading])

  const getGroups = (centers: ItemList<SportCenter>) => {
    if (!isLoading) return
    if (centers.items.length === 0) return

    const auxMap = new Map<string, TrainingGroup[]>()
    centers.items.forEach((center) => {
      groupService
        .getFilteredList(
          new Query<TrainingGroupQuery>({
            query: [new QueryParam<TrainingGroup>('centerID', center.id)],
          })
        )
        .subscribe((res) => {
          if (!res) return
          getRegistered(res.items)
          auxMap.set(center.id, trainingGroupToModelList(res.items))
        })
    })
    setIsLoading(!isLoading)
    setGroupMap(auxMap)
  }

  const getRegistered = (groups: TrainingGroup[]) => {
    const auxMap = registeredUserMap
    groups.forEach((g) => {
      userCircleGroupService
        .getFilteredList(
          new Query<UserTrainingGroupQuery>({
            query: [new QueryParam<UserTrainingGroupQuery>('groupID', g.id)],
            pager: { offset: 0, limit: 0 },
          })
        )
        .subscribe((res) => {
          if (!res) return
          auxMap.set(g.id, res.count)
        })
    })
    setRegisteredUserMap(auxMap)
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const handleCloseUnsubscribe = () => {
    const activePlanTMP = fromModelActivePlan(activePlan)
    activePlanTMP.status = ActivePlanStatusType.Pending
    activePlanTMP.reason = ''
    setActivePlan(toModelActivePlan(activePlanTMP))
    setOpenUnsubscribe(false)
    setIsLoading(true)
  }

  const handleOpenUnsubscribe = () => {
    const activePlanTMP = fromModelActivePlan(activePlan)
    activePlanTMP.status = ActivePlanStatusType.Refused
    activePlanTMP.reason = 'cantAssist'
    setActivePlan(toModelActivePlan(activePlanTMP))
    setOpenUnsubscribe(true)
  }

  const handlerSetMessage = (value: string) => {
    const activePlanTMP = fromModelActivePlan(activePlan)
    activePlanTMP.status = ActivePlanStatusType.Refused
    activePlanTMP.reason = value
    setActivePlan(toModelActivePlan(activePlanTMP))
  }

  const handleUnsubscribe = () => {
    activePlanService.update(fromModelActivePlan(activePlan)).subscribe((res) => {})
    userCircleGroupService
      .deleteByUserCircleID(userCircleActiveService.getActiveFullUserCircle()?.id ?? '')
      .subscribe((res) => {
        setSportCenter(toModelSportCenter(emptySportCenterDTO()))
        setGroup(toModelGroup(emptyTrainingGroupDTO()))
        setUserCircleGroup(UGtoModel(emptyUserTrainingGroupDTO()))
      })
    setOpenUnsubscribe(false)
    setIsLoading(true)
  }

  const handlerOpenSelectGroup = () => {
    setOpenSelectGroup(true)
  }

  const handlerCloseSelectGroup = () => {
    setOpenSelectGroup(false)
  }

  const handlerSetGroup = (groupID: string) => {
    setSelectedGroup(
      Object.assign(
        { ...selectedGroup },
        {
          userCircleID: userCircleActiveService.getActiveFullUserCircle()?.id ?? '',
          groupID: groupID,
        }
      )
    )
  }

  const handlerAcceptedSelectGroup = () => {
    const activePlanTMP = fromModelActivePlan(activePlan)
    const dateOne = new Date()
    const dateTwo = new Date()
    dateTwo.setMonth(dateTwo.getMonth() + 6)
    activePlanTMP.status = ActivePlanStatusType.Active
    activePlanTMP.reason = ''
    activePlanTMP.startDate = dateOne
    activePlanTMP.finishDate = dateTwo

    if (userCircleGroup.groupID === '') {
      activePlanService.update(activePlanTMP).subscribe((res) => {
        userCircleGroupService.add(selectedGroup).subscribe((res) => {
          enqueueSnackbar(t('changesWereSaved'), { variant: 'success' })
          setIsLoading(!isLoading)
          handlerCloseSelectGroup()
        })
      })
    } else {
      userCircleGroupService.update(selectedGroup).subscribe((res) => {
        enqueueSnackbar(t('changesWereSaved'), { variant: 'success' })
        setIsLoading(!isLoading)
        handlerCloseSelectGroup()
      })
    }
  }

  return (
    <>
      <Box className={style.mainTabs}>
        <Tabs
          orientation="horizontal"
          variant="scrollable"
          indicatorColor="secondary"
          textColor="inherit"
          value={tabValue}
          onChange={handleChangeTab}
          sx={{
            '& .Mui-selected': {
              backgroundColor: 'white !important',
            },
            '& .MuiButtonBase-root.MuiTab-root': {
              backgroundColor: '#f2f2f3',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              boxShadow: '1px 5px 20px rgba(0, 0, 0, 0.1)',
              fontWeight: 'bold',
              fontFamily: 'Poppins',
              fontSize: '18px',
              padding: '5px 20px 5px 20px',
              textTransform: 'none',
            },
            '& .MuiTabs-indicator': {
              backgroundColor: 'white',
            },
            margin: '0 !important',
            padding: '0 !important',
          }}
        >
          <Tab label={t('inclutionData')} {...a11yProps(0)} className={style.pageContainer} />
          <Tab label={t('mainData')} {...a11yProps(1)} />
          <Tab label={t('reports')} {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <InclutionData />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <GeneralData
            activePlan={activePlan}
            group={group}
            sportCenter={sportCenter}
            isUnsubscribe={openUnsubscribe}
          />
          <Box className={style.containerRowButton}>
            <Box className={style.containerButtonSpace}>
              <AppButton
                theme={ButtonTheme.whiteAndBlue}
                type={'button'}
                label={t('getOutProgram')}
                handler={handleOpenUnsubscribe}
              />
            </Box>
            <Box className={style.containerButtonSpace}>
              <AppButton
                theme={ButtonTheme.newEvent}
                type={'button'}
                label={t('changeGroup')}
                handler={handlerOpenSelectGroup}
              />
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Reports/>
        </TabPanel>
      </Box>
      <UnsubscribeActivePlan
        handlerMessage={handlerSetMessage}
        open={openUnsubscribe}
        handleClose={handleCloseUnsubscribe}
        handlerAccept={handleUnsubscribe}
      />
      <SelectGroup
        open={openSelectGroup}
        handlerClose={handlerCloseSelectGroup}
        handlerAccepted={handlerAcceptedSelectGroup}
        handlerSetGroup={handlerSetGroup}
        sportCenters={sportCenters}
        groupsMap={groupsMap}
        registeredUserMap={registeredUserMap}
        userCircleGroup={userCircleGroup}
      />
    </>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Box
      className={style.pageContainer}
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  )
}

function a11yProps(index: number) {
  return {
    id: `horizontal-tab-${index}`,
    'aria-controls': `horizontal-tabpanel-${index}`,
  }
}
