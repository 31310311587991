import { RouteProps, useNavigate } from 'react-router-dom'
import { DashboardSectionCard } from '../../../components/section-card/DashboardSectionCard'
import { Box, Grid } from '@mui/material'
// import useMediaQuery from '@mui/material/useMediaQuery'
// import { useTheme } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { NotificationsCard } from './notification-card/NotificationsCard'
import { ChatsCard } from './chats-card/ChatsCard'
import { CirclesCard } from './circles-card/CirclesCard'
import { EducationalContentCard } from './educational-content-card/EducationalContentCard'
import { ActiveQueriesCard } from './active-query-card/ActiveQueriesCard'
import { MonitoredActivityCard } from './monitored-activity-card/MonitoredActivityCard'
import { ActivePlanCard } from './active-plan-card/ActivePlanCard'
import React, { useState } from 'react'
import { ACTIVE_PLAN_CIRCLE_ID } from '../../../common/const'
import { UserCircleWithRelatersAndCircle } from '../../../modules/user-circle/models/UserCircleWithRelatersAndCircle'
import { NumericStatsCard } from './numeric-stats-card/NumericStatsCard'
import { MonthView } from '../../calendar/MonthView'
import { useCalendarEvents } from '../../../hooks/calendarEvents/useCalendarEvents'
import { ROUTE_WEARABLES } from '../../../routes/routes-constants'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const DashboardPatient = (props: RouteProps) => {
  // const theme = useTheme()
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { calendarEvents } = useCalendarEvents()

  const [selectedUserCircle, setSelectedUserCircle] = useState<UserCircleWithRelatersAndCircle>()
  const [numberPendingChats, setNumberPendingChats] = useState<number>(0)
  const [numberNewResources, setNumberNewResources] = useState<number>(0)
  const today = new Date()
  const vp = {
    calendarEvents,
    selectedDate: new Date(today.getFullYear(), today.getMonth(), 1),
    handlerEdit: () => {},
    handlerRemove: () => {},
    handlerAdd: () => {},
    handleNextMonth: () => {},
    handlePreviousMonth: () => {},
    canChangeMonth: false,
  }
  const navigate = useNavigate()
  const wearables = () =>{
    navigate(ROUTE_WEARABLES)
  }
  return (
    <Box>
      <DashboardSectionCard label={'calendar'}>
        <MonthView {...vp} />
      </DashboardSectionCard>
      <DashboardSectionCard label={'generalSection'}>
        <Grid width={'100%'} container spacing={2}>
          <Grid mt={4} item xs={12} sm={6} md={4}>
            <NotificationsCard
              ProfessionalNotifications={false}
              SetNumberNewResources={setNumberNewResources}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <Grid display={'flex'} xs={12} sm={6} md={12}>
              <Grid mt={4} mr={2} item xs={12} sm={6} md={6}>
                <CirclesCard
                  selectedUserCircle={selectedUserCircle}
                  setSelectedUserCircle={setSelectedUserCircle}
                />
              </Grid>
              <Grid mt={4} item xs={12} sm={6} md={6}>
                <ChatsCard setNumberPendingChats={setNumberPendingChats} />
              </Grid>
            </Grid>
            <Grid display={'flex'} xs={12} sm={6} md={12}>
              <Grid mt={4} mr={2} item xs={12} sm={6} md={6}>
                <EducationalContentCard />
              </Grid>
              <Grid mt={4} item xs={12} sm={6} md={6}>
                <ActiveQueriesCard />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DashboardSectionCard>

      <DashboardSectionCard label={'wearables'} showMore={true} onClick={wearables}>
        <MonitoredActivityCard />
      </DashboardSectionCard>
      {selectedUserCircle?.circle.id === ACTIVE_PLAN_CIRCLE_ID && (
        <DashboardSectionCard label={'activePlan'} marginTop={50}>
          <ActivePlanCard />
        </DashboardSectionCard>
      )}

      <NumericStatsCard
        numberPendingChats={numberPendingChats}
        numberNewResources={numberNewResources}
      ></NumericStatsCard>
    </Box>
  )
}
