import { Modal, Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import style from './StatusCircleModal.module.scss'
import iconClose from '../../../../assets/dashboard/closeIcon.svg'
import filterIcon from '../../../../assets/dashboard/filterIcon.svg'
import { Header } from '../../../../components/header/Header'
import { useTranslation } from 'react-i18next'
import { Query, QueryParam } from '../../../../common/api/Query'
import { getActivePlanContainer } from '../../../../container/active-plan-module'
import { ActivePlanService } from '../../../../modules/active-plan/services/ActivePlanService'
import { ACTIVE_PLAN_SERVICE_KEY } from '../../../../modules/active-plan'
import { AppTable } from '../../../../components/table'
import { Field } from '../../../../components/table_type/types'
import { ActivePlanWithUser } from '../../../../modules/active-plan/models/ActivePlanWithUser'
import { ActivePlanQuery } from '../../../../modules/active-plan/models/ActivePlan'
import { StatusPlanActive } from '../../../../modules/active-plan/enums/StatusPlanActive'

type StatusCircleModalProps = {
  open: boolean
  onClose: () => void
}

interface Fields {
  name: string
  cip: string
  dni: string
  protocol: string
  status: string
}

const activePlanService = getActivePlanContainer().get<ActivePlanService>(ACTIVE_PLAN_SERVICE_KEY)

export const StatusCircleModal: React.FC<StatusCircleModalProps> = ({ open, onClose }) => {
  const [circles, setCircles] = useState<Fields[]>([])
  const { t } = useTranslation()

  useEffect(() => {
    const subscription = activePlanService
      .getFilteredList(new Query({ query: [new QueryParam<ActivePlanQuery>('withUser', 'true')] }))
      .subscribe((response) => {
        if (!response) return
        const activePlanWithUser = response.items as ActivePlanWithUser[]
        const arrayFields: Fields[] = []
        if (activePlanWithUser.length === 0) return console.log('No hay datos')
        activePlanWithUser.forEach((item) => {
          arrayFields.push({
            name: item.user.name,
            cip: item.user.cip,
            dni: item.user.dni,
            protocol: item.protocol,
            status: item.status,
          })
        })
        setCircles(arrayFields)
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  const fields: Field<Fields>[] = [
    {
      name: 'name',
      label: t('name'),
    },
    {
      name: 'cip',
      label: t('cip'),
    },
    {
      name: 'dni',
      label: t('dni'),
    },
    {
      name: 'protocol',
      label: t('protocol'),
    },
    {
      name: 'status',
      label: t('status'),
    },
  ]

  return (
    <Modal className={style.modalContainer} open={open} onClose={onClose}>
      <Box className={style.content}>
        <Box className={style.iconContainer}>
          <img className={style.icon} src={iconClose} alt={'icon close'} onClick={onClose} />
        </Box>
        <Header label={'circleStatus'} />
        <Box>
          <Box mt={4} className={style.filterContainer}>
            <Box display={'flex'}>
              <img className={style.iconFilter} src={filterIcon} alt={'filter icon'} />
              <Typography className={style.filterText}>{t('filters')}</Typography>
            </Box>
          </Box>
          <Box>
            <Typography className={style.filterByCategoryText}>
              {t('filterByCategories')}:{' '}
            </Typography>
          </Box>
          <Box>
            <AppTable
              fields={fields}
              items={circles}
              rowKeyField={'cip'}
              rowStyleFunc={(item) => {
                switch (item.status) {
                  case StatusPlanActive.Active:
                    return '1px solid #6CE32B'
                  case StatusPlanActive.Pending:
                    return '1px solid #F18B24'
                  case StatusPlanActive.Rejected:
                    return '1px solid #CF3031'
                  case StatusPlanActive.Inactive:
                    return '1px solid #B6B5B5'
                  default:
                    return '1px solid #DBDADA' // Estilo por defecto
                }
              }}
            />{' '}
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
