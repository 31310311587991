// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Table_icon__1vl4E {\n  vertical-align: middle;\n  margin-right: 10px;\n}\n\n.Table_dir__3TKWX {\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://src/features/resource-patientView/Table.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".icon {\n  vertical-align: middle;\n  margin-right: 10px;\n}\n\n.dir {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "Table_icon__1vl4E",
	"dir": "Table_dir__3TKWX"
};
export default ___CSS_LOADER_EXPORT___;
