import React, { FormEvent, useEffect, useState } from 'react'
import { ARTICLE_SERVICE_KEY, TAG_SERVICE_KEY } from '../../modules/content'
import { FormCard } from '../../components/form-card/FormCard'
import { Box, MenuItem, Modal, Select, TextField } from '@mui/material'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { useNavigate } from 'react-router-dom'
import { ROUTE_ADMIN_TUTORIAL_TEMPLATES, ROUTE_MY_POSTS_CONTENT } from '../../routes/routes-constants'
import { useTranslation } from 'react-i18next'
import { getContentContainer } from '../../container/content-module'
import { ArticleDTO, emptyArticleDTO, fromModel } from '../../modules/content/models/ArticleDTO'
import { ArticleService } from '../../modules/content/services/ArticleService'
import { FileItem } from '../../components/form-card/FileItem'
import { File as F } from '../../modules/files/models/File'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import { TagService } from '../../modules/content/services/TagService'
import { Query } from '../../common/api/Query'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { TextFieldItem } from '../../components/form-card/TextFieldItem'
import { forkJoin, Observable } from 'rxjs'
import { Tag } from '../../modules/content/models/Tag'
import { RichText } from '../../components/text-editor/RichText'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { UserCircleService } from '../../modules/user-circle/services/UserCircleConfigService'
import { USER_CIRCLE_SERVICE_KEY } from '../../modules/user-circle'
import { ArticleRelevance, relevanceTypes } from 'modules/content/enums/ArticleRelevance'
import { ArticleStatus, statusTypes } from 'modules/content/enums/ArticleStatus'
import { DatePicker } from '@mui/x-date-pickers'
import { Header } from 'components/header/Header'
import { UserCircleWithCircle } from 'modules/user-circle/models/UserCircleWithCircle'
import { CircleDTO } from '../../modules/circle/models/CircleDTO'
import { getCircleContainer } from '../../container/circle-module'
import { CIRCLE_SERVICE_KEY, ICircleService } from '../../modules/circle'
import { CircleQuery } from '../../modules/circle/models/Circle'
import { emptyFileDTO, FileDTO } from '../../modules/files/models/FileDTO'
import Autocomplete from '@mui/material/Autocomplete'
import Alert from '@mui/material/Alert'
import genericStyle from '../../common/utils/generic.module.css'

import relevanciaNaranja from '../../assets/esfera/article/relevancia naranja.svg'
import relevanciaRojo from '../../assets/esfera/article/relevancia rojo.svg'
import relevanciaVerde from '../../assets/esfera/article/relevancia verde.svg'
import style from '../segmented-notifications-feat/CustomModal.module.css'

enum ArticleErrors {
  NO_TAGS = 'articleMustHaveTags',
}

type EditorProps = {
  id?: string
}

interface AutocompleteTagInterface {
  key: string
  value: string
}

const contentContainer = getContentContainer()
const articleService = contentContainer.get<ArticleService>(ARTICLE_SERVICE_KEY)
const tagService = contentContainer.get<TagService>(TAG_SERVICE_KEY)

const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

const fileContainer = getFileContainer()
const fileService = fileContainer.get<FileService>(FILE_SERVICE_KEY)

const articleRelevances = relevanceTypes()
const articleStatus = statusTypes()

const userCircleService = getUserCircleContainer().get<UserCircleService>(USER_CIRCLE_SERVICE_KEY)
const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)
export function Editor(props: EditorProps) {
  const { t } = useTranslation()

  const loggedUser = loggedUserService.get()
  const [circleUser, setCircleUser] = useState<UserCircleWithCircle>()
  const [article, setArticle] = useState<ArticleDTO>(emptyArticleDTO())
  const [circle, setCircle] = useState<CircleDTO[]>([])
  const [userCircles, setUserCircles] = useState<any>([])
  const [tagMap, setTagMap] = useState<Map<string, AutocompleteTagInterface>>(new Map())
  const [files, setFiles] = useState<F[]>([])
  const [file, setFile] = useState<FileDTO>(emptyFileDTO(loggedUser?.id ?? '', undefined, false))
  const [filesFront, setFilesFront] = useState<F[]>([])
  const [selectedTags, setSelectedTags] = useState<AutocompleteTagInterface[]>([])
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(!!props.id)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const navigate = useNavigate()

  const getTags = (ids: string[]): Observable<Tag[]> =>
    forkJoin(ids.map((id) => tagService.getByID(id)) as unknown) as Observable<Tag[]>

  useEffect(() => {
    if (props.id) {
      articleService.getByID(props.id).subscribe((res) => {
        if (res) {
          setArticle(fromModel(res))
          setIsLoading(false)
          if(res.rejectedReason !== undefined){
            setOpenModal(true)
          }

          props.id && fileService.getByArticleID(props.id).subscribe(async resp => {
            let file = resp.find(obj => !obj.mimeType.includes('image'))
            let files: any = []
            let filesFront: any = []
            if(file){
              files.push(file)
            }
            let imageRender = resp.find(obj => obj.mimeType.includes('image'))
            if(imageRender){
              filesFront.push(imageRender)
            }
            files.length > 0 && setFiles(files)
            filesFront.length > 0 && setFilesFront(filesFront)
          })

          getTags(res.tags).subscribe((res) => {
            setSelectedTags(res.filter((t) => t).map((t) => ({ key: t.id, value: t.name })))
          })
        }
      })
    }

    circleService.getFilteredList(new Query<CircleQuery>({})).subscribe((res) => {
      if (!res) return
      setCircle(res.items)
    })

    tagService.getFilteredList(new Query({ sort: [{ field: 'name' }] })).subscribe((res) => {
      const result = new Map()
      res.items.forEach((t) => {
        result.set(t.id, { key: t.id, value: t.name })
      })
      setTagMap(new Map(result))
    })

    if (loggedUser) {
      userCircleService.getUserCirclesByUserId(loggedUser.id).subscribe((res) => {
        if(res && (res?.length > 0)){
          setUserCircles(res)
        }
      })
      userCircleService.getUserCircleByUserIDWithCircle(loggedUser.id).subscribe((res) => {
        const userCircleSelected = res?.find((userCircle) => {
          return userCircle.userID === loggedUser.id
        })
        if (userCircleSelected !== null) {
          setCircleUser(userCircleSelected)
        }

        if (res !== undefined && props.id === undefined && userCircleSelected !== undefined) {
          const userID = loggedUser?.id ?? ''
          setArticle(
            Object.assign(
              { ...article },
              { circleId: "Selecciona un círculo", userID: userID }
            )
          )
        }
      })
    }
  }, [])

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setArticle(Object.assign({ ...article }, { [e.target.name]: e.target.value }))
  }

  const handleContent = (e: string) => {
    setArticle(Object.assign({ ...article }, { content: e }))
  }

  const handleCircle = (event: any) =>{
    let artType = ''
    let logged = JSON.parse(localStorage.getItem('logged user') || 'null').name

    if (article.type !== 'article' && article.type !== '') {
      artType = article.type
    } else {
      artType = 'article'
    }
    setArticle(Object.assign({ ...article }, { circleId: event.target.value as string, category: +(event.target.value as number), type: artType, authorName: logged }))
  }


  const handleRelevance = (event: any) =>
    setArticle(Object.assign({ ...article }, { relevance: event.target.value as string }))

  const handleSend = (event: any) =>
    setArticle(Object.assign({ ...article }, { status: ArticleStatus[ArticleStatus.pendiente] }))

  const handleSave = (event: any) =>
    setArticle(Object.assign({ ...article }, { status: ArticleStatus[ArticleStatus.borrador] }))

  const handleFiles = (field: string, value: F[]) => {
    setFiles([...value])
    let fileUpload = file

    if (value && value.length >= 0) {
      fileUpload = value[0]
    }
    setFile(fileUpload)
  }

  const handleFilesFront = (field: string, value: F[]) => {
    setFilesFront([...value])
    setArticle(Object.assign({ ...article }, { slug: value.map((f) => f.id)[0] }))
  }

  const handleTags = (value: any) => {
    setSelectedTags(value)
    setArticle(
      Object.assign({ ...article }, { tags: Array.isArray(value) ? value.map((t) => t.key) : [] })
    )
  }

  const goBack = () => {
    if(article.type==='article'){
      navigate(ROUTE_MY_POSTS_CONTENT)
    }else{
      navigate(ROUTE_ADMIN_TUTORIAL_TEMPLATES)
    }
  }

  const validateArticle = (): boolean => {
    if (!article.tags || article.tags.length === 0 && article.type === "article") {
      setErrorMessage(ArticleErrors.NO_TAGS)
      return false
    }
    if (!article.category) {
      setErrorMessage("Selecciona un círculo para continuar")
      return false
    }
    setErrorMessage('')
    return true
  }

  const saveArticle = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!validateArticle()) {
      return
    }
    let userCircleId = userCircles.find((obj:any) => obj.circle.id === article.category.toString()).id
    article.rejectedReason = undefined
    article.ogTitle = userCircleId
    article.userCircleID = userCircleId
    if (props.id) {
      articleService.update(article).subscribe((_) => goBack())
    } else {
      articleService.add(article).subscribe((_) => navigate(ROUTE_MY_POSTS_CONTENT))
    }
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  return (
    <Box className={genericStyle.pageContainer}>
      <Modal open={openModal} onClose={handleCloseModal}>
        <div className={style.agoraContainer}>
          <Box className={style.containerInside} style={{ width: '500px' }}>
            {/*<Header label={newNotification ? t('successfullySended') : t('successfullySended')}*/}
            {/*        icon={close}*/}
            {/*        onClick={closeCreated} />*/}
            <Box display="flex" justifyContent="center" textAlign={'center'} className={'titleSend'}>
              <h2 style={{ color: '#f29940' }}>
                {'Articulo rechazado'}
              </h2>

            </Box>
            <Box display="flex" justifyContent="center" textAlign={'center'} className={'titleSend'}>
              <h3>
                {article.rejectedReason}
              </h3>
            </Box>
            <Box display="flex" justifyContent="center">
              <Box style={{ padding: '10px' }}>
                <AppButton
                  theme={ButtonTheme.NewPrimary}
                  type={'submit'}
                  label={t('close')}
                  handler={handleCloseModal}
                />
              </Box>
            </Box>
          </Box>
        </div>
      </Modal>
      <Box>
        <Header label={t('content')} />
      </Box>

      <FormCard>
        <form onSubmit={(e) => saveArticle(e)}>
          <Box mb={3}>
            <p>{t('circle')}</p>
            <Select
              key={'circleId'}
              id={'circleId'}
              variant={'outlined'}
              value={article.circleId}
              onChange={handleCircle}
              required
              MenuProps={{
                MenuListProps: {
                  sx: {
                    'li.MuiButtonBase-root': {
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      fontFamily: 'Poppins',
                      paddingLeft: '10px',
                    },
                  },
                },
              }}
              SelectDisplayProps={{
                style: {
                  paddingLeft: '20px',
                  fontFamily: 'Poppins',
                },
              }}
              style={{ backgroundColor: '#f5f2f2', height: '40px', minWidth: '30%' }}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem key={"0"} value={"Selecciona un círculo"}>
                {"Selecciona un círculo"}
              </MenuItem>
              {userCircles.map((k:any) => {
                const keySelect = k.circle.id
                const labelSelect = k.circle.name

                return (
                  <MenuItem key={keySelect} value={keySelect}>
                    {labelSelect}
                  </MenuItem>
                )
              })}
              {/*<MenuItem value={circleUser?.circle.id}>{circleUser?.circle.name}</MenuItem>*/}
            </Select>
          </Box>

          <Box display={'flex'} alignItems={'center'}>
            <h4 style={{ marginRight: 8 }}>{t('dateCompletedForm')}</h4>
            <DatePicker
              renderInput={(params) => <TextField {...params} />}
              inputFormat={"dd/MM/yyyy"}
              key={article.id}
              value={article.createdAt}
              onChange={() => {}}
              disabled={true}
            />
          </Box>

          <Box mb={3}>
            <p>{t('selectRelevance')}</p>
            <Select
              key={'relevance'}
              id={'relevance'}
              variant={'outlined'}
              value={article.relevance}
              onChange={handleRelevance}
              required
              // fullWidth
              MenuProps={{
                MenuListProps: {
                  sx: {
                    'li.MuiButtonBase-root': {
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      fontFamily: 'Poppins',
                      paddingLeft: '10px',
                    },
                  },
                },
              }}
              SelectDisplayProps={{
                style: {
                  paddingLeft: '20px',
                  fontFamily: 'Poppins',
                },
              }}
              style={{ backgroundColor: '#f5f2f2', height: '40px', minWidth: '30%' }}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {Object.keys(articleRelevances).map((k) => {
                const relevanceText = articleRelevances[k as unknown as ArticleRelevance]
                const claveRelevancia = ArticleRelevance[parseInt(k)]

                let relevanceIcon = ''

                switch (parseInt(k)) {
                  case ArticleRelevance.baja:
                    relevanceIcon = relevanciaVerde
                    break
                  case ArticleRelevance.media:
                    relevanceIcon = relevanciaNaranja
                    break
                  case ArticleRelevance.alta:
                    relevanceIcon = relevanciaRojo
                    break
                  default:
                    break
                }

                return (
                  <MenuItem key={claveRelevancia} value={claveRelevancia}>
                    <div>
                      <img src={relevanceIcon}></img> {relevanceText}
                    </div>
                  </MenuItem>
                )
              })}
            </Select>
          </Box>

          {
            (article.type === 'article' || article.type === '') &&
            <Box mb={3}>
              <Autocomplete
                key={article.id}
                id={`${article.id}-standard`}
                options={Array.from(tagMap.values())}
                getOptionLabel={(opt: any) => opt.value}
                multiple={true}
                value={selectedTags}
                onChange={(_: any, v: any) => handleTags(v)}
                renderInput={(params: any) => (
                  <TextField {...params} variant="standard" label={t('tags')} />
                )}
                noOptionsText={t('noElementsAvailable')}
              />
            </Box>
          }

          <Box mb={3}>
            <p>{t('status')}</p>
            <Select
              key={'status'}
              id={'status'}
              variant={'outlined'}
              value={article.status}
              disabled={true}
              MenuProps={{
                MenuListProps: {
                  sx: {
                    'li.MuiButtonBase-root': {
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      fontFamily: 'Poppins',
                      paddingLeft: '10px',
                    },
                  },
                },
              }}
              SelectDisplayProps={{
                style: {
                  paddingLeft: '20px',
                  fontFamily: 'Poppins',
                },
              }}
              style={{ backgroundColor: '#f5f2f2', height: '40px', minWidth: '30%' }}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {Object.keys(articleStatus).map((k) => {
                const statusText = articleStatus[k as unknown as ArticleStatus]
                const claveEstado = ArticleStatus[parseInt(k)]

                return (
                  <MenuItem key={claveEstado} value={claveEstado}>
                    {statusText}
                  </MenuItem>
                )
              })}
            </Select>
          </Box>

          <Box mb={3}>
            <TextFieldItem
              field="title"
              value={article.title}
              label={'title'}
              type={'text'}
              handleChange={handleInput}
              rows={undefined}
              required={true}
            />
          </Box>

          <Box
            mb={3}
            style={{ border: '1px solid rgb(203 203 203)', borderRadius: 4, minHeight: 400 }}
          >
            {!isLoading && (
              <RichText
                content={article.content}
                handleChange={(e) => handleContent(e)}
                edit={!!props.id}
              />
            )}
          </Box>

          {/*<Box mb={3}>*/}
          {/*  <p>{t('selectCategory')}</p>*/}
          {/*  <Select*/}
          {/*    key={'pathology'}*/}
          {/*    id={'pathology'}*/}
          {/*    variant={'outlined'}*/}
          {/*    value={article.category}*/}
          {/*    onChange={handleCategory}*/}
          {/*    required*/}
          {/*    // fullWidth*/}
          {/*    MenuProps={{*/}
          {/*      MenuListProps: {*/}
          {/*        sx: {*/}
          {/*          'li.MuiButtonBase-root': {*/}
          {/*            display: 'flex',*/}
          {/*            flexDirection: 'column',*/}
          {/*            alignItems: 'flex-start',*/}
          {/*            fontFamily: 'Poppins',*/}
          {/*            paddingLeft: '10px',*/}
          {/*          },*/}
          {/*        },*/}
          {/*      },*/}
          {/*    }}*/}
          {/*    SelectDisplayProps={{*/}
          {/*      style: {*/}
          {/*        paddingLeft: '20px',*/}
          {/*        fontFamily: 'Poppins',*/}
          {/*      },*/}
          {/*    }}*/}
          {/*    style={{ backgroundColor: '#f5f2f2', height: '40px', minWidth: '30%' }}*/}
          {/*    inputProps={{ 'aria-label': 'Without label' }}*/}
          {/*  >*/}
          {/*    {Object.values(circle).map((k:CircleDTO) => (*/}
          {/*      <MenuItem key={k.id} value={k.id}>*/}
          {/*        {k.name}*/}
          {/*      </MenuItem>*/}
          {/*    ))}*/}
          {/*  </Select>*/}
          {/*</Box>*/}

          <Box mb={3}>
            <FileItem
              key={'files'}
              field={'files'}
              filesID={[file ? (file.id ?? ''): '']}
              handleFiles={handleFiles}
              userID={loggedUser?.id ?? ''}
              cleanFiles={false}
              uploadRemoveFileDirectly={true}
              disabled={false}
              destinationFolder={'Article'}
              eventID={article.id}
              type={"File"}
              defaultFile={files}
              isMultiple={false}
              maxFiles={1}
              edit={true}
            />
          </Box>

          {
            article.type !== 'template' &&
            <Box mb={3}>
            <FileItem
              key={'files'}
              field={'files'}
              filesID={article.files}
              handleFiles={handleFilesFront}
              userID={loggedUser?.id ?? ''}
              cleanFiles={false}
              label={t('addCover')}
              uploadRemoveFileDirectly={true}
              disabled={false}
              type={'Cover'}
              destinationFolder={'Article'}
              eventID={article.id}
              defaultFile={filesFront}
              isMultiple={false}
              maxFiles={1}
              edit={true}
            />
          </Box>
          }

          {errorMessage && (
            <Box mb={3}>
              <Alert severity="warning" key="errorMessage" id="errorMessage">
                {t(errorMessage)}
              </Alert>
            </Box>
          )}

          <Box display="flex" justifyContent="space-between">
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('cancel')}
              handler={goBack}
            />

            <Box>
              <AppButton
                theme={ButtonTheme.NewSecondary}
                type={'submit'}
                label={t('save')}
                handler={handleSave}
              />
              <span style={{ width: '20px', display: 'inline-block' }}></span>
              {
                (article.type !== "tutorial" && article.type !== "template") &&
                <AppButton
                theme={ButtonTheme.NewPrimary}
                type={'submit'}
                label={t('send')}
                handler={handleSend}
              />
              }
            </Box>
          </Box>
        </form>
      </FormCard>
    </Box>
  )
}
