import { Container, IInit } from '../../../common/container/Container'
import { ContentContainerConfig } from '../container'
import { Likes } from '../models/Likes'
import { Observable, of } from 'rxjs'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { catchError, map } from 'rxjs/operators'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { LikesDTO, toModel } from '../models/LikesDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'

export interface ILikesApi extends IInit {
  getByID(id: string): Observable<Likes | undefined>

  getLikesByArticleID(id: string | undefined): Observable<ItemList<Likes>>

  getCheckLikeExist(artId: string, userId: string): Observable<any>

  getLikesByUserID(id: string): Observable<ItemList<Likes>>

  add(e: LikesDTO): Observable<Likes | undefined>

  update(e: LikesDTO): Observable<Likes | undefined>

  delete(id: string): Observable<boolean>
}

export class LikesApi implements ILikesApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as ContentContainerConfig).moduleFullUrl + '/likes'
  }

  add(e: LikesDTO): Observable<Likes | undefined> {
    return this._httpClient.post<LikesDTO>({ url: this._url, body: e }).pipe(
      map<LikesDTO, Likes>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<Likes | undefined> {
    return this._httpClient.get<Likes>({ url: `${this._url}/${id}` }).pipe(
      map<LikesDTO, Likes>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getCheckLikeExist(artId: string, userId: string): Observable<any> {
    return this._httpClient.get<any>({ url: `${this._url}/articles/${artId}/users/${userId}` })
  }

  getLikesByArticleID(id: string): Observable<ItemList<Likes>> {
    return this._httpClient.get<ItemList<Likes>>({ url: `${this._url}/articles/${id}` }).pipe(
      map<ItemList<LikesDTO>, ItemList<Likes>>((dto) => {
        const itemList = emptyList<Likes>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<Likes>())
      })
    )
  }

  getLikesByUserID(id: string): Observable<ItemList<Likes>> {
    return this._httpClient.get<ItemList<Likes>>({ url: `${this._url}/users/${id}` }).pipe(
      map<ItemList<LikesDTO>, ItemList<Likes>>((dto) => {
        const itemList = emptyList<Likes>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<Likes>())
      })
    )
  }

  update(e: LikesDTO): Observable<Likes | undefined> {
    return this._httpClient.put<LikesDTO>({ url: this._url, body: e }).pipe(
      map<LikesDTO, Likes>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
}
