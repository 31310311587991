import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { Actions, Field, Pager } from '../../../components/table_type/types'
import { useNavigate } from 'react-router-dom'
import { ROUTE_CREATE, ROUTE_GROWTH_MONITORING_FORM } from '../../../routes/routes-constants'
import { Box } from '@mui/material'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import { AppTable } from '../../../components/table'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import {
  GrowthMonitoring,
  GrowthMonitoringQuery,
} from '../../../modules/patient-data/models/GrowthMonitoring'
import { getPatientDataContainer } from '../../../container/patient-data-module'
import { Query, QueryParam } from '../../../common/api/Query'
import { GrowthMonitoringService } from '../../../modules/patient-data/services/GrowthMonitoringService'
import { dateToDateString, twoDatesToMonths } from '../../../common/utils/date'
import { growthMonitoringTypes } from '../../../modules/patient-data/enums/GrowthMonitoringType'
import { getUserContainer } from '../../../container/user-module'
import editIcon from '../../../assets/table_icons/ico-edit.svg'
import toAgoraIcon from '../../../assets/table_icons/ico-subir.svg'
import { CustomModal } from '../../../components/modal/CustomModal'
import { Modal } from '@mui/material'
import { GROWTH_MONITORING_SERVICE_KEY } from '../../../modules/patient-data/container'
import { LOGGED_USER_SERVICE_KEY } from '../../../modules/users/container'
import { LoggedUserService } from '../../../modules/users/services/LoggedUserService'

import {
  emptyGrowthMonitoringDTO,
  fromModel,
  toModel,
  GrowthMonitoringDTO,
} from '../../../modules/patient-data/models/GrowthMonitoringDTO'
import { Permission } from 'common/permission'
import { useGetActiveUser } from '../../../hooks/get-active-user/useGetActiveUser'

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

const patientDataContainer = getPatientDataContainer()
const growthMonitoringService = patientDataContainer.get<GrowthMonitoringService>(
  GROWTH_MONITORING_SERVICE_KEY
)

export const GrowthMonitoringTable = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { activeUser } = useGetActiveUser()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [count, setCount] = useState<number>(0)
  const [growths, setGrowths] = useState<ItemList<GrowthMonitoring>>(emptyList<GrowthMonitoring>())
  const [growthsPerPage, setGrowthsPerPage] = useState<number>(10)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [currentGrowthMonitoring, setCurrentGrowthMonitoring] = useState<GrowthMonitoring>()
  const [growthMonitoringDTO] = useState<GrowthMonitoringDTO>(
    currentGrowthMonitoring ? fromModel(currentGrowthMonitoring) : emptyGrowthMonitoringDTO()
  )

  const handleToAgora = (g: GrowthMonitoring) => {
    if (g) setCurrentGrowthMonitoring(g)
    setOpenModal(true)
  }

  const handleSave = () => {
    const newGrowth = Object.assign({ ...growthMonitoringDTO }, { isUploaded: true })
    growthMonitoringService.update(toModel(newGrowth)).subscribe()
    setCurrentGrowthMonitoring(undefined)
    setOpenModal(false)
  }

  useEffect(() => {
    if (!isLoading) {
      return
    }
    growthMonitoringService
      .getFilteredList(
        new Query({
          pager: { limit: growthsPerPage, offset: page * growthsPerPage },
          query: [new QueryParam<GrowthMonitoringQuery>('userID', activeUser.user?.id ?? '')],
          sort: [{ field: 'date', desc: true }],
        })
      )
      .subscribe((res) => {
        setCount(res.count)
        setGrowths(res)
        setIsLoading(false)
      })
  }, [isLoading])

  const handlePaginationChange = (event: unknown, value: number) => {
    setPage(value)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setGrowthsPerPage(10)
      return
    }
    setGrowthsPerPage(Number.parseInt(event.target.value))
  }

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: growthsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, growthsPerPage])

  const createGrowthMonitoring = () => navigate(`${ROUTE_GROWTH_MONITORING_FORM}/${ROUTE_CREATE}`)

  const editGrowthMonitoring = (s: GrowthMonitoring) =>
    navigate(`${ROUTE_GROWTH_MONITORING_FORM}/${s.id}`)

  const fields: Field<GrowthMonitoring>[] = [
    {
      name: 'type',
      label: t('type'),
      renderFunc: (f, i) => t(growthMonitoringTypes()[i.type]),
    },
    {
      name: 'value',
      label: t('value'),
      renderFunc: (f, i) => {
        switch (growthMonitoringTypes()[i.type]) {
          case growthMonitoringTypes()[1]:
            return `${i.value} kgs`
          case growthMonitoringTypes()[2]:
          case growthMonitoringTypes()[3]:
            return `${i.value} cm`
          default:
            return `${i.value}`
        }
      },
    },
    {
      name: 'date',
      label: t('date'),
      renderFunc: (f, i) => {
        if (activeUser.user?.birthDate) {
          return (
            new Date(i.date).toLocaleDateString('es', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            }) +
            ' - ' +
            twoDatesToMonths(new Date(i.date), new Date(activeUser.user.birthDate))
          )
        }
        return dateToDateString(new Date(i.date))
      },
    },
  ]
  // TODO add year of data or months when minus 2 years
  const actions: Actions<GrowthMonitoring> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: editGrowthMonitoring,
        icon: editIcon,
        label: 'edit',
      },
      {
        handler: handleToAgora,
        icon: toAgoraIcon,
        label: 'cloudToAgora',
        hidden: () => !loggedUserService.userCan(Permission.uploadToAgora),
      },
    ],
  }

  const emptyActions: Actions<GrowthMonitoring> = {
    items: [],
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  return (
    <Box>
      <Box
        mb={3}
        display="flex"
        justifyContent="space-between"
        // className={styles.growthBox}
        style={
          loggedUserService.userCan(Permission.growthMonitoringActions)
            ? { visibility: 'visible' }
            : { visibility: 'hidden' }
        }
      >
        {loggedUserService.userCan(Permission.growthMonitoringActions) && (
          <div>
            <AppButton
              theme={ButtonTheme.NewPrimaryResponsive}
              type={'button'}
              label={t('add')}
              handler={createGrowthMonitoring}
            />
          </div>
        )}
      </Box>
      <Box>
        <AppTable
          items={growths.items}
          rowKeyField="id"
          fields={fields}
          actions={
            loggedUserService.userCan(Permission.growthMonitoringActions) ? actions : emptyActions
          }
          pager={pager}
        />
      </Box>
      <Modal open={openModal} onClose={handleCloseModal}>
        <CustomModal
          handleClose={handleCloseModal}
          handleSave={handleSave}
          title={t('cloudToAgora')}
          warningText={t('irreversibleAction')}
        />
      </Modal>
    </Box>
  )
}
