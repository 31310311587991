import i18n from '../../../i18n'

export enum ArticleRelevance {
  baja, // 0
  media, // 1
  alta, // 2
}

export const relevanceTypes = (): Record<ArticleRelevance, string> => ({
  [ArticleRelevance.baja]: i18n.t('relevanceLow'),
  [ArticleRelevance.media]: i18n.t('relevanceMiddle'),
  [ArticleRelevance.alta]: i18n.t('relevanceHigh'),
})
