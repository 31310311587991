import React, { useEffect, useState } from 'react'
import { Box, Modal } from '@mui/material'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import { useNavigate } from 'react-router-dom'
import {
  ROUTE_CREATE,
  ROUTE_FAMILIAR_DATA_FORM,
  ROUTE_MEDICAL_DATA,
} from '../../../routes/routes-constants'
import { useTranslation } from 'react-i18next'
import { getUserContainer } from '../../../container/user-module'
import { IUserService, USER_SERVICE_KEY } from '../../../modules/users'
import { AppTable, Field } from '../../../components/table'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { FamiliarData, FamiliarDataQuery } from '../../../modules/users/models/FamiliarData'
import { Actions, Pager } from '../../../components/table_type/types'
import { Query, QueryParam } from '../../../common/api/Query'
import { dateToDateTimeWithoutSecondsString } from '../../../common/utils/date'
import { reduceString } from '../../../common/utils/strings'
import editIcon from '../../../assets/table_icons/ico-edit.svg'
import deleteIcon from '../../../assets/table_icons/ico-eliminar.svg'
import { LOGGED_USER_SERVICE_KEY } from '../../../modules/users/container'
import { LoggedUserService } from '../../../modules/users/services/LoggedUserService'
import { Permission } from '../../../common/permission'
import styles from './FamiliarData.module.css'
import { CustomModal } from 'components/modal/CustomModal'
import { useIsRelated } from 'hooks/relatedUsers/getRelatedUsers'
import { getUserCircleContainer } from '../../../container/user-circle-module'
import {
  IUserCircleActiveService,
  USER_CIRCLE_ACTIVE_SERVICE_KEY,
} from '../../../modules/user-circle'

const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const UserCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)
export function FamiliarDataTable() {
  const { t } = useTranslation()
  const activeUser = UserCircleActiveService.getActiveFullUserCircle()
  const navigate = useNavigate()
  const { innerWidth } = window
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [currentFamiliarData, setcurrentFamiliarData] = useState<FamiliarData>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pager, setPager] = useState<Pager>()
  const [count, setCount] = useState<number>(0)
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [page, setPage] = useState<number>(0)
  const [familiarData, setFamiliarData] =
    useState<ItemList<FamiliarData>>(emptyList<FamiliarData>())

  useEffect(() => {
    if (!isLoading || !activeUser?.id) {
      return
    }
    userService
      .getInformationByUser(
        new Query({
          query: [new QueryParam<FamiliarDataQuery>('userID', activeUser.user.id)],
          sort: [{ field: 'date', desc: true }],
        })
      )
      .subscribe((res) => {
        setIsLoading(false)
        setFamiliarData(res)
        setCount(res.count)
      })
  }, [isLoading])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setDataPerPage(10)
      return
    }
    setDataPerPage(Number.parseInt(event.target.value))
  }

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: dataPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, dataPerPage])

  const editFamiliarData = (f: FamiliarData) => navigate(`${ROUTE_FAMILIAR_DATA_FORM}/${f.id}`)

  const removeFamiliarData = (f: FamiliarData) => {
    setcurrentFamiliarData(f)
    setOpenDeleteModal(true)
  }

  const fields: Field<FamiliarData>[] = [
    {
      name: 'information',
      label: t('information'),
      renderFunc: (f, i) => reduceString(i.information, innerWidth > 598 ? 70 : 20),
    },
    {
      name: 'date',
      label: t('date'),
      renderFunc: (f, i) => dateToDateTimeWithoutSecondsString(new Date(i.date)),
    },
  ]

  const actions: Actions<FamiliarData> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: editFamiliarData,
        icon: editIcon,
        label: 'edit',
        hidden: useIsRelated()
          ? (familiarData) => false
          : (familiarData) => loggedUserService.userCan(Permission.createFamiliarData),
      },
      {
        handler: removeFamiliarData,
        icon: deleteIcon,
        label: 'delete',
        hidden: useIsRelated() ? (familiarData) => false : (familiarData) => true,
      },
    ],
  }

  const goBack = () => navigate(`${ROUTE_MEDICAL_DATA}`)

  const createFamiliarData = () => navigate(`${ROUTE_FAMILIAR_DATA_FORM}/${ROUTE_CREATE}`)

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handleDeleteFData = () => {
    if (currentFamiliarData?.id)
      if (currentFamiliarData.id) {
        userService.deleteInformation(currentFamiliarData.id).subscribe((_) => setIsLoading(true))
      }
    setOpenDeleteModal(false)
    setIsLoading(true)
  }

  return (
    <Box>
      <Box
        mb={3}
        display="flex"
        justifyContent="space-between"
        style={{ visibility: useIsRelated() ? 'visible' : 'hidden' }}
        className={styles.familiarDataBox}
      >
        {loggedUserService.userCan(Permission.createFamiliarData) && (
          <AppButton
            theme={ButtonTheme.NewPrimaryResponsive}
            type={'button'}
            label={t('add')}
            handler={createFamiliarData}
          />
        )}
      </Box>
      <Box>
        <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
          <CustomModal
            handleClose={handleCloseDeleteModal}
            handleSave={handleDeleteFData}
            title={t('deleteFData')}
            warningText={t('irreversibleFDataAction')}
          />
        </Modal>
        <AppTable
          items={familiarData.items}
          rowKeyField="id"
          fields={fields}
          actions={actions}
          pager={pager}
        />
      </Box>
    </Box>
  )
}
