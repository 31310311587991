import React from 'react'
import { Box, TableBody, TableCell, TableRow } from '@mui/material'
import { Actions, Field } from '../table_type/types'
import { GenericTooltip } from '../generic-tooltip'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import Skeleton from '@mui/material/Skeleton'

export type BodyProps<T> = {
  readonly fields: Field<T>[]
  readonly items: T[]
  readonly rowKeyField: keyof T
  readonly actions?: Actions<T>
  readonly isLoading?: boolean
  readonly specialRowStyle?: boolean
  readonly rowStyleFunc?: (item: T) => boolean
  rowStyleStatus?: (item: T) => string
}

const BodyRow = styled(TableRow)(({ theme }) => ({
  '&:first-of-type': {
    borderTopLeftRadius: '25px',
    borderTopRightRadius: '8px',
  },
}))

interface TableCellWithStylesProps {
  specialRowStyle: boolean // Declarar customParam como una propiedad en la interfaz
}

const TableCellWithStyles = styled(TableCell)<TableCellWithStylesProps>(
  ({ theme, specialRowStyle }) => ({
    fontSize: '14px',
    fontFamily: 'poppins',
    borderTop: specialRowStyle ? '1px solid #E34D4E' : '1px solid #DBDADA',
    borderBottom: specialRowStyle ? '1px solid #E34D4E' : '1px solid #DBDADA',
    backgroundColor: specialRowStyle ? '#FEEFEF' : '#FFFFFF',
    '&:last-child': specialRowStyle
      ? {
          borderRight: '1px solid #E34D4E',
          borderRadius: '0px 10px 10px 0px',
        }
      : {
          borderRight: '1px solid #DBDADA',
          borderRadius: '0px 10px 10px 0px',
        },
    '&:first-of-type': specialRowStyle
      ? {
          borderLeft: '1px solid #E34D4E',
          borderRadius: '10px 0px 0px 10px',
        }
      : {
          borderLeft: '1px solid #DBDADA',
          borderRadius: '10px 0px 0px 10px',
        },
    padding: '0 auto 0 auto',
  })
)

const IconBody = styled('img')(({ theme }) => ({
  verticalAlign: 'middle',
  cursor: 'pointer',
  paddingTop: '0',
  paddingBottom: '0',
}))

export const Body = <T extends Record<string, any>>(props: BodyProps<T>) => {
  const { t } = useTranslation()

  const rows = props.items.map((item) => {
    const isSpecialRow = item.specialRowClass
    const actionStyle = props.actions?.styleFunc ? props.actions.styleFunc(item) : ''
    const rowStyleStatus = props.rowStyleStatus ? props.rowStyleStatus(item) : ''
    return !props.isLoading ? (
      <BodyRow key={item[props.rowKeyField]}>
        {Object.values(props.fields).map((field, i) => {
          const style = field.styleFunc ? field.styleFunc(field, item) : ''
          return (
            <TableCellWithStyles
              sx={{
                borderBottom: rowStyleStatus,
                borderTop: rowStyleStatus,
                '&:last-child': {
                  borderRight: rowStyleStatus,
                },
                '&:first-of-type': {
                  borderLeft: rowStyleStatus,
                },
              }}
              specialRowStyle={isSpecialRow}
              key={`${item[props.rowKeyField]}-cell` + i}
              className={style}
            >
              {field.renderFunc ? field.renderFunc(field, item) : item[field.name].toString()}
            </TableCellWithStyles>
          )
        })}

        {props.actions && props.actions.items?.length > 0 && (
          <TableCellWithStyles
            specialRowStyle={isSpecialRow}
            key={item[props.rowKeyField] + 'cell'}
            className={actionStyle}
          >
            <Box display="flex" justifyContent="center">
              {props.actions?.items?.map((a, i) => {
                const isLastItem = i === (props.actions?.items?.length ?? 0) - 1
                const iconStyle = isLastItem ? {} : { marginRight: '24px' }

                if (a.hidden && a.hidden(item)) {
                  return (
                    <Tooltip
                      key={`${item[props.rowKeyField]}-toolTip` + i}
                      style={{ ...iconStyle, visibility: 'hidden' }}
                      title={''}
                    >
                      <IconBody
                        style={{ visibility: 'hidden' }}
                        src={a.icon}
                        onClick={() => a.handler(item)}
                      />
                    </Tooltip>
                  )
                }
                return (
                  <GenericTooltip
                    key={`${item[props.rowKeyField]}-genericToolTip` + i}
                    values={[t(a.label ?? a.icon)]}
                    icon={
                      <IconBody src={a.icon} onClick={() => a.handler(item)} style={iconStyle} />
                    }
                    noUseFab={true}
                  />
                )
              })}
            </Box>
          </TableCellWithStyles>
        )}
      </BodyRow>
    ) : (
      <BodyRow key={item[props.rowKeyField]}>
        {Object.values(props.fields).map((field, i) => {
          const style = field.styleFunc ? field.styleFunc(field, item) : ''
          return (
            <TableCellWithStyles
              specialRowStyle={isSpecialRow}
              key={`${item[props.rowKeyField]}-cell2` + i}
              className={style}
            >
              <Skeleton />
            </TableCellWithStyles>
          )
        })}

        {props.actions && props.actions.items?.length > 0 && (
          <TableCellWithStyles
            specialRowStyle={isSpecialRow}
            key={item[props.rowKeyField] + 'cell'}
            className={actionStyle}
          >
            <Box display="flex" justifyContent="space-between">
              {props.actions.items.map((a, i) => {
                if (a.hidden && a.hidden(item)) {
                  return null
                }
                return (
                  <GenericTooltip
                    key={`${item[props.rowKeyField]}` + i}
                    values={[t(a.label ?? a.icon)]}
                    icon={<Skeleton variant="circular" width={10} height={10} />}
                    noUseFab={true}
                  />
                )
              })}
            </Box>
          </TableCellWithStyles>
        )}
      </BodyRow>
    )
  })

  return <TableBody>{rows}</TableBody>
}
