import { FormCard } from '../../components/form-card/FormCard'
import React, { FormEvent, useEffect, useState } from 'react'
import { emptyFileDTO, FileDTO, fromModel } from '../../modules/files/models/FileDTO'
import { TextFieldItem } from '../../components/form-card/TextFieldItem'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { ROUTE_RESOURCES } from '../../routes/routes-constants'
import { useTranslation } from 'react-i18next'
import { Query, QueryParam } from '../../common/api/Query'
import { User, UserQuery } from '../../modules/users/models/User'
import { getUserContainer } from '../../container/user-module'
import { ICircleService } from '../../modules/users/services/CircleService'
import {
  CIRCLE_SERVICE_KEY,
  LOGGED_USER_SERVICE_KEY,
  PROFESSIONALTYPE_SERVICE_KEY,
  ROLES_SERVICE_KEY,
  USER_SERVICE_KEY,
} from '../../modules/users/container'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { IUserService } from '../../modules/users'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { FileItem } from '../../components/form-card/FileItem'
import { File as F } from '../../modules/files/models/File'
import genericStyle from '../../common/utils/generic.module.css'
import { forkJoin, Observable } from 'rxjs'
import { RolesService } from 'modules/users/services/RolesServices'
import { Roles } from '../../modules/users/enums/Roles'
import styles from '../user-profile/Editor.module.css'
import professionalSMS from '../../assets/role_icons/ico-rol-profesional.svg'
import patient from '../../assets/role_icons/ico-rol-paciente.svg'
import career from '../../assets/role_icons/ico-rol-cuidador.svg'
import family from '../../assets/role_icons/ico-rol-familiar.svg'
import allMyCircles from '../../assets/left_menu/circulos.svg'
import externProfessional from '../../assets/role_icons/ico-rol-externo.svg'
import style from '../messenger/ConversationEditor.module.css'
import { LoadingFile } from '../../components/loading-spinner/LoadingSpinner'
import { ProfessionalType } from '../../modules/users/models/ProfessionalType'
import { PathologyDTO } from '../../modules/patient-data/models/PathologyDTO'
import { ProfessionalTypesService } from '../../modules/users/services/ProfessionalTypeService'
import { RolesType } from '../../common/enums/RolesType'
import { useNavigate } from 'react-router-dom'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { IUserCircleActiveService, USER_CIRCLE_ACTIVE_SERVICE_KEY } from '../../modules/user-circle'
import { Alert, Box, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'

type EditorProps = {
  id?: string
  isDir: boolean
  parentID: string | null
  breadcrumbUrl?: Map<string, string>
}

type AutocompleteUserInterface = {
  key: string
  value: string
  role: string
}

enum ResourceErrors {
  NO_FILE = 'noFile',
}

const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

const fileContainer = getFileContainer()
const fileService = fileContainer.get<FileService>(FILE_SERVICE_KEY)

const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)

const roleService = getUserContainer().get<RolesService>(ROLES_SERVICE_KEY)

const userContainer = getUserContainer()
const professionalTypeService = userContainer.get<ProfessionalTypesService>(
  PROFESSIONALTYPE_SERVICE_KEY
)

const userCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)

export function Editor(props: EditorProps) {
  const { t } = useTranslation()
  const circle = getUserContainer().get<ICircleService>(CIRCLE_SERVICE_KEY).getActiveUser()
  const activeUser = userCircleActiveService.getActiveFullUserCircle()
  const navigate = useNavigate()

  const loggedUser = loggedUserService.get()

  const [resource, setResource] = useState<FileDTO>(
    emptyFileDTO(loggedUser?.id, activeUser?.id, false)
  )
  const [users, setUsers] = useState<Map<string, AutocompleteUserInterface>>(new Map())
  const [selectedUsers, setSelectedUsers] = useState<AutocompleteUserInterface[]>([])
  const [files, setFiles] = useState<F[]>([])
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isSending, setIsSending] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)
  const [roles, setRoles] = useState<Map<string, string>>(new Map())
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentCirclePathology, setCurrentCirclePathology] = useState<string[]>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [professionalSpecialities, setProfessionalSpecialities] = useState<ProfessionalType[]>([])
  const [professionalType, setProfessionalType] = useState<ProfessionalType>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedProfessionalType, setSelectedProfessionalType] = useState<ProfessionalType>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [circlePathology, setCirclePathology] = useState<PathologyDTO[]>([])

  const getUsers = (ids: string[]): Observable<User[]> =>
    forkJoin(ids.map((id) => userService.getByID(id))) as unknown as Observable<User[]>

  useEffect(() => {
    roleService
      .getAll(
        new Query({
          sort: [{ field: 'name' }],
          pager: { offset: 0, limit: -1 },
        })
      )
      .subscribe((res) => {
        const tmpMap = new Map<string, string>()
        res.forEach((r) => tmpMap.set(r.id, r.name))
        setRoles(tmpMap)
      })

    if (props.id) {
      fileService.getByID(props.id).subscribe((res) => {
        if (res) {
          setResource(fromModel(res))
          setFiles([res])

          getUsers(res.sharedWith).subscribe((ul) => {
            setSelectedUsers(
              ul
                .filter((u) => u)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((u) => ({ key: u.id, value: u.name, role: u.roles?.length ? u.roles[0] : '' }))
            )
          })
        }
      })
    } else {
      setResource(Object.assign({ ...resource }, { isDir: props.isDir, parent: props.parentID }))
    }
    let arrayRelatersOfActiveUser: string[] = []
    if (activeUser?.user?.id) {
      arrayRelatersOfActiveUser = arrayRelatersOfActiveUser.concat(
        activeUser?.careersID,
        activeUser?.familiarsID,
        activeUser?.profExtID,
        activeUser?.profSmsID
      )
      userService
        .getFilteredItems(
          new Query({
            query: [new QueryParam<UserQuery>('ids', arrayRelatersOfActiveUser)],
          })
        )
        .subscribe((ul) => {
          const tmpMap = new Map()
          tmpMap.set('allMyCircles', { key: 'allMyCircles', value: 'allMyCircles' })
          ul.forEach((u) => {
            if (u.id !== loggedUser?.id) {
              tmpMap.set(u.id, {
                key: u.id,
                value: u.name,
                role: u.roles?.length ? u.roles[0] : '',
              })
            }
          })
          setUsers(new Map(tmpMap))
        })
    }
  }, [])

  useEffect(() => {
    if (isSending) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            return 0
          }
          const diff = Math.random() * 10
          return Math.min(oldProgress + diff, 100)
        })
      }, 500)

      clearInterval(timer)
    }
  })

  useEffect(() => {
    if (!loggedUser?.id) return
    const listAux: ProfessionalType[] = []
    const isContentManager = loggedUser?.roles?.some((role) => role === 'contentManager')
    if (isContentManager) {
      professionalTypeService
        .getAll(
          new Query({
            sort: [{ field: 'name' }],
            pager: { offset: 0, limit: -1 },
          })
        )
        .subscribe((res) => {
          if (!res) return
          setProfessionalSpecialities(res)
        })
    } else {
      professionalTypeService.getProfessionalTypeByUserID(loggedUser?.id).subscribe((res) => {
        if (!res) return
        listAux.push(res)
        setProfessionalSpecialities(listAux)
        setProfessionalType(listAux[0])
      })
    }
  }, [])

  useEffect(() => {
    /* if (!professionalType) return
     pathologiesService
      .getFilteredList(
        new Query<PathologyQuery>({
          query: [new QueryParam<PathologyQuery>('typeID', professionalType?.id)],
        })
      )
      .subscribe((res) => {
        if (!res) return
        setCirclePathology(res.items)
      }) */
  }, [professionalType, currentCirclePathology, resource])

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setResource(Object.assign({ ...resource }, { [e.target.name]: e.target.value }))

  const handleUsers = (value: AutocompleteUserInterface[]) => {
    if (!Array.isArray(value)) {
      return
    }
    if (value.find((v) => v.key === 'allMyCircles')) {
      const result = Array.from(users.values()).filter((v) => v.key !== 'allMyCircles')
      setSelectedUsers(result)
      setResource(Object.assign({ ...resource }, { sharedWith: result.map((v) => v.key) }))
      return
    }
    setSelectedUsers(value)
    setResource(Object.assign({ ...resource }, { sharedWith: value.map((v) => v.key) }))
  }

  const handleFiles = (field: string, value: F[]) => {
    if (!Array.isArray(value)) {
      return
    }
    setFiles([...value])
    if (value.length > 0) {
      const f = fromModel(value[0])
      setResource(
        Object.assign(
          { ...resource },
          { data: f.data, extension: f.extension, mimeType: f.mimeType }
        )
      )
    }
  }

  const goBack = () => {
    if (props.parentID) {
      navigate(`${ROUTE_RESOURCES}/${props.parentID}`, {
        state: { breadcrumbUrl: props.breadcrumbUrl },
      })
    } else {
      navigate(ROUTE_RESOURCES, { state: { breadcrumbUrl: props.breadcrumbUrl } })
    }
  }

  const validateResource = (): boolean => {
    if (!props.isDir && files.length === 0) {
      setErrorMessage(ResourceErrors.NO_FILE)
      setIsSending(false)
      return false
    }
    setErrorMessage('')
    return true
  }

  async function saveResource(e: FormEvent<HTMLFormElement>) {
    setIsSending(true)
    e.preventDefault()

    if (!validateResource()) {
      return
    }

    const newResource = Object.assign(
      { ...resource },
      {
        specialities: professionalType?.name,
        circles: currentCirclePathology?.map((c) => c).join(','),
        createdByDoctor: loggedUser?.roles.includes(RolesType.ProfessionalSMS),
      }
    )

    if (props.id) {
      fileService.update(newResource).subscribe(() => {
        setIsSending(false)
        goBack()
      })
    } else {
      fileService.add(newResource).subscribe(() => {
        setIsSending(false)
        goBack()
      })
    }
  }

  const getImage = (option: AutocompleteUserInterface) => {
    if (option.key === 'allMyCircles') {
      return <img className={styles.roleIcon} src={allMyCircles} alt={t('allMyCircles')} />
    }
    switch (roles.get(option.role)) {
      case Roles.Professional:
        return <img className={styles.roleIcon} src={professionalSMS} alt={t(Roles.Professional)} />

      case Roles.ContentManager:
        return <img className={style.roleIcon} src={professionalSMS} alt={t(Roles.Professional)} />

      case Roles.Patient:
        if (option.key !== circle?.id) {
          return <img className={styles.roleIcon} src={family} alt={t(Roles.FamilyOrTutor)} />
        }
        return <img className={styles.roleIcon} src={patient} alt={t(Roles.Patient)} />

      case Roles.CarerOrTeacher:
        return <img className={styles.roleIcon} src={career} alt={t(Roles.CarerOrTeacher)} />

      case Roles.FamilyOrTutor:
        if (option.key === circle?.id) {
          return <img className={styles.roleIcon} src={patient} alt={t(Roles.Patient)} />
        }
        return <img className={styles.roleIcon} src={family} alt={t(Roles.FamilyOrTutor)} />

      case Roles.ProfessionalExtern:
        return (
          <img
            className={styles.roleIcon}
            src={externProfessional}
            alt={t(Roles.ProfessionalExtern)}
          />
        )
    }
  }

  /*  const handleSelectPathology = (e: string) => {
    if (!props.id) {
      setCurrentCirclePathology([])
    }
    professionalTypeService.getByName(e).subscribe((res) => {
      if (res) {
        const aux: ProfessionalType = res
        setSelectedProfessionalType(aux)
        setProfessionalType(aux)
      }
    })
  }

  const handleDeletePathology = (type: string, p: string) => {
    if (type === 'professionalType') {
      if (professionalType?.name) {
        setProfessionalType(undefined)
        setCurrentCirclePathology([])
      }
    }
    if (type === 'circle') {
      const filteredArray = currentCirclePathology?.filter(function (e) {
        return e !== p
      })
      setCurrentCirclePathology(filteredArray)
    }
    console.info('You clicked the delete icon.')
  }
  const handleSelectCircle = (event: SelectChangeEvent<typeof currentCirclePathology>) => {
    const {
      target: { value },
    } = event
    if (Array.isArray(value)) {
      setCurrentCirclePathology(value)
    }
  } */

  return (
    <Box className={genericStyle.pageContainer}>
      <FormCard>
        <form onSubmit={saveResource}>
          <Box style={{ marginBottom: 13 }}>
            <TextFieldItem
              field="name"
              value={resource.name}
              label={'name'}
              type={'text'}
              handleChange={handleInput}
              rows={undefined}
              required={true}
            />
          </Box>

          <Box style={{ marginBottom: 13 }}>
            <Autocomplete
              key={resource.id}
              id={`${resource.id}-standard`}
              options={Array.from(users.values())}
              multiple
              getOptionLabel={(opt) => t(opt.value)}
              isOptionEqualToValue={(option, value) => option.key === value.key}
              value={selectedUsers}
              onChange={(_, v) => handleUsers(v)}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label={t('sharedWith')} />
              )}
              noOptionsText={t('noElementsAvailable')}
              renderOption={(props, option) => (
                <li {...props}>
                  <span style={{ fontFamily: 'Poppins' }}>{getImage(option)}</span>
                  <span style={{ fontFamily: 'Poppins' }}>{t(option.value)}</span>
                </li>
              )}
            />
          </Box>

          <Box style={{ marginBottom: 13 }}>
            {!props.isDir && (
              <FileItem
                key={'files'}
                field={'files'}
                filesID={[]}
                handleFiles={handleFiles}
                userID={loggedUser?.id ?? ''}
                cleanFiles={false}
                uploadRemoveFileDirectly={false}
                disabled={files.length > 0}
                defaultFile={files}
              />
            )}
          </Box>

          {errorMessage && (
            <Box style={{ marginBottom: 13 }}>
              <Alert severity="warning" key="errorMessage" id="errorMessage">
                {t(errorMessage)}
              </Alert>
            </Box>
          )}

          {isSending ? (
            <LoadingFile progress={progress} />
          ) : (
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <AppButton
                theme={ButtonTheme.NewSecondary}
                type={'button'}
                label={t('cancel')}
                handler={goBack}
              />
              <AppButton
                theme={ButtonTheme.NewPrimary}
                type={'submit'}
                label={t('save')}
                handler={() => {}}
              />
            </Box>
          )}
        </form>
      </FormCard>
    </Box>
  )
}
