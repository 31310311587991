import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Report, ReportQuery } from '../models/Report'
import { ReportDTO } from '../models/ReportDTO'
import { IReportApi } from '../api/ReportApi'

type Props = {
  apiKey: symbol
}

export type GeneralInfo={
  id: string,
  createdByUserID: string,
  createdAt: Date,
  reportID: string,
  healthCentre: string,
  prescribingProfessional: string,
  professionalCategory: string,
  programType: string,
  programStartDate: Date,
  programFinishDate: Date,
  reportStartDate: Date,
  reportFinishDate: Date
}

export interface IReportService extends IInit {
  getByID(id: string): Observable<Report | undefined>

  add(e: Report): Observable<Report | undefined>

  update(e: ReportDTO): Observable<Report | undefined>

  delete(id: string): Observable<boolean>

  getByUserCircleID(userCircleID: string): Observable<Report[] | undefined>

  getGeneralInformationbyId(reportId: string): Observable<GeneralInfo | undefined>

  getSpecificInformationbyId(reportId: string): Observable<any | undefined>

  getTrainingSchedulebyId(reportId: string): Observable<any | undefined>

  getSessionsAttendancebyId(reportId: string): Observable<any | undefined>

  getAvgAttendanceMonthById(reportId: string): Observable<any | undefined>

  getRcvValorationById(reportId: string): Observable<any | undefined>

  getAnthropometricById(reportId: string): Observable<any | undefined>

  getAvgIntensityMonthById(reportId: string): Observable<any | undefined>

  getAvgConditionMonthById(reportId: string): Observable<any | undefined>

  getConditionById(reportId: string): Observable<any | undefined>
}

export class ReportService implements IReportService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IReportApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IReportApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: ReportDTO): Observable<Report | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<Report | undefined> {
    return this._api.getByID(id)
  }


  update(e: ReportDTO): Observable<Report | undefined> {
    return this._api.update(e)
  }

  getByUserCircleID(userCircleID: string): Observable<Report[] | undefined> {
    return this._api.getByUserCircleID(userCircleID)
  }

  getGeneralInformationbyId(reportId: string): Observable<GeneralInfo | undefined> {
    return this._api.getGeneralInformationbyId(reportId)
  }

  getSpecificInformationbyId(reportId: string): Observable<any | undefined> {
    return this._api.getSpecificInformationbyId(reportId)
  }

  getTrainingSchedulebyId(reportId: string): Observable<any | undefined> {
    return this._api.getTrainingSchedulebyId(reportId)
  }

  getSessionsAttendancebyId(reportId: string): Observable<any | undefined> {
    return this._api.getSessionsAttendancebyId(reportId)
  }

  getAvgAttendanceMonthById(reportId: string): Observable<any | undefined> {
    return this._api.getAvgAttendanceMonthById(reportId)
  }

  getRcvValorationById(reportId: string): Observable<any | undefined> {
    return this._api.getRcvValorationById(reportId)
  }

  getAnthropometricById(reportId: string): Observable<any | undefined> {
    return this._api.getAnthropometricById(reportId)
  }

  getAvgIntensityMonthById(reportId: string): Observable<any | undefined> {
    return this._api.getAvgIntensityMonthById(reportId)
  }

  getAvgConditionMonthById(reportId: string): Observable<any | undefined> {
    return this._api.getAvgConditionMonthById(reportId)
  }

  getConditionById(reportId: string): Observable<any | undefined> {
    return this._api.getConditionById(reportId)
  }
}
