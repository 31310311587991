import React from 'react'
import { SegmentedNotificationsView } from '../../features/segmented-notifications-feat'
import { RouteProps } from '../../routes/AppRouter'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'
import { useParams } from 'react-router-dom'

export function SegmentedNotifications(props: RouteProps) {
  const title = props.title ?? ''
  const { id } = useParams()
  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <SegmentedNotificationsView id={id}/>
    </>
  )
}
