/* tslint:disable */
//@ts-nocheck
import React from 'react'
import isUrl from 'is-url'
import { RenderLeafProps, useFocused, useSelected, useSlate, useSlateStatic } from 'slate-react'
import { Transforms, Editor as SlateEditor, Range, Element as SlateElement, Node } from 'slate'
import { Editor, LinkElement } from './Types'
import styles from './TextEditor.module.css'
import { Button, Icon } from './ComponentsTextEditor'
import DeleteIcon from '@mui/icons-material/Delete'
import LinkIcon from '@mui/icons-material/Link'

export const withInlines = (editor: Editor) => {
  const { normalizeNode } = editor
  editor.normalizeNode = (entry) => {
    const [node, path] = entry
    if (SlateElement.isElement(node) && node.type === 'paragraph') {
      const children = Array.from(Node.children(editor, path))
      for (const [child, childPath] of children) {
        // remove link nodes whose text value is empty string.
        // empty text links happen when you move from link to next line or delete link line.
        if (
          SlateElement.isElement(child) &&
          child.type === 'link' &&
          child.children[0].text === ''
        ) {
          if (children.length === 1) {
            Transforms.removeNodes(editor, { at: path })
            Transforms.insertNodes(editor, {
              type: 'paragraph',
              children: [{ text: '' }],
            })
          } else {
            Transforms.removeNodes(editor, { at: childPath })
          }
          return
        }
      }
    }
    normalizeNode(entry)
  }

  const { insertData, insertText } = editor

  editor.isInline = (element) => ['link'].includes(element.type)

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = (data) => {
    const text = data.getData('text/plain')

    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

const insertLink = (editor: Editor, url: string) => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
}

const isLinkActive = (editor: Editor) => {
  const [link] = SlateEditor.nodes(editor, {
    match: (n) => !SlateEditor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
  return !!link
}

const unwrapLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => !SlateEditor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
}

const wrapLink = (editor: Editor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link: LinkElement = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}

const InlineChromiumBugfix = () => (
  <span
    contentEditable={false}
    style={{
      fontSize: 0,
    }}
  >
    ${String.fromCodePoint(160) /* Non-breaking space */}
  </span>
)

export const removeLink = (editor: Editor, opts = {}) => {
  Transforms.unwrapNodes(editor, {
    ...opts,
    match: (n) => !SlateEditor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
}

export const LinkComponent = ({ attributes, children, element }: any) => {
  const editor = useSlateStatic()
  const selected = useSelected()
  const focused = useFocused()
  return (
    <div className={styles.elementLink}>
      <a
        target="_blank"
        rel={'noreferrer'}
        style={{ cursor: 'pointer' }}
        {...attributes}
        href={element.url}
      >
        <InlineChromiumBugfix />
        {children}
        <InlineChromiumBugfix />
      </a>
      {selected && focused && (
        <div className={styles.popUp} contentEditable={false}>
          <button className={styles.buttonDeleteLink} onClick={() => removeLink(editor)}>
            <DeleteIcon />
          </button>
          <a
            style={{ width: '90%', overflow: 'hidden', height: '40px' }}
            href={element.url}
            rel="noreferrer"
            target="_blank"
          >
            {element.url}
          </a>
        </div>
      )}
    </div>
  )
}

export const TextInLine = (props: RenderLeafProps) => {
  const { attributes, children, leaf } = props
  return (
    <span
      // The following is a workaround for a Chromium bug where,
      // if you have an inline at the end of a block,
      // clicking the end of a block puts the cursor inside the inline
      // instead of inside the final {text: ''} node
      // https://github.com/ianstormtaylor/slate/issues/4704#issuecomment-1006696364
      className={leaf.text === '' ? styles.textLeaf : ''}
      {...attributes}
    >
      {children}
    </span>
  )
}

export const AddLinkButton = () => {
  const editor = useSlate()
  const isActive = isLinkActive(editor)
  return (
    <Button
      active={isActive}
      className={styles.generalButton}
      onMouseDown={(event: any) => {
        event.preventDefault()
        const url = window.prompt('Enter the URL of the link:')
        if (!url) return
        insertLink(editor, url)
      }}
      reversed
    >
      <Icon>
        <LinkIcon />
      </Icon>
    </Button>
  )
}
