import React, { useEffect, useState } from 'react'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { Query, QueryParam } from '../../common/api/Query'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Card, Typography } from '@mui/material'
import style from './Search.module.css'
import { getFileContainer } from 'container/file-module'
import { FileService } from 'modules/files/services/FileService'
import { FILE_SERVICE_KEY } from 'modules/files'
import { File, FileQuery } from 'modules/files/models/File'
import { downloadFile, viewImageFile } from 'common/utils/file'
import { Article } from '../../modules/content/models/Article'
import { ArticleService } from '../../modules/content/services/ArticleService'
import { ARTICLE_SERVICE_KEY } from '../../modules/content'
import { getContentContainer } from '../../container/content-module'
import { Pager } from '../../components/table_type/types'
import esferaLogo from '../../assets/brand_logo/smsBlue.svg'
import { ROUTE_ARTICLES } from '../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import { RichText } from '../../components/text-editor/RichText'
import docx from '../../assets/template_icons/docx-6-64.png'
import pdf from '../../assets/template_icons/icon-file-type-pdf-64.png'
import xls from '../../assets/template_icons/icon-file-type-excel-64.png'
import ppt from '../../assets/template_icons/icon-file-type-ppt-64.png'
import video from '../../assets/template_icons/video-143-64.png'
import image from '../../assets/template_icons/image-gallery.png'

const contentContainer = getContentContainer()
const fileContainer = getFileContainer()
const articlesService = contentContainer.get<ArticleService>(ARTICLE_SERVICE_KEY)
const filesService = fileContainer.get<FileService>(FILE_SERVICE_KEY)

type TemplateProps = {
  categoryList: string[]
  search?: string
  mostViewed?: boolean
  circles: number[]
}

export enum CircleType {
  'Porfiria' = 1234,
  'Diabetes' = 5678,
  'Tensión' = 9123,
  'Niño sano' = 9999,
  'Epilepsia' = 1111,
  'Neurologia' = 1112,
  'Oncología' = 2112,
  'Esguince de tobillo' = 2212,
  'Esguince de rodilla' = 2222,
  'Plan Activa' = 1113,
  'Medicina General' = 1114,
  'Cardiología' = 1115,
  'Cirugía' = 1116,
  'Rehabilitación' = 1117
}

const imageList = new Map()

export function TableGrid(props: TemplateProps) {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [templates, setTemplates] = useState<ItemList<File>>(emptyList<File>())
  const [articles, setArticles] = useState<ItemList<Article>>(emptyList<Article>())
  const [articlesInit, setInitArticles] = useState<ItemList<Article>>(emptyList<Article>())
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [articlesPerPage, setArticlesPerPage] = useState<number>(50)
  const [count, setCount] = useState<number>(0)
  const [extension, setExtension] = useState<any>("")
  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
  }, [props.categoryList])

  useEffect(() => {

    const articlesQuery = [
      new QueryParam<Article>('type', 'template'), new QueryParam<Article>('categories', props.circles)
    ]
    if (props.categoryList.length > 0) {
      let aux: [] = []
      // @ts-ignore
      props.categoryList.forEach((obj) => aux.push(CircleType[obj]))

      articlesQuery.push(new QueryParam<Article>('categories', aux))
    }
    articlesService
      .getFilteredList(
        new Query({
          query: articlesQuery,
          pager: { offset: page * articlesPerPage, limit: articlesPerPage },
        })
      )
      .subscribe((res) => {
        setIsLoading(false)
        const articleList = emptyList<Article>()

        articleList.count = 0
        for (let i = res.items.length - 1; i >= 0; i--) {
          const articleItem = res.items[i]
          if (props.search !== undefined)
            if (
              articleItem.title.toLowerCase().includes(props.search.toLowerCase()) ||
              articleItem.content.toLowerCase().includes(props.search.toLowerCase())
            ) {
              articleList.items.push(articleItem)
              articleList.count++
            }

          if (articleItem.slug !== 'slug') {
            // Download the image if exists
            filesService.downloadByID(articleItem.slug).subscribe(async (res) => {
              if (res) {
                const image = await viewImageFile(res)
                imageList.set(articleItem.slug, image)
              }
            })
          }
        }

        setArticles(articleList)
        setInitArticles(articleList)
        setCount(res.count)
      })

  }, [props.search, props.categoryList])

  useEffect(() => {
    if (!isLoading) {
      return
    }
    const articlesQuery = [
      new QueryParam<Article>('type', 'template'),
      new QueryParam<Article>('categories', props.circles)
    ]
    if (props.categoryList.length > 0) {
      articlesQuery.push(new QueryParam<Article>('categories', props.categoryList))
    }

    articlesService
      .getFilteredList(
        new Query({
          query: articlesQuery,
          sort: [{ field: 'title' }],
          // pager: { offset: page * articlesPerPage, limit: articlesPerPage },
        })
      )
      .subscribe((res) => {
        setIsLoading(false)
        const articleList = emptyList<Article>()

        articleList.count = 0
        for (let i = res.items.length - 1; i >= 0; i--) {
          const articleItem = res.items[i]
          articleList.items.push(articleItem)
          articleList.count++

          // TODO CHECK FILE SERVICE

          // if (articleItem.slug !== 'slug') {
          //   // Download the image if exists
          //   filesService.downloadByID(articleItem.slug).subscribe(async (res) => {
          //     if (res) {
          //       const image = await viewImageFile(res)
          //       imageList.set(articleItem.slug, image)
          //     }
          //   })
          // }
        }

        setArticles(articleList)
        setInitArticles(articleList)
        setCount(res.count)
      })
  }, [])

  // useEffect(() => {
  //   if (!isLoading) {
  //     return
  //   }
  //
  //   filesService
  //     .getFilteredItems(
  //       new Query<FileQuery>({
  //         query: [new QueryParam<FileQuery>('basePath', 'Template')],
  //         sort: [{ field: 'name' }],
  //       })
  //     )
  //     .subscribe((res) => {
  //       setIsLoading(false)
  //
  //       const templateList = emptyList<File>()
  //       templateList.count = 0
  //       for (let i = res.items.length - 1; i >= 0; i--) {
  //         const templateItem = res.items[i]
  //         if (props.categoryList.length > 0) {
  //           // @ts-ignore
  //           if(props.categoryList.find((obj) => obj === CircleType[templateItem.circles])!==undefined){
  //             templateList.items.push(templateItem)
  //             templateList.count++
  //           }
  //         } else {
  //           templateList.items.push(templateItem)
  //           templateList.count++
  //         }
  //       }
  //
  //       setTemplates(templateList)
  //       setCount(res.count)
  //     })
  // }, [isLoading, props.categoryList])

  useEffect(() => {
    setIsLoading(true)
  }, [count])

  // const handleSee = (template: File) => {
  //   if (template && template.id) {
  //     // Download the image if exists
  //     filesService.downloadByID(template.id).subscribe(async (res) => {
  //       if (res) {
  //         downloadFile(res.name, res.mimeType, res.data)
  //       }
  //     })
  //   }
  // }
  const handleSee = (event: any, article: Article) => {
    if (
      !event.target.className.includes('like_button') &&
      !event.target.className.includes('comment_button')
    ) {
      navigate(`${ROUTE_ARTICLES}/${article.id}`)
    }
  }

  const handleContent = (e: string) => {

  }

  return (
    <>
      <Box /* className={styles.modalContainer} */>
        <Grid container spacing={2}>
          {articles.items.map((item, index) => {
            let icon = undefined
            if (item.slug) {
              if(item.slug === "doc" || item.slug === "docx"){
                icon = docx
              }else if(item.slug === "ppt" || item.slug === "pptx"){
                icon = ppt
              }else if(item.slug === "xls" || item.slug === "xlsx"){
                icon = xls
              }else if(item.slug === "pdf"){
                icon = pdf
              }else if(item.slug === "avi" || item.slug === "wmv" || item.slug === "mkv" || item.slug === "mp4" || item.slug === "mov"){
                icon = video
              }else if(item.slug === "jpeg" || item.slug === "jpg" || item.slug === "bmp" || item.slug === "gif" || item.slug === "png"){
                icon = image
              }
            }
            let content = <div></div>
            if (item && item.content && item.content.charAt(0) === '[') {
              content = <RichText content={item.content} edit={true} view={true} />
            }

            return (
                <Grid item xs={12} sm={6} md={3} key={item.id}>
                  <Card
                    className={style.card}
                    sx={{ borderRadius: 5, borderColor: '#68b3e0' }}
                    variant="outlined"
                    onClick={(event) => handleSee(event, item)}
                  >
                    <div className={style.imageContainer}>
                      <div>
                        <Typography variant="h6" color="silver" className={style.title}>
                          {item.title}
                        </Typography>
                        {/*@ts-ignore*/}
                        <Typography variant="h7" color="textPrimary" className={style.titleEditor}>
                          {content}
                        </Typography>
                        <div className={style.icon}>
                          <img src={icon}/>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Grid>
              )
          })}
        </Grid>
      </Box>
    </>
  )
}
