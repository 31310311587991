import { DashBoard } from './DashBoard'

export interface DashBoardDTO {
  labels: string[]
  data: number[]
}

export function toDashBoardModel(d: DashBoardDTO): DashBoard {
  return new DashBoard(d)
}

export function emptyDashBoardDTO() {
  return {
    labels: [],
    data: [],
  }
}
