import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Wearable, WearableQuery } from '../models/Wearable'
import { WearableApi } from '../api/WearableApi'
import { UserToken } from '../models/UserToken'
import { UserTokenDTO } from '../models/UserTokenDTO'

type Props = {
  apiKey: symbol
}

export interface IWearablesService extends IInit {
  getFilteredItems(q: Query<WearableQuery>): Observable<Wearable[]>
  getHeartRate(q: Query<WearableQuery>): Observable<any>
  getHeartRateByDate(q: Query<WearableQuery>): Observable<any>
  createToken(e: UserTokenDTO): Observable<UserToken | undefined>
  deleteToken(id: string): Observable<boolean>
  getLoginType(id: string): Observable<number>
}

export class WearableService implements IWearablesService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: WearableApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<WearableApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getFilteredItems(q: Query<WearableQuery>): Observable<Wearable[]> {
    return this._api.getFilteredItems(q).pipe()
  }

  getHeartRate(q: Query<WearableQuery>): Observable<any> {
    return this._api.getHeartRate(q).pipe()
  }

  getHeartRateByDate(q: Query<WearableQuery>): Observable<any> {
    return this._api.getHeartRateByDate(q).pipe()
  }

  getData(date: string): Observable<any>{
    return this._api.getData(date).pipe()
  }

  createToken(e: UserTokenDTO): Observable<UserToken | undefined> {
    return this._api.createToken(e)
  }

  deleteToken(id: string): Observable<boolean> {
    return this._api.deleteToken(id)
  }

  getLoginType(id: string): Observable<number> {
    return this._api.getLoginType(id)
  }
}
