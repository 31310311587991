import { Box } from '@mui/material'
import { Header } from '../header/Header'
import style from './CustomModal.module.css'
import { useTranslation } from 'react-i18next'
import { AppButton, ButtonTheme } from '../app-button/AppButton'
import close from '../../assets/table_icons/ico-eliminar.svg'
import React, { useState } from 'react'
import { TextFieldItem } from '../form-card/TextFieldItem'
import { Alert } from '@mui/material'

type modalProps = {
  title: string
  author?: string
  name?: string
  description?: string
  handleCreateVariable?: (name: string, description: string) => void
  handleClose: () => void
  handleSave: (description: string) => void
}

export const RejectModal = (props: modalProps): JSX.Element => {
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [name, setName] = useState<string>(props.name ? props.name : '')
  const [description, setDescription] = useState<string>(props.description ? props.description : '')
  const [error2, setError2] = useState<boolean>(false)

  /*  const changeName = (event: any) => {
    setName(event.target.value)
  } */

  const changeDescription = (event: any) => {
    setDescription(event.target.value)
  }

  const handleSave = () => {
    if (props.handleCreateVariable) {
      if (name === '' || description === '') {
        setError2(true)
        return
      }
      props.handleCreateVariable(name, description)
    }

    props.handleSave(description)
  }

  return (
    <div>
      <div className={style.agoraContainer}>
        <Box className={style.containerInside}>
          <Header label={t('rejectArticle')} icon={close} onClick={props.handleClose} />
          <form>
            <Box mb={3}>
              <p style={{ textAlign: 'center' }}>{t('justifyDeci')}</p>
            </Box>
            <Box mb={3}>
              <b>{t('article')}: </b>
              <span>{props.title}</span>
            </Box>
            <Box mb={3}>
              <b>{t('author')}: </b>
              <span>{props.author}</span>
            </Box>

            <Box>
              <form style={{ display: 'flex', flexDirection: 'column' }}>
                <TextFieldItem
                  field={'description'}
                  value={description}
                  label={'description'}
                  type={'text'}
                  handleChange={changeDescription}
                  rows={4}
                  required={true}
                />
              </form>
              {error2 ? (
                <Alert style={{ marginTop: 8, marginLeft: 8 }} severity="warning">
                  Todos los campos son obligatorios
                </Alert>
              ) : null}
            </Box>

            <Box mt={1} mb={1} className={style.buttons}>
              <Box style={{ marginRight: 4 }}>
                <AppButton
                  theme={ButtonTheme.NewSecondary}
                  type={'button'}
                  label={t('cancel')}
                  handler={props.handleClose}
                />
              </Box>
              <AppButton
                disabled={!description || description.length === 0}
                theme={ButtonTheme.NewPrimary}
                type={'button'}
                label={t('submit')}
                handler={handleSave}
              />
            </Box>
          </form>
        </Box>
      </div>
    </div>
  )
}
