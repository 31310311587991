import React, { FormEvent, useEffect, useState } from 'react'
import { FormCard } from '../../components/form-card/FormCard'
import { Box, MenuItem, Select } from '@mui/material'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { useNavigate } from 'react-router-dom'
import { ROUTE_TEMPLATES_PRO_CONTENT, ROUTE_TUTORIAL_CONTENT } from '../../routes/routes-constants'
import { useTranslation } from 'react-i18next'
import { FileItem } from '../../components/form-card/FileItem'
import { File as F } from '../../modules/files/models/File'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import { TextFieldItem } from '../../components/form-card/TextFieldItem'
import genericStyle from '../../common/utils/generic.module.css'
import { Header } from 'components/header/Header'
import { FileDTO, emptyFileDTO } from 'modules/files/models/FileDTO'
import { RichText } from '../../components/text-editor/RichText'
import { ArticleDTO, emptyArticleDTO } from '../../modules/content/models/ArticleDTO'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { UserCircleService } from '../../modules/user-circle/services/UserCircleConfigService'
import { USER_CIRCLE_SERVICE_KEY } from '../../modules/user-circle'
import { ArticleStatus } from '../../modules/content/enums/ArticleStatus'
import { ArticleService } from '../../modules/content/services/ArticleService'
import { ARTICLE_SERVICE_KEY } from '../../modules/content'
import { getContentContainer } from '../../container/content-module'
import Alert from '@mui/material/Alert'

type EditorProps = {
  id?: string
}

const loggedUserContainer = getUserContainer()
const contentContainer = getContentContainer()

const articleService = contentContainer.get<ArticleService>(ARTICLE_SERVICE_KEY)
const userCircleService = getUserCircleContainer().get<UserCircleService>(USER_CIRCLE_SERVICE_KEY)
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

export function Editor(props: EditorProps) {
  const { t } = useTranslation()

  const loggedUser = loggedUserService.get()
  const [article, setArticle] = useState<ArticleDTO>(emptyArticleDTO())

  const [file, setFile] = useState<FileDTO>(emptyFileDTO(loggedUser?.id ?? '', undefined, false))
  const [filesFront, setFilesFront] = useState<F[]>([])

  const [files, setFiles] = useState<F[]>([])
  const [circle, setCircle] = useState<any>([])
  const [errorMessage, setErrorMessage] = useState<string>('')
  const navigate = useNavigate()

  const handleInputText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setArticle(Object.assign({ ...article }, { [e.target.name]: e.target.value }))
  }

  const handleContent = (e: string) => {
    setArticle(Object.assign({ ...article }, { content: e }))
  }

  const handleCategory = (event: any) =>{
    let userID = JSON.parse(localStorage.getItem("logged user") || "null").id
    let logged = JSON.parse(localStorage.getItem("logged user") || "null").name
    setArticle(Object.assign({ ...article }, {
      category: +(event.target.value as number), type: 'template', circleId: event.target.value as string, authorName: logged,
      userID: userID, relevance: 'baja', status: ArticleStatus[ArticleStatus.publicado],
    }))
    setFile(Object.assign({ ...file }, {circles: event.target.value}))
  }


  useEffect(() => {
    let logged = JSON.parse(localStorage.getItem("logged user") || "null")
    userCircleService.getUserCirclesByUserId(logged.id).subscribe((res) => {
      if(res && (res?.length > 0)){
        setCircle(res)
      }
    })

    // circleService.getFilteredList(new Query<CircleQuery>({})).subscribe((res) => {
    //   if (!res) return
    //   setCircle(res.items)
    // })
  }, [])

  useEffect(() => {
    if (props.id) {
    }
  }, [])

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFile(Object.assign({ ...file }, { [e.target.name]: e.target.value }))
  }

  const handleFiles = (field: string, value: F[]) => {
    setFiles([...value])

    let fileUpload = file
    let extension = ""

    if(value[0] && value[0].extension){
      extension = value[0].extension;
    }
    setArticle(Object.assign({ ...article }, { slug: extension }))
    if (value && value.length >= 0) {
      fileUpload = value[0]
    }
    setFile(fileUpload)
  }

  const handleFilesFront = (field: string, value: F[]) => {
    setFilesFront([...value])
    setArticle(Object.assign({ ...article }, { slug: value.map((f) => f.id)[0] }))
  }

  const validateArticle = (): boolean => {
    if (!article.category) {
      setErrorMessage("Selecciona un círculo para continuar")
      return false
    }
    setErrorMessage('')
    return true
  }

  const saveArticle = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!validateArticle()) {
      return
    }

    let userCircleId = circle.find((obj:any) => obj.circle.id === article.category.toString()).id
    article.ogTitle = userCircleId
    article.userCircleID = userCircleId
    if (props.id) {
      articleService.update(article).subscribe((_) => goBack())
    } else {
      articleService.add(article).subscribe((_) => navigate(ROUTE_TEMPLATES_PRO_CONTENT))
    }
  }

  const goBack = () => navigate(ROUTE_TEMPLATES_PRO_CONTENT)

  const handleSend = () => {
    goBack()
  }
  return (
    <Box className={genericStyle.pageContainer}>
      <Box>
        <Header label={t('templatesPro')} />
      </Box>

      <FormCard>
        <form>
          <Box mb={3}>
            <TextFieldItem
              field="title"
              value={article.title}
              label={'title'}
              type={'text'}
              handleChange={handleInputText}
              rows={undefined}
              required={true}
            />
          </Box>
          {/*<Box mb={3}>*/}
          {/*  <TextFieldItem*/}
          {/*    field="specialities"*/}
          {/*    value={file.specialities}*/}
          {/*    label={'title'}*/}
          {/*    type={'text'}*/}
          {/*    handleChange={handleInput}*/}
          {/*    rows={undefined}*/}
          {/*    required={true}*/}
          {/*  />*/}
          {/*</Box>*/}

          <Box
            mb={3}
            style={{ border: '1px solid rgb(203 203 203)', borderRadius: 4, minHeight: 400 }}
          >
            <RichText
              content={article.content}
              handleChange={(e) => handleContent(e)}
              edit={!!props.id}
            />
          </Box>

          <Box mb={3}>
            <p>{t('selectCircle')}</p>
            <Select
              key={'pathology'}
              id={'pathology'}
              variant={'outlined'}
              value={article.category}
              onChange={handleCategory}
              required
              // fullWidth
              MenuProps={{
                MenuListProps: {
                  sx: {
                    'li.MuiButtonBase-root': {
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      fontFamily: 'Poppins',
                      paddingLeft: '10px',
                    },
                  },
                },
              }}
              SelectDisplayProps={{
                style: {
                  paddingLeft: '20px',
                  fontFamily: 'Poppins',
                },
              }}
              style={{ backgroundColor: '#f5f2f2', height: '40px', minWidth: '30%' }}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {circle.map((k:any) => {
                const keySelect = k.circle.id
                const labelSelect = k.circle.name

                return (
                  <MenuItem key={keySelect} value={keySelect}>
                    {labelSelect}
                  </MenuItem>
                )
              })}

            </Select>
          </Box>

          <Box mb={3}>
            <FileItem
              key={'files'}
              field={'files'}
              filesID={[file ? (file.id ?? ''): '']}
              handleFiles={handleFiles}
              userID={loggedUser?.id ?? ''}
              // userCircleID={file.circles}
              // circles={file.circles}
              cleanFiles={false}
              uploadRemoveFileDirectly={true}
                  disabled={
                    !article ||
                    !article.title ||
                    article.title === undefined ||
                    article.title.length === 0
                  }
              destinationFolder={'Article'}
              eventID={article.id}
              defaultFile={files}
              isMultiple={false}
              maxFiles={1}
              edit={true}
            />
          </Box>
          {errorMessage && (
            <Box mb={3}>
              <Alert severity="warning" key="errorMessage" id="errorMessage">
                {t(errorMessage)}
              </Alert>
            </Box>
          )}
          <Box display="flex" justifyContent="space-between">
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('cancel')}
              handler={goBack}
            />

             <Box>
              <AppButton
                theme={ButtonTheme.NewPrimary}
                type={'submit'}
                label={t('save')}
                handler={saveArticle}
              />
            </Box>
          </Box>
        </form>
      </FormCard>
    </Box>
  )
}
