// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CircleRegister_buttonsContainer__3XAGo {\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  margin-top: 100px;\n  margin-bottom: 200px;\n}\n.CircleRegister_buttons__1TbnO {\n  padding: 30px !important;\n  background-color: #67b3e0 !important;\n  border-radius: 0 10px 10px 0 !important;\n  color: #fff !important;\n  font-size: 1rem !important;\n  font-family: 'Poppins', sans-serif !important;\n  text-transform: none !important;\n}\n\n.CircleRegister_imageButtons__2iq5V {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #0163a6;\n  width: 80px;\n  height: 80px;\n  border-radius: 10px 0 0 10px !important;\n}\n\n.CircleRegister_textFieldBox__2LDtm {\n  width: 100%;\n  text-align: right !important;\n}\n", "",{"version":3,"sources":["webpack://src/features/circleRegister/CircleRegister.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,wBAAwB;EACxB,oCAAoC;EACpC,uCAAuC;EACvC,sBAAsB;EACtB,0BAA0B;EAC1B,6CAA6C;EAC7C,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,uCAAuC;AACzC;;AAEA;EACE,WAAW;EACX,4BAA4B;AAC9B","sourcesContent":[".buttonsContainer {\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  margin-top: 100px;\n  margin-bottom: 200px;\n}\n.buttons {\n  padding: 30px !important;\n  background-color: #67b3e0 !important;\n  border-radius: 0 10px 10px 0 !important;\n  color: #fff !important;\n  font-size: 1rem !important;\n  font-family: 'Poppins', sans-serif !important;\n  text-transform: none !important;\n}\n\n.imageButtons {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #0163a6;\n  width: 80px;\n  height: 80px;\n  border-radius: 10px 0 0 10px !important;\n}\n\n.textFieldBox {\n  width: 100%;\n  text-align: right !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonsContainer": "CircleRegister_buttonsContainer__3XAGo",
	"buttons": "CircleRegister_buttons__1TbnO",
	"imageButtons": "CircleRegister_imageButtons__2iq5V",
	"textFieldBox": "CircleRegister_textFieldBox__2LDtm"
};
export default ___CSS_LOADER_EXPORT___;
