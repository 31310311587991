// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Editor_month__1DVni {\n  border: 1px solid #000000;\n  padding: 0 10px 0 10px;\n  margin-bottom: 10px;\n  border-radius: 4px;\n}\n", "",{"version":3,"sources":["webpack://src/features/patient-calendar-config/Editor.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".month {\n  border: 1px solid #000000;\n  padding: 0 10px 0 10px;\n  margin-bottom: 10px;\n  border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"month": "Editor_month__1DVni"
};
export default ___CSS_LOADER_EXPORT___;
