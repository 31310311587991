import React, { useEffect, useRef, useState } from 'react'
import { Message } from '../../modules/messenger/models/Message'
import { Box } from '@mui/material'
import { Link } from 'react-router-dom'
import style from './Messages.module.css'
import { useTranslation } from 'react-i18next'
import { dateToDateTimeWithoutSecondsString } from '../../common/utils/date'
import { getUserContainer } from '../../container/user-module'
import { UserService } from '../../modules/users/services/UserService'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { Query, QueryParam } from '../../common/api/Query'
import { User, UserQuery } from '../../modules/users/models/User'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { viewFile } from '../../common/utils/file'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { Description } from '@mui/icons-material'

const userContainer = getUserContainer()
const usersService = userContainer.get<UserService>(USER_SERVICE_KEY)
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

const filesService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)

type MessagesProps = {
  messages: Message[]
}

export function Messages(props: MessagesProps): JSX.Element {
  const loggedUser = loggedUserService.get()

  const { t } = useTranslation()

  const [messagesAuthor, setMessagesAuthor] = useState<User[]>()

  const divMessagesScroll = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (divMessagesScroll.current !== null) {
      divMessagesScroll.current.scrollTop = divMessagesScroll.current.scrollHeight
    }
    usersService
      .getFilteredList(
        new Query({
          query: [
            new QueryParam<UserQuery>(
              'id',
              props.messages.map((m) => m.author)
            ),
          ],
        })
      )
      .subscribe((res) => {
        setMessagesAuthor(res.items)
      })
  }, [props.messages])

  const formattedDate = (createdAt: Date): string => dateToDateTimeWithoutSecondsString(createdAt)

  const getCorrectMessage = (text: string) => {
    const result = text.split('*')
    return result.length === 1 ? (
      <>{text}</>
    ) : (
      <>
        <Description />
        <Link to={'.'} onClick={() => viewResource(result[1])}>
          {result[2]}
        </Link>
      </>
    )
  }

  const viewResource = (id: string) => {
    filesService.downloadByID(id).subscribe((res) => {
      if (res) {
        viewFile(res)
      }
    })
  }

  return (
    <div className={getDivMessagesScrollStyle(props.messages.length)} ref={divMessagesScroll}>
      {!props.messages.length ? (
        <Box component="h3">{t('noMessages')}</Box>
      ) : (
        props.messages.map((m) => (
          <Box
            key={m.id}
            className={style.messageContainer}
            display="flex"
            flexDirection="column"
            alignItems={m.author === loggedUser?.id ? 'flex-end' : 'flex-start'}
          >
            <Box className={getMessageContentStyle(loggedUser?.id ?? '', m.author)}>
              <p className={style.author}>{messagesAuthor?.find((u) => u.id === m.author)?.name}</p>
              <p>{getCorrectMessage(m.text)}</p>
            </Box>
            <Box component="span">{formattedDate(m.createdAt)}</Box>
          </Box>
        ))
      )}
    </div>
  )
}

const getMessageContentStyle = (loggedUsername: string, author: string) =>
  `${style.messageContent} ${
    author === loggedUsername ? style.messageContentSent : style.messageContentReceived
  }`

const getDivMessagesScrollStyle = (messagesLength: number) =>
  `${style.divMessagesScroll} ${messagesLength === 0 ? style.divMessagesScrollEmpty : ''}`
