// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CustomModal_containerInside__1D7tA {\n  flex-direction: column;\n  width: 650px;\n  display: flex;\n  background-color: var(--color-white);\n  padding: 1.5rem;\n}\n\n.CustomModal_agoraContainer__3MRJ6 {\n  display: flex;\n  justify-content: center;\n  padding-top: 13%;\n}\n\n.CustomModal_buttons__2QEwt {\n  display: flex;\n  justify-content: space-between;\n  width: 38%;\n  margin-left: 31%;\n  padding-top: 3%;\n}\n\n.CustomModal_buttonsCancel__2iNqi {\n  display: flex;\n  justify-content: space-between;\n  width: 38%;\n  margin-left: 40%;\n  padding-top: 3%;\n}\n\n.CustomModal_alert__1U-bb {\n  height: 90px;\n  display: flex;\n  justify-content: center;\n  font-size: 20px;\n  margin-top: 3.5%;\n}\n", "",{"version":3,"sources":["webpack://src/components/modal/CustomModal.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,oCAAoC;EACpC,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,UAAU;EACV,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,UAAU;EACV,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".containerInside {\n  flex-direction: column;\n  width: 650px;\n  display: flex;\n  background-color: var(--color-white);\n  padding: 1.5rem;\n}\n\n.agoraContainer {\n  display: flex;\n  justify-content: center;\n  padding-top: 13%;\n}\n\n.buttons {\n  display: flex;\n  justify-content: space-between;\n  width: 38%;\n  margin-left: 31%;\n  padding-top: 3%;\n}\n\n.buttonsCancel {\n  display: flex;\n  justify-content: space-between;\n  width: 38%;\n  margin-left: 40%;\n  padding-top: 3%;\n}\n\n.alert {\n  height: 90px;\n  display: flex;\n  justify-content: center;\n  font-size: 20px;\n  margin-top: 3.5%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerInside": "CustomModal_containerInside__1D7tA",
	"agoraContainer": "CustomModal_agoraContainer__3MRJ6",
	"buttons": "CustomModal_buttons__2QEwt",
	"buttonsCancel": "CustomModal_buttonsCancel__2iNqi",
	"alert": "CustomModal_alert__1U-bb"
};
export default ___CSS_LOADER_EXPORT___;
