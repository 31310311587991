export type CommentsProps = {
  id: string
  articleID: string
  userID: string
  content: string
  createdAt: Date
  updatedAt: Date
}

export class Comments {
  private readonly _id: string

  private readonly _articleID: string
  private readonly _userID: string
  private readonly _content: string
  private readonly _createdAt: Date
  private readonly _updatedAt: Date

  constructor(props: CommentsProps) {
    this._id = props.id
    this._articleID = props.articleID
    this._userID = props.userID
    this._content = props.content
    this._createdAt = props.createdAt
    this._updatedAt = props.updatedAt
  }

  get id(): string {
    return this._id
  }

  get articleID(): string {
    return this._articleID
  }

  get content(): string {
    return this._content
  }

  get userID(): string {
    return this._userID
  }

  get createdAt(): Date {
    return this._createdAt
  }

  get updatedAt(): Date {
    return this._updatedAt
  }
}
