import React from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { CreateParticipant as C } from '../../features/users'
import { useParams } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function EditParticipant(props: RouteProps) {
  const { id } = useParams()
  const title = props.title || ''

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <C id={id} />
    </>
  )
}
