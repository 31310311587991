import i18n from '../../../i18n'

export enum ArticleStatus {
  borrador, // 0
  pendiente, // 1
  publicado, // 2
  rechazado, // 3
  validando, // 4
}

export const statusTypes = (): Record<ArticleStatus, string> => ({
  [ArticleStatus.borrador]: i18n.t('draft'),
  [ArticleStatus.pendiente]: i18n.t('pending'),
  [ArticleStatus.publicado]: i18n.t('published'),
  [ArticleStatus.rechazado]: i18n.t('refushed'),
  [ArticleStatus.validando]: i18n.t('validating'),
})
