import { Container } from '../../common/container/Container'

export type NotificationContainerConfig = {
  moduleFullUrl: string
}

export type NotificationsProps = {
  parentContainer: Container
  config: NotificationContainerConfig
}

export const NOTIFICATIONS_MODULE = Symbol('NOTIFICATIONS_MODULE')

export const NOTIFICATION_API_KEY = Symbol('NOTIFICATION_API_KEY')
export const SEGMENTED_NOTIFICATION_API_KEY = Symbol('SEGMENTED_NOTIFICATION_API_KEY')
export const MESSAGE_API_KEY = Symbol('MESSAGE_API_KEY')
export const USER_CONFIG_API_KEY = Symbol('USER_CONFIG_API_KEY')

export const NOTIFICATION_SERVICE_KEY = Symbol('NOTIFICATION_SERVICE_KEY')
export const MESSAGES_SERVICE_KEY = Symbol('MESSAGES_SERVICE_KEY')
export const USER_CONFIG_SERVICE_KEY = Symbol('USER_CONFIG_SERVICE_KEY')
export const SEGMENTED_NOTIFICATIONS_SERVICE_KEY = Symbol('SEGMENTED_NOTIFICATIONS_KEY')
