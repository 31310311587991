// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Configuration_configurationList__1dm5p {\n  width: calc(100% - 20px);\n  background-color: var(--color-white);\n  border-radius: 2rem;\n}\n\n.Configuration_configurationLink__3CC4y {\n  width: 100%;\n  text-decoration: none;\n  cursor: pointer;\n}\n\n.Configuration_configurationItem__2hhlT {\n  grid-column-gap: 1rem;\n  column-gap: 1rem;\n  display: flex;\n  justify-content: flex-start;\n  margin-left: 35px;\n}\n\n.Configuration_version__17Ri_ {\n  padding: 10px;\n  color: #757575;\n}\n\n.Configuration_configurationSeparator__15H7c {\n  margin: 0;\n  width: 95%;\n}\n\n.Configuration_configurationItemLabel__10iVH {\n  font-size: 1.2rem;\n  text-align: left;\n  display: block;\n}\n\n.Configuration_icon__3HG0j {\n  width: 30px;\n}\n", "",{"version":3,"sources":["webpack://src/features/configuration/Configuration.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,oCAAoC;EACpC,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,qBAAgB;EAAhB,gBAAgB;EAChB,aAAa;EACb,2BAA2B;EAC3B,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".configurationList {\n  width: calc(100% - 20px);\n  background-color: var(--color-white);\n  border-radius: 2rem;\n}\n\n.configurationLink {\n  width: 100%;\n  text-decoration: none;\n  cursor: pointer;\n}\n\n.configurationItem {\n  column-gap: 1rem;\n  display: flex;\n  justify-content: flex-start;\n  margin-left: 35px;\n}\n\n.version {\n  padding: 10px;\n  color: #757575;\n}\n\n.configurationSeparator {\n  margin: 0;\n  width: 95%;\n}\n\n.configurationItemLabel {\n  font-size: 1.2rem;\n  text-align: left;\n  display: block;\n}\n\n.icon {\n  width: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"configurationList": "Configuration_configurationList__1dm5p",
	"configurationLink": "Configuration_configurationLink__3CC4y",
	"configurationItem": "Configuration_configurationItem__2hhlT",
	"version": "Configuration_version__17Ri_",
	"configurationSeparator": "Configuration_configurationSeparator__15H7c",
	"configurationItemLabel": "Configuration_configurationItemLabel__10iVH",
	"icon": "Configuration_icon__3HG0j"
};
export default ___CSS_LOADER_EXPORT___;
