import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { ItemList } from '../../../common/models/ItemList'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { VisualizationsDTO } from '../models/VisualizationsDTO'
import { Visualizations } from '../models/Visualizations'
import { IVisualizationsApi } from '../api/VisualizationsApi'

type Props = {
  apiKey: symbol
}

export interface IVisualizationsService extends IInit {
  getByID(id: string): Observable<Visualizations | undefined>

  getVisualizationsByArticleID(id: string | undefined): Observable<ItemList<Visualizations>>

  getVisualizationsByUserID(id: string | undefined): Observable<ItemList<Visualizations>>

  getVisualizationsByCircleID(id: string | undefined): Observable<ItemList<Visualizations>>

  add(e: VisualizationsDTO): Observable<Visualizations | undefined>

  update(e: VisualizationsDTO): Observable<Visualizations | undefined>

  delete(id: string): Observable<boolean>
}

export class VisualizationService implements IVisualizationsService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IVisualizationsApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container): void {
    this._container = c
    this._api = this._container.get<IVisualizationsApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: VisualizationsDTO): Observable<Visualizations | undefined> {
    return this._api.add(e)
  }

  update(e: VisualizationsDTO): Observable<Visualizations | undefined> {
    return this._api.update(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<Visualizations | undefined> {
    return this._api.getByID(id)
  }

  getVisualizationsByArticleID(id: string | undefined): Observable<ItemList<Visualizations>> {
    return this._api.getVisualizationsByArticleID(id)
  }

  getVisualizationsByUserID(id: string | undefined): Observable<ItemList<Visualizations>> {
    return this._api.getVisualizationsByUserID(id)
  }

  getVisualizationsByCircleID(id: string | undefined): Observable<ItemList<Visualizations>> {
    return this._api.getVisualizationsByCircleID(id)
  }
}
