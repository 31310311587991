import { Likes } from './Likes'
import { v4 as uuidv4 } from 'uuid'

export interface LikesDTO {
  id: string
  articleID: string
  userID: string
  createdAt: Date
  updatedAt: Date
}

export function emptyLikesDTO(): LikesDTO {
  return {
    id: uuidv4(),
    articleID: '',
    userID: '',
    createdAt: new Date(),
    updatedAt: new Date(),
  }
}

export function fromModel(t: Likes): LikesDTO {
  return {
    id: t.id,
    articleID: t.articleID,
    userID: t.userID,
    createdAt: t.createdAt,
    updatedAt: t.updatedAt,
  }
}

export function toModel(d: LikesDTO): Likes {
  return new Likes(d)
}
