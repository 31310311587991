import { DashboardSectionCard } from '../../../../components/section-card/DashboardSectionCard'
import React, { useEffect, useState } from 'react'
import { getUserCircleContainer } from '../../../../container/user-circle-module'
import {
  IUserCircleActiveService,
  IUserCircleService,
  USER_CIRCLE_ACTIVE_SERVICE_KEY,
  USER_CIRCLE_SERVICE_KEY,
} from '../../../../modules/user-circle'
import { UserCircleWithRelatersAndCircle } from '../../../../modules/user-circle/models/UserCircleWithRelatersAndCircle'
import { getUserContainer } from '../../../../container/user-module'
import { ILoggedUserService } from '../../../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../../../modules/users'
import { Box, ListItem, ListItemText } from '@mui/material'
import style from '../chats-card/ChatsCard.module.scss'
import { listItemTextStyle } from '../notification-card/NotificationsCardStyleMui'
import patientIcon from '../../../../assets/role_icons/ico-rol-paciente.svg'
import professionalSMSIcon from '../../../../assets/role_icons/ico-rol-profesional.svg'
import familyIcon from '../../../../assets/role_icons/ico-rol-familiar.svg'
import externProfessionalIcon from '../../../../assets/role_icons/ico-rol-externo.svg'
import career from '../../../../assets/role_icons/ico-rol-cuidador.svg'

type CirclesCardProps = {
  selectedUserCircle: UserCircleWithRelatersAndCircle | undefined
  setSelectedUserCircle: (userCircle: UserCircleWithRelatersAndCircle) => void
}

const userCircleService = getUserCircleContainer().get<IUserCircleService>(USER_CIRCLE_SERVICE_KEY)

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)

export const CirclesCard: React.FC<CirclesCardProps> = ({
  setSelectedUserCircle,
  selectedUserCircle,
}) => {
  const [userCircles, setUserCircles] = useState<UserCircleWithRelatersAndCircle[]>([])
  const loggedUser = loggedUserService.get()
  const activeFullUserCircle = userCircleActiveService.getActiveFullUserCircle()

  useEffect(() => {
    userCircleService
      .getWichIncludesUserIDWithRelatersAndCircle(loggedUser?.id ?? '')
      .subscribe((res) => {
        if (res) {
          setUserCircles(
            res.filter((userCircle) => userCircle?.user?.id === activeFullUserCircle?.user?.id)
          )
        }
      })
  }, [])

  const getRoleIcon = (userCircle: UserCircleWithRelatersAndCircle) => {
    if (userCircle.profExt.some((profExt) => profExt.id === loggedUser?.id)) {
      return externProfessionalIcon
    }
    if (userCircle.profSms.some((prof) => prof.id === loggedUser?.id)) {
      return professionalSMSIcon
    }
    if (userCircle.familiars.some((fam) => fam.id === loggedUser?.id)) {
      return familyIcon
    }
    if (userCircle.careers.some((carer) => carer.id === loggedUser?.id)) {
      return career
    }

    return patientIcon
  }
  return (
    <DashboardSectionCard label={'Tus Círculos'}>
      {userCircles.map((userCircle) => (
        <ListItem
          key={userCircle.id}
          onClick={(event) => {
            setSelectedUserCircle(userCircle)
          }}
          sx={{
            hover: 'none',
            cursor: 'pointer',
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <Box width={'100%'}>
            <Box
              sx={{
                backgroundColor:
                  selectedUserCircle?.id === userCircle.id ? 'rgb(241, 139, 36, 0.15)' : 'white',
                borderColor:
                  selectedUserCircle?.id === userCircle.id ? 'rgb(241, 139, 36)' : '#68B3E0',
              }}
              display={'flex'}
              alignItems={'center'}
              mr={1}
              ml={1}
              className={style.chatContainer}
            >
              <ListItemText sx={listItemTextStyle}>{userCircle.circle.name}</ListItemText>

              <ListItemText sx={listItemTextStyle}>
                <img src={getRoleIcon(userCircle)} alt={'roleIcon'} />
              </ListItemText>

              <ListItemText sx={listItemTextStyle}>{userCircle.user.name}</ListItemText>
            </Box>
          </Box>
        </ListItem>
      ))}
    </DashboardSectionCard>
  )
}
