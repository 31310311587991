import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useNavigate } from 'react-router-dom'
import { ROUTE_ARTICLES, ROUTE_ARTICLES_HISTORY } from '../../routes/routes-constants'
import { Article } from '../../modules/content/models/Article'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { Query, QueryParam, QueryParamN } from '../../common/api/Query'
import { Pager, Search, SearchValue } from '../../components/table_type/types'
import { getContentContainer } from '../../container/content-module'
import { ArticleService } from '../../modules/content/services/ArticleService'
import { ARTICLE_SERVICE_KEY } from '../../modules/content'
import { AppTable, Field } from '../../components/table'
import { useTranslation } from 'react-i18next'
import searchIcon from '../../assets/resource_icons/search_icon.svg'
import likeIcon from '../../assets/buttons/Like.svg'
import deleteIcon from '../../assets/left_menu/icono-close.svg'
import relevanciaNaranja from '../../assets/esfera/article/relevancia naranja.svg'
import relevanciaRojo from '../../assets/esfera/article/relevancia rojo.svg'
import relevanciaVerde from '../../assets/esfera/article/relevancia verde.svg'
import { getUserContainer } from '../../container/user-module'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { Box, Modal } from '@mui/material'
import { RejectModal } from 'components/modal/RejectModal'
import { ArticleRelevance } from 'modules/content/enums/ArticleRelevance'
import { ArticleStatus } from 'modules/content/enums/ArticleStatus'
import { Header } from '../../components/header/Header'
import { fromModel } from '../../modules/content/models/ArticleDTO'
import style from './Search.module.css'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { getNotificationContainer } from 'container/notification-module'
import { NotificationService } from 'modules/notifications/services/NotificationService'
import { NOTIFICATION_SERVICE_KEY } from 'modules/notifications'
import { DeliveryStatus } from 'modules/notifications/enums/DeliveryStatus'
import { PriorityLevel } from 'modules/notifications/enums/PriorityLevel'
import { TransportType } from 'common/enums/TransportType'
import { Notification } from 'modules/notifications/models/Notification'
import { MESSAGES_SERVICE_KEY } from 'modules/notifications'
import { MessageService } from 'modules/notifications/services/MessageService'
import { Message } from 'modules/notifications/models/Message'
import { SourceType } from 'modules/notifications/enums/SourceType'
import { getCircleContainer } from '../../container/circle-module'
import { CircleService } from '../../modules/circle/services/CircleService'
import { CIRCLE_SERVICE_KEY } from '../../modules/circle'
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner'
import loaderStyles from '../../components/loading-spinner/LoadingSpinner.module.css'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { UserCircleService } from '../../modules/user-circle/services/UserCircleConfigService'
import { USER_CIRCLE_SERVICE_KEY } from '../../modules/user-circle'
import genericStyle from '../../common/utils/generic.module.css'

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const contentContainer = getContentContainer()
const articlesService = contentContainer.get<ArticleService>(ARTICLE_SERVICE_KEY)

const messengerContainer = getNotificationContainer()
const messengerService = messengerContainer.get<MessageService>(MESSAGES_SERVICE_KEY)

const notificationContainer = getNotificationContainer()
const notificationService = notificationContainer.get<NotificationService>(NOTIFICATION_SERVICE_KEY)

const userCircleService = getUserCircleContainer().get<UserCircleService>(USER_CIRCLE_SERVICE_KEY)
const authorService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const circleService = getCircleContainer().get<CircleService>(CIRCLE_SERVICE_KEY)

type ArticleProps = {
  tagIDList: string[],
  // circles: number[]
}

const authorList = new Map()
const circleList = new Map()

export function TableValidate(props: ArticleProps) {
  const { t } = useTranslation()

  const loggedUser = loggedUserService.get()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [articles, setArticles] = useState<ItemList<Article>>(emptyList<Article>())
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [articlesPerPage, setArticlesPerPage] = useState<number>(50)
  const [pager, setPager] = useState<Pager>()
  const [openRejectModal, setOpenRejectModal] = useState<boolean>(false)
  // const [circleList, setCircleList] = useState<Map<string, string>>(new Map())
  const [selectedArticle, setSelectedArticle] = useState<Article>()
  const navigate = useNavigate()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filtering, setFiltering] = useState<boolean>(false)
  const [circles, setUserCircles] = useState<any>([])
  const [searcher, setSearcher] = useState<SearchValue<Article>[]>([
    {
      name: 'id',
      label: t('search') + '...',
      value: '',
    },
  ])

  const search: Search<Article> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<Article>[]) => {
      setFiltering(!!svs[0].value)

      const result = svs.map((s) => {
        if (s.type !== 'date' || !s.value) {
          return s
        }

        const date = new Date(s.value)
        date.setDate(date.getDate() + 1)
        date.setHours(1, 0, 0, 0)
        return Object.assign({ ...s }, { value: date.toJSON() })
      })

      setSearcher(result)
    },
  }

  useEffect(() => {
    let circles:any[] = []
    let logged = JSON.parse(localStorage.getItem("logged user") || "null")
    userCircleService.getUserCirclesByUserId(logged.id).subscribe((res) => {
      if(res && (res?.length > 0)){
        res.forEach(obj => circles.push(Number(obj.circle.id)))
      }
      setUserCircles(circles)
    })
  }, [])

  const getArticles = () => {

    let query = [
      new QueryParam<Article>('type', 'article'),
      new QueryParam<Article>('categories', circles),
      new QueryParam<Article>('status', [ArticleStatus[ArticleStatus.pendiente]])
    ]

    if(searcher[0].value !== '' && searcher[0].value !== undefined){
      query.push(new QueryParam<Article>('titleCoincidences', searcher[0].value))
    }

    articlesService
      .getFilteredList(
        new Query({
          query: query,
          pager: { offset: page * articlesPerPage, limit: articlesPerPage },
        })
      )
      .subscribe((res) => {
        // setIsLoading(false)
        const articleList = emptyList<Article>()

        articleList.count = 0
        for (let i = res.items.length - 1; i >= 0; i--) {
          const articleItem = res.items[i]

          if (articleItem.id !== undefined) {
            articlesService.getAuthorAndCategory(articleItem.id).subscribe((response) => {
              if (response !== undefined) {
                articleItem.author = response.author
                articleItem.categoryText = response.category
              }
              articleList.items.push(articleItem)
              articleList.count++
              articleList.items = articleList.items.sort((a, b) => {
                return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
              })
            })
          } else {
            articleList.items.push(articleItem)
            articleList.count++
            articleList.items = articleList.items.sort((a, b) => {
              return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            })
          }
        }

        setArticles(articleList)
        setCount(res.count)
      })
  }

  useEffect(() => {
    getArticles()
  }, [circles])

  useEffect(() => {
    getArticles()
  }, [searcher])

  useEffect(() => {
    // setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: articlesPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, articlesPerPage])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setArticlesPerPage(10)
      return
    }
    setArticlesPerPage(Number.parseInt(event.target.value))
  }

  const handleSee = (article: Article) => navigate(`${ROUTE_ARTICLES}/${article.id}`)

  const handleSeeHistory = (event: any) => {
    navigate(`${ROUTE_ARTICLES_HISTORY}`)
  }

  const approveArticle = (event: any) => {
    const articleApprove = selectedArticle

    if (articleApprove != null) {
      articleApprove.status = ArticleStatus[ArticleStatus.publicado]
      // articleApprove.circleId = articleApprove.ogTitle
      articleApprove.userCircleID = articleApprove.ogTitle
      articlesService.update(fromModel(articleApprove)).subscribe((_) => {
        setIsLoading(true)
        getArticles()
      })
      setSelectedArticle(undefined)
    }

    // TODO - no necesario de momento -
    // if(articleApprove && articleApprove.id) {
    //   const msg = new Message({
    //     id: uuidv4(),
    //     title: "Nuevo articulo: " + selectedArticle?.title,
    //     description: "Add new article" + selectedArticle?.id,
    //     message: "Add new article" + selectedArticle?.id,
    //     sourceType: SourceType.ContentNewArticle,
    //     url: articleApprove.id,
    //   })
    //
    //   const notif = new Notification({
    //     userCircleID: articleApprove.ogTitle,
    //     createdAt: new Date(),
    //     sendAt: new Date(),
    //     deliveryStatus: DeliveryStatus.Sent,
    //     id: uuidv4(),
    //     message: msg?.id ?? '',
    //     priorityLevel: PriorityLevel.Normal,
    //     recipientID: articleApprove.userId,
    //     senderID: loggedUser?.id ?? '',
    //     transportType: TransportType.App,
    //     sourceType: SourceType.ContentNewArticle,
    //     endDate: new Date(),
    //     frequency: 0,
    //     lastSendAt: new Date(),
    //   })
    //
    //   messengerService.add(msg).subscribe(() => {
    //     notificationService.add(notif).subscribe(() => {
    //     })
    //   })
    // }
  }

  const rejectArticle = (description: string) => {
    const articleApprove = selectedArticle
    if (articleApprove != null) {
      articleApprove.rejectedReason = description;
      articleApprove.status = ArticleStatus[ArticleStatus.rechazado]
      articleApprove.userCircleID = articleApprove.ogTitle
      articlesService.update(fromModel(articleApprove)).subscribe((_) => {
        setIsLoading(true)
        getArticles()
      })

      if(articleApprove && articleApprove.id){
        const msg = new Message({
          id: uuidv4(),
          title: selectedArticle?.title+ " rechazado",
          description: description,
          message: description,
          sourceType: SourceType.ContentArticleRejected,
          url: articleApprove.id,
        })

        const notif = new Notification({
          userCircleID: articleApprove.ogTitle,
          createdAt: new Date(),
          sendAt: new Date(),
          deliveryStatus: DeliveryStatus.Sent,
          id: uuidv4(),
          message: msg?.id ?? '',
          priorityLevel: PriorityLevel.Normal,
          recipientID: articleApprove.userId,
          senderID: loggedUser?.id ?? '',
          transportType: TransportType.App,
          sourceType: SourceType.ContentArticleRejected,
          endDate: new Date(),
          frequency: 0,
          lastSendAt: new Date(),
        })

        messengerService.add(msg).subscribe(() => {
          notificationService.add(notif).subscribe(() => {})
        })
        getArticles()
        setSelectedArticle(undefined)
      }
    }

    setOpenRejectModal(false)
  }

  const handleOpenModalRejectArticle = () => {
    if (selectedArticle?.id) {
      setOpenRejectModal(true)
    }
  }

  const handleCloseRejectModal = () => {
    setOpenRejectModal(false)
  }

  const handleCheck = (article: Article) => {
    if (article.id === selectedArticle?.id) {
      setSelectedArticle(undefined)
    } else {
      setSelectedArticle(article)
    }
  }

  const showContentChildrens = (paragraph: any) => {
    if (paragraph?.children != null) {
      return (
        <div>
          {paragraph.children.map((item: any) => {
            // eslint-disable-next-line react/jsx-key
            return <span>{showContentChildrens(item)}</span>
          })}
        </div>
      )
    } else {
      return <span>{paragraph.text}</span>
    }
  }

  // @ts-ignore
  const fields: Field<Article>[] = [
    {
      name: 'id',
      label: '',
      renderFunc: (f, i) => {
        const estaSeleccionado = selectedArticle?.id === i.id
        return (
          <span>
            <input
              className={style.check}
              type="checkbox"
              checked={estaSeleccionado}
              onClick={() => handleCheck(i)}
            />
          </span>
        )
      },
    },
    {
      name: 'title',
      label: t('title'),
      renderFunc: (f, i) => (
        <span style={{ margin: '0px', cursor: 'pointer' }} onClick={() => handleSee(i)}>
          {i.title}
        </span>
      ),
    },
    {
      name: 'createdAt',
      label: t('creationDate'),
      renderFunc: (f, i) => new Date(i.createdAt).toLocaleDateString(),
    },
    {
      name: 'updatedAt',
      label: t('lastUpdate'),
      renderFunc: (f, i) => new Date(i.updatedAt).toLocaleDateString(),
    },
    {
      name: 'content',
      label: t('description'),
      renderFunc: (f, i) => {
        try {
          const jsonContent = JSON.parse(i.content)
          const textContent = jsonContent.map((itemElement: any) =>
            showContentChildrens(itemElement)
          )
          return <div className={style.contentPreview}>{textContent}</div>
        } catch (error) {
          return <div className={style.contentPreview}>{i.content}</div>
        }
      },
    },
    {
      name: 'authorName',
      label: t('author'),
      //@ts-ignore
      renderFunc: (f, i) => {
        return i.authorName
      },
    },
    {
      name: 'relevance',
      label: t('relevance'),
      renderFunc: (f, i) => {
        let relevanceIcon = ''
        let colorRelevance = ''

        switch (i.relevance) {
          case ArticleRelevance[ArticleRelevance.baja]:
            colorRelevance = '#6ce32b'
            relevanceIcon = relevanciaVerde
            break
          case ArticleRelevance[ArticleRelevance.media]:
            colorRelevance = 'orange'
            relevanceIcon = relevanciaNaranja
            break
          case ArticleRelevance[ArticleRelevance.alta]:
            colorRelevance = 'red'
            relevanceIcon = relevanciaRojo
            break
          default:
            break
        }

        return (
          <span style={{ color: colorRelevance }}>
            <img src={relevanceIcon}></img>
          </span>
        )
      },
    },
    {
      name: 'circleId',
      label: t('circle'),
      //@ts-ignore
      renderFunc: (f, i) => {
        // const circleName = circleList.get(i.circleId)
        return i.categoryText
      },
    },
  ]

  return (
    <>
      <Modal open={openRejectModal} onClose={handleCloseRejectModal}>
        <RejectModal
          handleClose={handleCloseRejectModal}
          handleSave={rejectArticle}
          title={selectedArticle?.title ?? ''}
          author={authorList.get(selectedArticle?.id)}
        />
      </Modal>
      <div className={style.gridContainer}>
        <div>
          <Box>
            <Header label={t('Actions')} />
          </Box>

          <Box>
            <br />
            <AppButton
              disabled={!selectedArticle}
              theme={!selectedArticle ? ButtonTheme.disabled : ButtonTheme.Addgroup}
              label={t('approve')}
              type={'button'}
              startIcon={likeIcon}
              handler={approveArticle}
            />
          </Box>

          <Box>
            <br />
            <AppButton
              disabled={!selectedArticle}
              theme={!selectedArticle ? ButtonTheme.disabled : ButtonTheme.RemoveGroup}
              label={t('reject')}
              type={'button'}
              startIcon={deleteIcon}
              handler={handleOpenModalRejectArticle}
            />
          </Box>

          <Box>
            <br />
            <hr />
            <br />
          </Box>

          <Box>
            <AppButton
              theme={ButtonTheme.NewPrimary}
              label={t('viewHistory')}
              type={'button'}
              startIcon={searchIcon}
              handler={handleSeeHistory}
            />
          </Box>
        </div>
        <div>
          {/*{!isLoading ? (*/}
              <AppTable
                items={articles.items}
                rowKeyField="id"
                fields={fields}
                search={search}
                // actions={actions}
                pager={pager}
              />
          {/*) : (*/}
          {/*  <LoadingSpinner className={loaderStyles.loadingSpinner} />*/}
          {/*)}*/}
        </div>
      </div>
    </>
  )
}
