import React, { useEffect, useState, useMemo } from 'react'
import GenericChartDashboard from '../../../../components/dashboard/generic-chart-dashboard/GenericChartDashboard'
import { ChartEnum } from '../../../../components/dashboard/generic-chart-dashboard/ChartEnum'
import { ActivePlanService } from '../../../../modules/active-plan/services/ActivePlanService'
import { Query } from '../../../../common/api/Query'

type Status = {
  type: 'active' | 'rejected' | 'pending' | 'closed'
  count: number
}

type PieCircleStateCardProps = {
  activePlanService: ActivePlanService
}

const PieCircleStateCard: React.FC<PieCircleStateCardProps> = ({ activePlanService }) => {
  const [circleStatus, setCircleStatus] = useState<Status[]>([])

  const statusTypes = useMemo(
    () => ({
      active: 'Activos',
      rejected: 'Rechazado',
      pending: 'Pendiente',
      closed: 'Cerrado',
    }),
    []
  )

  const backgroundColors = useMemo(
    () => ({
      active: '#0063A6',
      rejected: '#F18B24',
      pending: '#B6B5B5',
      closed: '#F1DD25',
    }),
    []
  )

  useEffect(() => {
    const subscription = activePlanService
      .getFilteredList(new Query({ query: [] }))
      .subscribe((response) => {
        if (!response) return
        const statusCounts = {
          active: response.items.filter((item) => item.status === 'active').length,
          rejected: response.items.filter((item) => item.status === 'rejected').length,
          pending: response.items.filter((item) => item.status === 'pending').length,
          closed: response.items.filter((item) => item.status === 'closed').length,
        }

        const filteredStatusCounts: Status[] = Object.entries(statusCounts)
          .filter(([_, count]) => count > 0)
          .map(([type, count]) => ({
            type: type as 'active' | 'rejected' | 'pending' | 'closed',
            count,
          }))

        setCircleStatus(filteredStatusCounts)
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [activePlanService])

  const data = circleStatus.map((status) => status.count)
  const backgroundColor = circleStatus.map((status) => backgroundColors[status.type])
  const labels = circleStatus.map((status) => statusTypes[status.type])

  const datasets = [
    {
      labels,
      data,
      backgroundColor,
    },
  ]

  return (
    <GenericChartDashboard
      datasets={datasets}
      labels={labels}
      title={''}
      typeChart={ChartEnum.Pie}
      legendPosition={'right'}
      showGridLines={false}
      showXAxis={false}
      showYAxis={false}
    />
  )
}

export default PieCircleStateCard
