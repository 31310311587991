import React, { useEffect, useState } from 'react'
import { Box, Modal } from '@mui/material'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import {
  ROUTE_ARTICLES,
  ROUTE_ARTICLES_FORM,
  ROUTE_CREATE,
  ROUTE_MESSENGER, ROUTE_SEGMENTED_NOTIFICATIONS_FORM_EDIT, ROUTE_SEGMENTED_NOTIFICATIONS,
  ROUTE_TAG_LIST,
} from '../../routes/routes-constants'
import { useTranslation } from 'react-i18next'
import loaderStyles from '../../components/loading-spinner/LoadingSpinner.module.css'
import { Query, QueryParam } from '../../common/api/Query'
import { getContentContainer } from '../../container/content-module'
import { TAG_SERVICE_KEY } from '../../modules/content'
import { LoadingSpinner } from 'components/loading-spinner/LoadingSpinner'
import { TagService } from '../../modules/content/services/TagService'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import genericStyle from '../../common/utils/generic.module.css'
import searchIcon from '../../assets/resource_icons/search_icon.svg'
import styles from './List.module.css'
import { Header } from '../../components/header/Header'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { Permission } from '../../common/permission'
import chatIcon from '../../assets/left_menu/chat.svg'
import { ChatWidget } from '../newsWall/ChatWidget'
import Button from '@mui/material/Button'
import rightArrow from '../../assets/calendar/arrow.svg'
import { Timeline } from '@mui/lab'
import style from '../../components/loading-spinner/LoadingSpinner.module.css'
import { NotificationCard } from './NotificationCard'
import { Searcher } from './Searcher'
import { useGetNotifications } from '../../hooks/notifications/useGetNotifications'
import { GroupModal } from './NewGroup'
import { emptyCommentsDTO } from '../../modules/content/models/CommentsDTO'
import { NotificationData } from './NotificationData'
import { SegmentedNotificationService } from '../../modules/notifications/services/SegmentedNotificationService'
import { SEGMENTED_NOTIFICATION_API_KEY } from '../../modules/notifications'
import { getNotificationContainer } from '../../container/notification-module'
import { SegmentedNotificationDTO } from '../../modules/notifications/models/SegmentedNotificationDTO'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { SegmentedNotification } from '../../modules/notifications/models/SegmentedNotification'
import { FileQuery } from '../../modules/files/models/File'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { UserCircleService } from '../../modules/user-circle/services/UserCircleConfigService'
import {
  IUserCircleActiveService,
  USER_CIRCLE_ACTIVE_SERVICE_KEY,
  USER_CIRCLE_SERVICE_KEY,
} from '../../modules/user-circle'
import { UserCircleWithCircle } from '../../modules/user-circle/models/UserCircleWithCircle'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { Article } from '../../modules/content/models/Article'

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const contentContainer = getContentContainer()
const tagsService = contentContainer.get<TagService>(TAG_SERVICE_KEY)

const notificationContainer = getNotificationContainer()
const segmentedNotificationService = notificationContainer.get<SegmentedNotificationService>(SEGMENTED_NOTIFICATION_API_KEY)
const userCircleService = getUserCircleContainer().get<UserCircleService>(USER_CIRCLE_SERVICE_KEY)
const UserCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)

type SearchProps = {
  tagList: string[]
}

type EditorProps = {
  id?: string
}

function Screen(){
  return(
    <div className={styles.screen}>
      <ForwardToInboxIcon fontSize={"large"} style={{color:"rgb(104, 179, 224)", fontSize:"150px"}}/>
      <p>
        Crea un grupo nuevo o
      </p>
      <p>
        selecciona un grupo ya existente
      </p>
    </div>
  )
}

function ScreenPatient(){
  return(
    <div className={styles.screen}>
      <ForwardToInboxIcon fontSize={"large"} style={{color:"rgb(104, 179, 224)", fontSize:"150px"}}/>
      <p>
        Selecciona un grupo ya existente
      </p>
    </div>
  )
}

export function View(props: EditorProps) {
  const activeUser = UserCircleActiveService.getActiveFullUserCircle()
  const loggedUser = loggedUserService.get()

  const { t } = useTranslation()

  const [circleUser, setCircleUser] = useState<UserCircleWithCircle>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [notificationGroups, setNotificationGroups] = useState<ItemList<SegmentedNotification>>(emptyList<SegmentedNotification>())
  const [notificationGroupsOriginal, setNotificationGroupsOriginal] = useState<ItemList<SegmentedNotification>>(emptyList<SegmentedNotification>())
  const [tagList, setTagList] = useState<Map<string, string>>(new Map())
  const [tagSearch, setTagSearch] = useState<string[]>([])
  const [groupSelected, setGroupSelected] = useState<any>("")
  const [openGroupModal, setOpenGroupModal] = useState<boolean>(false)
  const [isPatient, setPatient] = useState<boolean>(false)
  const navigate = useNavigate()

  const handleCloseGroupModal = () => {
    setNotificationGroups(emptyList<SegmentedNotification>())
    setGroupSelected("")
    setOpenGroupModal(false)
  }
  const handleOpenGroupModal = () => {
    setOpenGroupModal(true)
  }

  useEffect(() => {
    console.log(props)
    debugger;
    let circles:any[] = []
    let logged = JSON.parse(localStorage.getItem("logged user") || "null")
    userCircleService.getUserCirclesByUserId(logged.id).subscribe((res) => {
      if(res && (res?.length > 0)){
        res.forEach(obj => circles.push(obj.circle.id.toString()))
      }
      const articlesQuery = [
        new QueryParam<any>('circlesIds', circles)
      ]
      segmentedNotificationService.getFilteredList(
        new Query({
          query: articlesQuery,
        })
      ).subscribe((res) => {
        let notifications = emptyList<SegmentedNotification>()

        notifications.items  = res.items.sort((a, b) => {
          return b.updatedAt.getTime() - a.updatedAt.getTime()
        })
        setNotificationGroups(notifications)
        setNotificationGroupsOriginal(notifications)
      })
    })

    if (loggedUser) {
      if (!activeUser?.id) {
        return
      }
      userCircleService.getUserCircleByUserIDWithCircle(activeUser.user.id).subscribe((res) => {
        const userCircleSelected = res?.find((userCircle) => {
          return userCircle.userID === loggedUser.id
        })
        if (userCircleSelected !== null) {
          setCircleUser(userCircleSelected)
        }
      })
      if(loggedUser.roles.find((item) => item === "patient")){
        setPatient(true)
      }
    }
  },[groupSelected, openGroupModal])

  useEffect(() => {
    if(props.id && notificationGroupsOriginal.items.length !== 0){
      const group = notificationGroups.items.find(obj => {
        return obj.id === props.id
      })
      let data = {
        data: group,
        id: group?.id,
        title: group?.name
      }
      group && setGroupSelected(data)
    }
  }, [props.id, notificationGroupsOriginal])

  const uploadComment = (comment: string) => {
    setOpenGroupModal(false)
  }

  const handleSearch = (data: any) => {
    if(data.length != 0)
    setNotificationGroups(data)
    setNotificationGroups(Object.assign({ ...notificationGroups }, { items: data }))
  }

  const handleSelectNotification = (data:any) => {
    setGroupSelected(data)
    navigate(`${ROUTE_SEGMENTED_NOTIFICATIONS}/${data.id}`)
  }

  return (
    <>
      <Modal open={openGroupModal} onClose={handleCloseGroupModal}>
        <GroupModal
          handleClose={handleCloseGroupModal}
          handleSave={uploadComment}
          segmentedId={groupSelected.id}
          userId={loggedUser?.id}
          userCircleId={circleUser?.id}
        />
      </Modal>
      {!isLoading ? (
        <Box className={styles.wallContainer} style={{ height: '80%'}}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            className={styles.chatWidgetContainer}
            style={{ maxHeight: '93%', overflowY: 'auto' }}
          >
            <div style={{position:"sticky", top:"0", backgroundColor: "#ffffff", zIndex:1000}}>
              <Box
                className={style.searcherContainer}
                component="section"
                display="flex"
                justifyContent="space-between"
              >
                <Searcher
                  handler={handleSearch}
                  data={notificationGroupsOriginal}
                />
              </Box>
              {
                !isPatient &&
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    flex: 1,
                  }}
                >
                  <Button
                    onClick={handleOpenGroupModal}
                    variant="contained"
                    fullWidth={true}
                    endIcon={<img src={rightArrow} alt="right arrow" />}
                    style={{
                      backgroundColor: '#68b3e0',
                      marginTop: '10px',
                      float: 'right',
                      color: 'white',
                    }}
                  >
                    {t('newGroup')}
                  </Button>
                </Box>
              }
            </div>
            <Box>
              <Box className={style.blueLine} />
            </Box>
            <Box>
              {
                notificationGroupsOriginal &&
                notificationGroups.items.map((obj,index) => {
                  return(
                    <NotificationCard
                      id={obj.id}
                      clickEvent={handleSelectNotification}
                      title={obj.name}
                      data={obj}
                      avatarUrl={'AvatarUrl'}/>
                  )
                })
              }
            </Box>
          </Box>
          {
            groupSelected !== '' ?
              <NotificationData
                title={groupSelected.title}
                description={groupSelected.description}
                data={groupSelected}
                segmentedId={groupSelected.id}
                userId={loggedUser?.id}
                userCircleId={circleUser?.id}
                handleClose={handleCloseGroupModal}
                segmentedGroup={groupSelected}
                isPatient={isPatient}
              /> : isPatient ? <ScreenPatient/> : <Screen/>
          }

        </Box>
      ) : (
        <LoadingSpinner className={style.loadingSpinner} />
      )}
    </>
  )
}
