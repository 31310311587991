// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CircleConfigurator_container__1qG-U {\n  background-color: #ffffff;\n  flex-direction: column;\n  display: flex;\n}\n\n.CircleConfigurator_headerTable__aj7G6 {\n  display: flex;\n  justify-content: space-between;\n}\n", "",{"version":3,"sources":["webpack://src/features/circle-configurator/CircleConfigurator.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC","sourcesContent":[".container {\n  background-color: #ffffff;\n  flex-direction: column;\n  display: flex;\n}\n\n.headerTable {\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CircleConfigurator_container__1qG-U",
	"headerTable": "CircleConfigurator_headerTable__aj7G6"
};
export default ___CSS_LOADER_EXPORT___;
