import React from 'react'
import { TemplatesSearch } from 'features/template'
import { RouteProps } from '../../routes/AppRouter'
import { useLocation } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

type TemplateProps = {
  id?: string
  title?: string
} & RouteProps

export function Templates(props: RouteProps) {
  const title = props.title ?? ''

  const location = useLocation()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const state: TemplateProps = location?.state as TemplateProps

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <TemplatesSearch
        tagList={location.search !== '' ? location.search.slice(1).split('&') : []}
      />
    </>
  )
}
