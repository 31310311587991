import { Box, Tooltip } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ROUTE_PATIENT_FORMS } from '../../routes/routes-constants'
import { FORM_SERVICE_KEY, USER_FORM_SERVICE_KEY } from '../../modules/forms'
import { UserFormService } from '../../modules/forms/services/UserFormService'
import { getFormContainer } from '../../container/form-module'
import { FormService } from '../../modules/forms/services/FormService'
import { getUserContainer } from '../../container/user-module'
import { Query, QueryParam } from '../../common/api/Query'
import {
  IUserService,
  LOGGED_USER_SERVICE_KEY,
  ROLES_SERVICE_KEY,
  USER_SERVICE_KEY,
} from '../../modules/users'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { UserForm, UserFormQuery } from '../../modules/forms/models/UserForm'
import genericStyle from '../../common/utils/generic.module.css'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { ReadingStatus } from '../../modules/forms/enums/ReadingStatus'
import { Form } from '../../modules/forms/models/Form'
import { AppTable, Field } from '../../components/table'
import { Actions, Pager, Search, SearchValue } from '../../components/table_type/types'
import styles from './Table.module.css'
import seeIcon from '../../assets/table_icons/ico-ver.svg'
import editIcon from '../../assets/table_icons/ico-edit.svg'
import { reduceString } from '../../common/utils/strings'
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner'
import loaderStyles from '../../components/loading-spinner/LoadingSpinner.module.css'
import seeIconResponsive from '../../assets/resource_icons/ico-eye.svg'
import editIconResponsive from '../../assets/resource_icons/ico-editar.svg'
import { Roles } from '../../modules/users/enums/Roles'
import { RolesService } from '../../modules/users/services/RolesServices'
import patient from '../../assets/role_icons/ico-rol-paciente.svg'
import family from '../../assets/role_icons/ico-rol-familiar.svg'
import professionalSMS from '../../assets/role_icons/ico-rol-profesional.svg'
import externProfessional from '../../assets/role_icons/ico-rol-externo.svg'
import career from '../../assets/role_icons/ico-rol-cuidador.svg'
import { Chip } from '@mui/material'
import { User } from '../../modules/users/models/User'
import { useLocation, useNavigate } from 'react-router-dom'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { IUserCircleActiveService, USER_CIRCLE_ACTIVE_SERVICE_KEY } from '../../modules/user-circle'
import { useGetActiveUser } from '../../hooks/get-active-user/useGetActiveUser'

const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)
const formContainer = getFormContainer()
const formService = formContainer.get<FormService>(FORM_SERVICE_KEY)
const userFormService = formContainer.get<UserFormService>(USER_FORM_SERVICE_KEY)
const roleService = userContainer.get<RolesService>(ROLES_SERVICE_KEY)

type RowItem = {
  id: string
  title: string
  assigned: Date
  description?: string
  status: ReadingStatus
  formUserID: string
  response: Date
  user: string
  role: string
  specialty?: string
  pathology?: string[]
}

export function Table(): JSX.Element {
  const { t } = useTranslation()
  const { innerWidth } = window
  const navigate = useNavigate()
  const { activeUser } = useGetActiveUser()

  const loggedUser = loggedUserService.get()
  const isDoctor = loggedUser?.roles.includes(Roles.Professional)
  const isExtern = loggedUser?.roles.includes(Roles.ProfessionalExtern)
  const userCircle = getUserCircleContainer()
    .get<IUserCircleActiveService>(USER_CIRCLE_ACTIVE_SERVICE_KEY)
    .getActiveFullUserCircle()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [userForms, setUserForms] = useState<ItemList<RowItem>>(emptyList<RowItem>())
  const [filteredUserForms, setFilteredUserForms] =
    useState<ItemList<RowItem>>(emptyList<RowItem>())
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [roles, setRoles] = useState<Map<string, string>>(new Map())
  const imgRef = useRef<HTMLImageElement>(null)
  const [searcher, setSearcher] = useState<SearchValue<UserFormQuery>[]>([
    {
      name: 'allFields',
      label: t('search') + '...',
      value: '',
    },
  ])
  const location = useLocation()

  const loadData = async () => {
    if (!loggedUser || !userCircle) {
      return
    }

    console.log(activeUser.id)
    try {
      const rolesResponse = await roleService
        .getAll(
          new Query({
            sort: [{ field: 'name' }],
            pager: { offset: 0, limit: -1 },
          })
        )
        .toPromise()

      const rolesMap = new Map<string, string>()
      rolesResponse.forEach((role) => rolesMap.set(role.id, role.name))
      setRoles(rolesMap)

      const auxList = emptyList<RowItem>()
      let query: QueryParam<UserFormQuery>[]

      if (isExtern) {
        query = [
          new QueryParam<UserFormQuery>('userID', userCircle.user.id),
          new QueryParam<UserFormQuery>('userCircleID', userCircle.id),
          new QueryParam<UserFormQuery>('external', 'true'),
        ]
      } else if (isDoctor) {
        query = [
          new QueryParam<UserFormQuery>('userCircleID', userCircle.id),
          new QueryParam<UserFormQuery>('readingStatus', ReadingStatus.Completed),
        ]
      } else {
        query = [
          new QueryParam<UserFormQuery>('userID', loggedUser.id),
          new QueryParam<UserFormQuery>('userCircleID', userCircle.id),
        ]
      }

      const ufl = await userFormService
        .getFilteredListWithCustomOrder(
          new Query({
            pager: { offset: page * rowsPerPage, limit: rowsPerPage },
            query,
            sort: [{ field: 'assigned', desc: true }],
          })
        )
        .toPromise()

      const userforms: UserForm[] = []
      const formPromises: Promise<Form | undefined>[] = []
      const userPromises: Promise<User | undefined>[] = []

      ufl.items.forEach((uf) => {
        userforms.push(uf)
        formPromises.push(formService.getByID(uf.formID || '').toPromise())
        userPromises.push(userService.getByID(uf.userID).toPromise())
      })

      const formResults = await Promise.all(formPromises)
      const userResults = await Promise.all(userPromises)

      formResults.forEach((form, index) => {
        const uf = userforms[index]
        const user = userResults[index]
        if (!form || !uf || !user) {
          return
        }

        const name = user.name || ''
        let role
        const rolesArray = [...roles.entries()]

        const findRoleExtern = rolesArray.find(([_, value]) => value === Roles.ProfessionalExtern)
        const findRoleCareer = rolesArray.find(([_, value]) => value === Roles.CarerOrTeacher)

        if (uf.circleID === user?.id) {
          const key = rolesArray.find(([_, value]) => value === Roles.Patient)
          role = key ? key[0] : ''
        } else if (
          uf.circleID !== user.id &&
          findRoleExtern &&
          !user?.roles.includes(findRoleExtern[0]) &&
          findRoleCareer &&
          !user?.roles.includes(findRoleCareer[0])
        ) {
          const key = rolesArray.find(([_, value]) => value === Roles.FamilyOrTutor)
          role = key ? key[0] : ''
        } else {
          role = user.roles[0] || ''
        }
        const response = uf.userFormValues[uf.userFormValues.length - 1]?.date || ''

        const item: RowItem = {
          id: form.id ?? '',
          title: form.title,
          description: form.description,
          assigned: form.created,
          status: uf.readingStatus,
          formUserID: uf.id ?? '',
          response,
          user: name,
          role,
          pathology: form.circles.split(','),
          specialty: form.specialities,
        }

        auxList.items.push(item)
      })

      setUserForms(auxList)
      setCount(ufl.count)
    } catch (error) {
      // Manejar el error de manera adecuada
      console.error('Error:', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if ((location as any).state?.update) {
      loadData().then()
    }
  }, [location])

  useEffect(() => {
    loadData().then()
  }, [isLoading])

  useEffect(() => {
    setIsLoading(!isLoading)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, rowsPerPage])

  useEffect(() => {
    const aux: ItemList<RowItem> = emptyList<RowItem>()

    if (searcher[0].value) {
      if (userForms) {
        userForms.items.forEach((item) => {
          const searcherString = searcher[0].value ?? '---@#'
          if (
            item.pathology?.join(';').toLowerCase().includes(searcherString.toLocaleLowerCase())
          ) {
            aux.items.push(item)
          }
        })
      }
      setFilteredUserForms(aux)
    }
    // en caso de borrar el filtro vacio el array filtrado.
    else {
      setFilteredUserForms(emptyList<RowItem>())
    }
  }, [searcher])

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setRowsPerPage(10)
      return
    }
    setRowsPerPage(Number.parseInt(event.target.value))
  }

  const viewPatientForm = (r: RowItem) =>
    navigate(`${ROUTE_PATIENT_FORMS}/${r.formUserID}`, { state: { edit: false } })

  const editPatientForm = (r: RowItem) => {
    navigate(`${ROUTE_PATIENT_FORMS}/${r.formUserID}`, {
      state: { edit: true, userForm: r },
    })
  }

  const handlePaginationChange = (e: unknown, value: number) => setPage(value)

  const isCompleted = (r: RowItem): boolean => {
    return r.status === ReadingStatus.Completed
  }

  const getUserRole = (r: RowItem): string => roles.get(r.role) ?? ''

  const search: Search<UserFormQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<UserFormQuery>[]) => {
      // setFiltering(!!svs[0].value)

      const result = svs.map((s) => {
        if (s.type !== 'date' || !s.value) {
          return s
        }

        const date = new Date(s.value)
        date.setDate(date.getDate() + 1)
        date.setHours(1, 0, 0, 0)
        return Object.assign({ ...s }, { value: date.toJSON() })
      })

      setSearcher(result)
    },
  }

  const fields: Field<RowItem>[] = [
    {
      name: 'title',
      label: t('title'),
      styleFunc: (f, i) =>
        i.status === ReadingStatus.Pending ? styles.pendingStatus : styles.completedStatus,
    },
    {
      name: 'description',
      label: t('description'),
      renderFunc: (f, i) => reduceString(i.description ? i.description : '', 60),
      styleFunc: (f, i) =>
        i.status === ReadingStatus.Pending ? styles.pendingStatus : styles.completedStatus,
    },
    {
      name: 'assigned',
      label: t('date'),
      renderFunc: (f, i) => {
        if (i.status === ReadingStatus.Pending) {
          return ''
        }
        return i.response ? new Date(i.response).toLocaleDateString('es-ES') : ''
      },
      styleFunc: (f, i) =>
        i.status === ReadingStatus.Pending ? styles.pendingStatus : styles.completedStatus,
    },
    {
      name: 'status',
      label: t('status'),
      renderFunc: (f, i) => t(ReadingStatus[i.status].toLowerCase()),
      styleFunc: (f, i) =>
        i.status === ReadingStatus.Pending ? styles.pendingStatus : styles.completedStatus,
    },
  ]

  const profesionalfields: Field<RowItem>[] = [
    {
      name: 'title',
      label: t('title'),
    },
    {
      name: 'user',
      label: t('user'),
    },
    {
      name: 'role',
      label: t('role'),
      renderFunc: (f, i) => {
        const role = getUserRole(i)
        let img = ''
        switch (role) {
          case Roles.Patient:
            img = patient
            break
          case Roles.FamilyOrTutor:
            img = family
            break
          case Roles.Professional:
            img = professionalSMS
            break
          case Roles.ProfessionalExtern:
            img = externProfessional
            break
          case Roles.CarerOrTeacher:
            img = career
            break
          case Roles.ContentManager:
            img = professionalSMS
            break
        }
        const tooltipContent = t(role) || role // Contenido para el tooltip

        return (
          <div>
            {innerWidth < 598 ? (
              <Box display={'flex'} alignItems={'center'}>
                <img
                  style={{ width: '15px', height: '15px', margin: '4px', marginLeft: '8px' }}
                  src={img}
                  alt={t(role)}
                />
                <h5 style={{ margin: '0' }}>{t(role)}</h5>
              </Box>
            ) : (
              <Tooltip title={tooltipContent}>
                <img src={img} alt={tooltipContent} ref={imgRef} />
              </Tooltip>
            )}
          </div>
        )
      },
    },
    {
      name: 'specialty',
      label: t('specialty'),
      renderFunc: (f, i) => {
        if (i.specialty) {
          return (
            <Chip
              style={{
                backgroundColor: '#dddedf',
                borderRadius: '9px',
                marginLeft: '8px',
              }}
              label={<span style={{ fontFamily: 'Poppins', color: '#000' }}>{i.specialty}</span>}
            />
          )
        }
        return ''
      },
    },
    {
      name: 'pathology',
      label: t('circle'),
      renderFunc: (f, i) => {
        if (i.pathology && i.pathology?.length > 0) {
          return i.pathology.map((p) => (
            <Chip
              key={i.id + p}
              style={{
                backgroundColor: '#d1dee6',
                marginLeft: '8px',
                marginBottom: window.innerWidth <= 1782 ? '8px' : '0px',
              }}
              label={<span style={{ fontFamily: 'Poppins', color: '#000' }}>{p}</span>}
            ></Chip>
          ))
        }
        return ''
      },
    },
    {
      name: 'assigned',
      label: t('date'),
      renderFunc: (f, i) => {
        if (i.status === ReadingStatus.Pending) {
          return ''
        }
        return i.response ? new Date(i.response).toLocaleDateString('es-ES') : ''
      },
    },
    {
      name: 'status',
      label: t('status'),
      renderFunc: (f, i) => t(ReadingStatus[i.status].toLowerCase()),
      styleFunc: (f, i) =>
        i.status === ReadingStatus.Pending ? styles.pendingStatus : styles.completedStatus,
    },
  ]

  const actions: Actions<RowItem> = {
    actionsColumn: t('action'),
    items: [
      {
        handler: viewPatientForm,
        icon: innerWidth > 598 ? seeIcon : seeIconResponsive,
        label: 'see',
        hidden: (r) => !isCompleted(r),
      },
      {
        handler: editPatientForm,
        icon: innerWidth > 598 ? editIcon : editIconResponsive,
        label: 'edit',
        hidden: (r) => isCompleted(r),
      },
    ],
  }

  // TODO add searcher by user
  return (
    <Box className={genericStyle.pageContainer}>
      {!isLoading ? (
        isDoctor ?? isExtern ? (
          // en caso de estar filtrando mostramos los items del array filtrado.
          filteredUserForms.items.length > 0 ? (
            <>
              <AppTable
                fields={profesionalfields}
                items={filteredUserForms.items}
                rowKeyField="id"
                actions={actions}
                pager={pager}
                search={search}
                isPreventDefault={true}
              />
            </>
          ) : (
            <AppTable
              fields={profesionalfields}
              items={userForms.items}
              rowKeyField="id"
              actions={actions}
              pager={pager}
              search={search}
              isPreventDefault={true}
            />
          )
        ) : (
          <AppTable
            fields={fields}
            items={userForms.items}
            rowKeyField="id"
            actions={actions}
            pager={pager}
          />
        )
      ) : (
        <LoadingSpinner className={loaderStyles.loadingSpinner} />
      )}
    </Box>
  )
}
