import React from 'react'
import { AdminTemplTut } from '../../features/tutorial-template-admin'
import { RouteProps } from '../../routes/AppRouter'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function AdminTutorialTemplates(props: RouteProps) {
  const title = props.title ?? ''

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <AdminTemplTut />
    </>
  )
}
