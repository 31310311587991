import { UserToken } from './UserToken'

export interface UserTokenDTO {
  code: string
  userID: string
  loginType: number
  codeVerifier: string
  state: string
}

export function fromModel(ut: UserToken): UserTokenDTO {
  return {
    code: ut.code,
    userID: ut.userID,
    loginType: ut.loginType,
    codeVerifier: ut.codeVerifier,
    state: ut.state
  }
}

export function toModel(ut: UserTokenDTO): UserToken {
  return new UserToken(ut)
}
