import React from 'react'
import { ArticlesSearchMyPosts } from '../../features/article'
import { RouteProps } from '../../routes/AppRouter'
import { useLocation } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function ArticlesMyPosts(props: RouteProps) {
  const title = props.title ?? ''

  const location = useLocation()

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <ArticlesSearchMyPosts/>
    </>
  )
}
