import { Box, Divider, Icon, IconButton, Tooltip } from '@mui/material'
import style from '../../patient-calendar-config/Modal.module.css'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Header } from '../../../components/header/Header'
import close from '../../../assets/table_icons/ico-eliminar.svg'

import {
  dateToDateTimeWithoutSecondsString,
  dateToTimeWithoutSecondsString,
} from '../../../common/utils/date'

import { Training } from '../../../modules/training/models/Training'
import { SportCenter } from '../../../modules/sport-center/models/SportCenter'
import {
  emptySportCenterDTO,
  toModel as SCtoModel,
} from '../../../modules/sport-center/models/SportCenterDTO'
import { getSportCenterContainer } from '../../../container/sport-center-module'
import { SportCenterService } from '../../../modules/sport-center/services/SportCenterService'
import { SPORT_CENTER_SERVICE_KEY } from '../../../modules/sport-center'
import { getTrainingGroupContainer } from '../../../container/training-group-module'
import { TrainingGroupService } from '../../../modules/training-group/services/TrainingGroupService'
import { TRAINING_GROUP_SERVICE_KEY } from '../../../modules/training-group'
import styles from '../../sportCenter-config/AddSeeEditSportCenterModal.module.css'
import editIcon from '../../../assets/table_icons/ico-edit-hover.svg'
import { getUserCircleContainer } from '../../../container/user-circle-module'
import {
  IUserCircleActiveService,
  USER_CIRCLE_ACTIVE_SERVICE_KEY,
} from '../../../modules/user-circle'
import { getUserContainer } from '../../../container/user-module'
import { LoggedUserService } from '../../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../../modules/users'
import { EditTrainingModal } from './EditTrainingModal'
import { getTrainingContainer } from '../../../container/training-module'
import { TrainingService } from '../../../modules/training/services/TrainingService'
import { TRAINING_SERVICE_KEY } from '../../../modules/training'
import { fromModel as trainingFromModel } from '../../../modules/training/models/TrainingDTO'
import { useCalendarEvents } from '../../../hooks/calendarEvents/useCalendarEvents'

const sportCenterService =
  getSportCenterContainer().get<SportCenterService>(SPORT_CENTER_SERVICE_KEY)
const groupService = getTrainingGroupContainer().get<TrainingGroupService>(
  TRAINING_GROUP_SERVICE_KEY
)
const userCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)
const loggedUserService = getUserContainer().get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const trainingService = getTrainingContainer().get<TrainingService>(TRAINING_SERVICE_KEY)

type TrainingModalProps = {
  handleClose: () => void
  training: Training
  canSetIntensity?: boolean
}
const UserCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)

export const TrainingModal = (props: TrainingModalProps) => {
  const { t } = useTranslation()
  const { innerWidth } = window
  const hours = `${dateToTimeWithoutSecondsString(new Date(props.training.startTime))} -
    ${dateToTimeWithoutSecondsString(new Date(props.training.finishTime))}`
  const [sportCenter, setSportCenter] = useState<SportCenter>(SCtoModel(emptySportCenterDTO()))
  const [titleEdit] = useState<string>(t('edit_icon'))
  const loggedUser = loggedUserService.get()
  const externProfs = userCircleActiveService.getActiveFullUserCircle()?.profExtID
  const activeUser = UserCircleActiveService.getActiveFullUserCircle()
  const [openModal, setOpenModal] = useState<boolean>(props.canSetIntensity ?? false)
  const [isPatient, setIsPatient] = useState<boolean>(false)
  const { refreshCalendarEvents, setRefreshCalendarEvents } = useCalendarEvents()

  useEffect(() => {
    if (!props.training) return
    groupService.getByID(props.training.groupID).subscribe((respond) => {
      if (!respond) return
      sportCenterService.getByID(respond.centerID).subscribe((res) => {
        if (!res) return
        setSportCenter(res)
      })
    })
  }, [props.training])

  const handlerOpenEditModal = () => {
    setOpenModal(true)
  }

  useEffect(() => {
    if (!userCircleActiveService.getActiveFullUserCircle()?.user.id && !loggedUser?.id) return

    if (userCircleActiveService.getActiveFullUserCircle()?.user.id === loggedUser?.id) {
      setIsPatient(true)
    }
  }, [loggedUser?.id, userCircleActiveService.getActiveFullUserCircle()?.user.id])

  const isTrainer = () => {
    if (!externProfs) return false
    if (Array.isArray(externProfs)) {
      return externProfs.some((trainer) => trainer === loggedUser?.id)
    }
    return externProfs === loggedUser?.id
  }

  const handlerClose = () => {
    setOpenModal(false)
  }

  const handlerAccept = (value: number) => {
    if (isPatient) {
      props.training.intensity = value
      props.training.attendance = 1
    } else {
      props.training.physicalCondition = value
    }
    //
    props.training.groupID = props.training.groupID ?? ''
    props.training.userCircleID = activeUser?.id ?? ''
    trainingService.update(trainingFromModel(props.training)).subscribe((res) => {})
    // actualizo el contxto de los eventos
    setRefreshCalendarEvents(!refreshCalendarEvents)
    handlerClose()
  }
  console.log('entrenamiento', props.training)
  return (
    <>
      <Box className={style.modalView}>
        <div className={style.header}>
          <Header label={t('eventInformation')} icon={close} onClick={props.handleClose} />
        </div>

        <Box mt={1} mb={1} display={'flex'} justifyContent={'flex-start'}>
          <p className={style.labelTitle}>{t('activePlan') + ': ' + t('trainingSession')}</p>
        </Box>
        {innerWidth > 490 && <Divider style={{ backgroundColor: '#68b3e0', margin: 8 }} />}

        <>
          <Box mt={1} mb={1} display={'flex'} justifyContent={'space-between'}>
            <p className={style.titleLabel}>{t('eventCategory')}</p>
            <p className={style.label}>{t('exercise')}</p>
          </Box>
          <Box mt={1} mb={1} display={'flex'} justifyContent={'space-between'}>
            <p className={style.titleLabel}>{t('Fecha de inicio')}</p>
            <p className={style.label}>
              {dateToDateTimeWithoutSecondsString(new Date(props.training.startTime))}
            </p>
          </Box>
          <Box mt={1} mb={1} display={'flex'} justifyContent={'space-between'}>
            <p className={style.titleLabel}>{t('Fecha de fin')}</p>
            <p className={style.label}>
              {dateToDateTimeWithoutSecondsString(new Date(props.training.finishTime))}
            </p>
          </Box>

          <Box mt={1} mb={1} display={'flex'} justifyContent={'space-between'}>
            <p className={style.titleLabel}>{t('hour')}</p>
            <p className={style.label}>{hours}</p>
          </Box>

          <Box mt={1} mb={1} display={'flex'} justifyContent={'space-between'}>
            <p className={style.titleLabel}>{t('place')}</p>
            <p className={style.label}>{sportCenter.location}</p>
          </Box>

          <Box mt={1} mb={1} display={'flex'} justifyContent={'space-between'}>
            <p className={style.titleLabel}>{t('levelPhisicalCondition')}</p>
            <Box className={style.containerRow}>
              <p className={style.label}>{props.training.physicalCondition}</p>
              <Tooltip title={titleEdit} style={!isTrainer() ? { display: 'none' } : {}}>
                <IconButton onClick={handlerOpenEditModal}>
                  <Icon className={styles.iconEdit}>
                    <img alt="edit" src={editIcon} />
                  </Icon>
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

          <Box mt={1} mb={1} display={'flex'} justifyContent={'space-between'}>
            <p className={style.titleLabel}>{t('trainingIntensity')}</p>
            <Box className={style.containerRow}>
              <p className={style.label}>{props.training.intensity}</p>
              <Tooltip title={titleEdit} style={!isPatient ? { display: 'none' } : {}}>
                <IconButton onClick={handlerOpenEditModal}>
                  <Icon className={styles.iconEdit}>
                    <img alt="edit" src={editIcon} />
                  </Icon>
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

          <Box mt={1} mb={1} display={'flex'} justifyContent={'space-between'}>
            <p className={style.titleLabel}>{t('asistanceConfirmation')}</p>
            <p className={style.label}>{props.training.attendance === 1 ? 'Si' : 'No'}</p>
          </Box>
        </>
      </Box>
      <EditTrainingModal
        open={openModal}
        handlerClose={handlerClose}
        handlerAccept={handlerAccept}
        training={props.training}
        patient={isPatient}
      />
    </>
  )
}
