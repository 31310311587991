import { Box, Modal } from '@mui/material'
import { CustomModal } from '../../../../components/modal/CustomModal'
import { AppTable, Field } from '../../../../components/table'
import React, { useEffect, useRef, useState } from 'react'
import { Pathology, PathologyQuery } from '../../../../modules/patient-data/models/Pathology'
import { Actions, Pager, Search, SearchValue } from '../../../../components/table_type/types'
import editIcon from '../../../../assets/table_icons/ico-edit.svg'
import deleteIcon from '../../../../assets/table_icons/ico-eliminar.svg'
import { Query, QueryParam, QueryParamN } from '../../../../common/api/Query'
import { useTranslation } from 'react-i18next'
import { ProfessionalType } from '../../../../modules/users/models/ProfessionalType'
import {
  emptyPathologyDTO,
  PathologyDTO,
} from '../../../../modules/patient-data/models/PathologyDTO'
import { AppButton, ButtonTheme } from '../../../../components/app-button/AppButton'
import { v4 as uuidv4 } from 'uuid'
import { getUserContainer } from '../../../../container/user-module'
import { PROFESSIONALTYPE_SERVICE_KEY } from '../../../../modules/users'
import { ProfessionalTypesService } from '../../../../modules/users/services/ProfessionalTypeService'
import { useNavigate } from 'react-router-dom'
import { ROUTE_CONFIGURATION } from '../../../../routes/routes-constants'

const userContainer = getUserContainer()
const professionalTypeService = userContainer.get<ProfessionalTypesService>(
  PROFESSIONALTYPE_SERVICE_KEY
)

export function PathologyTable() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [count, setCount] = useState<number>(0)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pathologies, setPathologies] = useState<Pathology[]>()

  const [pathologiesPerPage, setPathologiesPerPage] = useState<number>(10)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false)
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [currentPathology, setCurrentPathology] = useState<PathologyDTO>(emptyPathologyDTO())
  const currentP = useRef(emptyPathologyDTO())
  const nameEdit = useRef('')
  const descriptionEdit = useRef('')
  const professionalEdit = useRef('')
  const [allProffesionalTypes, setAllProffesinalTypes] = useState<ProfessionalType[]>([])
  const [searcher, setSearcher] = useState<SearchValue<PathologyQuery>[]>([
    {
      name: 'name',
      label: t('search') + '...',
    },
  ])

  useEffect(() => {
    const searchTerms = [...searcherQuery(searcher)]
    const name = searchTerms.find((s) => s.name === 'name')
    if (name) {
      /* pathologyService
        .getFilteredList(
          new Query({
            pager: { limit: pathologiesPerPage, offset: page * pathologiesPerPage },
            query: [new QueryParam<PathologyQuery>('name', name.value), ...searcherQuery(searcher)],
            sort: [{ field: 'name', desc: false }],
          })
        )
        .subscribe({
          next: (res) => {
            setCount(res.count)
            setPathologies(res.items)
          },
        })
    } else {
      pathologyService
        .getFilteredList(
          new Query({
            pager: { limit: pathologiesPerPage, offset: page * pathologiesPerPage },
            sort: [{ field: 'name', desc: false }],
          })
        )
        .subscribe({
          next: (res) => {
            setCount(res.count)
            setPathologies(res.items)
          },
        })
    } */
    }
  }, [isLoading])

  useEffect(() => {
    professionalTypeService.getAll(new Query({})).subscribe((res) => {
      setAllProffesinalTypes(res)
    })
  }, [])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setPathologiesPerPage(10)
      return
    }
    setPathologiesPerPage(Number.parseInt(event.target.value))
  }

  useEffect(() => {
    setIsLoading(!isLoading)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: pathologiesPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, pathologiesPerPage])

  const objectEditPathology = (name: string, description: string, professional: string) => {
    currentP.current = Object.assign(
      { ...currentPathology },
      {
        id: currentP.current.id,
        name,
        description,
        typeID: professional,
        symptoms: currentP.current.symptoms,
      }
    )
  }

  const searchPathology: Search<PathologyQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<PathologyQuery>[]) => {
      setSearcher(svs)
      setIsLoading(!isLoading)
    },
  }
  const createPathology = () => {
    if (currentPathology) {
      currentP.current.id = uuidv4()
      /* pathologyService
        .add(toModelPathology(currentP.current))
        .subscribe(() => setIsLoading(!isLoading)) */
    }
    setOpenCreateModal(false)
  }

  const handleCreateVariable2 = (name: string, description: string, professional: string) => {
    currentP.current = Object.assign(
      { ...currentPathology },
      { name, symptoms: null, typeID: professional, description }
    )
    createPathology()
    // setCurrentProfessionalType(Object.assign({ ...currentProfessionalType }, {'name': name, 'description': description}))
  }

  const editPathology = (s: Pathology) => {
    currentP.current = s
    nameEdit.current = currentP.current.name
    descriptionEdit.current = currentP.current.description
    professionalEdit.current = currentP.current.typeID
    setOpenEditModal(true)
  }
  const editSavePathology = () => {
    if (currentPathology) {
      /* pathologyService.update(toModelPathology(currentP.current)).subscribe((res) => {
        setIsLoading(!isLoading)
      }) */
    }

    setOpenEditModal(false)
  }

  const removePathology = (s: Pathology) => {
    setCurrentPathology(s)
    setOpenDeleteModal(true)
  }

  const handleDeletePathology = () => {
    if (currentPathology?.id) {
      // pathologyService.delete(currentPathology.id).subscribe(() => setIsLoading(!isLoading))
    }
    setOpenDeleteModal(false)
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handleCloseCreateModal = () => {
    setOpenCreateModal(false)
  }

  const handleCloseEditModal = () => {
    setOpenEditModal(false)
  }
  const goBack = () => navigate(ROUTE_CONFIGURATION)

  const fields2: Field<Pathology>[] = [
    {
      name: 'name',
      label: t('name'),
    },
    {
      name: 'description',
      label: t('description'),
    },
    {
      name: 'id',
      label: t('fatherSection'),
      renderFunc: (f, i) =>
        allProffesionalTypes.filter((p) => p.id === i.typeID).length > 0
          ? allProffesionalTypes.filter((p) => p.id === i.typeID)[0].name
          : '',
    },
  ]

  const actions2: Actions<Pathology> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: editPathology,
        icon: editIcon,
        label: 'edit',
      },
      {
        handler: removePathology,
        icon: deleteIcon,
        label: 'delete',
      },
    ],
  }

  return (
    <Box>
      <Box mb={3} display="flex" justifyContent="space-between">
        <AppButton
          theme={ButtonTheme.NewSecondary}
          type={'button'}
          label={t('back')}
          handler={goBack}
        />
        <AppButton
          theme={ButtonTheme.NewPrimary}
          type={'button'}
          label={t('addCircle')}
          handler={() => setOpenCreateModal(true)}
        />
      </Box>

      <AppTable
        items={pathologies ?? []}
        rowKeyField="id"
        fields={fields2}
        actions={actions2}
        pager={pager}
        search={searchPathology}
      />
      <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <CustomModal
          handleClose={handleCloseDeleteModal}
          handleSave={handleDeletePathology}
          title={t('deleteCircle')}
          warningText={t('irreversibleCircleAction')}
        />
      </Modal>

      <Modal open={openCreateModal} onClose={handleCloseCreateModal}>
        <CustomModal
          handleClose={handleCloseCreateModal}
          handleSave={() => {}}
          handleCreateVariable2={handleCreateVariable2}
          title={t('addCircle')}
          allProfessional={allProffesionalTypes}
          create={true}
        />
      </Modal>
      <Modal open={openEditModal} onClose={handleCloseEditModal}>
        <CustomModal
          handleClose={handleCloseEditModal}
          handleSave={editSavePathology}
          handleCreateVariable2={objectEditPathology}
          title={t('editCircle')}
          allProfessional={allProffesionalTypes}
          create={true}
          name={nameEdit.current}
          description={descriptionEdit.current}
          professional={professionalEdit.current}
        />
      </Modal>
    </Box>
  )
}

const searcherQuery = (
  svs: SearchValue<PathologyQuery>[]
): QueryParam<PathologyQuery>[] | QueryParamN<PathologyQuery>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))
