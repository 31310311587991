// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChatsCard_chatContainer__15ADR {\n  display: flex;\n  align-items: center;\n  border: 2px solid #AFD7EF;\n  padding: 10px;\n  border-radius: 10px;\n  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922) !important;\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://src/features/dashboard/patient/chats-card/ChatsCard.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,kEAAA;EACA,eAAA;AACF","sourcesContent":[".chatContainer{\n  display: flex;\n  align-items: center;\n  border: 2px solid #AFD7EF;\n  padding:10px;\n  border-radius:10px;\n  box-shadow: 0px 4px 4px 0px #00000040 !important;\n  cursor: pointer ;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatContainer": "ChatsCard_chatContainer__15ADR"
};
export default ___CSS_LOADER_EXPORT___;
