/* tslint:disable */
//@ts-nocheck
import styles from '../PatientData.module.css'
import { Box, Table, TableBody, TableCell, TableRow, Tabs, Tab, Typography } from '@mui/material'

import stylePatient from '../../user-profile/Editor.module.css'
import React from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { PatientDataDTO } from '../../../modules/patient-data/models/PatientDataDTO'
import Divider from '@mui/material/Divider'
import { styled } from '@mui/material/styles'

type ExpandedDataProps = {
  data: PatientDataDTO
}

export const ExpandedMedicalData = (props: ExpandedDataProps) => {
  const { t } = useTranslation()

  const included = (key: string) => {
    switch (key) {
      case 'nif':
        return true
      case 'cipa':
        return true
      case 'centerName':
        return true
      case 'phone':
        return true
      default:
        return false
    }
  }

  const formatDate = (value: any, key: string) => {
    if (value && key) {
      if (key.includes('Date')) return dayjs(value).format('DD/MM/YYYY HH:MM')
      if (key.includes('weight')) return value + ' kg'
      if (key.includes('height' || 'cranialPerimeter')) return value + ' cm'
      return value
    }
    return null
  }

  interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const [value, setValue] = React.useState(0)
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  const StyledTab = styled(Tab)({
    '& .MuiTab-root.Mui-selected': {
      color: 'white',
      backgroundColor: '#68b3e0',
      borderRadius: '5px 5px 0px 0px',
    },
    '& .MuiTab-root': {
      backgroundColor: '#f4f3f3',
      border: '1px solid gray',
      borderRadius: '5px 5px 0px 0px',
      marginTop: '40px',
      paddingBottom: '1em',
      minWidth: '5%',
      width: '20%',
    },
  })

  return (
    <>
      <Box>
        <Box>
          <Divider component="div" />
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              style: { display: 'none' },
            }}
          >
            <StyledTab
              className={stylePatient.labelTab}
              label={t('dataFrom0to1month')}
              {...a11yProps(0)}
            />
            <StyledTab
              className={stylePatient.labelTab}
              label={t('dataFrom2to6month')}
              {...a11yProps(1)}
            />
            <StyledTab
              className={stylePatient.labelTab}
              label={t('dataFrom7to23month')}
              {...a11yProps(2)}
            />
            <StyledTab
              className={stylePatient.labelTab}
              label={t('dataFrom2to5years')}
              {...a11yProps(3)}
            />
            <StyledTab
              className={stylePatient.labelTab}
              label={t('dataFrom6to13years')}
              {...a11yProps(4)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {props.data?.zero2One ? (
            <Box mb={3} className={styles.listContainer} style={{ cursor: 'auto' }}>
              <Table>
                <TableBody>
                  <>
                    {props.data?.zero2One
                      ? Object.entries(props.data.zero2One)
                          .filter(([key, _]) => !included(key))
                          .filter(([_, value]) => value)
                          .map(([key, value]) =>
                            key === 'controlledPregnancy' ? (
                              <>
                                <p className={stylePatient.sectionLabel}>{'Anamnesis'}</p>
                                <TableRow className={stylePatient.row}>
                                  <TableCell>
                                    <p className={stylePatient.label}>{t(key)}</p>
                                  </TableCell>
                                  <TableCell className={stylePatient.textFieldBox}>
                                    {formatDate(value, key)}
                                  </TableCell>
                                </TableRow>
                              </>
                            ) : key === 'meconium' ? (
                              <>
                                <p className={stylePatient.sectionLabel}>{'Período neonatal'}</p>
                                <TableRow className={stylePatient.row}>
                                  <TableCell>
                                    <p className={stylePatient.label}>{t(key)}</p>
                                  </TableCell>
                                  <TableCell className={stylePatient.textFieldBox}>
                                    {formatDate(value, key)}
                                  </TableCell>
                                </TableRow>
                              </>
                            ) : key === 'height' ? (
                              <>
                                <p className={stylePatient.sectionLabel}>
                                  {'Desarrollo psicomotor'}
                                </p>
                                <TableRow className={stylePatient.row}>
                                  <TableCell>
                                    <p className={stylePatient.label}>{t(key)}</p>
                                  </TableCell>
                                  <TableCell className={stylePatient.textFieldBox}>
                                    {formatDate(value, key)}
                                  </TableCell>
                                </TableRow>
                              </>
                            ) : (
                              <TableRow className={stylePatient.row}>
                                <TableCell>
                                  <p className={stylePatient.label}>{t(key)}</p>
                                </TableCell>
                                <TableCell className={stylePatient.textFieldBox}>
                                  {formatDate(value, key)}
                                </TableCell>
                              </TableRow>
                            )
                          )
                      : null}
                  </>
                </TableBody>
              </Table>
            </Box>
          ) : null}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {props.data?.two2SixMonths ? (
            <Box mb={3} className={styles.listContainer} style={{ cursor: 'auto' }}>
              <Table>
                <TableBody>
                  <>
                    {props.data?.two2SixMonths
                      ? Object.entries(props.data.two2SixMonths)
                          .filter(([_, value]) => value)
                          .map(([key, value]) =>
                            key === 'height' ? (
                              <>
                                <p className={stylePatient.sectionLabel}>
                                  {'Desarrollo psicomotor'}
                                </p>
                                <TableRow className={stylePatient.row}>
                                  <TableCell>
                                    <p className={stylePatient.label}>{t(key)}</p>
                                  </TableCell>
                                  <TableCell className={stylePatient.textFieldBox}>
                                    {formatDate(value, key)}
                                  </TableCell>
                                </TableRow>
                              </>
                            ) : (
                              <TableRow key={key} className={stylePatient.row}>
                                <TableCell>
                                  <p className={stylePatient.label}>{t(key)}</p>
                                </TableCell>
                                <TableCell className={stylePatient.textFieldBox}>
                                  {formatDate(value, key)}
                                </TableCell>
                              </TableRow>
                            )
                          )
                      : null}
                  </>
                </TableBody>
              </Table>
            </Box>
          ) : null}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {props.data?.seven2TwentyThreeMonths ? (
            <Box mb={3} className={styles.listContainer} style={{ cursor: 'auto' }}>
              <Table>
                <TableBody>
                  <>
                    {props.data?.seven2TwentyThreeMonths
                      ? Object.entries(props.data.seven2TwentyThreeMonths)
                          .filter(([_, value]) => value)
                          .map(([key, value]) =>
                            key === 'height' ? (
                              <>
                                <p className={stylePatient.sectionLabel}>
                                  {'Desarrollo psicomotor'}
                                </p>
                                <TableRow className={stylePatient.row}>
                                  <TableCell>
                                    <p className={stylePatient.label}>{t(key)}</p>
                                  </TableCell>
                                  <TableCell className={stylePatient.textFieldBox}>
                                    {formatDate(value, key)}
                                  </TableCell>
                                </TableRow>
                              </>
                            ) : (
                              <TableRow key={key} className={stylePatient.row}>
                                <TableCell>
                                  <p className={stylePatient.label}>{t(key)}</p>
                                </TableCell>
                                <TableCell className={stylePatient.textFieldBox}>
                                  {formatDate(value, key)}
                                </TableCell>
                              </TableRow>
                            )
                          )
                      : null}
                  </>
                </TableBody>
              </Table>
            </Box>
          ) : null}
        </TabPanel>
        <TabPanel value={value} index={3}>
          {props.data?.two2FiveYears ? (
            <Box mb={3} className={styles.listContainer} style={{ cursor: 'auto' }}>
              <Table>
                <TableBody>
                  <>
                    {props.data?.two2FiveYears
                      ? Object.entries(props.data.two2FiveYears)
                          .filter(([_, value]) => value)
                          .map(([key, value]) =>
                            key === 'height' ? (
                              <>
                                <p className={stylePatient.sectionLabel}>
                                  {'Desarrollo psicomotor'}
                                </p>
                                <TableRow className={stylePatient.row}>
                                  <TableCell>
                                    <p className={stylePatient.label}>{t(key)}</p>
                                  </TableCell>
                                  <TableCell className={stylePatient.textFieldBox}>
                                    {formatDate(value, key)}
                                  </TableCell>
                                </TableRow>
                              </>
                            ) : (
                              <TableRow key={key} className={stylePatient.row}>
                                <TableCell>
                                  <p className={stylePatient.label}>{t(key)}</p>
                                </TableCell>
                                <TableCell className={stylePatient.textFieldBox}>
                                  {formatDate(value, key)}
                                </TableCell>
                              </TableRow>
                            )
                          )
                      : null}
                  </>
                </TableBody>
              </Table>
            </Box>
          ) : null}
        </TabPanel>
        <TabPanel value={value} index={4}>
          {props.data?.six2ThirteenYears ? (
            <Box mb={3} className={styles.listContainer} style={{ cursor: 'auto' }}>
              <Table>
                <TableBody>
                  <>
                    {props.data?.six2ThirteenYears
                      ? Object.entries(props.data.six2ThirteenYears)
                          .filter(([_, value]) => value)
                          .map(([key, value]) =>
                            key === 'height' ? (
                              <>
                                <p className={stylePatient.sectionLabel}>
                                  {'Desarrollo psicomotor'}
                                </p>
                                <TableRow className={stylePatient.row}>
                                  <TableCell>
                                    <p className={stylePatient.label}>{t(key)}</p>
                                  </TableCell>
                                  <TableCell className={stylePatient.textFieldBox}>
                                    {formatDate(value, key)}
                                  </TableCell>
                                </TableRow>
                              </>
                            ) : (
                              <TableRow key={key} className={stylePatient.row}>
                                <TableCell>
                                  <p className={stylePatient.label}>{t(key)}</p>
                                </TableCell>
                                <TableCell className={stylePatient.textFieldBox}>
                                  {formatDate(value, key)}
                                </TableCell>
                              </TableRow>
                            )
                          )
                      : null}
                  </>
                </TableBody>
              </Table>
            </Box>
          ) : null}
        </TabPanel>
      </Box>
    </>
  )
}

export default ExpandedMedicalData
