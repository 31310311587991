// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotificationCard_containerList__2ILqE {\n  min-height: 400px;\n  cursor: pointer;\n}\n\n.NotificationCard_divider__2L65e {\n  margin-top: 10px !important;\n  margin-bottom: 10px !important;\n  background-color: #AFD7EF !important;\n  width: 100% !important;\n}\n\n.NotificationCard_listItemText__3_RAm {\n  font-family: \"Poppins\", sans-serif !important;\n  text-transform: capitalize;\n}\n\n.NotificationCard_image__3toAn {\n  width: 100px;\n  margin-right: 10px;\n}\n\n.NotificationCard_title__OE7Ak {\n  color: #7fb6de;\n}\n\n.NotificationCard_titleEditor__T6Fnk {\n  padding: 0 10px;\n  font-weight: bold !important;\n}\n.NotificationCard_titleEditor__T6Fnk span:last-child {\n  display: -webkit-box;\n  -webkit-line-clamp: 1;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}", "",{"version":3,"sources":["webpack://src/features/dashboard/patient/notification-card/NotificationCard.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,eAAA;AACF;;AAMA;EACI,2BAAA;EACA,8BAAA;EACF,oCAAA;EACA,sBAAA;AAHF;;AAMA;EACE,6CAAA;EACA,0BAAA;AAHF;;AAMA;EACE,YAAA;EACA,kBAAA;AAHF;;AAMA;EACE,cAAA;AAHF;;AAMA;EACE,eAAA;EACA,4BAAA;AAHF;AAKE;EACE,oBAAA;EACA,qBAAA;EACA,4BAAA;EACA,gBAAA;EACA,uBAAA;AAHJ","sourcesContent":[".containerList{\n  min-height: 400px;\n  cursor: pointer;\n}\n\n.icon{\n\n}\n\n.divider{\n    margin-top: 10px !important;\n    margin-bottom: 10px !important;\n  background-color: #AFD7EF !important;\n  width: 100% !important\n}\n\n.listItemText{\n  font-family: 'Poppins', sans-serif !important;\n  text-transform: capitalize;\n}\n\n.image{\n  width: 100px;\n  margin-right: 10px;\n}\n\n.title{\n  color:#7fb6de\n}\n\n.titleEditor {\n  padding: 0 10px;\n  font-weight: bold !important;\n\n  span:last-child{\n    display: -webkit-box;\n    -webkit-line-clamp: 1;\n    -webkit-box-orient: vertical;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerList": "NotificationCard_containerList__2ILqE",
	"divider": "NotificationCard_divider__2L65e",
	"listItemText": "NotificationCard_listItemText__3_RAm",
	"image": "NotificationCard_image__3toAn",
	"title": "NotificationCard_title__OE7Ak",
	"titleEditor": "NotificationCard_titleEditor__T6Fnk"
};
export default ___CSS_LOADER_EXPORT___;
