import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material'
import { Actions, Field, Sort } from '../table_type/types'
import { v4 as uuidv4 } from 'uuid'

type HeadProps<T> = {
  readonly fields: Field<T>[]
  readonly sort?: Sort<T>
  readonly actions?: Actions<T>
}

export function Head<T extends Record<string, any>>(props: HeadProps<T>) {
  const handleSort = (name: keyof T) => () => props.sort?.handleSort && props.sort.handleSort(name)

  const useStyles = () => ({
    head: {
      marginLeft: '10px !important',
      backgroundColor: '#FFFFFF',
      color: '#515151',
      border: 'none !important',
      fontFamily: 'Montserrat-SemiBold, sans-serif !important',
      fontSize: '16px !important',
      fontStyle: 'normal !important',
      fontWeight: '700 !important',
      lineHeight: '24px !important',
    },
    actions: {
      fontWeight: '700 !important',
      backgroundColor: '#FFFFFF',
      color: '#515151',
      border: 'none !important',
      fontSize: '16px !important',
      fontFamily: 'Montserrat, sans-serif !important',
    },
  })

  const classes = useStyles()

  const headCells = Object.values(props.fields).map((f) => (
    <TableCell sx={classes.head} key={(f.name as string) + uuidv4()}>
      {f.sortable ? (
        <TableSortLabel
          sx={{ border: 'none', fontFamily: 'Poppins' }}
          active={f.name === props.sort?.name}
          direction={props.sort?.direction ?? 'asc'}
          onClick={handleSort(f.name)}
        >
          {f.label}
        </TableSortLabel>
      ) : (
        f.label
      )}
    </TableCell>
  ))

  return (
    <TableHead sx={{ border: 'none', fontFamily: 'Poppins' }}>
      <TableRow sx={{ border: 'none', fontFamily: 'Poppins' }}>
        {headCells}
        {props.actions?.actionsColumn && (
          <TableCell
            sx={{
              ...classes.actions,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {props.actions.actionsColumn}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  )
}
