export type { IEpilepticalCrisisService } from './services/EpilepticalCrisisService'
export type { IEpilepticalCrisisDetailsService } from './services/EpilepticalCrisisDetailsService'
export type { IUserEpilepticalConfigService } from './services/UserEpilepticalConfigService'

export {
  EPILEPTICAL_CRISIS_MODULE,
  EPILEPTICAL_CRISIS_API_KEY,
  EPILEPTICAL_CRISIS_SERVICE_KEY,
  EPILEPTICAL_CRISIS_DETAILS_API_KEY,
  EPILEPTICAL_CRISIS_DETAILS_SERVICE_KEY,
  USER_EPILEPTICAL_CONFIG_API_KEY,
  USER_EPILEPTICAL_CONFIG_SERVICE_KEY,
} from './container'
