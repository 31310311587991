import { Container, IInit } from '../../../common/container/Container'
import { Article } from '../models/Article'
import { ContentContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { catchError, map } from 'rxjs/operators'
import { prepareURL } from '../../../common/api/http-helpers'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { ArticleDTO, toModel } from '../models/ArticleDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { id } from 'date-fns/locale'

export interface IArticleApi extends IInit {
  getByID(id: string): Observable<Article | undefined>

  getInteractions(id: string | undefined): Observable<any>

  getAuthorAndCategory(id: string | undefined): Observable<any>

  getFilteredList(q: Query<Article>): Observable<ItemList<Article>>

  getByUser(q: Query<Article>): Observable<ItemList<Article>>

  add(e: ArticleDTO): Observable<Article | undefined>

  update(e: ArticleDTO): Observable<Article | undefined>

  delete(id: string): Observable<boolean>
}
const apiURL = process.env.REACT_APP_BACKEND_URL
export class ArticleApi implements IArticleApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _urlByUser!: string
  private _urlInteractions!: string
  private _urlAuthorAndCategory!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as ContentContainerConfig).moduleFullUrl + '/articles'
    this._urlByUser =
      (this._container.config as ContentContainerConfig).moduleFullUrl + '/articles-by-user'
    this._urlInteractions = this._url + '/interactions'
    this._urlAuthorAndCategory = this._url + '/author-and-category'
  }

  add(e: ArticleDTO): Observable<Article | undefined> {
    return this._httpClient.post<Article>({ url: this._url, body: e }).pipe(
      map<ArticleDTO, Article>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<Article | undefined> {
    return this._httpClient.get<Article>({ url: `${this._url}/${id}` }).pipe(
      map<ArticleDTO, Article>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(q: Query<Article>): Observable<ItemList<Article>> {
    const url = this._url
    return this._httpClient.get<ItemList<Article>>({ url: prepareURL(url, q) }).pipe(
      map<ItemList<ArticleDTO>, ItemList<Article>>((dto) => {
        const itemList = emptyList<Article>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<Article>())
      })
    )
  }

  getByUser(q: Query<Article>): Observable<ItemList<Article>> {
    const url = this._urlByUser
    return this._httpClient.get<ItemList<Article>>({ url: prepareURL(url, q) }).pipe(
      map<ItemList<ArticleDTO>, ItemList<Article>>((dto) => {
        const itemList = emptyList<Article>()
        itemList.count = dto.count || dto.length

        if (dto && dto.items) {
          itemList.items = dto.items.map((d) => toModel(d))
        } else if (dto.length > 0) {
          dto.forEach((element: any) => {
            itemList.items.push(element)
          })
        }

        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<Article>())
      })
    )
  }

  update(e: ArticleDTO): Observable<Article | undefined> {
    return this._httpClient.put<Article>({ url: this._url, body: e }).pipe(
      map<ArticleDTO, Article>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getAuthorAndCategory(id: string | undefined): Observable<any> {
    const url = this._urlAuthorAndCategory
    return this._httpClient.get<any>({ url: `${url}/${id}` }).pipe(
      map<any, any>((d) => d),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getInteractions(id: string | undefined): Observable<any> {
    const url = this._urlInteractions
    return this._httpClient.get<any>({ url: `${url}/${id}` }).pipe(
      map<any, any>((d) => d),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
}
