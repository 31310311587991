import { Box } from '@mui/material'
import style from './NavigationEsfera.module.css'
import { Link } from 'react-router-dom'
import React from 'react'
import { LinkType } from './NavigationEsfera'
import { useTranslation } from 'react-i18next'
import { SubMenu } from './SubMenu'
import { getLinkStyle, getListItemStyle } from './stylesFunctions'

type ArticleSubMenuProps = {
  articleOpen: boolean
  setArticleOpen: (hover: boolean) => void
  selectedMenu: string
  setSelectedMenu: (menuSelected: string) => void
  links: LinkType[]
  navigate: (route: string) => void
  anchorEl: HTMLElement | null
}

export const ArticleSubMenu = ({
  articleOpen,
  setArticleOpen,
  selectedMenu,
  setSelectedMenu,
  links,
  navigate,
  anchorEl,
}: ArticleSubMenuProps) => {
  const { t } = useTranslation()

  return (
    <SubMenu
      anchorEl={anchorEl}
      open={articleOpen}
      setOpen={setArticleOpen}
      setCircleHover={setArticleOpen}
      style={style.subMenuContainer}
    >
      <>
        <h2>{t('library')}</h2>
        <Box style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
          {links
            .filter((l) => l.isVisible && l.subMenuArticle)
            .map((l) => (
              <Link
                onClick={() => {
                  navigate(l.route)
                  setSelectedMenu(l.route)
                }}
                key={l.title}
                to={l.route}
                className={getLinkStyle(
                  selectedMenu,
                  style.circleSubMenuActive,
                  ...l.additionalRoutes,
                  l.route
                )}
                id={l.route}
              >
                <div className={style.menuDiv}>
                  <Box
                    style={{ padding: '0 60px 0 60px' }}
                    key={l.title}
                    className={getListItemStyle(
                      false,
                      selectedMenu || '',
                      style.circleSubMenuItem,
                      style.circleSubMenuItemActive,
                      ...(l?.additionalRoutes || ''),
                      l?.route || ''
                    )}
                  >
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                      <Box style={{ margin: 8, minWidth: 40 }}>
                        {l.route === selectedMenu ? (
                          <img src={l.activeIcon} alt={t(l.altSrc)} />
                        ) : (
                          <img src={l.icon} alt={t(l.altSrc)} />
                        )}
                      </Box>
                      <Box>
                        <h3 className={style.titleNameSubMenu}>{t(l.title)}</h3>
                      </Box>
                    </Box>
                  </Box>
                </div>
              </Link>
            ))}
        </Box>
      </>
    </SubMenu>
  )
}
