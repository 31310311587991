import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardComponent, GenericChartDashboardComponent } from './ActivePlanCard'
import { Training } from '../../../../modules/training/models/Training'

type EffortLevelCardProps = {
  intensity: Training[]
}

export const EffortLevelCard: React.FC<EffortLevelCardProps> = ({ intensity }) => {
  const { t } = useTranslation()
  const colors = ['#0063A6', '#F18B24', '#B6B5B5', '#F1DD25', '#68B3E0', '#4CBF0D']
  const [labels, setLabels] = useState<string[]>([])

  const makeLabel = () => {
    if (labels.length !== 0) return
    const result: string[] = []
    for (let i = 1; i < 7; i++) {
      result.push('Día ' + i)
    }
    setLabels(result)
  }

  makeLabel()

  const datasets = intensity.map((intens, index) => {
    const auxArray: number[] = [0, 0, 0, 0, 0, 0]
    auxArray[index] = intens.intensity
    return {
      label: labels[index],
      data: auxArray,
      borderColor: colors[index],
      backgroundColor: colors[index],
    }
  })

  // console.log(intensity, datasets)

  return (
    <CardComponent title={t('effortLevel')}>
      <GenericChartDashboardComponent
        datasets={datasets}
        labels={labels}
        title={'Día entrenado'}
        typeChart="bar"
        legendPosition={'bottom'}
      />
    </CardComponent>
  )
}
