import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import React, { useEffect, useState } from 'react'
import { File as F } from '../../modules/files/models/File'
import {
  ROUTE_CREATE,
  ROUTE_RESOURCES,
  ROUTE_RESOURCES_FORM,
  ROUTE_RESOURCES_SHARED_WITH_ME,
} from '../../routes/routes-constants'
import { emptyFileDTO, FileDTO, fromModel, toModel } from '../../modules/files/models/FileDTO'
import style from '../generic/GenericTable.module.css'
import { cloneDeep } from 'lodash'
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner'
import loaderStyles from '../../components/loading-spinner/LoadingSpinner.module.css'
import createFile from '../../assets/resource_icons/ico-file-crear.svg'
import createDirectory from '../../assets/resource_icons/ico-folder.svg'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users/container'
import { getUserContainer } from '../../container/user-module'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { useTranslation } from 'react-i18next'
import genericStyle from '../../common/utils/generic.module.css'
import { downloadFile, viewFile } from '../../common/utils/file'
import { AppTable, Field } from '../../components/table'
import { Actions, Pager } from '../../components/table_type/types'
import { forkJoin, Observable } from 'rxjs'
import { User } from '../../modules/users/models/User'
import { reduceString } from '../../common/utils/strings'
import { UserService } from '../../modules/users/services/UserService'
import {
  ILoggedUserService,
  LoggedUserService,
} from '../../modules/users/services/LoggedUserService'
import tableStyles from './Table.module.css'
import folder from '../../assets/resource_icons/folder.svg'
import file from '../../assets/resource_icons/icono-file.svg'
import seeIcon from '../../assets/table_icons/ico-ver.svg'
import seeIconResponsive from '../../assets/resource_icons/ico-eye.svg'
import editIcon from '../../assets/table_icons/ico-edit.svg'
import editIconResponsive from '../../assets/resource_icons/ico-editar.svg'
import downloadIcon from '../../assets/table_icons/ico-download.svg'
import downloadIconResponsive from '../../assets/resource_icons/ico-download.svg'
import deleteIcon from '../../assets/table_icons/ico-eliminar.svg'
import deleteIconResponsive from '../../assets/resource_icons/ico-eliminar.svg'
import toAgoraIcon from '../../assets/table_icons/ico-subir.svg'
import ShareIcon from '../../assets/resource_icons/ico-share-azul.svg'
import { CustomModal } from '../../components/modal/CustomModal'
import { Permission } from '../../common/permission'
import { Query, QueryParam } from '../../common/api/Query'
import { SharedWith, SharedWithQuery } from '../../modules/sharedWith/models/SharedWith'
import { ShareModal } from '../../components/share-modal/ShareModal'
import { useIsRelated } from 'hooks/relatedUsers/getRelatedUsers'
import { Box, Breadcrumbs, Chip, Modal, Stack, Tooltip } from '@mui/material'
import styles from '../share-resources/ShareResources.module.css'
import { RolesType } from '../../common/enums/RolesType'
import { useLocation, useNavigate } from 'react-router-dom'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { IUserCircleActiveService, USER_CIRCLE_ACTIVE_SERVICE_KEY } from '../../modules/user-circle'

const userContainer = getUserContainer()
const loggedUserService2 = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userService = userContainer.get<UserService>(USER_SERVICE_KEY)
const userCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)
const fileContainer = getFileContainer()
const filesService = fileContainer.get<FileService>(FILE_SERVICE_KEY)

type ResourcesProps = {
  resource?: string
  breadcrumbUrl?: Map<string, string>
}

export function Table(props: ResourcesProps): JSX.Element {
  const { t } = useTranslation('translation')
  const location = useLocation()
  const navigate = useNavigate()
  const userCircle = userCircleActiveService.getActiveFullUserCircle()
  const loggedUser = loggedUserService.get()
  const rel = useIsRelated()
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [resources, setResources] = useState<F[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [breadcrumbUrl, setBreadcrumbUrl] = useState<Map<string, string>>(new Map())
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [filesPerPage, setFilesPerPage] = useState<number>(10)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [currentFile, setCurrentFile] = useState<F>()
  const [userNames, setUserNames] = useState<Map<string, string>>(new Map())
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fileDTO, setFileDTO] = useState<FileDTO>(
    currentFile ? fromModel(currentFile) : emptyFileDTO('', '', false)
  )
  const [isSystem, setIsSystem] = useState<string>('')
  const isSharedFolder = location.pathname.includes(ROUTE_RESOURCES_SHARED_WITH_ME)
  const [mapSharedUsers, setMapSharedUsers] = useState<Map<string, string[]>>(
    new Map<string, string[]>()
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sharedFiles, setSharedFiles] = useState<SharedWith[]>([])
  const [openModalShare, setOpenModalShare] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [shareWithArray, setShareWithArray] = useState<string[] | string>(t('anyone'))

  const { innerWidth } = window
  const [isLoadResource, setIsLoadResource] = useState<boolean>(false)
  const [currentDirectory, setCurrentDirectory] = useState<F | null>(null)
  const [isCreatedByDoctor, setIsCreatedByDoctor] = useState<boolean>(false)

  console.log('props', props.resource)
  useEffect(() => {
    console.log('neotro al useffect', isLoading)
    if (isLoading) {
      console.log('ojo con esta', userCircle)
      console.log('aqui')
      if (!loggedUser) return
      console.log('no por aqui')
      if (!props.resource) {
        console.log('netro al iff')
        filesService
          .sharedFileIn(
            new Query({
              query: [
                new QueryParam<SharedWithQuery>(
                  'userId',
                  !loggedUser.roles.includes('professionalSMS')
                    ? loggedUser.id
                    : userCircle?.user.id ?? ''
                ),
                new QueryParam<SharedWithQuery>('fileId', props.resource ?? ''),
                new QueryParam<SharedWithQuery>('circleId', userCircle?.id ?? ''),
              ],
              pager: { offset: page * filesPerPage, limit: filesPerPage },
            })
          )
          .subscribe((res) => {
            if (!res.items) return
            setResources(res.items)
            setCount(res.count)
            res.items.forEach((f) => {
              setIsSystem(f?.isSystem ? f?.isSystem : '')
              handleSubscribeFileService(f)
            })
          })
      } else {
        console.log('entro al else')
        filesService
          .sharedFileIn(
            new Query({
              query: [
                new QueryParam<SharedWithQuery>(
                  'userId',
                  !loggedUser.roles.includes('professionalSMS')
                    ? loggedUser.id
                    : userCircle?.user.id ?? ''
                ),
                new QueryParam<SharedWithQuery>('fileId', props.resource ?? ''),
                new QueryParam<SharedWithQuery>('circleId', userCircle?.id ?? ''),
              ],
              pager: { offset: page * filesPerPage, limit: filesPerPage },
            })
          )
          .subscribe((res) => {
            setResources(res.items)
            setCount(res.count)
            res.items.forEach((f) => {
              filesService
                .getFilteredItemsSharedWithMe(
                  new Query({
                    query: [new QueryParam<SharedWithQuery>('fileId', f.id ?? '')],
                  })
                )
                .subscribe((res) => {
                  setSharedFiles(res)
                })
            })
          })
      }
    }

    return () => {
      setIsLoading(false)
      handleSubscribeFileService(undefined)
    }
  }, [isLoading, page, filesPerPage])

  useEffect(() => {
    setIsLoading(true)
  }, [props.resource])

  useEffect(() => {
    const mapAux: Map<string, string[]> = new Map<string, string[]>()
    resources.forEach((r) => {
      // TODO fix this
      /* filesService.getUsersSharedByFileID(r.id ?? '').subscribe((res) => {
        mapAux.set(r.id ?? '', res)
      }) */
    })
    if (mapAux !== undefined) {
      setMapSharedUsers(mapAux)
    }
  }, [resources])

  useEffect(() => {
    if (props.breadcrumbUrl) {
      setBreadcrumbUrl(props.breadcrumbUrl)
    }
  }, [props.breadcrumbUrl])

  const getUsers = (ids: string[]): Observable<User[]> =>
    forkJoin(ids.map((id) => userService.getByID(id))) as unknown as Observable<User[]>

  useEffect(() => {
    let users: string[] = []
    resources.forEach((r) => {
      users = users.concat(r.sharedWith, [r.creator])
    })
    getUsers(users).subscribe((res) => {
      const newMap = new Map()
      res.forEach((user) => {
        if (user) {
          newMap.set(user.id, user.name)
        }
      })
      setUserNames(newMap)
    })
    const interval = setInterval(() => {
      setIsLoading(false)
    }, 500)
    return () => {
      clearInterval(interval)
    }
  }, [resources])

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: filesPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, filesPerPage])
  useEffect(() => {
    if (!props.resource) return
    if (isLoading) {
      filesService
        .getByID(props.resource || null)
        .toPromise()
        .then((res) => {
          if (!res) return
          setIsSystem(res?.isSystem ? res?.isSystem : '')
          setIsCreatedByDoctor(res.createdByDoctor)
          handleSubscribeFileService(res)
        })
    }
  }, [isLoading, props.resource])

  useEffect(() => {
    if (currentDirectory === null) return
    handleSubscribeFileService(currentDirectory)
  }, [props.resource])

  const handleSubscribeFileService = (res: F | undefined) => {
    const interval = setInterval(() => {
      setIsLoading(false)
    }, 500)

    if (props.resource) {
      if (breadcrumbUrl.get(props.resource || '')) {
        let breadcrumbUrlCopy = Array.from(breadcrumbUrl, ([key, value]) => ({ key, value }))
        breadcrumbUrlCopy = breadcrumbUrlCopy.slice(
          0,
          breadcrumbUrlCopy.findIndex(({ key }) => key === props.resource) + 1
        )
        setBreadcrumbUrl(new Map(breadcrumbUrlCopy.map((i) => [i.key, i.value])))
      } else {
        const breadcrumbCopy = cloneDeep<Map<string, string>>(breadcrumbUrl)
        breadcrumbCopy.set(props.resource, res?.name ?? '-')
        setBreadcrumbUrl(breadcrumbCopy)
      }
    } else {
      setBreadcrumbUrl(new Map())
    }

    return () => {
      clearInterval(interval)
    }
  }

  const createResource = () =>
    navigate(`${ROUTE_RESOURCES_FORM}/${ROUTE_CREATE}`, {
      state: { parentID: props.resource ?? null, breadcrumbUrl },
    })

  const createDir = () =>
    navigate(`${ROUTE_RESOURCES_FORM}/${ROUTE_CREATE}`, {
      state: { parentID: props.resource ?? null, breadcrumbUrl, isDir: true },
    })

  const editResource = (resource: F) =>
    navigate(`${ROUTE_RESOURCES_FORM}/${resource.id}`, {
      state: {
        parentID: props.resource ?? null,
        breadcrumbUrl,
        isDir: resource.isDir,
      },
    })

  const downloadResource = (resource: F) => {
    setIsLoadResource(true)
    filesService.download(resource).subscribe((res) => {
      if (res) {
        downloadFile(res.name, res.mimeType, res.data)
        setIsLoadResource(false)
      } else {
        setIsLoadResource(true)
      }
    })
  }

  const viewResource = (resource: F) => {
    setIsLoadResource(true)
    filesService.download(resource).subscribe((res) => {
      if (res) {
        viewFile(res)
        setIsLoadResource(false)
      } else {
        setIsLoadResource(true)
      }
    })
  }

  const handleCloseShareModal = () => {
    setOpenModalShare(false)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleSave = () => {
    const newFile = Object.assign({ ...fileDTO }, { isUploaded: true })
    filesService.update(toModel(newFile)).subscribe()
    setCurrentFile(undefined)
    setOpenModal(false)
  }

  const handleClick = (resource: F) => {
    if (resource.isDir) {
      setCurrentDirectory(resource)
      handleSubscribeFileService(resource)
      navigateToResources(resource.id ?? null, isSharedFolder, resource)
    }
  }

  const removeResource = (resource: F) => {
    setCurrentFile(resource)
    setOpenDeleteModal(true)
  }

  const navigateToResources = (
    id: string | null,
    goToSharedFolder: boolean = false,
    resource: F | null
  ) => {
    if (id) {
      setCurrentDirectory(resource)
      navigate(`${goToSharedFolder ? ROUTE_RESOURCES_SHARED_WITH_ME : ROUTE_RESOURCES}/${id}`, {
        state: {
          breadcrumbUrl,
        },
      })
    } else {
      setCurrentDirectory(null)
      navigate(`${goToSharedFolder ? ROUTE_RESOURCES_SHARED_WITH_ME : ROUTE_RESOURCES}`, {
        state: {
          breadcrumbUrl: new Map(),
        },
      })
    }
  }

  async function handleBreadcrumbClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    const resourceID = event.currentTarget.id
    if (Number(resourceID) === -1) {
      setCurrentDirectory(null)
      navigateToResources(null, isSharedFolder, null)
    } else {
      filesService.getByID(resourceID).subscribe((res) => {
        if (!res) return
        setCurrentDirectory(res)
        navigateToResources(resourceID, isSharedFolder, null)
      })
    }
  }

  async function handleBreadcrumbBackClick(_: React.MouseEvent<HTMLElement, MouseEvent>) {
    if (currentDirectory) {
      const parentID = currentDirectory.parent ?? null
      setCurrentDirectory(null)
      setIsCreatedByDoctor(false)
      navigateToResources(parentID, isSharedFolder, null)
    } else {
      setCurrentDirectory(null)
      setIsCreatedByDoctor(false)
      navigateToResources(null, isSharedFolder, null)
    }
  }

  const backToResources = () => {
    setIsCreatedByDoctor(false)
    navigate(ROUTE_RESOURCES)
  }

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setFilesPerPage(10)
      return
    }
    setFilesPerPage(Number.parseInt(event.target.value))
  }

  const handleToAgora = (item: F) => {
    if (item) setCurrentFile(item)
    setOpenModal(true)
  }

  const isCreator = (r: F) => r.creator === loggedUser?.id
  const fields: Field<F>[] = [
    {
      name: 'name',
      label: t('title'),
      renderFunc: (f, i) => (
        <div className={i.isDir ? tableStyles.dir : ''} onClick={() => handleClick(i)}>
          <img src={i.isDir ? folder : file} className={tableStyles.icon} />
          <span style={{ color: i.creator === loggedUser?.id ? 'green' : '' }}>{i.name}</span>
        </div>
      ),
    },
    {
      name: 'creator',
      label: t('creator'),
      renderFunc: (f, i) => (i.isSystem !== '' ? 'Sistema' : userNames.get(i.creator) ?? ''),
    },
    {
      name: 'sharedWith',
      label: innerWidth > 598 ? t('sharedWith') : '',
      renderFunc: (f, i) => {
        if (innerWidth < 598) {
          return ''
        }
        const mshared = mapSharedUsers.get(i.id ?? '')
        if (mshared && mshared?.length > 0) {
          const joinedNames = mshared?.join(', ')
          return (
            <div>
              <Tooltip title={joinedNames || ''}>
                <div>{reduceString(joinedNames || '', 30)}</div>
              </Tooltip>
            </div>
          )
        }
        // if (i.isPublic) return t('every')
        return t('anyone')
      },
    },
    {
      name: 'specialities',
      label: t('specialty'),
      renderFunc: (f, i) => {
        if (i.specialities) {
          return (
            <Chip
              style={{
                backgroundColor: '#dddedf',
                borderRadius: '9px',
                marginLeft: '8px',
                marginBottom: '8px',
              }}
              label={<span style={{ fontFamily: 'Poppins', color: '#000' }}>{i.specialities}</span>}
              key={i.specialities + i.id}
            />
          )
        }
        return ''
      },
    },
    {
      name: 'circles',
      label: t('circle'),
      renderFunc: (f, i) => {
        if (i.circles && i.circles?.length > 0) {
          const array = i.circles.split(',')
          return array.map((p, index) => (
            <Chip
              style={{
                backgroundColor: '#d1dee6',
                marginLeft: '8px',
                marginBottom: '8px',
              }}
              label={<span style={{ fontFamily: 'Poppins', color: '#000' }}>{p}</span>}
              key={p + index + i.id}
            />
          ))
        }
        return ''
      },
    },
  ]

  const actions: Actions<F> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: viewResource,
        icon: innerWidth > 598 ? seeIcon : seeIconResponsive,
        label: 'see',
        hidden: (r) =>
          rel
            ? (!isCreator(r) && !rel) || r.isSystem !== '' || r.isDir
            : (!rel && r.isSystem !== '') || r.isDir,
      },
      {
        handler: editResource,
        icon: innerWidth > 598 ? editIcon : editIconResponsive,
        label: 'edit',
        hidden: rel ? (r) => !isCreator(r) || r.isSystem !== '' : () => true,
      },
      {
        handler: downloadResource,
        icon: innerWidth > 598 ? downloadIcon : downloadIconResponsive,
        label: 'download',
        hidden: (r) => r.isDir,
      },
      {
        handler: handleToAgora,
        icon: toAgoraIcon,
        label: 'cloudToAgora',
        hidden: rel
          ? () =>
              !loggedUserService2.userCan(Permission.uploadToAgora) ||
              !(typeof props.resource === 'undefined')
          : () => true,
      },
      {
        handler: removeResource,
        icon: innerWidth > 598 ? deleteIcon : deleteIconResponsive,
        label: 'delete',
        hidden: rel ? (r) => !isCreator(r) || r.isSystem !== '' : (r) => true,
      },
    ],
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handleDeleteResource = () => {
    if (currentFile)
      filesService.delete(currentFile).subscribe((_) => {
        setIsLoading(true)
      })
    setOpenModal(false)
    setOpenDeleteModal(false)
    const interval = setInterval(() => {
      setIsLoading(false)
    }, 500)
    return () => {
      clearInterval(interval)
    }
  }

  const breadcrumbItems = Array.from(breadcrumbUrl.entries()).map(([key, value]) => (
    <span className={styles.breadcrumbItem} key={key}>
      <a style={{ fontFamily: 'Poppins' }} id={key} onClick={handleBreadcrumbClick}>
        {value}
      </a>
    </span>
  ))

  return (
    <>
      <Box className={genericStyle.pageContainer}>
        <Stack display={'flex'} mb={2} spacing={2}>
          <Breadcrumbs separator=">" aria-label="breadcrumb" color={'#68b3e0'}>
            {breadcrumbItems}
          </Breadcrumbs>
        </Stack>
        <Box className={style.buttonContainer}>
          {(!isSharedFolder && isSystem === '') || !props.resource ? (
            <>
              {loggedUser?.roles.includes(RolesType.ProfessionalSMS) ? (
                <>
                  <AppButton
                    theme={ButtonTheme.NewPrimary}
                    type={'button'}
                    label={t('createResource')}
                    startIcon={createFile}
                    handler={createResource}
                  />
                  <AppButton
                    theme={ButtonTheme.NewPrimary}
                    type={'button'}
                    label={t('createDir')}
                    startIcon={createDirectory}
                    handler={createDir}
                  />
                </>
              ) : rel && !isCreatedByDoctor ? (
                <>
                  <AppButton
                    theme={ButtonTheme.NewPrimary}
                    type={'button'}
                    label={t('createResource')}
                    startIcon={createFile}
                    handler={createResource}
                  />
                  <AppButton
                    theme={ButtonTheme.NewPrimary}
                    type={'button'}
                    label={t('createDir')}
                    startIcon={createDirectory}
                    handler={createDir}
                  />
                </>
              ) : (
                ''
              )}
              {props.resource && (
                <AppButton
                  theme={ButtonTheme.NewSecondary}
                  type={'button'}
                  label={t('backOneToResources')}
                  handler={handleBreadcrumbBackClick}
                />
              )}
            </>
          ) : (
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('backToResources')}
              handler={backToResources}
            />
          )}
        </Box>
        <Box mt={3}>
          {resources.length > 0 && (
            <AppTable
              items={resources}
              rowKeyField="id"
              fields={fields}
              actions={actions}
              pager={pager}
            />
          )}
          {resources.length === 0 && (
            <h1 key="no-resources" className={style.noElements}>
              {t('noResources')}
            </h1>
          )}
          {!currentFile?.isDir && (
            <Modal
              open={openDeleteModal}
              className={style.eventModal}
              onClose={handleCloseDeleteModal}
            >
              <>
                <CustomModal
                  handleClose={handleCloseDeleteModal}
                  handleSave={handleDeleteResource}
                  title={t('deleteResource')}
                  warningText={t('irreversibleResourceAction')}
                />
              </>
            </Modal>
          )}
          {currentFile?.isDir && (
            <Modal
              open={openDeleteModal}
              className={style.eventModal}
              onClose={handleCloseDeleteModal}
            >
              <>
                <CustomModal
                  handleClose={handleCloseDeleteModal}
                  handleSave={handleDeleteResource}
                  title={t('deleteDirectory')}
                  warningText={t('irreversibleDirectoryAction')}
                />
              </>
            </Modal>
          )}
        </Box>
        <Modal open={openModal} onClose={handleCloseModal}>
          <>
            <CustomModal
              handleClose={handleCloseModal}
              handleSave={handleSave}
              title={t('cloudToAgora')}
              warningText={t('irreversibleAction')}
            />
          </>
        </Modal>
        <Modal open={openModalShare} onClose={handleCloseShareModal} className={style.sharedModal}>
          <>
            <ShareModal
              title={'Compartido con'}
              icon={ShareIcon}
              handleContent={shareWithArray}
              handleClose={handleCloseShareModal}
            />
          </>
        </Modal>
      </Box>
      {isLoadResource ? (
        <Box
          style={{
            backgroundColor: '#0a09098f',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '130px',
          }}
        >
          <LoadingSpinner className={loaderStyles.loadingResourceSpinner} />
        </Box>
      ) : null}
    </>
  )
}
