import React, { useEffect, useState } from 'react'
import { CalendarViewProps } from './types'
import { Box, Modal, Tooltip } from '@mui/material'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { dateToTimeWithoutSecondsString } from '../../common/utils/date'
import { useTranslation } from 'react-i18next'
import style from './ListView.module.css'
import { Query, QueryParam } from '../../common/api/Query'
import { AppTable, Field } from '../../components/table'
import { Actions } from '../../components/table_type/types'
import { EventCategory } from '../../modules/calendar/enums/EventCategory'
import othersIcon from '../../assets/events_icons/bola-otros.svg'
import adviceIcon from '../../assets/events_icons/bola-consejos.svg'
import landmarkIcon from '../../assets/events_icons/bola-hitos.svg'
import vaccineIcon from '../../assets/events_icons/bola-vacunas.svg'
import dateIcon from '../../assets/events_icons/bola-cita.svg'
import oralhealthIcon from '../../assets/events_icons/bola-bucodental.svg'
import reviewIcon from '../../assets/events_icons/bola-revision.svg'
import foodIcon from '../../assets/events_icons/bola-alimentacion.svg'
import seeIcon from '../../assets/table_icons/ico-ver.svg'
import epilepticCrisis from '../../assets/calendar/epileptic_crisis.svg'
import treatments from '../../assets/calendar/treatments.svg'
import generalSymptom from '../../assets/calendar/general_symptom.svg'
import personalEvent from '../../assets/calendar/personalEvent.svg'
import { CustomModal } from '../../components/modal/CustomModal'
import styles from './MonthView.module.css'
import { Training } from '../../modules/training/models/Training'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { IUserCircleActiveService, USER_CIRCLE_ACTIVE_SERVICE_KEY } from '../../modules/user-circle'
import { getTrainingContainer } from '../../container/training-module'
import { TrainingService } from '../../modules/training/services/TrainingService'
import { TRAINING_SERVICE_KEY } from '../../modules/training'
import { emptyList, ItemList } from '../../common/models/ItemList'
import trainingIcon from '../../assets/active_plan_icons/training_calendar.svg'
import { TrainingModal } from './trainingModal/TrainingModal'
import {
  emptyTrainingDTO,
  toModel as trainingToModel,
} from '../../modules/training/models/TrainingDTO'
import { AllCalendarEventDTO } from '../../modules/calendar/models/AllCalendarEventDTO'
import { useCalendarEvents } from '../../hooks/calendarEvents/useCalendarEvents'
import { CalendarModal } from './CalendarModal'
import { getIconFromSymptomIDIcon } from './SpecificSymptomIconsEnum'
import { CalendarTooltip } from './CalendarTooltip'

dayjs.extend(isBetween)

const UserCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)

const trainingService = getTrainingContainer().get<TrainingService>(TRAINING_SERVICE_KEY)

type ListViewProps = CalendarViewProps

export function ListView(props: ListViewProps) {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [selectedEvent, setSelectedEvent] = useState<AllCalendarEventDTO>()
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const currentDate = new Date(props.selectedDate)
  const [trainings, setTrainings] = useState<ItemList<Training>>(emptyList<Training>)
  const [openTrainingModal, setOpenTrainingModal] = useState<boolean>(false)
  const [selectedTraining, setSelectedTraining] = useState<Training>(
    trainingToModel(emptyTrainingDTO())
  )
  const [canSetIntensity, setCanSetIntensity] = useState<boolean>(false)
  const { calendarEvents } = useCalendarEvents()
  const [todayCalendarEvents, setTodayCalendarEvents] = useState<AllCalendarEventDTO[]>([])

  useEffect(() => {
    // filter today calendar events
    const aux: AllCalendarEventDTO[] = []

    calendarEvents.forEach((event) => {
      const day = dayjs(props.selectedDate)
      const startDate = dayjs(event.startDate)
      const finishDate = dayjs(event.finishDate)
      const sameDays = day.isSame(finishDate, 'day') || day.isSame(startDate, 'day')
      const betweenDays = day.isBetween(startDate, finishDate)
      if (sameDays || betweenDays) {
        aux.push(event)
      }
    })
    setTodayCalendarEvents(aux)
  }, [calendarEvents])

  useEffect(() => {
    if (!isLoading) return
    const startDate = new Date(props.selectedDate)
    startDate.setHours(0, 0, 0, 0)
    const finishDate = new Date(startDate)
    finishDate.setHours(23, 59, 59, 999)

    trainingService
      .getFilteredList(
        new Query<Training>({
          query: [
            new QueryParam<Training>('startTime', startDate.toISOString()),
            new QueryParam<Training>('finishTime', finishDate.toISOString()),
            new QueryParam<Training>(
              'userCircleID',
              UserCircleActiveService.getActiveFullUserCircle()?.id ?? ''
            ),
          ],
        })
      )
      .subscribe((res) => {
        if (!res) return
        setTrainings(res)
        setIsLoading(false)
      })
  }, [props.selectedDate, isLoading])

  const getIcon = (type: EventCategory, idIcon: string) => {
    let title, src, color
    switch (type) {
      case EventCategory.Reviews:
        title = 'reviews'
        src = reviewIcon
        break

      case EventCategory.Medication:
        title = 'medication'
        src = treatments
        break

      case EventCategory.Appointment:
        title = 'medicalappointment'
        src = dateIcon
        break

      case EventCategory.Vaccines:
        title = 'vaccine'
        src = vaccineIcon
        break

      case EventCategory.Food:
        title = 'food'
        src = foodIcon
        break

      case EventCategory.Advices:
        title = 'advices'
        src = adviceIcon
        break
      case EventCategory.OralHealth:
        title = 'oralHealth'
        src = oralhealthIcon
        break

      case EventCategory.Landmarks:
        title = 'landmarks'
        src = landmarkIcon
        break

      case EventCategory.EpilepticCrisis:
        title = 'epilepticCrisis'
        src = epilepticCrisis
        break

      case EventCategory.Symptoms:
        title = 'symptoms'
        if (idIcon !== '') {
          src = getIconFromSymptomIDIcon(idIcon)
        } else {
          src = generalSymptom
        }
        break

      case EventCategory.TrainingPending:
        title = 'training'
        src = trainingIcon
        color = '#b6b5b5'
        break

      case EventCategory.TrainingAssisted:
        title = 'training'
        src = trainingIcon
        color = '#6ce32b'
        break

      case EventCategory.TrainingAbsent:
        title = 'training'
        src = trainingIcon
        color = '#cf3031'
        break
      case EventCategory.PersonalEvent:
        title = 'personalEvent'
        src = personalEvent
        break
      case EventCategory.Treatments:
        title = 'treatments'
        src = treatments
        break

      default:
        title = 'others'
        src = othersIcon
        break
    }
    return (
      <Tooltip title={t(title) ?? title}>
        {title === 'training' ? (
          <img
            style={{ backgroundColor: color }}
            className={styles.imgTraining}
            src={trainingIcon}
          />
        ) : (
          <img style={{ width: 40, height: 40 }} src={src} alt={title} />
        )}
      </Tooltip>
    )
  }

  const handleClick = (e: AllCalendarEventDTO) => {
    if (e.title === t('train')) {
      handlerClickTraining(e)
    } else {
      setSelectedEvent(e)
      setOpenModal(true)
    }
  }
  // const handleCloseModal = () => setOpenModal(false)

  const editEvent = (e: AllCalendarEventDTO) => {
    props.handlerEdit(e)
    setOpenModal(false)
  }

  const deleteEvent = (_: AllCalendarEventDTO) => {
    if (selectedEvent) props.handlerRemove(selectedEvent)
    setOpenModal(false)
    setOpenDeleteModal(false)
  }

  const fields: Field<AllCalendarEventDTO>[] = [
    {
      name: 'title',
      label: t('title'),
    },
    {
      name: 'startDate',
      label: t('hour'),
      renderFunc: (f, i) => {
        const hour = new Date(i.startDate).getHours()
        const minutes = new Date(i.finishDate).getMinutes()
        const finishHour = new Date(i.finishDate).getHours()
        const finishMinutes = new Date(i.finishDate).getMinutes()
        if (hour === 2 && minutes === 0 && finishHour === 2 && finishMinutes === 0) {
          return '- : -'
        }
        return `${dateToTimeWithoutSecondsString(
          new Date(i.startDate)
        )}-${dateToTimeWithoutSecondsString(new Date(i.finishDate))}`
      },
    },
    {
      name: 'finishDate',
      label: t('eventDuration'),
      renderFunc: (f, i) => {
        const hour = i.startDate.getHours()
        const minutes = i.finishDate.getMinutes()
        const finishHour = i.finishDate.getHours()
        const finishMinutes = i.finishDate.getMinutes()
        if (hour === 2 && minutes === 0 && finishHour === 2 && finishMinutes === 0) {
          return 'Todo el día'
        }
        const duration = dayjs(i.finishDate).locale('es').from(dayjs(i.startDate), true)
        if (duration === '6 años') return t('nofinish')
        return duration
      },
    },
    {
      name: 'description',
      label: t('detailEvent'),
    },
    {
      name: 'eventCategory',
      label: t('type'),
      renderFunc: (f, i) => getIcon(i.eventCategory, i.idIcon),
    },
  ]

  const actions: Actions<AllCalendarEventDTO> = {
    actionsColumn: t('action'),
    items: [
      {
        handler: handleClick,
        icon: seeIcon,
        label: 'see',
      },
    ],
  }

  const handleCloseDeleteModal = () => setOpenDeleteModal(false)

  const handleDeleteEvent = () => {
    //  if (selectedEvent) props.handlerRemove(selectedEvent)
    setOpenModal(false)
    setOpenDeleteModal(false)
  }

  const handlerTrainingCloseModal = () => {
    setCanSetIntensity(false)
    setOpenTrainingModal(false)
  }

  const handlerClickTraining = (event: AllCalendarEventDTO) => {
    const training = trainings.items.find((value) => value && value.id === event.id)
    if (!training) return
    setSelectedTraining(training)
    setOpenTrainingModal(true)
  }

  // Mostrar modal en caso que la entrada es desde email
  useEffect(() => {
    if (!props.currentTraining) return
    if (trainings.items.length === 0) return

    const training = trainings.items.find((value) => value && value.id === props.currentTraining)
    if (!training) return
    setSelectedTraining(training)
    setCanSetIntensity(true)
    setOpenTrainingModal(true)
  }, [props.currentTraining, trainings.items])

  const tableComponent = () => {
    return (
      <AppTable
        fields={fields}
        actions={actions}
        items={todayCalendarEvents}
        rowKeyField="id"
        fieldsInDraggable={true}
      />
    )
  }

  const handleCloseModal = () => setOpenModal(false)

  useEffect(() => {
    tableComponent()
  }, [fields])

  return (
    <>
      {!isLoading && (
        <>
          <Box className={style.role} display="flex" alignItems="center">
            <div className={styles.arrowsMonth}>
              {dayjs(currentDate)
                .locale(navigator.language.split('-')[0])
                .format('DD MMMM YYYY')
                .toUpperCase()}
            </div>
          </Box>
          <Box display={'flex'} className={style.listContainer}>
            {window.innerWidth < 599 ? (
              <CalendarTooltip events={todayCalendarEvents} />
            ) : (
              tableComponent()
            )}
          </Box>
        </>
      )}
      {selectedEvent && (
        <Modal open={openModal} className={style.eventModal} onClose={handleCloseModal}>
          <CalendarModal
            event={selectedEvent}
            handleClose={handleCloseModal}
            handleEdit={editEvent}
            handleRemove={deleteEvent}
          />
        </Modal>
      )}
      <Modal open={openDeleteModal} className={style.eventModal} onClose={handleCloseDeleteModal}>
        <CustomModal
          handleClose={handleCloseDeleteModal}
          handleSave={handleDeleteEvent}
          title={t('deleteEvent')}
          warningText={t('irreversibleEventAction')}
        />
      </Modal>
      <Modal
        open={openTrainingModal}
        className={style.eventModal}
        onClose={handlerTrainingCloseModal}
      >
        <TrainingModal
          training={selectedTraining}
          handleClose={handlerTrainingCloseModal}
          canSetIntensity={canSetIntensity}
        />
      </Modal>
    </>
  )
}
