import React, { useEffect, useState } from 'react'
import { CalendarViewProps } from './types'
import { getMonday } from '../../common/utils/date'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material'
import dayjs from 'dayjs'
import styles from './MonthView.module.css'
import appointmentIcon from '../../assets/events_icons/ico-appointment.svg'
import othersIcon from '../../assets/events_icons/bola-otros.svg'
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner'
import { EventCategory } from '../../modules/calendar/enums/EventCategory'
import { CalendarTooltip } from './CalendarTooltip'
import { useCalendarEvents } from '../../hooks/calendarEvents/useCalendarEvents'
import { AllCalendarEventDTO } from '../../modules/calendar/models/AllCalendarEventDTO'
import treatments from '../../assets/calendar/treatments.svg'
import generalSymptom from '../../assets/calendar/general_symptom.svg'
import personalEvent from '../../assets/calendar/personalEvent.svg'
import { getIconFromSymptomIDIcon } from './SpecificSymptomIconsEnum'
import sportGray from '../../assets/calendar/sport_gray.svg'
import sportGreen from '../../assets/calendar/sport_green.svg'
import sportRed from '../../assets/calendar/sport_red.svg'
import { useTranslation } from 'react-i18next'

export function MonthView(props: CalendarViewProps) {
  const LIMIT_DRAW_EVENTS = 8

  const [firstDate, setFirstDate] = useState<Date>(getMonday(props.selectedDate))
  const [month, setMonth] = useState<any[]>([])
  const [currentDate, setCurrentDate] = useState<Date>(props.selectedDate)
  const { calendarEvents } = useCalendarEvents()
  const { t } = useTranslation()
  const weekDays = [
    t('monday'),
    t('tuesday'),
    t('wednesday'),
    t('thursday'),
    t('friday'),
    t('saturday'),
    t('sunday'),
  ]

  const handlePreviousMonth = () => {
    props.handlePreviousMonth()
  }
  const handleNextMonth = () => {
    props.handleNextMonth()
  }

  useEffect(() => {
    const tmpMonday = getMonday(props.selectedDate)
    if (tmpMonday.getTime() !== firstDate.getTime()) {
      setFirstDate(tmpMonday)
    }
    setCurrentDate(props.selectedDate)
  }, [props.selectedDate])

  useEffect(() => {
    const newDate = new Date(props.selectedDate)
    newDate.setDate(1)
  }, [props.selectedDate])

  useEffect(() => {
    const firstDayOfMonth = new Date(currentDate)
    firstDayOfMonth.setDate(1)
    const newDate = new Date(currentDate)
    const lastDay = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0).getDate()
    const month = []
    for (let i = 0; i < firstDayOfMonth.getDay() - 1; i++) {
      month.push('')
    }
    if (firstDayOfMonth.getDay() === 0) {
      for (let i = 0; i < 6; i++) {
        month.push('')
      }
    }

    for (let j = 0; j < lastDay; j++) {
      month.push(firstDayOfMonth.setDate(j + 1))
    }
    setMonth(month)
  }, [currentDate])

  const getEventByDate = (date: Date): AllCalendarEventDTO[] => {
    const eventsArr = calendarEvents.filter((ev) => {
      const day = dayjs(date)
      const startDate = dayjs(ev.startDate)
      const finishDate = dayjs(ev.finishDate)
      const sameDays = day.isSame(finishDate, 'day') || day.isSame(startDate, 'day')
      const betweenDays = day.isBetween(startDate, finishDate)

      if (sameDays || betweenDays) {
        return true
      }
      return false
    })

    return eventsArr
  }

  const drawEvent = (d: Date) => {
    let drawedEvents = 0
    const today = new Date()
    const returnObj = calendarEvents.map((event: AllCalendarEventDTO, k) => {
      let image
      switch (event.eventCategory) {
        case EventCategory.Symptoms:
          if (event.idIcon !== '') {
            image = getIconFromSymptomIDIcon(event.idIcon)
          } else {
            image = generalSymptom
          }
          break
        case EventCategory.EpilepticCrisis:
          if (event.idIcon !== '') {
            image = getIconFromSymptomIDIcon(event.idIcon)
          }
          break
        case EventCategory.Treatments:
          image = treatments
          break
        case EventCategory.PersonalEvent:
          image = personalEvent
          break
        case EventCategory.Appointment:
          image = appointmentIcon
          break
        case EventCategory.Others:
          image = othersIcon
          break
        case EventCategory.TrainingPending:
          image = d < today ? sportRed : sportGray
          break
        case EventCategory.TrainingAssisted:
          image = sportGreen
          break
        default:
          image = ''
          break
      }

      const day = dayjs(d)
      const startDate = dayjs(event.startDate)
      const finishDate = dayjs(event.finishDate)
      const sameDays = day.isSame(finishDate, 'day') || day.isSame(startDate, 'day')
      const betweenDays = day.isBetween(startDate, finishDate)

      const drawThisEvent = sameDays || betweenDays
      if (drawThisEvent) {
        drawedEvents++
      }
      return (
        <div key={event.id + k.toString()}>
          {drawThisEvent && drawedEvents <= LIMIT_DRAW_EVENTS ? (
            <div className={styles.date}>
              {image ? <img className={styles.img} src={image} /> : null}
            </div>
          ) : null}
        </div>
      )
    })
    return (
      <>
        {window.innerWidth < 599 && drawedEvents > 0 ? (
          <p className={styles.orangePoint}></p>
        ) : (
          <>
            {returnObj}
            {drawedEvents > LIMIT_DRAW_EVENTS ? <p className={styles.puntitos}>...</p> : null}
          </>
        )}
      </>
    )
  }

  return (
    <>
      <React.Suspense fallback={<LoadingSpinner />}>
        <div className={styles.arrowsMonth}>
          {props.canChangeMonth && (
            <button className={styles.styleArrow} onClick={handlePreviousMonth}>
              &lt;
            </button>
          )}
          {dayjs(currentDate)
            .locale(navigator.language.split('-')[0])
            .format('MMMM YYYY')
            .toUpperCase()}
          {props.canChangeMonth && (
            <button className={styles.styleArrow} onClick={handleNextMonth}>
              &gt;
            </button>
          )}
        </div>
        <TableContainer className={styles.tabRow}>
          <Table>
            {/* DIAS */}
            <TableHead className={styles.tabHeader}>
              <TableRow>
                {weekDays.map((i, d) => (
                  <TableCell className={styles.dateName} key={i + d}>
                    <div className={styles.weekDay}>{i}</div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {month.map((w, j) => {
                if (j === 0 || j % 7 === 0) {
                  let n = 0
                  return (
                    <TableRow
                      style={{ fontFamily: 'Poppins', top: 0 }}
                      key={w.toString() + n.toString()}
                    >
                      {month.map((d, i) => {
                        if (
                          (i < 7 && j === 0 && n < 7) ||
                          (j === 7 && n < 7 && i < 14 && i > 6) ||
                          (j === 14 && n < 7 && i < 21 && i > 13) ||
                          (j === 21 && n < 7 && i < 28 && i > 20) ||
                          (j === 28 && n < 7 && i < 35 && i > 27) ||
                          (j === 35 && n < 7 && i < 42 && i > 34)
                        ) {
                          n++

                          return (
                            <TableCell className={styles.tabCell} key={d.toString() + i.toString()}>
                              <Box className={styles.date}>
                                <div className={styles.dataDivNumber}>
                                  {month[i] !== '' ? dayjs(month[i]).get('date') : null}
                                </div>
                              </Box>
                              <Tooltip
                                title={<CalendarTooltip events={getEventByDate(new Date(d))} />}
                                arrow={true}
                                placement="right"
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      backgroundColor: 'white',
                                      boxShadow: '1px 1px 5px 3px transparent',
                                      '& .MuiTooltip-arrow': {
                                        color: 'white',
                                      },
                                    },
                                  },
                                }}
                              >
                                <Box>
                                  <Box className={styles.containerRow}>
                                    {drawEvent(new Date(d))}
                                  </Box>
                                </Box>
                              </Tooltip>
                            </TableCell>
                          )
                        }
                        return null
                      })}
                    </TableRow>
                  )
                }
                return null
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </React.Suspense>
    </>
  )
}
