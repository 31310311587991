import React, { useEffect, useState } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'

import { useTranslation } from 'react-i18next'
import genericStyle from '../../../common/utils/generic.module.css'
import styles from '../PatientData.module.css'
import stylePatient from '../../user-profile/Editor.module.css'
import { v4 as uuidv4 } from 'uuid'
import downBlanco from '../../../assets/table_icons/down-blanco.svg'
import { getUserContainer } from '../../../container/user-module'
import { USER_SERVICE_KEY } from '../../../modules/users'
import dayjs from 'dayjs'
import ExpandedMedicalData from './ExpandedMedicalData'
import { getPatientDataContainer } from '../../../container/patient-data-module'
import { PatientDataService } from '../../../modules/patient-data/services/PatientDataService'
import { PATIENT_DATA_API_KEY } from '../../../modules/patient-data'
import { PatientDataDTO } from '../../../modules/patient-data/models/PatientDataDTO'
import { IUserService } from '../../../modules/users'
import { FamiliarDataTable } from './FamiliarDataTable'
import { LandmarkTable } from './LandmarkTable'
import { GrowthMonitoringTable } from './GrowthMonitoringTable'
import { getUserCircleContainer } from '../../../container/user-circle-module'
import {
  IUserCircleActiveService,
  USER_CIRCLE_ACTIVE_SERVICE_KEY,
} from '../../../modules/user-circle'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATIENT_DATA } from '../../../routes/routes-constants'

const patientDataService = getPatientDataContainer().get<PatientDataService>(PATIENT_DATA_API_KEY)
const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)

const UserCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)

export function MedicalData() {
  const { t } = useTranslation()
  const activeUser = UserCircleActiveService.getActiveFullUserCircle()
  const navigate = useNavigate()

  const medicalCard = ['patientData', 'familiar-data', 'landmarks', 'growth-monitoring']
  const [data, setData] = useState<PatientDataDTO>()
  const [address, setAddress] = useState<string>('')
  const [town, setTown] = useState<string>('')

  useEffect(() => {
    if (!activeUser?.user?.cip) {
      return
    }
    patientDataService.getByCIPA(activeUser?.user.cip).subscribe((res) => {
      let PD: PatientDataDTO
      if (!res) return
      PD = res
      patientDataService.getSeleneIndicators(activeUser?.user.cip).subscribe((resS) => {
        if (!resS) {
          setData(PD)
          return
        }
        let oP = ''
        let kV = ''
        let bH = ''
        let bHV = ''
        let pX = ''
        resS.indicators.forEach((i) => {
          switch (i.id) {
            case 'NEO_PROFILOCULAR':
              oP = i.value
              break
            case 'RN_ADMVITK':
              kV = i.value
              break
            case 'NEO_DOGAMMA_HepB':
              bH = i.value
              break
            case 'NEO_DOVACU_HepB':
              bHV = i.value
              break
            case 'CRIB_PULSI_RESULT':
              pX = i.value
              break
            default:
              break
          }
        })
        PD = {
          ...PD,
          zero2One: {
            nif: PD.zero2One.nif,
            centerName: PD.zero2One.centerName,
            cipa: PD.zero2One.cipa,
            phone: PD.zero2One.phone,
            controlledPregnancy: PD.zero2One.controlledPregnancy,
            gestationType: PD.zero2One.gestationType,
            uniqueFetus: PD.zero2One.uniqueFetus,
            observations: PD.zero2One.observations,
            bloodType: PD.zero2One.bloodType,
            meconium: PD.zero2One.meconium,
            lactation: PD.zero2One.lactation,
            metabolic: PD.zero2One.metabolic,
            metabolicDate: PD.zero2One.metabolicDate,
            deafness: PD.zero2One.deafness,
            deafnessDate: PD.zero2One.deafnessDate,
            ocularProfilax: oP,
            kVitamin: kV,
            bHepatitis: bH,
            bHepatitisVaccine: bHV,
            pulsioximetria: pX,
            height: PD.zero2One.height,
            heightDate: PD.zero2One.heightDate,
            weight: PD.zero2One.weight,
            weightDate: PD.zero2One.weightDate,
            cranialPerimeter: PD.zero2One.cranialPerimeter,
            cranialPerimeterDate: PD.zero2One.cranialPerimeterDate,
          },
        }
        setData(PD)
      })
    })

    userService
      .getCitizenData({
        id: activeUser?.user.cip,
        typeData: 'cim',
      })
      .subscribe((res) => {
        if (!res) {
          setAddress('Calle prueba, nº123 3º A')
          setTown('El Palmar')
          return
        }
        let addressAux = ''
        if (res.Body.CitizenConsResponse.DataCitizen.Citizen.Address) {
          if (res.Body.CitizenConsResponse.DataCitizen.Citizen.Address.RoadType)
            addressAux = res.Body.CitizenConsResponse.DataCitizen.Citizen.Address.RoadType
          if (res.Body.CitizenConsResponse.DataCitizen.Citizen.Address.RoadName)
            addressAux =
              addressAux + ' ' + res.Body.CitizenConsResponse.DataCitizen.Citizen.Address.RoadName
          if (res.Body.CitizenConsResponse.DataCitizen.Citizen.Address.RoadNumber)
            addressAux =
              addressAux + ' ' + res.Body.CitizenConsResponse.DataCitizen.Citizen.Address.RoadNumber
          if (res.Body.CitizenConsResponse.DataCitizen.Citizen.Address.Other)
            addressAux =
              addressAux + ' ' + res.Body.CitizenConsResponse.DataCitizen.Citizen.Address.Other
          setAddress(addressAux)
          res.Body.CitizenConsResponse.DataCitizen.Citizen.Address.CityName &&
            setTown(res.Body.CitizenConsResponse.DataCitizen.Citizen.Address.CityName)
        }
      })
  }, [])

  const replaceString = (item: string): string => {
    const index = item.indexOf('-')
    if (index > -1) {
      return (
        item.slice(0, index) +
        item.replace('-', '').charAt(index).toUpperCase() +
        item.slice(index + 2)
      )
    }
    return item
  }

  const goBack = () => navigate(ROUTE_PATIENT_DATA)

  const CircleMedicalData = () => {
    return (
      <Box mb={3} className={styles.listContainer} style={{ cursor: 'auto' }}>
        <Table>
          <TableBody>
            <TableRow className={stylePatient.row}>
              <TableCell>
                <p className={stylePatient.label}>{t('fullName')}</p>
              </TableCell>
              <TableCell className={stylePatient.textFieldBox}>
                {activeUser?.user.name} {activeUser?.user.lastname}
              </TableCell>
            </TableRow>
            <TableRow className={stylePatient.row}>
              <TableCell>
                <p className={stylePatient.label}>{t('birthDate')}</p>
              </TableCell>
              <TableCell className={stylePatient.textFieldBox}>
                {dayjs(activeUser?.user?.birthDate).format('DD/MM/YYYY')}
              </TableCell>
            </TableRow>
            <TableRow className={stylePatient.row}>
              <TableCell>
                <p className={stylePatient.label}>{t('Address')}</p>
              </TableCell>
              <TableCell className={stylePatient.textFieldBox}>{address}</TableCell>
            </TableRow>
            <TableRow className={stylePatient.row}>
              <TableCell>
                <p className={stylePatient.label}>{t('Town')}</p>
              </TableCell>
              <TableCell className={stylePatient.textFieldBox}>{town}</TableCell>
            </TableRow>
            <TableRow className={stylePatient.row}>
              <TableCell>
                <p className={stylePatient.label}>{t('Phone')}</p>
              </TableCell>
              <TableCell className={stylePatient.textFieldBox}>
                {activeUser?.user ? activeUser?.user.phone : ''}
              </TableCell>
            </TableRow>
            <TableRow className={stylePatient.row}>
              <TableCell>
                <p className={stylePatient.label}>{t('Clinic')}</p>
              </TableCell>
              <TableCell className={stylePatient.textFieldBox}>
                {data ? data.zero2One.centerName : ''}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <p style={{ border: '1px solid #68b3e0', margin: '0px 0px 0px' }} />

        {data && <ExpandedMedicalData data={data} />}
      </Box>
    )
  }
  const medicalCardTable = [
    <CircleMedicalData key={uuidv4()} />,
    <FamiliarDataTable key={uuidv4()} />,
    <LandmarkTable key={uuidv4()} />,
    <GrowthMonitoringTable key={uuidv4()} />,
  ]

  const [expanded, setExpanded] = React.useState<number | false>(false)
  const handleChange = (panel: number) => (event: React.ChangeEvent<any>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Box className={genericStyle.pageContainer}>
      <Box mb={3} display={'flex'} justifyContent={'space-between'}>
        <AppButton
          theme={ButtonTheme.NewSecondary}
          type={'button'}
          label={t('back')}
          handler={goBack}
        />
      </Box>
      {medicalCard.map((item, index) => (
        <Accordion
          key={item + index}
          elevation={0}
          style={{ padding: 0 }}
          expanded={expanded === index}
          onChange={handleChange(index)}
        >
          <AccordionSummary
            sx={{
              '& ': {
                color: 'white',
                backgroundColor: '#68b3e0',
                borderRadius: '10px',
                marginBottom: '10px',
                marginLeft: '0px',
                marginRight: '0px',
                padding: '0px 10px 0px',
                height: '20px',
                width: '100%',
                fontFamily: 'Poppins',
              },
            }}
            expandIcon={<img src={downBlanco} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {t(replaceString(item))}
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: '0px 0px 0px',
            }}
          >
            <Box className={styles.accordionDetails}>{medicalCardTable[index]}</Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  )
}
