/* tslint:disable */
//@ts-nocheck
import React, { Ref, PropsWithChildren } from 'react'
import ReactDOM from 'react-dom'

interface BaseProps {
  className: string
  [key: string]: unknown
}

export const Button = React.forwardRef(function Button(
  {
    active,
    reversed,
    ...props
  }: PropsWithChildren<
    {
      active: boolean
      reversed: boolean
    } & BaseProps
  >,
  ref: Ref<HTMLButtonElement>
) {
  return (
    <button
      {...props}
      ref={ref}
      style={{
        cursor: 'pointer',
        color: `${reversed ? (active ? 'white' : '#aaa') : active ? 'black' : '#ccc'}`,
      }}
    />
  )
})

export const EditorValue = React.forwardRef(
  (
    {
      className,
      value,
      ...props
    }: PropsWithChildren<
      {
        value: any
      } & BaseProps
    >,
    ref: Ref<HTMLDivElement>
  ) => {
    const textLines = value.document.nodes
      .map((node: { text: any }) => node.text)
      .toArray()
      .join('\n')
    return (
      <div
        ref={ref}
        {...props}
        style={{
          margin: '30px -20px 0',
        }}
      >
        <div
          style={{
            fontSize: '14px',
            padding: '5px 20px',
            color: '#404040',
            borderTop: '2px solid #eeeeee',
            background: ' #f8f8f8',
          }}
        >
          Slate's value as text
        </div>
        <div
          style={{
            color: '#404040',
            font: '12px monospace',
            whiteSpace: 'pre-wrap',
            padding: '10px 20px',
          }}
        >
          {textLines}
        </div>
      </div>
    )
  }
)

export const Icon = React.forwardRef(
  ({ className, ...props }: PropsWithChildren<BaseProps>, ref: Ref<HTMLSpanElement>) => (
    <span
      {...props}
      ref={ref}
      style={{
        fontSize: '18px',
        verticalAlign: 'text-bottom',
      }}
    />
  )
)

export const Instruction = React.forwardRef(
  ({ className, ...props }: PropsWithChildren<BaseProps>, ref: Ref<HTMLDivElement>) => (
    <div
      {...props}
      ref={ref}
      style={{
        whiteSpace: 'pre-wrap',
        margin: '0 -20px 10px',
        padding: '10px 20px',
        fontSize: '14px',
        background: '#f8f8e8',
      }}
    />
  )
)

export const Menu = React.forwardRef(
  ({ className, ...props }: PropsWithChildren<BaseProps>, ref: Ref<HTMLDivElement>) => (
    <div {...props} ref={ref} />
  )
)

export const Portal = ({ children }: any) => {
  return typeof document === 'object' ? ReactDOM.createPortal(children, document.body) : null
}

export const Toolbar = React.forwardRef(
  ({ className, ...props }: PropsWithChildren<BaseProps>, ref: Ref<HTMLDivElement>) => (
    <Menu
      {...props}
      ref={ref}
      className={{
        position: 'relative',
        padding: '1px 18px 17px',
        margin: ' 0 - 20px',
        borderBottom: '2px solid #eee',
        marginBottom: '20px',
      }}
    />
  )
)
