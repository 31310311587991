export enum ChartEnum {
  Bar = 'bar',
  Line = 'line',
  Pie = 'pie',
  Doughnut = 'doughnut',
  Radar = 'radar',
  PolarArea = 'polarArea',
  Bubble = 'bubble',
  Scatter = 'scatter',
  Area = 'area',
  Mixed = 'mixed',
  HorizontalBar = 'horizontalBar',
  StackedBar = 'stackedBar',
  StackedHorizontalBar = 'stackedHorizontalBar',
  StackedArea = 'stackedArea',
  StackedLine = 'stackedLine',
  StackedHorizontalLine = 'stackedHorizontalLine',
  StackedRadar = 'stackedRadar',
  StackedPolarArea = 'stackedPolarArea',
  StackedBubble = 'stackedBubble',
  StackedScatter = 'stackedScatter',
  StackedMixed = 'stackedMixed',
  StackedDoughnut = 'stackedDoughnut',
  StackedPie = 'stackedPie',
  StackedHorizontalPie = 'stackedHorizontalPie',
  StackedHorizontalDoughnut = 'stackedHorizontalDoughnut',
  StackedHorizontalArea = 'stackedHorizontalArea',
  StackedHorizontalBarArea = 'stackedHorizontalBarArea',
  StackedHorizontalLineArea = 'stackedHorizontalLineArea',
  StackedHorizontalRadarArea = 'stackedHorizontalRadarArea',
  StackedHorizontalPolarArea = 'stackedHorizontalPolarArea',
  StackedHorizontalBubbleArea = 'stackedHorizontalBubbleArea',
  StackedHorizontalScatterArea = 'stackedHorizontalScatterArea',
  StackedHorizontalMixedArea = 'stackedHorizontalMixedArea',
  StackedHorizontalDoughnutArea = 'stackedHorizontalDoughnutArea',
  StackedHorizontalPieArea = 'stackedHorizontalPieArea',
  StackedHorizontalBarLine = 'stackedHorizontalBarLine',
  StackedHorizontalLineLine = 'stackedHorizontalLineLine',
  StackedHorizontalRadarLine = 'stackedHorizontalRadarLine',
  StackedHorizontalPolarLine = 'stackedHorizontalPolarLine',
  StackedHorizontalBubbleLine = 'stackedHorizontalBubbleLine',
  StackedHorizontalScatterLine = 'stackedHorizontalScatterLine',
  StackedHorizontalMixedLine = 'stackedHorizontalMixedLine',
  StackedHorizontalDoughnutLine = 'stackedHorizontalDoughnutLine',
}
