export type CityHallContactProps = {
  id: string
  name: string
  email: string
  phone: string
}

export class CityHallContact {
  private readonly _id: string
  private _name: string
  private _email: string
  private _phone: string

  constructor(props: CityHallContactProps) {
    this._id = props.id
    this._name = props.name
    this._email = props.email
    this._phone = props.phone
  }

  get id(): string {
    return this._id
  }

  get name(): string {
    return this._name
  }

  set name(value: string) {
    this._name = value
  }

  get email(): string {
    return this._email
  }

  set email(value: string) {
    this._email = value
  }

  get phone(): string {
    return this._phone
  }

  set phone(value: string) {
    this._phone = value
  }
}
