import { SxProps } from '@mui/material'

export const dividerStyle: SxProps = {
  width: '100%',
  marginBottom: 4,
}

export const TypographyStyle: SxProps = {
  fontWeight: 'bold',
  fontSize: '1.2rem',
  fontFamily: 'Montserrat, sans-serif',
  textAlign: 'left',
}
