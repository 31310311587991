// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FamiliarData_familiarDataBox__3QRWn {\n  height: 40px;\n}\n\n@media (max-width: 320px) {\n  .FamiliarData_growthBox__2KrLB {\n    height: 95px;\n  }\n}\n\n@media (min-width: 321px) {\n  .FamiliarData_growthBox__2KrLB {\n    height: 92px;\n  }\n}\n\n@media (min-width: 600px) {\n  .FamiliarData_growthBox__2KrLB {\n    height: 68px;\n  }\n}\n\n@media (min-width: 1024px) {\n  .FamiliarData_growthBox__2KrLB {\n    height: 93px;\n  }\n}\n\n@media (min-width: 1280px) {\n  .FamiliarData_growthBox__2KrLB {\n    height: 50px;\n    width: 70px;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/features/patient-data/medicalData/FamiliarData.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,YAAY;IACZ,WAAW;EACb;AACF","sourcesContent":[".familiarDataBox {\n  height: 40px;\n}\n\n@media (max-width: 320px) {\n  .growthBox {\n    height: 95px;\n  }\n}\n\n@media (min-width: 321px) {\n  .growthBox {\n    height: 92px;\n  }\n}\n\n@media (min-width: 600px) {\n  .growthBox {\n    height: 68px;\n  }\n}\n\n@media (min-width: 1024px) {\n  .growthBox {\n    height: 93px;\n  }\n}\n\n@media (min-width: 1280px) {\n  .growthBox {\n    height: 50px;\n    width: 70px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"familiarDataBox": "FamiliarData_familiarDataBox__3QRWn",
	"growthBox": "FamiliarData_growthBox__2KrLB"
};
export default ___CSS_LOADER_EXPORT___;
