// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QuestionsForm_container__10ubf {\n  margin-top: 20px;\n  margin-bottom: 32px;\n}\n.QuestionsForm_title__2heX9 {\n  font-family: 'Poppins', sans-serif !important;\n  font-size: 18px !important;\n  color: #68b3e0;\n  font-weight: bold !important;\n}\n\n.QuestionsForm_moveIcon__WTIRv {\n  margin-right: 10px;\n}\n", "",{"version":3,"sources":["webpack://src/features/circle-configurator/add-new-circle/view 4 Forms/add-new-form-modals/QuestionsForm.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,6CAA6C;EAC7C,0BAA0B;EAC1B,cAAc;EACd,4BAA4B;AAC9B;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".container {\n  margin-top: 20px;\n  margin-bottom: 32px;\n}\n.title {\n  font-family: 'Poppins', sans-serif !important;\n  font-size: 18px !important;\n  color: #68b3e0;\n  font-weight: bold !important;\n}\n\n.moveIcon {\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "QuestionsForm_container__10ubf",
	"title": "QuestionsForm_title__2heX9",
	"moveIcon": "QuestionsForm_moveIcon__WTIRv"
};
export default ___CSS_LOADER_EXPORT___;
