// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ActivePlanCard_card__2MQv8 {\n  height: 100%;\n  margin-right: 8px;\n}\n\n.ActivePlanCard_containerCard__1evGJ {\n  display: flex;\n  flex-direction: column;\n}\n\n.ActivePlanCard_rowSpaceBetween__3DHrJ {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}", "",{"version":3,"sources":["webpack://src/features/dashboard/patient/active-plan-card/ActivePlanCard.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AACA","sourcesContent":[".card{\n  height:100%;\n  margin-right: 8px;\n}\n\n.containerCard{\n  display:flex;\n  flex-direction: column;\n}\n\n.rowSpaceBetween{\ndisplay: flex;\nflex-direction: row;\njustify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "ActivePlanCard_card__2MQv8",
	"containerCard": "ActivePlanCard_containerCard__1evGJ",
	"rowSpaceBetween": "ActivePlanCard_rowSpaceBetween__3DHrJ"
};
export default ___CSS_LOADER_EXPORT___;
