export const validExtensions =
  '.pdf, .xml, .txt, .html, .doc, .docx, .odt, .ods, .odp, .odg,' +
  ' .dotx, .docm, .dotm, .xls, .xlsx, .xltx, .xlsm, .xltm, .xlam, .xlsb, .ppt, .pptx, .potx,' +
  ' .ppsx, .ppam, .pptm, .potm, .ppsm,' +
  '.mpp, .vsd, .rtf, .jpeg, .png, .gif, .tif,' +
  ' .svf, .svg, .p12, .cert, .ps, .bz, .bz2, .zip, .gzip, .rar, .rm,' +
  ' .aif, .au, .midi, .mpg, .mp3,' +
  ' .wmv, .mkv, .avi, .mp4,' +
  '.dvi, .avi, .mov, .bin, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, ' +
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template, application/vnd.ms-word.document.macroEnabled.12,' +
  'application/vnd.ms-word.template.macroEnabled.12, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,' +
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template, application/vnd.ms-excel.sheet.macroEnabled.12,' +
  'application/vnd.ms-excel.template.macroEnabled.12, application/vnd.ms-excel.addin.macroEnabled.12,' +
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12, application/vnd.ms-powerpoint, ' +
  'application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.presentationml.template,' +
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.ms-powerpoint.addin.macroEnabled.12,' +
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12, application/vnd.ms-powerpoint.template.macroEnabled.12,' +
  'application/vnd.ms-powerpoint.slideshow.macroEnabled.12'
