import React, { useState } from 'react'
import { Action, Search, SearchValue } from '../table_type/types'
import { Box } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useTranslation } from 'react-i18next'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { makeStyles } from '@mui/styles'

type SearchProps<Q> = {
  readonly search: Search<Q>
  readonly autocompleteItems?: string[]
  readonly autocompleteLabel?: string
  readonly autocompleteAction?: Action
  disabled?: boolean
  isPreventDefault?: boolean
}

const useStyles = makeStyles(() => ({
  label: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '16px',
  },
  root: {
    fontFamily: 'Poppins',
    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
      fontFamily: 'Poppins',
    },
  },
  font: {
    fontFamily: 'Poppins',
  },
}))

export function Searcher<Q extends Record<string, any>>(props: SearchProps<Q>) {
  const { t } = useTranslation()

  const [values, setValues] = useState<SearchValue<Q>[]>(props.search.searchValues)
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (props.isPreventDefault) {
      e.preventDefault()
    }
    props.search.handleSearch([...values])
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    values.find((sv) => e.target.name === sv.name)!.value = e.target.value
    setValues([...values])
    props.search.handleSearch([...values])
  }

  const handleDate = (date: Date | null) => {
    if (date == null) {
      try {
        values.forEach((sv) => {
          if (sv.name === 'birthDate') sv.value = ''
          if (sv.name === 'symptomStartDate') sv.value = ''
        })
        setValues([...values])
        props.search.handleSearch([...values])
      } catch (e) {
        console.error(e)
      }
    } else {
      try {
        const dateValue = new Date(date).toDateString()
        values.forEach((sv) => {
          if (sv.name === 'birthDate') sv.value = dateValue
          if (sv.name === 'symptomStartDate') sv.value = dateValue
        })
        setValues([...values])
        props.search.handleSearch([...values])
      } catch (e) {
        console.error(e)
      }
    }
  }

  const classes = useStyles()

  return (
    // <form onSubmit={(event) => handleSubmit(event)}>
      <Box marginBottom="5px">
        <Box display="flex" justifyContent={'flex-end'} flexWrap="wrap">
          {values.map((v) =>
            v.type === 'date' ? (
              <Box
                mr={2}
                width={v.width}
                marginBottom={'5px'}
                marginRight={'5px'}
                key={v.name as string}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                  <DatePicker
                    key={'date'}
                    PopperProps={{
                      sx: {
                        '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                          fontFamily: 'Poppins',
                          textTransform: 'capitalize',
                        },
                        '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                          fontFamily: 'Poppins',
                        },
                        '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                          fontFamily: 'Poppins',
                        },
                        '& .css-3eghsz-PrivatePickersYear-button': {
                          fontFamily: 'Poppins',
                        },
                      },
                    }}
                    onError={(reason, value) => {
                      switch (reason) {
                        case 'invalidDate':
                          setDateTimePickerError(t('invalidDateMessage'))
                          break
                        case 'minDate':
                          setDateTimePickerError(t('minDateMessage'))
                          break
                      }
                    }}
                    renderInput={(props) => (
                      <TextField
                        disabled={props.disabled}
                        InputProps={{
                          classes: {
                            root: classes.root,
                            input: classes.label,
                          },
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.label,
                          },
                        }}
                        sx={{
                          '.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input': {
                            fontFamily: 'Poppins',
                          },
                          '.MuiInputBase-input': {
                            fontFamily: 'Poppins',
                          },
                          '.MuiOutlinedInput-input': {
                            fontFamily: 'Poppins',
                          },
                          '.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root': {
                            fontFamily: 'Poppins',
                          },
                          '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                            fontFamily: 'Poppins',
                          },
                          '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                            fontFamily: 'Poppins',
                          },
                        }}
                        style={
                          window.innerWidth < 378
                            ? { width: '120px' }
                            : window.innerWidth < 490
                              ? { width: '150px' }
                              : { width: '220px' }
                        }
                        size={'small'}
                        helperText={props.error && DateTimePickerError}
                        {...props}
                      />
                    )}
                    inputFormat="DD/MM/YYYY"
                    onChange={handleDate}
                    value={v.value ? new Date(v.value) : null}
                  />
                </LocalizationProvider>
              </Box>
            ) : (
              <Box
                mr={2}
                width={v.width}
                marginBottom={'5px'}
                marginRight={'5px'}
                key={v.name as string}
              >
                <TextField
                  disabled={props.disabled}
                  sx={{
                    '& .MuiFormLabel-root': {
                      fontFamily: 'Poppins',
                      zIndex: 0,
                    },
                    '& .MuiInputBase-input': {
                      fontFamily: 'Poppins',
                    },
                    '& .MuiOutlinedInput-input': {
                      fontFamily: 'Poppins',
                    },
                    '& .MuiInputBase-root.MuiOutlinedInput-root': {
                      fontFamily: 'Poppins',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      fontFamily: 'Poppins',
                    },
                  }}
                  InputProps={{
                    style: { fontFamily: 'Poppins' },
                    classes: {
                      root: classes.root,
                      input: classes.label,
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                    },
                  }}
                  name={v.name as string}
                  label={v.label}
                  style={{
                    width:
                      window.innerWidth < 378
                        ? '120px'
                        : window.innerWidth < 490
                          ? '150px'
                          : '220px',
                    fontFamily: 'Poppins',
                  }}
                  onChange={handleChange}
                  type={v.type ?? 'text'}
                  value={v.value ?? ''}
                  variant="outlined"
                  size="small"
                />
              </Box>
            )
          )}
          {props.autocompleteAction && props.autocompleteItems && (
            <Autocomplete
              // FIXME check this??
              onChange={props.autocompleteAction.handleAction}
              disablePortal
              options={props.autocompleteItems}
              style={
                window.innerWidth < 378
                  ? { width: '120px' }
                  : window.innerWidth < 490
                    ? { width: '150px' }
                    : { width: '220px' }
              }
              renderInput={(params) => (
                <TextField
                  sx={{
                    '.css-1pysi21-MuiFormLabel-root-MuiInputLabel-root': {
                      fontFamily: 'Poppins',
                    },
                    '.MuiInputBase-input': {
                      fontFamily: 'Poppins',
                    },
                    '.MuiOutlinedInput-input': {
                      fontFamily: 'Poppins',
                    },
                    '.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root': {
                      fontFamily: 'Poppins',
                    },
                    '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                      fontFamily: 'Poppins',
                    },
                    '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                      fontFamily: 'Poppins',
                    },
                  }}
                  {...params}
                  label={props.autocompleteLabel}
                  variant="outlined"
                  size="small"
                />
              )}
              classes={{
                option: classes.font,
                groupLabel: classes.font,
              }}
            />
          )}
        </Box>
      </Box>
    // </form>
  )
}
