import { PatientSymptomCondition } from './PatientSymptomCondition'

export type SymptomProps = {
  id: string | undefined
  symptomID: string
  userID: string
  userCircleID: string
  symptomStartDate: Date
  finishDate: Date
  symptomType: string
  comment: string
  value: string
  idIcon: string
  creatorID: string
  isUploaded: boolean
  conditions: PatientSymptomCondition[]
}

export class PatientSymptom {
  private _id: string | undefined
  private readonly _symptomID: string
  private readonly _userID: string
  private readonly _creatorID: string

  private _comment: string
  private _value: string
  private _idIcon: string
  private _userCircleID: string
  private _isUploaded: boolean
  private _symptomStartDate: Date
  private _finishDate: Date
  private _symptomType: string
  private _conditions: PatientSymptomCondition[]

  constructor(p: SymptomProps) {
    this._id = p.id
    this._symptomID = p.symptomID
    this._userID = p.userID
    this._symptomStartDate = p.symptomStartDate
    this._finishDate = p.finishDate
    this._symptomType = p.symptomType
    this._comment = p.comment
    this._value = p.value
    this._idIcon = p.idIcon
    this._creatorID = p.creatorID
    this._isUploaded = p.isUploaded
    this._conditions = p.conditions
    this._userCircleID = p.userCircleID
  }

  get id(): string | undefined {
    return this._id
  }

  set id(value: string | undefined) {
    this._id = value
  }

  get symptomID(): string {
    return this._symptomID
  }

  get userID(): string {
    return this._userID
  }

  get creatorID(): string {
    return this._creatorID
  }

  get comment(): string {
    return this._comment
  }

  set comment(value: string) {
    this._comment = value
  }

  get userCircleID(): string {
    return this._userCircleID
  }

  set userCircleID(value: string) {
    this._userCircleID = value
  }

  get isUploaded(): boolean {
    return this._isUploaded
  }

  get value(): string {
    return this._value
  }

  set value(value: string) {
    this._value = value
  }

  get idIcon(): string {
    return this._idIcon
  }

  set idIcon(value: string) {
    this._idIcon = value
  }

  get symptomStartDate(): Date {
    return this._symptomStartDate
  }

  set symptomStartDate(value: Date) {
    this._symptomStartDate = value
  }

  get finishDate(): Date {
    return this._finishDate
  }

  set finishDate(value: Date) {
    this._finishDate = value
  }

  get symptomType(): string {
    return this._symptomType
  }

  set symptomType(value: string) {
    this._symptomType = value
  }

  get conditions(): PatientSymptomCondition[] {
    return this._conditions
  }

  set conditions(value: PatientSymptomCondition[]) {
    this._conditions = value
  }
}

export interface PatientSymptomQuery {
  symptomIDs: string[]
  name: string
  symptomStartDate: Date
  finishDate: Date
  creatorIDs: string[]
  creatorName: string
  creatorID: string
  symptomType: string
  userCircleID: string
  userID: string
  symptomTypeName: string
}
