import { Likes } from '../models/Likes'
import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { ItemList } from '../../../common/models/ItemList'
import { IStatusService } from '../../../common/status/StatusService'
import { ILikesApi } from '../api/LikesApi'
import { LikesDTO } from '../models/LikesDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'

type Props = {
  apiKey: symbol
}

export interface ILikesService extends IInit {
  getByID(id: string): Observable<Likes | undefined>

  getLikesByArticleID(id: string): Observable<ItemList<Likes>>

  getCheckLikeExist(artId: string, userId: string): Observable<any>

  getLikesByUserID(id: string): Observable<ItemList<Likes>>

  add(e: LikesDTO): Observable<Likes | undefined>

  update(e: LikesDTO): Observable<Likes | undefined>

  delete(id: string): Observable<boolean>
}

export class LikesService implements ILikesService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: ILikesApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container): void {
    this._container = c
    this._api = this._container.get<ILikesApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: LikesDTO): Observable<Likes | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<Likes | undefined> {
    return this._api.getByID(id)
  }

  getLikesByArticleID(id: string | undefined): Observable<ItemList<Likes>> {
    return this._api.getLikesByArticleID(id)
  }

  getCheckLikeExist(artId: string, userId: string): Observable<any | undefined> {
    return this._api.getCheckLikeExist(artId, userId)
  }

  getLikesByUserID(id: string): Observable<ItemList<Likes>> {
    return this._api.getLikesByUserID(id)
  }

  update(e: LikesDTO): Observable<Likes | undefined> {
    return this._api.update(e)
  }
}
