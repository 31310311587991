import { Notification } from '../models/Notification'
import { INotificationApi } from '../api/NotificationApi'
import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { IStatusService } from '../../../common/status/StatusService'
import { DeliveryStatus } from '../enums/DeliveryStatus'
import { ItemList } from '../../../common/models/ItemList'
import { STATUS_SERVICE_KEY } from '../../../container/app'

type Props = {
  apiKey: symbol
}

export interface INotificationService extends IInit {
  getByID(id: string): Observable<Notification | undefined>

  getFilteredItems(q: Query<Notification>): Observable<Notification[]>

  add(e: Notification): Observable<Notification | undefined>

  update(e: Notification): Observable<Notification | undefined>

  updateStatus(id: string, status: DeliveryStatus): Observable<Notification | undefined>

  getFilteredList(q: Query<Notification>): Observable<ItemList<Notification>>

  updateStatusByMessage(id: string): Observable<Notification | undefined>

  getBySegmentedId(id: string): Observable<ItemList<Notification>>
}

export class NotificationService implements INotificationService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: INotificationApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  getByID(id: string): Observable<Notification | undefined> {
    return this._api.getByID(id)
  }

  getFilteredItems(q: Query<Notification>): Observable<Notification[]> {
    return this._api.getFilteredItems(q).pipe()
  }

  add(e: Notification): Observable<Notification | undefined> {
    return this._api.add(e)
  }

  update(e: Notification): Observable<Notification | undefined> {
    return this._api.update(e)
  }

  updateStatus(id: string, status: DeliveryStatus): Observable<Notification | undefined> {
    return this._api.updateStatus(id, status)
  }

  updateStatusByMessage(id: string): Observable<Notification | undefined> {
    return this._api.updateStatusByMessage(id)
  }
  getBySegmentedId(id: string): Observable<ItemList<Notification>> {
    return this._api.getBySegmentedId(id)
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<INotificationApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getFilteredList(q: Query<Notification>): Observable<ItemList<Notification>> {
    return this._api.getFilteredList(q)
  }
}
