import React from 'react'
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material'
import { Actions, Field, Sort } from '../table_type/types'

type HeadProps<T> = {
  readonly fields: Field<T>[]
  readonly sort?: Sort<T>
  readonly actions?: Actions<T>
}

export function Head<T extends Record<string, any>>(props: HeadProps<T>) {
  const handleSort = (name: keyof T) => () => props.sort?.handleSort && props.sort.handleSort(name)

  const tableCell = (
    <TableCell
      sx={{
        border: 'none',
        fontWeight: 'bold',
        color: '#515151',
        fontSize: '14px',
        fontFamily: 'Montserrat-SemiBold, sans-serif',
      }}
    ></TableCell>
  )

  const headCells = Object.values(props.fields).map((f, index) => (
    <TableCell
      sx={{
        border: 'none',
        fontWeight: 'bold',
        color: '#515151',
        fontSize: '14px',
        fontFamily: 'Montserrat-SemiBold, sans-serif',
        '&:second-child': {
          paddingLeft: '20px',
        },
      }}
      key={f.name as string}
    >
      {f.sortable ? (
        <TableSortLabel
          active={f.name === props.sort?.name}
          direction={props.sort?.direction ?? 'asc'}
          onClick={handleSort(f.name)}
        >
          {f.label}
        </TableSortLabel>
      ) : (
        f.label
      )}
    </TableCell>
  ))

  return (
    <TableHead>
      <TableRow>
        {tableCell}
        {headCells}
      </TableRow>
    </TableHead>
  )
}
