import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { Avatar, Box, Card, CardContent, Divider, Grid, Tab, Tabs, Typography } from '@mui/material'
import genericStyle from '../../../common/utils/generic.module.css'
import {
  ROUTE_GOALS_FORM,
  ROUTE_LINK_WEARABLE,
  ROUTE_PATIENT_DATA,
} from '../../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import { WearableType, wereableTypes } from '../../../modules/patient-data/enums/WearableType'
import styles from './Wearables.module.css'
import { GenericChart } from '../../../components/generic-chart/GenericChart'
import { getUserContainer } from '../../../container/user-module'
import { WearableService } from '../../../modules/patient-data/services/WearableService'
import { WEARABLE_SERVICE_KEY } from '../../../modules/patient-data/container'
import { getPatientDataContainer } from '../../../container/patient-data-module'
import { Query, QueryParam } from '../../../common/api/Query'
import { Wearable, WearableQuery } from '../../../modules/patient-data/models/Wearable'
import { ICircleService } from '../../../modules/users/services/CircleService'
import { CIRCLE_SERVICE_KEY, LOGGED_USER_SERVICE_KEY } from '../../../modules/users/container'
import { LoggedUserService } from '../../../modules/users/services/LoggedUserService'
import { Permission } from '../../../common/permission'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useGetActiveUser } from '../../../hooks/get-active-user/useGetActiveUser'
import {
  avatarStyle, dividerStyle, TypographyProfileText,
  TypographyProfileTitleInformation,
} from '../../dashboard/patient/monitored-activity-card/MonitoredActivityCardStyleMui'
import stepIcon from '../../../assets/dashboard/stepsIcon.svg'
import caloriesIcon from '../../../assets/dashboard/caloriesIcon.svg'
import graphIcon from '../../../assets/patient_data/ico-evolutivo.svg'
import ProgressSemiCircle from '../../../components/dashboard/circle-progress/SemiCircleProgress'
import ProgressCircle from '../../../components/dashboard/circle-progress/CircleProgress'
import { BarChart, LineChart } from '@mui/x-charts'

// import wereablesData from "./mock.json"
import { dateToYYYYMMDD } from '../../../common/utils/date'
import { Header } from '../../../components/header/Header'
import Button from '@mui/material/Button'

const wearableTypes = wereableTypes()

const patientDataContainer = getPatientDataContainer()
const wearableService = patientDataContainer.get<WearableService>(WEARABLE_SERVICE_KEY)

const loggedUserService = getUserContainer().get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

const xLabels = [
  '09:00',
  '12:00',
  '15:00',
  '18:00',
  '21:00',
]

const heartRate = [2, 4, 60, 75, 90]

export const Graphs = ({ data, returnToWearables, type}: any) => {
  const circle = getUserContainer().get<ICircleService>(CIRCLE_SERVICE_KEY).getActiveUser()

  const { t } = useTranslation()

  const [tabValue, setTabValue] = useState<number>(0)
  const [fromDate, setFromDate] = useState<Date>(
    new Date(new Date().setDate(new Date().getDate() - 7)),
  )
  const [toDate, setToDate] = useState<Date>(new Date())
  const [wearableResult, setWearableResult] = useState<any>([])
  const [dataGraphX, setDataGraphX] = useState<any>([])
  const [dataGraphY, setDataGraphY] = useState<any>([])
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')
  const [yAxisLabel, setYAxisLabel] = useState<string>('Y')
  const [measureType, setMeasureType] = useState<number>(0)
  const [isBarChart, setGraphType] = useState<boolean>(true)
  const navigate = useNavigate()

  useEffect(() => {
    switch (type){
      case "steps":
        setYAxisLabel(t("steps"))
        setMeasureType(1)
        break;
      case "calories":
        setYAxisLabel("KCal")
        setMeasureType(2)
        break;
      case "distance":
        setYAxisLabel("Km")
        setMeasureType(3)
        break;
      case "sleepHours":
        setYAxisLabel(t("hours"))
        setMeasureType(4)
        break;
      case "pulse2":
        setYAxisLabel("Lat/min")
        setGraphType(false)
        setMeasureType(5)
        break;
      case "bloodPreasure":
        setYAxisLabel("mmHg")
        setGraphType(false)
        setMeasureType(9)
        break;
      case "fat":
        setYAxisLabel("% Grasa corporal")
        setMeasureType(8)
        break;
      case "weight":
        setYAxisLabel("Kg")
        setMeasureType(7)
        break;
      case "temperature":
        setYAxisLabel("ºC")
        setGraphType(false)
        setMeasureType(10)
        break;
    }
  }, [])

  useEffect(() => {
    if(wearableResult.fitbitResponse)
    switch (type) {
      case "steps":
        parseData(wearableResult.fitbitResponse["activities-steps"]);
        break;
      case "calories":
        parseData(wearableResult.fitbitResponse["activities-activityCalories"]);
        break;
      case "distance":
        parseData(wearableResult.fitbitResponse["activities-distance"]);
        break;
      case "sleepHours":
        parseData(wearableResult.fitbitResponse["sleep"]);
        break;
      case "pulse2":
        parseData(wearableResult.fitbitResponse["activities-heart"]);
        break;
      case "bloodPreasure":
        parseData(wearableResult.fitbitResponse["activities-heart"]);
        break;
      case "fat":
        parseData(wearableResult.fitbitResponse["body-fat"]);
        break;
      case "weight":
        parseData(wearableResult.fitbitResponse["body-weight"]);
        break;
    }
  }, [wearableResult])


  // useEffect(() => {
  //   let date = new Date()
  //   let newDate = dateToYYYYMMDD(date)
  //   wearableService.getData(newDate).subscribe((res) => {
  //     alert(res)
  //     if (res) {
  //       setWearablesData(res)
  //     }
  //     // console.log(res)
  //   })
  // }, [])

  useEffect(() => {
    if (localStorage.getItem('selected user circle') && measureType !== 0) {
      let userCircle = JSON.parse(localStorage.getItem('selected user circle') || 'null')
      wearableService
        .getFilteredItems(
          new Query({
            query: [
              new QueryParam<WearableQuery>('userID', userCircle.user.id),
              new QueryParam<WearableQuery>('startDate', fromDate.toISOString()),
              new QueryParam<WearableQuery>('finishDate', toDate.toISOString()),
              new QueryParam<WearableQuery>('type', measureType),
            ],
          }),
        )
        .subscribe((res) => {
          setWearableResult(res)
        })
    }
  }, [measureType, fromDate, toDate])


  const goBack = () => navigate(ROUTE_PATIENT_DATA)

  const parseData = (data: any) => {
    let array1: any[] = []
    let array2: any[] = []
    data.forEach((obj:any) => {
      array1.push(obj.dateTime)
      array2.push(obj.value)
    })
    setDataGraphX(array1)
    setDataGraphY(array2)
  }
  const handleFromDateChange = (date: any) => {
    if (date) {
      setFromDate(date.toDate())
    }
  }

  const handleToDateChange = (date: any) => {
    if (date) {
      setToDate(date.toDate())
    }
  }

  const measurementUnit = (): string => {
    switch (tabValue + 1) {
      case WearableType.Steps:
        return t('steps')
      case WearableType.Calories:
        return 'kcal'
      case WearableType.Distance:
        return t('meters')
      case WearableType.SleepHours:
        return t('hours')
      case WearableType.RestingPulse:
        return t('pulse')
      case WearableType.OxygenSaturation:
        return '%'
      case WearableType.Weight:
        return 'kg'
      case WearableType.Fat:
        return '%'
      case WearableType.BloodPressure:
        return 'mmHG'
      case WearableType.Temperature:
        return '°C'
      default:
        return ''
    }
  }


  const chartTypeByWearables = (): string => {
    switch (tabValue + 1) {
      case WearableType.BloodPressure:
        return 'line'
      case WearableType.Weight:
        return 'line'
      default:
        return 'bar'
    }
  }


  const checkData = (data: string): string => {
    if (data) {
      return data
    }
    return ''
  }

  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          flex: 1,
          position: 'sticky',
          top: '0',
          backgroundColor: '#ffffff',
          zIndex: 100,
          borderBottom: '3px solid #f18b24',
        }}
      >
        <Header divider={true} label={t(type)} />
        <>
          <p>{t('from')}:</p>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
            <DatePicker
              key={'date'}
              PopperProps={{
                sx: {
                  '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                    fontFamily: 'Poppins',
                    textTransform: 'capitalize',
                  },
                  '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                    fontFamily: 'Poppins',
                  },
                  '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                    fontFamily: 'Poppins',
                  },
                  '& .css-3eghsz-PrivatePickersYear-button': {
                    fontFamily: 'Poppins',
                  },
                },
              }}
              // onError={(reason, value) => {
              //   switch (reason) {
              //     case 'invalidDate':
              //       setDateTimePickerError(t('invalidDateMessage'))
              //       break
              //     case 'minDate':
              //       setDateTimePickerError(t('minDateMessage'))
              //       break
              //   }
              // }}
              renderInput={(props) => (
                <TextField
                  style={{ width: '20%' }}
                  sx={{
                    '.css-1ptx2yq-MuiInputBase-root-MuiInput-root': { paddingRight: '17px' },
                    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                      fontFamily: 'Poppins',
                    },
                  }}
                  variant={'outlined'}
                  // helperText={props.error && DateTimePickerError}
                  {...props}
                />
              )}
              inputFormat="DD/MM/YYYY"
              value={fromDate}
              onChange={handleFromDateChange}
            />
          </LocalizationProvider>
          <p>{t('to')}:</p>

          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
            <DatePicker
              key={'date'}
              PopperProps={{
                sx: {
                  '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                    fontFamily: 'Poppins',
                    textTransform: 'capitalize',
                  },
                  '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                    fontFamily: 'Poppins',
                  },
                  '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                    fontFamily: 'Poppins',
                  },
                  '& .css-3eghsz-PrivatePickersYear-button': {
                    fontFamily: 'Poppins',
                  },
                },
              }}
              // onError={(reason, value) => {
              //   switch (reason) {
              //     case 'invalidDate':
              //       setDateTimePickerError(t('invalidDateMessage'))
              //       break
              //     case 'minDate':
              //       setDateTimePickerError(t('minDateMessage'))
              //       break
              //   }
              // }}
              renderInput={(props) => (
                <TextField
                  style={{ width: '20%' }}
                  sx={{
                    '.css-1ptx2yq-MuiInputBase-root-MuiInput-root': { paddingRight: '17px' },
                    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                      fontFamily: 'Poppins',
                    },
                  }}
                  variant={'outlined'}
                  // helperText={props.error && DateTimePickerError}
                  {...props}
                />
              )}
              inputFormat="DD/MM/YYYY"
              value={toDate}
              onChange={handleToDateChange}
            />
          </LocalizationProvider>
          <Grid item xs={3} display={"flex"} paddingLeft={"30px"} justifyContent={"end"}>
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'button'}
              label={t('back')}
              handler={returnToWearables}
            />
          </Grid>
        </>
      </Box>
      <Box paddingTop={"50px"}>
        <Grid item display={"flex"} padding={"15px"} flexDirection={"column"} border={"1px solid rgb(104 213 231)"} borderRadius={"17px"} alignItems={"center"} justifyContent={"center"}>
          {isBarChart ?
            <BarChart
              colors={["#f18b24"]}
              grid={{ horizontal: true }}
              xAxis={[{
                scaleType: 'band',
                data: dataGraphX,
              }]}
              // yAxis={[{ label: yAxisLabel, labelStyle: {color: "red"} }]}
              series={[{ data: dataGraphY }]}
              width={1100}
              height={400}
            />
            :
            <LineChart
              colors={["#f18b24"]}
              disableLineItemHighlight={false}
              grid={{ horizontal: true }}
              xAxis={[{ scaleType: 'band', data: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio'] }]}
              series={[
                { curve: 'linear', data: [1, 5, 2, 6, 3, 9.3] },
              ]}
              width={1000}
              height={400}
            />}
        </Grid>
      </Box>
      {type==="bloodPreasure" &&
        <>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginTop: "50px",
              flex: 1,
              position: 'sticky',
              top: '0',
              backgroundColor: '#ffffff',
              zIndex: 100,
              borderBottom: '3px solid #f18b24',
            }}
          >
            <Header divider={true} label={t("spo2-title")} />
          </Box>
          <Box paddingTop={"50px"}>
            <Grid item display={"flex"} padding={"15px"} flexDirection={"column"} border={"1px solid rgb(104 213 231)"} borderRadius={"17px"} alignItems={"center"} justifyContent={"center"}>
              <LineChart
                colors={["#f18b24"]}
                disableLineItemHighlight={false}
                grid={{ horizontal: true }}
                xAxis={[{ scaleType: 'band', data: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio'] }]}
                series={[
                  { curve: 'linear', data: [80, 90, 93, 88, 83, 89.3] },
                ]}
                width={1000}
                height={400}
              />
            </Grid>
          </Box>
        </>
      }
    </>
  )
}
