import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Card, Grid } from '@mui/material'
import styles from './PatientData.module.css'
import { useNavigate } from 'react-router-dom'
import icoCartilla from '../../assets/esfera/patient-data/book.svg'
import icoCartillaHover from '../../assets/esfera/patient-data/hover-book.svg'
import icoEvolutivo from '../../assets/esfera/patient-data/evolutionary-control.svg'
import icoEvolutivoHover from '../../assets/esfera/patient-data/evolutionary-control-hover.svg'
import icoSintomaHover from '../../assets/esfera/patient-data/disease-hover.svg'
import icoTratamientoHover from '../../assets/esfera/patient-data/medication-hover.svg'
import icoVacunasHover from '../../assets/esfera/patient-data/calendar-hover.svg'
import icoSaludbucalHover from '../../assets/esfera/patient-data/dentist-hover.svg'
import icoWearablesHover from '../../assets/esfera/patient-data/wearable-hover.svg'
import icoSintoma from '../../assets/esfera/patient-data/disease.svg'
import icoTratamiento from '../../assets/esfera/patient-data/medication.svg'
import icoVacunas from '../../assets/esfera/patient-data/calendar.svg'
import icoSaludbucal from '../../assets/esfera/patient-data/dentist.svg'
import icoWearables from '../../assets/esfera/patient-data/wearable.svg'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { Permission } from '../../common/permission'
import iconTraining from '../../assets/active_plan_icons/training.svg'
import iconTrainingHover from '../../assets/active_plan_icons/training_hover.svg'
import { ActivePlanStatusType } from '../../common/enums/activePlan'
import { ActivePlan } from '../../modules/active-plan/models/ActivePlan'
import { getActivePlanContainer } from '../../container/active-plan-module'
import { ActivePlanService } from '../../modules/active-plan/services/ActivePlanService'
import { ACTIVE_PLAN_SERVICE_KEY } from '../../modules/active-plan'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { IUserCircleActiveService, USER_CIRCLE_ACTIVE_SERVICE_KEY } from '../../modules/user-circle'

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const activePlanService = getActivePlanContainer().get<ActivePlanService>(ACTIVE_PLAN_SERVICE_KEY)
const userCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)

type PatientDataItem = {
  name: string
  permission: Permission
  icon: string
  hoverIcon: string
}

export function PatientData() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const patientDataItems: PatientDataItem[] = [
    {
      name: 'medicalData',
      permission: Permission.seePatientData,
      icon: icoCartilla,
      hoverIcon: icoCartillaHover,
    },
    {
      name: 'evolutionControl',
      permission: Permission.seeEvolutionControl,
      icon: icoEvolutivo,
      hoverIcon: icoEvolutivoHover,
    },
    {
      name: 'symptoms',
      permission: Permission.seePatientData,
      icon: icoSintoma,
      hoverIcon: icoSintomaHover,
    },
    {
      name: 'treatments',
      permission: Permission.seePatientData,
      icon: icoTratamiento,
      hoverIcon: icoTratamientoHover,
    },
    {
      name: 'vaccinationSchedule',
      permission: Permission.seeVaccinationCalendar,
      icon: icoVacunas,
      hoverIcon: icoVacunasHover,
    },
    {
      name: 'oralHealth',
      permission: Permission.seeDentists,
      icon: icoSaludbucal,
      hoverIcon: icoSaludbucalHover,
    },
    {
      name: 'wearables',
      permission: Permission.seeWearable,
      icon: icoWearables,
      hoverIcon: icoWearablesHover,
    },
    {
      name: 'activePlan',
      permission: Permission.seeWearable, // TODO
      icon: iconTraining,
      hoverIcon: iconTrainingHover,
    },
  ]

  const handleOnClick = (type: string) => navigate(`./${type}`)
  const [activeItemName, setActiveItemName] = React.useState<string | null>(null)
  const [activePlan, setActivePlan] = useState<ActivePlan[]>()

  useEffect(() => {
    activePlanService
      .getByUserCircleID(userCircleActiveService.getActiveFullUserCircle()?.id ?? '')
      .subscribe((res) => {
        if (!res) return
        setActivePlan(res)
      })
  }, [])

  const checkPermissionActivePlan = (patientData: PatientDataItem): boolean => {
    if (patientData.permission === Permission.seeActivePlan) {
      if (
        activePlan?.find(
          (value) =>
            value?.status === ActivePlanStatusType.Active ||
            value?.status === ActivePlanStatusType.Refused ||
            value?.status === ActivePlanStatusType.Pending
        )
      ) {
        return true
      }
      return false
    }
    return loggedUserService.userCan(patientData.permission)
  }

  return (
    <Box className={styles.centerBlock2}>
      <Grid container spacing={2}>
        {patientDataItems
          .filter((i) => checkPermissionActivePlan(i))
          .map((item) => (
          ((item.name === 'activePlan' && JSON.parse(localStorage.getItem("selected user circle") || "null").circle.id === '1113') || (item.name !== 'activePlan')) &&
            <Grid item xs={6} md={3} key={item.name}>
              <Card
                className={styles.hoverCard}
                sx={{ borderRadius: 5, borderColor: '#68b3e0' }}
                variant="outlined"
                onClick={() => handleOnClick(item.name)}
                onMouseEnter={() => setActiveItemName(item.name)}
                onMouseLeave={() => setActiveItemName(null)}
              >
                <div className={styles.iconBlock}>
                  <img
                    src={activeItemName === item.name ? item.hoverIcon : item.icon}
                    alt={item.name}
                  />
                </div>
                <div>
                  <h2 className={styles.textBlock} style={{ whiteSpace: 'pre-line' }}>
                    {t(item.name)}
                    <hr className={styles.horizontalLine} />
                  </h2>
                </div>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Box>
  )
}
