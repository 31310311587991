// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Remover_row__bAC2S {\n  border-bottom: 1px solid var(--color-gray);\n}\n\n.Remover_label__EbUZQ {\n  font-weight: bold !important;\n}\n\n.Remover_cell__36OEI {\n  padding-left: 30px;\n}\n", "",{"version":3,"sources":["webpack://src/features/form-generator/Remover.module.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;AAC5C;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".row {\n  border-bottom: 1px solid var(--color-gray);\n}\n\n.label {\n  font-weight: bold !important;\n}\n\n.cell {\n  padding-left: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "Remover_row__bAC2S",
	"label": "Remover_label__EbUZQ",
	"cell": "Remover_cell__36OEI"
};
export default ___CSS_LOADER_EXPORT___;
