import { RouteProps } from '../../routes/AppRouter'
import { useTranslation } from 'react-i18next'
import { ProfessionalTypeConfig as PT } from '../../features/patient-data'
import React from 'react'
import { useParams } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function ProfessionalConfig(props: RouteProps) {
  const { id } = useParams()
  const title = props.title || ''
  const { t } = useTranslation()

  return (
    <>
      <CurrentNavHeaderEsfera title={t(title)} />
      <PT />
    </>
  )
}
