import React, { useEffect, useRef, useState } from 'react'
import { Box, Modal } from '@mui/material'
import { ShortDescription } from './ShortDescription'
import { Message } from '../../modules/messenger_consulta/models/Message'
import { Searcher } from './Searcher'
import { Messages } from './Messages'
import { Header } from './Header'
import { Sender } from './Sender'
import 'dayjs'
import style from './Messenger.module.css'
import { CustomModal } from '../../components/modal/CustomModal'
import { File } from '../../modules/files/models/File'
import { reduceString } from '../../common/utils/strings'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { getMessengerContainer } from '../../container/messenger-module'
import { ConversationService } from '../../modules/messenger/services/ConversationService'
import { CONVERSATION_SERVICE_KEY } from '../../modules/messenger'
import { Query, QueryParam } from '../../common/api/Query'
import { ConversationEditor } from './ConversationEditor'
import { cloneDeep } from 'lodash'
import { useTranslation } from 'react-i18next'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import { SwitchCustom } from './SwitchStyle'
import { getMessengerConsultaContainer } from '../../container/messenger-consulta-module'
import { CONSULTA_SERVICE_KEY } from '../../modules/messenger_consulta'
import { ConsultaService } from '../../modules/messenger_consulta/services/ConsultaService'
import { Consulta } from '../../modules/messenger_consulta/models/Consulta'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { IUserCircleActiveService, USER_CIRCLE_ACTIVE_SERVICE_KEY } from '../../modules/user-circle'

const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const messengerContainer = getMessengerContainer()
const conversationsService = messengerContainer.get<ConversationService>(CONVERSATION_SERVICE_KEY)
const queryService = getMessengerConsultaContainer().get<ConsultaService>(CONSULTA_SERVICE_KEY)

export type QueryProps = {
  conversationID?: string
}
const UserCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)
export function QueryView(props: QueryProps): JSX.Element {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const activeUser = UserCircleActiveService.getActiveFullUserCircle()
  const [currentConversation, setCurrentConversation] = useState<Consulta>()
  const [chatList, setChatList] = useState<JSX.Element[]>([])
  const [conversationCollection, setConversationCollection] = useState<Consulta[]>([])
  const previousConversation = usePreviousConversation() ?? currentConversation
  const [originalConversations, setOriginalConversations] = useState<Consulta[]>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [showClosedQuery, setShowClosedQuery] = useState<boolean>(false)
  const [isAdd, setIsAdd] = useState<boolean>(false)

  useEffect(() => {
    if (props.conversationID) {
      queryService.getByID(props.conversationID).subscribe((res) => {
        if (res) {
          setCurrentConversation(res)
        }
      })
    }
  }, [])

  useEffect(() => {
    if (!isLoading || !loggedUser?.id) {
      return
    }
    queryService
      .getFilteredItems(
        new Query({
          query: [
            new QueryParam<Consulta>('userID', activeUser?.user.id ?? ''),
            new QueryParam<Consulta>('circleID', activeUser?.circle?.id ?? ''),
          ],
        })
      )
      .subscribe((res) => {
        setChatList(res.map((c) => conversationToShortDescription(c)))
        setConversationCollection(res)
        setOriginalConversations(res)
        setIsLoading(false)
      })
  }, [isLoading])

  useEffect(() => {
    if (!currentConversation) {
      setConversationCollection([...conversationCollection])
    }
  }, [currentConversation])

  function usePreviousConversation() {
    const ref = useRef(currentConversation)
    useEffect(() => {
      ref.current = currentConversation
    })
    return ref.current
  }

  const conversationToShortDescription = (conversation: Consulta) => {
    const messages = orderMessagesByCreationDate(conversation.messages)
    const lengthLimit = 70
    const lastMessage =
      conversation.messages.length === 0
        ? ''
        : reduceString(messages[messages.length - 1].text, lengthLimit)
    const conversationName = reduceString(conversation.name, lengthLimit)
    return showClosedQuery ? (
      <ShortDescription
        key={conversation.id}
        conversationID={conversation.id}
        conversationName={conversationName}
        conversationDescription={conversation.description}
        avatarUrl={'avatarUrl'}
        description={conversation.description}
        lastMessage={lastMessage}
        notReadMessages={conversation.unreadMessages}
        handlerConversation={handleShortDescription}
        isCurrentConversation={conversation.id === currentConversation?.id}
      />
    ) : !conversation.closed ? (
      <ShortDescription
        key={conversation.id}
        conversationID={conversation.id}
        conversationName={conversationName}
        conversationDescription={conversation.description}
        avatarUrl={'avatarUrl'}
        description={conversation.description}
        lastMessage={lastMessage}
        notReadMessages={conversation.unreadMessages}
        handlerConversation={handleShortDescription}
        isCurrentConversation={conversation.id === currentConversation?.id}
      />
    ) : (
      <></>
    )
  }

  const orderMessagesByCreationDate = (messages: Message[]): Message[] => {
    return [...messages].sort((m1, m2) => m1.createdAt.getTime() - m2.createdAt.getTime())
  }

  const handleShortDescription = (conversationID: string | undefined) => {
    const conversation = conversationCollection.find((c) => c.id === conversationID)
    if (conversation) {
      conversation.unreadMessages = 0
      // FIXME problem with async setState currentConversation
      setCurrentConversation(conversation)
      setIsLoading(true)
    }
  }
  useEffect(() => {
    if (!currentConversation) return
    queryService.getByID(currentConversation.id ?? '').subscribe((res) => {
      setCurrentConversation(res)
    })
  }, [isAdd])

  useEffect(() => {
    replaceChatList()
  }, [currentConversation])

  const replaceChatList = () => {
    if (!currentConversation || !previousConversation) return

    const mapResult = [...chatList]
    replaceConversation(mapResult, previousConversation)
    replaceConversation(mapResult, currentConversation)

    setChatList(mapResult)
  }

  const replaceConversation = (conversationList: JSX.Element[], conversation: Consulta) => {
    const index = conversationCollection.findIndex((c) => c.id === conversation.id)
    conversationList.splice(index, 1, conversationToShortDescription(conversation))
  }

  useEffect(() => {
    const mapResult = [...conversationCollection].map((c) => conversationToShortDescription(c))
    setChatList(mapResult)
    replaceOriginalConversations()
  }, [conversationCollection])

  const replaceOriginalConversations = () => {
    if (originalConversations) {
      const newOriginalConversations = [...originalConversations]
      conversationCollection.forEach((c) => {
        const index = newOriginalConversations.findIndex((o) => o.id === c.id)
        if (index !== -1) {
          newOriginalConversations.splice(index, 1, c)
        }
      })
      setOriginalConversations(newOriginalConversations)
    }
  }

  const handleSearch = (s: string) => {
    const filteredConversations = originalConversations?.filter((c) => {
      const name = c.name.toLowerCase()
      return name.includes(s.toLowerCase())
    })
    if (filteredConversations) {
      setConversationCollection(filteredConversations)
    }
  }

  const handleSender = (m: Message, f: File[]) => {
    if (currentConversation) {
      f.forEach((file) => m.addFile(file.id ?? ''))
      const conversation = cloneDeep<Consulta>(currentConversation)

      conversation.addMessage(m)
      // conversationsService.update(conversation).subscribe((_) => {
      setCurrentConversation(conversation)
      replaceConversationCollection(conversation)
      // })
      queryService.addMessage(m).subscribe()
    }
  }

  function replaceConversationCollection(conversation: Consulta) {
    const conversations = [...conversationCollection]

    const index = conversations.findIndex((c) => c.id === conversation.id)
    conversations.splice(index, 1, conversation)

    setConversationCollection(conversations)
  }

  const reverseConversationCollection = () => {
    setConversationCollection([...conversationCollection.reverse()])
  }

  const handleNewConversation = (): void => {
    setCurrentConversation(undefined)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleSaveModal = (c: Consulta, isUpdate: boolean) => {
    setOpenModal(false)
    setCurrentConversation(undefined)
    setIsAdd(false)
    queryService.add(c).subscribe((res) => {
      if (res) {
        if (originalConversations) setOriginalConversations([...originalConversations, res])
        setConversationCollection([...conversationCollection, res])
        setCurrentConversation(res)
        setIsAdd(true)
      }
    })
  }

  const handleCloseQuery = () => {
    if (currentConversation) {
      currentConversation.closed = !currentConversation.closed
      queryService.update(currentConversation).subscribe((res) => {
        setIsLoading(true)
      })
    }
  }

  const handleEditConversation = () => {
    setOpenModal(true)
  }

  const handleDeleteConversation = () => {
    setOpenDeleteModal(true)
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handleDeleteEvent = () => {
    if (!currentConversation?.id) {
      return
    }
    conversationsService.delete(currentConversation.id).subscribe((_) => {
      setIsLoading(true)
      setOriginalConversations(
        originalConversations?.filter((c) => c.id !== currentConversation.id)
      )
      setConversationCollection(
        conversationCollection.filter((c) => c.id !== currentConversation.id)
      )
      setCurrentConversation(undefined)
    })
    setOpenModal(false)
    setOpenDeleteModal(false)
    setIsLoading(true)
  }

  return (
    <>
      {!isLoading ? (
        <div className={style.messengerContainer}>
          <Box component="section" width="30%" className={style.conversationWidget}>
            <Box
              className={style.searcherContainer}
              component="section"
              display="flex"
              justifyContent="space-between"
            >
              <Searcher
                handler={handleSearch}
                reverse={reverseConversationCollection}
                handleNewConversation={handleNewConversation}
              />
            </Box>
            <Box className={style.switchButtonContainer}>
              <p>Mostrar consultas cerradas</p>
              <SwitchCustom
                checked={showClosedQuery}
                onClick={() => {
                  setShowClosedQuery(!showClosedQuery)
                  setIsLoading(true)
                }}
              />
            </Box>
            <Box>
              <Box className={style.blueLine} />
            </Box>
            <Box className={style.chatList} style={{ maxHeight: '50vh' }} component="section">
              {chatList}
            </Box>
          </Box>
          {currentConversation && (
            <Box
              className={style.messengerWidget}
              component="section"
              width="70%"
              display="flex"
              flexDirection="column"
            >
              <Box className={style.headerContainer}>
                <Header
                  headerName={currentConversation.name || ''}
                  avatarUrl={undefined}
                  creatorID={currentConversation.users.find((u) => !u.isAdmin)?.userID ?? ''}
                  description={currentConversation.description || ''}
                  handleEdit={handleEditConversation}
                  handleDelete={handleDeleteConversation}
                  handleCloseQuery={handleCloseQuery}
                  currentConversation={currentConversation}
                  conversationUsers={currentConversation.users.map((cu) => cu.userID)}
                />
              </Box>
              <Box className={style.messagesContainer} flexGrow={1}>
                <Messages messages={currentConversation.messages || []} />
              </Box>
              <Box>
                <Sender
                  handler={handleSender}
                  conversationId={currentConversation.id ?? ''}
                  conversationClosed={currentConversation.closed}
                />
              </Box>
            </Box>
          )}
          <Modal
            open={openDeleteModal}
            className={style.eventModal}
            onClose={handleCloseDeleteModal}
          >
            <CustomModal
              handleClose={handleCloseDeleteModal}
              handleSave={handleDeleteEvent}
              title={t('deleteConversation')}
              warningText={t('irreversibleConversationAction')}
            />
          </Modal>
          {!currentConversation && (
            <Box className={style.emptyConversation}>
              <h1 className={style.labelCreateOrSelectConversation}>
                {t('createOrSelectConversation')}
              </h1>
            </Box>
          )}
        </div>
      ) : null}
      <Modal
        className={style.modalNewConversation}
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ConversationEditor
          query={currentConversation}
          handleClose={handleCloseModal}
          handleSave={handleSaveModal}
        />
      </Modal>
    </>
  )
}
