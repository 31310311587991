import { v4 as uuidv4 } from 'uuid'

export type PathologyProps = {
  id?: string
  name: string
  symptoms: string[]
  typeID: string
  description: string
}

export class Pathology {
  private readonly _id: string
  private readonly _name: string
  private readonly _symptoms: string[]
  private readonly _typeID: string
  private readonly _description: string

  constructor(p: PathologyProps) {
    this._id = p.id || uuidv4()
    this._name = p.name
    this._symptoms = p.symptoms
    this._typeID = p.typeID
    this._description = p.description
  }

  get id(): string {
    return this._id
  }

  get name(): string {
    return this._name
  }

  get symptoms(): string[] {
    return this._symptoms
  }
  get typeID(): string {
    return this._typeID
  }

  get description(): string {
    return this._description
  }
}

export interface PathologyQuery {
  id: string
  name: string
  userID: string
  typeID: string
  description: string
}
