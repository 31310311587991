import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import {
  ARTICLE_API_KEY,
  ARTICLE_SERVICE_KEY,
  CONTENT_MODULE,
  ContentProps,
  TAG_API_KEY,
  TAG_SERVICE_KEY,
  LIKES_API_KEY,
  LIKES_SERVICE_KEY,
  VISUALIZATIONS_SERVICE_KEY,
  VISUALIZATIONS_API_KEY,
  COMMENTS_SERVICE_KEY,
  COMMENTS_API_KEY,
} from '../modules/content/container'
import { ArticleApi } from '../modules/content/api/ArticleApi'
import { ArticleService } from '../modules/content/services/ArticleService'
import { TagService } from '../modules/content/services/TagService'
import { TagApi } from '../modules/content/api/TagApi'
import { LikesApi } from '../modules/content/api/LikesApi'
import { LikesService } from '../modules/content/services/LikesService'
import { VisualizationService } from '../modules/content/services/VisualizationService'
import { CommentsService } from '../modules/content/services/CommentsService'
import { VisualizationsApi } from '../modules/content/api/VisualizationsApi'
import { CommentsApi } from '../modules/content/api/CommentsApi'

let container: Container

function init(p: ContentProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(ARTICLE_API_KEY, new ContainerItem({ instance: new ArticleApi() }))
  items.set(TAG_API_KEY, new ContainerItem({ instance: new TagApi() }))
  items.set(LIKES_API_KEY, new ContainerItem({ instance: new LikesApi() }))
  items.set(VISUALIZATIONS_API_KEY, new ContainerItem({ instance: new VisualizationsApi() }))
  items.set(COMMENTS_API_KEY, new ContainerItem({ instance: new CommentsApi() }))

  items.set(
    ARTICLE_SERVICE_KEY,
    new ContainerItem({ instance: new ArticleService({ apiKey: ARTICLE_API_KEY }) })
  )
  items.set(
    TAG_SERVICE_KEY,
    new ContainerItem({ instance: new TagService({ apiKey: TAG_API_KEY }) })
  )
  items.set(
    LIKES_SERVICE_KEY,
    new ContainerItem({ instance: new LikesService({ apiKey: LIKES_API_KEY }) })
  )
  items.set(
    VISUALIZATIONS_SERVICE_KEY,
    new ContainerItem({ instance: new VisualizationService({ apiKey: VISUALIZATIONS_API_KEY }) })
  )
  items.set(
    COMMENTS_SERVICE_KEY,
    new ContainerItem({ instance: new CommentsService({ apiKey: COMMENTS_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getContentContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[CONTENT_MODULE],
      },
    })
  }

  return container
}
