import { Box, Modal } from '@mui/material'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import { AppTable, Field } from '../../../components/table'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATIENT_DATA } from '../../../routes/routes-constants'
import { Treatment, TreatmentQuery } from '../../../modules/patient-data/models/Treatment'
import { Actions, Pager } from '../../../components/table_type/types'
import { useTranslation } from 'react-i18next'
import { Query, QueryParam } from '../../../common/api/Query'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { getUserContainer } from '../../../container/user-module'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../../modules/users'
import { getPatientDataContainer } from '../../../container/patient-data-module'
import { TREATMENT_SERVICE_KEY } from '../../../modules/patient-data'
import { TreatmentService } from '../../../modules/patient-data/services/TreatmentService'
import { reduceString } from '../../../common/utils/strings'
import genericStyle from '../../../common/utils/generic.module.css'
import { LoggedUserService } from '../../../modules/users/services/LoggedUserService'
import { forkJoin, Observable } from 'rxjs'
import { User } from '../../../modules/users/models/User'
import editIcon from '../../../assets/table_icons/ico-edit.svg'
import editIconBlue from '../../../assets/table_icons/ico-edit-hover.svg'
import deleteIcon from '../../../assets/table_icons/ico-eliminar.svg'
import deleteIconBlue from '../../../assets/table_icons/ico-eliminar-hover.svg'
import { CustomModal } from 'components/modal/CustomModal'
import { Permission } from '../../../common/permission'
import { useIsRelated } from 'hooks/relatedUsers/getRelatedUsers'
import { getUserCircleContainer } from '../../../container/user-circle-module'
import {
  IUserCircleActiveService,
  USER_CIRCLE_ACTIVE_SERVICE_KEY,
} from '../../../modules/user-circle'
import { ModalAddTreatments } from './ModalAddtreatments/ModalAddTreatments'

const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)

const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const patientDataContainer = getPatientDataContainer()
const treatmentService = patientDataContainer.get<TreatmentService>(TREATMENT_SERVICE_KEY)
const UserCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)

export function Table() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const activeUser = UserCircleActiveService.getActiveFullUserCircle()
  const { innerWidth } = window

  const loggedUser = loggedUserService.get()
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openAddModal, setOpenAddModal] = useState<boolean>(false)
  const [currentTreatment, setCurrentTreatment] = useState<Treatment>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [count, setCount] = useState<number>(0)
  const [treatments, setTreatments] = useState<ItemList<Treatment>>(emptyList<Treatment>())
  const [treatmentsPerPage, setTreatmentsPerPage] = useState<number>(10)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [users, setUsers] = useState<Map<string, string>>(new Map())
  const [editTreatmentID, setEditTreatmentID] = useState<string>()

  const getUsers = (ids: string[]): Observable<User[]> =>
    forkJoin(ids.map((id) => userService.getByID(id))) as unknown as Observable<User[]>

  useEffect(() => {
    if (!activeUser?.id) {
      return
    }
    treatmentService
      .getFilteredList(
        new Query({
          query: [new QueryParam<TreatmentQuery>('userCircleID', activeUser?.id)],
          pager: { limit: treatmentsPerPage, offset: page * treatmentsPerPage },
          sort: [{ field: 'date', desc: true }],
        })
      )
      .subscribe((res) => {
        setCount(res.count)
        setTreatments(res)
        getUsers(res.items.map((s) => s.creatorID)).subscribe((ul) => {
          const newMap = new Map()

          ul.filter((u) => u).forEach((u) => {
            newMap.set(u.id, u.name)
          })
          setUsers(newMap)
        })
      })
    setIsLoading(false)
  }, [isLoading, openAddModal])

  const goBack = () => navigate(ROUTE_PATIENT_DATA)

  const editTreatment = (t: Treatment) => {
    setEditTreatmentID(t.id)
    setOpenAddModal(true)
  }

  const removeTreatment = (t: Treatment) => {
    setCurrentTreatment(t)
    setOpenDeleteModal(true)
  }

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setTreatmentsPerPage(10)
      return
    }
    setTreatmentsPerPage(Number.parseInt(event.target.value))
  }

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: treatmentsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, treatmentsPerPage])

  const isCreator = (t: Treatment): boolean => t.creatorID === loggedUser?.id

  const fields: Field<Treatment>[] = [
    {
      name: 'name',
      label: t('name'),
    },
    {
      name: 'date',
      label: t('startDate'),
      renderFunc: (f, i) =>
        new Date(i.date).toLocaleDateString('es', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
    },
    {
      name: 'endDate',
      label: t('finishDate'),
      renderFunc: (f, i) => {
        if (!i.endDate) return ''
        if (new Date(i.endDate) < new Date(1910, 1)) return 'Actualmente'
        return new Date(i.endDate).toLocaleDateString('es', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })
      },
    },
    {
      name: 'duration',
      label: t('duration'),
    },
    {
      name: 'frequency',
      label: t('frequency'),
    },
    {
      name: 'actualDose',
      label: t('dose'),
      renderFunc: (f, i) => i.actualDose.toString() ?? '',
    },
    {
      name: 'reason',
      label: t('reason'),
      renderFunc: (f, i) => reduceString(i.reason ?? '', 100),
    },
    {
      name: 'creatorID',
      label: t('creator'),
      renderFunc: (f, i) => users.get(i.creatorID) ?? '',
    },
  ]

  const actions: Actions<Treatment> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: editTreatment,
        icon: innerWidth > 598 ? editIcon : editIconBlue,
        label: 'edit',
        hidden: useIsRelated() ? (t) => !isCreator(t) : (t) => true,
      },
      {
        handler: removeTreatment,
        icon: innerWidth > 598 ? deleteIcon : deleteIconBlue,
        label: 'delete',
        hidden: useIsRelated() ? (t) => !isCreator(t) : (t) => true,
      },
    ],
  }

  const handleCloseDeleteModal = () => setOpenDeleteModal(false)

  const handleCloseAddModal = () => setOpenAddModal(false)
  const handleOpenAddModal = () => {
    setEditTreatmentID(undefined)
    setOpenAddModal(true)
  }

  const handleDeleteTreatment = () => {
    if (currentTreatment?.id)
      treatmentService.delete(currentTreatment.id).subscribe((_) => setIsLoading(true))
    setOpenDeleteModal(false)
    setIsLoading(true)
  }

  return (
    <Box className={genericStyle.pageContainer}>
      <Box mb={3} display="flex" justifyContent="space-between">
        <AppButton
          theme={ButtonTheme.NewSecondary}
          type={'button'}
          label={t('back')}
          handler={goBack}
        />
        {loggedUserService.userCan(Permission.registerTreatments) && (
          <AppButton
            theme={ButtonTheme.NewPrimary}
            type={'button'}
            label={t('createTreatment')}
            handler={handleOpenAddModal}
          />
        )}
      </Box>
      <Box>
        <ModalAddTreatments
          id={editTreatmentID}
          open={openAddModal}
          onClose={handleCloseAddModal}
        />
        <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
          <>
            <CustomModal
              handleClose={handleCloseDeleteModal}
              handleSave={handleDeleteTreatment}
              title={t('deleteTreatment')}
              warningText={t('irreversibleTreatmentAction')}
            />
          </>
        </Modal>
        <AppTable
          items={treatments.items}
          rowKeyField="id"
          fields={fields}
          actions={actions}
          pager={pager}
          fieldsInDraggable={true}
        />
      </Box>
    </Box>
  )
}
